import Background from "Components/Atoms/Background/background";
import Space from "Components/Atoms/Space/space";
import { modalToggle } from "Store/Slices/modal";
import { useAppDispatch } from "Store/store";
import { ReactComponent as ModalX } from "Util/svg/modalX.svg";

const ImgModal = ({ url }: { url: string }) => {
  const dispatch = useAppDispatch();
  console.log(url);
  return (
    <Background modalNum={9}>
      <div
        style={{ maxWidth: "800px", maxHeight: "800px" }}
        className="p-32 overflow-auto bg-f2f3f8"
      >
        <div
          onClick={() => dispatch(modalToggle(9))}
          className="flex justify-end cursor-pointer"
        >
          <ModalX />
        </div>
        <Space y={20} />
        <a href={url} target="_blank" rel="noreferrer">
          <img className="w-full" src={url} alt="" />
        </a>
      </div>
    </Background>
  );
};

export default ImgModal;
