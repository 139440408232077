import Input from "Components/Atoms/Input/input";
import Label from "Components/Atoms/Label/label";
import Space from "Components/Atoms/Space/space";
import { ReactComponent as Require } from "Util/svg/require.svg";
import { ReactComponent as Check } from "Util/svg/check.svg";
import { ReactComponent as Fail } from "Util/svg/fail.svg";
import Button from "Components/Atoms/Button/button";
import { useState } from "react";
import { getCsOverLapApi } from "Apis/apis";

/**
 * 로그인페이지 : ID PW 인풋창
 */
const InputRowId = ({
  id,
  name,
  value,
  setValue,
  check,
  setCheck,
  require = true,
  disable = false,
}: {
  id: string;
  name: string;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  check: boolean;
  setCheck: React.Dispatch<React.SetStateAction<boolean>>;
  require?: boolean;
  disable?: boolean;
}) => {
  /**
   * 아이디 중복여부 검사
   */

  const emailReg =
    /^[A-Za-z0-9]([-_.]?[A-Za-z0-9])*@[A-Za-z0-9]([-_.]?[A-Za-z0-9])*\.[A-Za-z]{2,3}$/;
  const overlap = async () => {
    if (value.match(emailReg) === null) {
      alert("잘못된 이메일 형식입니다.");
      return setCheck(false);
    }

    if (value === "") alert("아이디를 입력 해주세요");

    const { data } = await getCsOverLapApi(value);
    if (data.valid_id) {
      alert(data.message);
      setCheck(data.valid_id);
    } else {
      alert(data.message);
      setCheck(data.valid_id);
    }
  };

  const inputBox = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  return (
    <div className="flex items-center">
      <div className="flex items-center px-16 py-20 bg-e5e6ed w-130">
        <Label labelfor={id} name={name} fontSize={16} />
        <Space x={4} />
        <Require />
      </div>
      <div className="flex-1 h-full bg-white p-13">
        <div className="flex items-center">
          <div className="w-300">
            <Input
              id={id}
              placeholder="이메일을 입력해주세요."
              value={value}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => inputBox(e)}
              disabled={disable}
              type="email"
              required={true}
            />
          </div>
          {disable ? null : (
            <>
              <div className="-ml-30">{check ? <Check /> : <Fail />}</div>
              <Space x={20} />
              <Button onClick={overlap} size="md" btnText="중복체크" />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default InputRowId;
