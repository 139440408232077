import { Switch, Table } from "antd";
import Button from "Components/Atoms/Button/button";
import SearchSelect from "Components/Molecules/SearchSelect/searchSelect";
import React, { useEffect, useState } from "react";
import useServiceNo from "Util/Hooks/useServiceIdx";
import { ColumnsType } from "antd/es/table";
import Space from "Components/Atoms/Space/space";
import { useSelector } from "react-redux";
import { ReducerType } from "Store/store";
import { useLocation, useNavigate } from "react-router-dom";
import LANG, { getLang } from "Util/lang";
import { AutoMail } from "types/interface";
import { getAutoMailApi, putAutoMailApi } from "Apis/apis";

const AutoAnswer = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const serviceList = useSelector((state: ReducerType) =>
    state.serviceList.filter((el) => el.state !== 2 && el.state !== 1),
  );
  const [serviceNo, setServiceNo] = useServiceNo();
  const [autoMailList, setAutoMailList] = useState<AutoMail[]>([]);
  const onSubmit = () => {
    navigate("", {
      state: { page: 1, serviceNo: serviceNo },
    });
  };
  const getAutoMail = async () => {
    const { serviceNo = -1 } = location.state || {};
    setServiceNo(serviceNo);
    const {
      data: { autoMail },
    } = await getAutoMailApi(serviceNo);
    setAutoMailList(autoMail);
  };
  const onClickSwitch = async (e: boolean, autoMail: AutoMail) => {
    const body = {
      state: e ? 0 : 1,
    };

    await putAutoMailApi(body, autoMail.mail_auto_no);

    setAutoMailList((_autoMail: AutoMail[]) => {
      const newAutoMail = _autoMail.map((mail: any) => {
        if (mail.mail_auto_no !== autoMail.mail_auto_no) return mail;

        const _mail = { ...mail };
        _mail.state = e ? 0 : 1;
        return _mail;
      });
      return newAutoMail;
    });
  };
  const columns: ColumnsType<AutoMail> = [
    {
      title: "서비스명",
      dataIndex: "service_name",
      align: "center",
      width: "200px",
    },
    {
      title: LANG[getLang()].title,
      dataIndex: "title",
      align: "center",
      render: (now, row) => {
        return (
          <span
            className="cursor-pointer"
            onClick={() =>
              navigate("/user/autofix", { state: row.mail_auto_no })
            }
          >
            {now}
          </span>
        );
      },
    },
    {
      title: "자동 응답",
      dataIndex: "state",
      align: "center",
      render: (now, row) => {
        return (
          <Switch
            checked={now ? false : true}
            checkedChildren="ON"
            unCheckedChildren="OFF"
            onChange={(e) => onClickSwitch(e, row)}
          />
        );
      },
    },
    {
      title: "배너 광고",
      dataIndex: "banner_count",
      align: "center",
      render: (now) => <span>{now}개</span>,
    },
    {
      title: LANG[getLang()].date,
      dataIndex: "date",
      align: "center",
      width: "200px",
    },
  ];
  useEffect(() => {
    getAutoMail();
  }, [location.state]);
  return (
    <div>
      <SearchSelect
        dataList={serviceList}
        onClick={onSubmit}
        setValue={setServiceNo}
        value={serviceNo}
      />
      <Space y={16} />
      <div className="max-w-7xl">
        <div className="flex justify-end">
          <Button
            btnText={`+ ${LANG[getLang()].register}`}
            onClick={() => navigate("/user/autoadd")}
          />
        </div>
        <Space y={24} />
        <Table
          dataSource={autoMailList}
          rowKey="mail_auto_no"
          columns={columns}
        />
      </div>
    </div>
  );
};

export default AutoAnswer;
