import { useState } from "react";

import Background from "Components/Atoms/Background/background";
import H2 from "Components/Atoms/H2/h2";
import Space from "Components/Atoms/Space/space";
import Button from "Components/Atoms/Button/button";
import { modalToggle } from "Store/Slices/modal";
import { useAppDispatch } from "Store/store";
import { ReactComponent as ModalX } from "Util/svg/modalX.svg";
import Input from "Components/Atoms/Input/input";
import { postPhoneCheckApi } from "Apis/apis";
import { useQueryClient } from "@tanstack/react-query";

/**
 * 모달 molecule
 *
 */
const MaskingModal = ({
  qnaNo,
  setMasking,
}: {
  qnaNo: number;
  setMasking: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const [reason, setReason] = useState("");

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (reason === "") {
      alert("사유를 입력해주세요.");
      return;
    }

    const body = {
      qnaNo,
      reason,
    };

    await postPhoneCheckApi(body);
    setMasking(false);
    dispatch(modalToggle(6));
    queryClient.invalidateQueries(["useGetQDetailApi", qnaNo]);
  };

  return (
    <Background modalNum={6}>
      <div style={{ width: "640px" }} className="p-32 bg-f2f3f8">
        <div className="flex items-center justify-between">
          <H2 isPrimary={true} name="확인 사유 입력" />

          <div
            className="inline-block cursor-pointer"
            onClick={() => dispatch(modalToggle(6))}
          >
            <ModalX />
          </div>
        </div>
        <Space y={20} />
        <form className="flex" onSubmit={onSubmit}>
          <Input
            id="phone"
            value={reason}
            onChange={(e) => {
              setReason(e.target.value);
            }}
            width={70}
            placeholder="사유를 입력 해주세요."
          />
          <Space x={20} />
          <Button btnText="제출" type="submit" />
        </form>
      </div>
    </Background>
  );
};

export default MaskingModal;
