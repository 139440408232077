import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SmsRedux {
  phoneNumber: string;
  serviceNo: number;
}

const initialState: SmsRedux = {
  phoneNumber: "",
  serviceNo: -1,
};

export const smsNumber = createSlice({
  name: "smsNumber",
  initialState,
  reducers: {
    setSmsNumber: (state, action: PayloadAction<SmsRedux>) => {
      return action.payload;
    },
  },
});

export const { setSmsNumber } = smsNumber.actions;
export default smsNumber.reducer;
