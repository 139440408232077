import { useState, useEffect } from "react";

import Background from "Components/Atoms/Background/background";
import H2 from "Components/Atoms/H2/h2";
import Space from "Components/Atoms/Space/space";
import { modalToggle } from "Store/Slices/modal";
import { useAppDispatch } from "Store/store";
import { ReactComponent as ModalX } from "Util/svg/modalX.svg";
import Category from "../Category/category";
import Button from "Components/Atoms/Button/button";
import { putQusetoinCGApi } from "Apis/apis";
import LANG, { getLang } from "Util/lang";
import { useQueryClient } from "@tanstack/react-query";

const CGModal = ({ serviceNo, qIdx }: { serviceNo: number; qIdx: number }) => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const [bCode, setBCode] = useState(-1);
  const [mCode, setMCode] = useState(-1);

  const onSubmit = async () => {
    if (bCode === -1 || mCode === -1) {
      alert("카테고리를 선택 해주세요");
      return;
    }
    const confirm = window.confirm("수정 하시겠습니까?");
    if (!confirm) return;

    const body = {
      bigCategoryCode: bCode,
      middleCategoryCode: mCode,
    };

    await putQusetoinCGApi(qIdx, body);
    // queryClient.invalidateQueries(["QnaListApi", reduxBody]);
    queryClient.invalidateQueries(["useGetQDetailApi", qIdx]);
    dispatch(modalToggle(7));
  };

  return (
    <Background modalNum={7}>
      <div style={{ width: "640px" }} className="p-32 bg-f2f3f8">
        <div className="flex items-center justify-between">
          <H2 isPrimary={true} name="카테고리 수정" />
          <div
            className="inline-block cursor-pointer"
            onClick={() => dispatch(modalToggle(7))}
          >
            <ModalX />
          </div>
        </div>
        <Space y={20} />
        <div className="flex ">
          <Category
            serviceNo={serviceNo}
            bCode={bCode}
            setBCode={setBCode}
            mCode={mCode}
            setMCode={setMCode}
          />
          <Space x={40} />
          <Button onClick={onSubmit} btnText={LANG[getLang()].change} />
        </div>
      </div>
    </Background>
  );
};

export default CGModal;
