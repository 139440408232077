import Button from "Components/Atoms/Button/button";
import H2 from "Components/Atoms/H2/h2";
import Space from "Components/Atoms/Space/space";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import LoginInput from "Components/Molecules/Logininput/loginInput";
import { useAppDispatch } from "Store/store";
import {
  ClickInputForm,
  Item,
  MiddleData,
  Notice,
  TelegramDetail,
} from "types/interface";
import Checkbox from "Components/Atoms/Checkbox/checkbox";

import Label from "Components/Atoms/Label/label";
import FileButton from "Components/Atoms/Button/fileButton";
import FileNameList from "Components/Atoms/FileNameList/fileNameList";
import { setFormData } from "Util/function";
import { logoutMode } from "Store/Slices/layouts";
import Quill from "Components/Atoms/Quill/quill";
import { getTelegramApi } from "Apis/baseApis";
import LANG, { getLang } from "Util/lang";
import {
  useGetAlarmDetailApi,
  getNoticeListApi,
  getCategoryItemApi,
  postQna,
  getQnaImage,
  getCategoryListApi,
  getValidationServiceApi,
} from "Apis/apis";
import Input from "Components/Atoms/Input/input";
import InquiryNotice from "Components/Atoms/InquiryNotice/inquiryNotice";
import Select from "Components/Atoms/Select/select";
import InqueryPolicyModal from "Components/Molecules/InqueryPolicyModal/inqueryPolicyModal";
import { ReactComponent as ArrowSVG } from "Util/svg/leftArrow.svg";
import { modalToggle } from "Store/Slices/modal";

import CustomInquiry from "./customInquiry";

const AGREE: ClickInputForm[] = [{ idx: 1, label: "개인정보 수집 동의" }];

const Index = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const serviceNo = +(params.idx || -1);
  const location = useLocation();
  const { data } = useGetAlarmDetailApi(serviceNo);
  const setServiceNo = () => {
    const obj: { [index: string]: string } = {};
    location.search
      .substr(1)
      .split("&")
      .map((el) => el.split("="))
      .forEach((el) => {
        const key = decodeURI(el[0]);
        const value = decodeURI(el[1]);
        obj[key] = value;
      });
    return obj;
  };
  const {
    device_info: deviceInfo = "",
    user_name: userName = "",
    opt1 = "",
    opt2 = "",
    opt3 = "",
    user_id = "",
    user_email = "",
  } = setServiceNo();

  const {
    admin_token = "",
    all_room_id = "",
    keyword_room_id = "",
    vip_room_id = "",
    all_alarm = -1,
    keyword_alarm = -1,
    vip_alarm = -1,
    alarm_keyword = [],
  } = data ? data.telegram : ({} as TelegramDetail);

  const [userId, setUserId] = useState(user_id);
  const [email, setEmail] = useState(user_email);
  const [title, setTitle] = useState("");
  const [contents, setContents] = useState("");
  const [fileList, setFileList] = useState<File[]>([]);
  const [agree, setAgree] = useState<number[]>([]);
  const [itemList, setItemList] = useState<Item[]>([]);
  const [middleCategoryCode, setMiddleCategoryCode] = useState(-1);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [noticeList, setnoticeList] = useState<Notice[]>([]);
  const [logoImg, setLogoImg] = useState("");
  const [pageTitle, setPageTitle] = useState("");
  const [pageContent, setPageContent] = useState("");
  const [CGList, setCGList] = useState<ClickInputForm[]>([]);
  const [valid, setValid] = useState<number>(-1); // 서비스 유효성 체크
  const [serviceName, setServiceName] = useState("");
  const [prolicy, setProlicy] = useState("");
  const subDomain = window.location.host.split(".")[0];
  const domainIdx = window.location.pathname.split("/")[2];
  const [middleCategoryList, setMiddleCategoryList] = useState<MiddleData[]>(
    [],
  );
  const isDevelop = process.env.NODE_ENV === "development";
  const getValidation = async () => {
    const body = [subDomain, domainIdx];
    const {
      data: { service },
    } = await getValidationServiceApi(body);

    setValid(service.service_valid);
    setServiceName(service.service_name);
    if (service.service_valid === 2 && !isDevelop) {
      alert("서비스가 존재하지않습니다.");
      return navigate(-1);
    }
  };

  const getCategory = async () => {
    const {
      data: {
        category: { big_category, middle_category },
      },
    } = await getCategoryListApi(serviceNo);
    setMiddleCategoryList(middle_category);
    const cateList: ClickInputForm[] = middle_category
      .filter((el) => el.state === 0) // 승인된 중카테고리
      .filter((el) => {
        // 승인된 대카테고리
        const bCG = big_category.find(
          (bc) => bc.big_category_code === el.big_category_code,
        );

        return bCG?.state === 0;
      })
      .map((el) => {
        const bCGName = big_category.find(
          (bc) => bc.big_category_code === el.big_category_code,
        )?.big_category_name;
        return {
          idx: el.middle_category_code,
          label: `${bCGName} : ${el.middle_category_name}`,
        };
      });
    setCGList([
      {
        idx: -1,
        label: "문의 유형",
      },
      ...cateList,
    ]);
  };

  // 문자열 byte 체크
  const getBytes = (str: string) => {
    let character;
    let charBytes = 0;

    for (let i = 0; i < str.length; i += 1) {
      character = str.charAt(i);

      if (escape(character).length > 4) charBytes += 2;
      else charBytes += 1;
    }

    return charBytes;
  };

  // form 확인
  const pass = (): boolean => {
    if (userId === "") {
      alert("회원번호를 입력 해주세요");
      return false;
    } else if (middleCategoryCode === -1) {
      alert("문의 유형을 선택 해주세요");
      return false;
    } else if (email === "") {
      alert("이메일을 입력 해주세요");
      return false;
    } else if (title === "") {
      alert("제목을 입력 해주세요");
      return false;
    } else if (contents === "") {
      alert("내용을 입력 해주세요");
      return false;
    } else if (phoneNumber === "") {
      alert("연락처를 입력 해주세요");
      return false;
    }

    return true;
  };

  // 카테고리별 인풋
  const getItemList = async () => {
    if (!middleCategoryCode) return;
    const {
      data: { category_item },
    } = await getCategoryItemApi(middleCategoryCode);
    const _itemlist = category_item.map((el) => {
      el.value = "";

      return el;
    });

    setItemList(_itemlist);
  };

  // 카테고리별 인풋 onchange
  const onChangeItemValue = (text: string, idx: number) => {
    const inputItemsCopy: Item[] = JSON.parse(JSON.stringify(itemList));
    inputItemsCopy[idx].value = text;
    setItemList(inputItemsCopy);
  };

  const sendTelegram = (vip: number) => {
    const wordList = alarm_keyword.map((el) => el.keyword) || [];
    const checkWord = wordList.findIndex((el) =>
      (contents + title).includes(el),
    );
    if (vip_alarm) {
      if (vip === 0) {
        getTelegramApi(
          admin_token,
          vip_room_id,
          `${userId} 님의 문의가 등록 되었습니다.`,
        );
      }
    }

    if (keyword_alarm) {
      if (checkWord !== -1) {
        getTelegramApi(
          admin_token,
          keyword_room_id,
          `${wordList[checkWord]} 관련 문의가 등록되었습니다.`,
        );
        getTelegramApi(admin_token, keyword_room_id, `문의제목 : ${title}`);
      }
    }

    if (all_alarm) {
      getTelegramApi(
        admin_token,
        all_room_id,
        `${serviceName} 서비스에 문의가 등록되었습니다.`,
      );
    }
  };

  // 문의
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!data) return;

    const isPass: boolean = pass();
    if (!isPass) return;
    if (!agree[0]) {
      alert("개인정보 동의 수집에 동의 해주세요");
      return;
    }

    const contentsLength = getBytes(contents);
    if (contentsLength > 65530) {
      alert("문의내용의 길이가 65530byte를 초과 하였습니다.");
      return;
    }

    const item = itemSetting();
    const { userAgent } = navigator;
    const bigCategoryCode = middleCategoryList.find(
      (el) => el.middle_category_code === middleCategoryCode,
    )?.big_category_code;
    // prettier-ignore
    const body = { title, contents, userId, serviceNo, bigCategoryCode, middleCategoryCode, item, email, phoneNumber, userAgent, deviceInfo, opt1, opt2, opt3, userName};
    const formData: FormData = new FormData();

    setFormData(formData, body);
    for (let i = 0; i < fileList.length; i++) {
      formData.append("qna", fileList[i]);
    }

    const {
      data: { vip },
    } = await postQna(formData);
    alert("문의가 접수되었습니다.");
    sendTelegram(vip);

    navigate(`/menu/${serviceNo}`);
  };

  // 공지사항
  const getNotice = async () => {
    const {
      data: { notice: noticeList },
    } = await getNoticeListApi(serviceNo);
    // 승인난것중에서 최신3개
    const _notice = noticeList.filter((notice) => !notice.state).slice(0, 3);
    setnoticeList(_notice);
  };

  // api쏠때 inputList값 정형화
  const itemSetting = () => {
    const item: { [index: string]: string } = {};
    itemList.forEach((el) => (item[el.item_name] = el.value || ""));
    return JSON.stringify(item);
  };

  // 로고 및 서비스소개
  const getImage = async () => {
    const {
      data: { image },
    } = await getQnaImage(serviceNo);

    setLogoImg(image.icon || "/imgs/common/service_icon.png");
    setPageTitle(image.page_title);
    setPageContent(image.description);
    setProlicy(image.prolicy);
  };

  useEffect(() => {
    getItemList();
    getCategory();
  }, [middleCategoryCode]);

  useEffect(() => {
    getValidation();

    getNotice();
    getImage();
    dispatch(logoutMode());
  }, []);

  if ((serviceNo === 336 && subDomain === "addfit") || (serviceNo === 336 && subDomain === "test_server") || ((serviceNo === 337 || serviceNo === 339) && subDomain === "localhost:3000")) {
    return <CustomInquiry />;
  } else {
    return (
      <div className="h-[100vh] login-bg relative">
        <form
          onSubmit={onSubmit}
          className="absolute px-40 py-48 overflow-auto bg-white rounded-lg w-460 max-w-full p-center max-h-[95vh] pretty-scrollbar">
          <Link to={`/menu/${serviceNo}`} className="flex items-center">
            <div className="flex">
              <ArrowSVG />
              <p>뒤로가기</p>
            </div>
          </Link>
          <Space y={24} />
          <Link to={`/menu/${serviceNo}`} className="flex items-center">
            <img
              src={logoImg || "/imgs/common/inquery_default.png"}
              alt="로고이미지"
              className="w-48 h-48 rounded-lg "></img>
            <Space x={8} />
            <div className="flex flex-col ">
              <span className="font-bold text-14 text-primary">
                {pageTitle}
              </span>
              <span className="text-12 text-primary">{pageContent}</span>
            </div>
          </Link>
          <Space y={16} />
          <H2 name="1:1 문의" />
          <Space y={20} />
          <InquiryNotice noticeList={noticeList} />
          <Space y={20} />

          <LoginInput
            id="userId"
            type="text"
            placeholder="회원번호(또는 닉네임)"
            value={userId}
            setValue={setUserId}
            name="회원번호"
          />
          <Space y={20} />
          <LoginInput
            id="email"
            type="email"
            placeholder="이메일 주소를 입력해주세요"
            value={email}
            setValue={setEmail}
            name="답변 받을 이메일 주소"
            required
          />
          <Space y={20} />
          <LoginInput
            id="number"
            type="text"
            placeholder="ex) 01012345678"
            value={phoneNumber}
            setValue={setPhoneNumber}
            name="연락처"
            required
          />
          <Space y={20} />
          <div className="">
            <Label labelfor="category" name="문의 유형" />
            <div className="flex justify-start">
              <Select
                dataList={CGList}
                val={middleCategoryCode}
                setVal={setMiddleCategoryCode}
                border
                full
              />
            </div>
            <Space y={16} />
          </div>
          <LoginInput
            id="title"
            type="text"
            placeholder="제목을 입력해주세요"
            value={title}
            setValue={setTitle}
            name={LANG[getLang()].title}
          />
          <Space y={8} />
          <Quill
            placeHolder={`내용을 입력해주세요
최대 입력 가능한 글자용량은 65,536 byte입니다.`}
            value={contents}
            setValue={setContents}
            toolbar={false}
          />
          <Space y={20} />
          {itemList.map((el, idx: number) => (
            <div key={el.category_item_no}>
              <Label labelfor={el.item_name} name={el.item_name} />
              <Input
                id={el.item_name}
                type="text"
                bottom={false}
                placeholder={`${el.item_name} 입력`}
                value={el.value || ""}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onChangeItemValue(e.currentTarget.value, idx)
                }
              />
              <Space y={16} />
            </div>
          ))}

          <Label labelfor="img" name="이미지" />
          <Space y={4} />
          <FileButton
            value={fileList}
            setValue={setFileList}
            size="md"
            max={3}
          />
          <Space y={8} />
          <div className="grid grid-cols-2 gap-8">
            <FileNameList fileList={fileList} setFileList={setFileList} />
          </div>

          <Space y={20} />
          <div className="flex gap-8">
            <Checkbox value={agree} setValue={setAgree} dataList={AGREE} />
            <Button
              type="button"
              onClick={() => dispatch(modalToggle(1))}
              btnText="더보기"
              size="sm"
            />
          </div>
          <Space y={20} />
          <div
            className={`${
              valid === 0 && prolicy !== "" ? "" : "pointer-events-none"
            }`}>
            <Button
              type="submit"
              btnText="보내기"
              color={valid === 0 && prolicy !== "" ? "primary" : "ready"}
              full
            />
          </div>
        </form>
        <InqueryPolicyModal policy={prolicy} />
      </div>
    );
  }
};

export default Index;
