import Button from "Components/Atoms/Button/button";
import Space from "Components/Atoms/Space/space";

import { ReactComponent as KR } from "Util/svg/kr.svg";
import { ReactComponent as EN } from "Util/svg/en.svg";
import { ReactComponent as CN } from "Util/svg/cn.svg";
import { ReactComponent as JP } from "Util/svg/jp.svg";
import { translate } from "Util/function";

import { useDispatch, useSelector } from "react-redux";
import { ReducerType } from "Store/store";
import { levelCheck } from "Store/Slices/user";
const langList = [
  {
    lang: "한국어",
    svg: KR,
    type: "ko",
  },
  {
    lang: "영어",
    svg: EN,
    type: "en",
  },
  {
    lang: "중국어 간체",
    svg: CN,
    type: "zh-CN",
  },
  {
    lang: "중국어 번체",
    svg: CN,
    type: "zh-TW",
  },
  {
    lang: "일본어",
    svg: JP,
    type: "ja",
  },
];

/**
 * 전체화면 백그라운드
 * @param  text 원본 문자열
 * @param  setText 번역될 문자열 setState
 * @returns
 */
const TranceBtnList = ({ text, onClick }: { text: string; onClick: any }) => {
  const { product_level } = useSelector((state: ReducerType) => state.user);
  const dispatch = useDispatch();
  const onClickBtn = async (lang: string) => {
    const translatedText = await translate(text, lang);
    onClick(translatedText);
  };

  return (
    <div className="flex items-center">
      {langList.map((lang) => (
        <Button
          onClick={() =>
            dispatch(
              levelCheck({
                sl: "g03",
                fuc: () => onClickBtn(lang.type),
              })
            )
          }
          key={lang.lang}
          color="normal"
          size="sm"
          btnText={
            <div className="flex items-center">
              <lang.svg />
              <Space x={4} />
              <span>{lang.lang}</span>
            </div>
          }
          standardItem="g03"
        />
      ))}
    </div>
  );
};

export default TranceBtnList;
