import InputRowSelect from "Components/Molecules/InputRowSelect/inputRowSelect";
import { ReducerType } from "Store/store";
import useServiceNo from "Util/Hooks/useServiceIdx";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LANG, { getLang } from "Util/lang";
import InputText from "Components/Molecules/InputText/inputText";
import InputRow from "Components/Molecules/InputRow/inputRow";
import LabelQuill from "Components/Molecules/LabelQuill/labelQuill";
import Button from "Components/Atoms/Button/button";
import Space from "Components/Atoms/Space/space";
import InputRowRadio from "Components/Molecules/InputRowRadio/inputRowRadio";
import { ADDBanner, RadioForm } from "types/interface";
import PreviewEmailModal from "Components/Molecules/PreviewModal/previewEmailModal";
import { modalToggle } from "Store/Slices/modal";
import { useLocation, useNavigate } from "react-router-dom";
import {
  deleteEmailApi,
  getDetailEmailApi,
  getServiceIdDetailApi,
  postEmailBannerApi,
  putEmailApi,
} from "Apis/apis";
import InputRowImg from "Components/Molecules/InputRowImg/inputRowImg";

import AddBannerModal from "Components/Molecules/AddBannerModal/addBannerModal";
import ExampleEmailModal from "Components/Molecules/PreviewModal/ExampleEmailModal";
import InputRowBannerButton from "Components/Molecules/InputRowBannerButton/inputRowBannerButton";
const RADIO_TYPE: RadioForm[] = [
  {
    idx: 1,
    label: "대기",
    id: "wait",
  },
  {
    idx: 0,
    label: "승인",
    id: "ok",
  },
];
const EmailFix = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const serviceList = useSelector((state: ReducerType) =>
    state.serviceList.filter((el) => el.state !== 2 && el.state !== 1),
  );
  const [title, setTitle] = useState("");
  const [serviceNo, setServiceNo] = useServiceNo();
  const [supportMail, setSupportMail] = useState("");
  const [serviceName, setServiceName] = useState("");
  const [bannerFile, setBannerFile] = useState<File>({} as File);
  const [imgUrl, setImgUrl] = useState("");
  const [originName, setOriginName] = useState("");
  const [footer, setFooter] = useState("");
  const [state, setState] = useState(1);

  const [addFile1, setAddFile1] = useState<File>({} as File);
  const [addOrigin1, setOrigin1] = useState("");
  const [addLink1, setAddLink1] = useState("");
  const [addName1, setAddName1] = useState("");
  const [addImgUrl1, setAddImgUrl1] = useState("");
  const [addMailNo1, setAddMailNo1] = useState(0);
  const [addFile2, setAddFile2] = useState<File>({} as File);
  const [addOrigin2, setOrigin2] = useState("");
  const [addLink2, setAddLink2] = useState("");
  const [addName2, setAddName2] = useState("");
  const [addImgUrl2, setAddImgUrl2] = useState("");
  const [addMailNo2, setAddMailNo2] = useState(0);
  const [addFile3, setAddFile3] = useState<File>({} as File);
  const [addOrigin3, setOrigin3] = useState("");
  const [addLink3, setAddLink3] = useState("");
  const [addName3, setAddName3] = useState("");
  const [addImgUrl3, setAddImgUrl3] = useState("");
  const [addMailNo3, setAddMailNo3] = useState(0);
  const [bannerList, setBannerList] = useState<ADDBanner[]>([]);

  const fileInputs = [
    {
      type: 1,
      ad_name: addName1,
      url: addLink1,
      order: 1,
      mail_no: addMailNo1,
      madbanner: addFile1,
    },
    {
      type: 1,
      ad_name: addName2,
      url: addLink2,
      order: 2,
      mail_no: addMailNo2,
      madbanner: addFile2,
    },
    {
      type: 1,
      ad_name: addName3,
      url: addLink3,
      order: 3,
      mail_no: addMailNo3,
      madbanner: addFile3,
    },
  ];
  const getHelpMail = async () => {
    const {
      data: { service },
    } = await getServiceIdDetailApi(serviceNo);

    setSupportMail(service.support_mail);
    setServiceName(service.service_name);
  };

  const getDetail = async () => {
    const {
      data: { mailDesign },
    } = await getDetailEmailApi(location.state || "");
    setTitle(mailDesign.title);
    setServiceNo(mailDesign.service_no);
    setImgUrl(mailDesign.mail_banner);
    setFooter(mailDesign.footer);
    setState(mailDesign.state);
    setOriginName(mailDesign.original_filename);
    setOrigin1(mailDesign.ad_banner[0].originFilename);
    setAddLink1(mailDesign.ad_banner[0].url);
    setAddName1(mailDesign.ad_banner[0].ad_name);
    setAddImgUrl1(mailDesign.ad_banner[0].banner_image);
    setAddMailNo1(mailDesign.ad_banner[0].mail_ad_no);
    setOrigin2(mailDesign.ad_banner[1].originFilename);
    setAddLink2(mailDesign.ad_banner[1].url);
    setAddName2(mailDesign.ad_banner[1].ad_name);
    setAddImgUrl2(mailDesign.ad_banner[1].banner_image);
    setAddMailNo2(mailDesign.ad_banner[1].mail_ad_no);
    setOrigin3(mailDesign.ad_banner[2].originFilename);
    setAddLink3(mailDesign.ad_banner[2].url);
    setAddName3(mailDesign.ad_banner[2].ad_name);
    setAddImgUrl3(mailDesign.ad_banner[2].banner_image);
    setAddMailNo3(mailDesign.ad_banner[2].mail_ad_no);
    setBannerList(mailDesign.ad_banner);
  };
  const pass = (): boolean => {
    if (serviceNo === -1) {
      alert("서비스를 선택해주세요.");
      return false;
    } else if (footer === "<p><br></p>") {
      alert("푸터를 입력해주세요.");
      return false;
    }
    return true;
  };
  const putEmailDetail = async () => {
    const isPass: boolean = pass();
    if (!isPass) return;
    const formData: any = new FormData();
    formData.append("serviceNo", serviceNo);
    formData.append("footer", footer);
    formData.append("state", state);
    formData.append("mail", bannerFile);
    formData.append("title", title);
    await putEmailApi(formData, location.state || "");

    for (let i = 0; i < fileInputs.length; i++) {
      const { type, ad_name, url, order, madbanner, mail_no } = fileInputs[i];
      if (ad_name === "" && url === "") continue;
      if (ad_name !== "" && url !== "" && mail_no !== -1) continue;

      const bannerData: any = new FormData();
      bannerData.append("loadNo", location.state);
      bannerData.append("type", type);
      bannerData.append("order", order);
      bannerData.append("adName", ad_name);
      bannerData.append("url", url);
      bannerData.append("madbanner", madbanner);
      await postEmailBannerApi(bannerData);
    }

    alert("이메일디자인이 수정되었습니다.");
    navigate("/user/maildesign", { state: { page: 2, serviceNo: serviceNo } });
  };

  const deleteEmail = async () => {
    await deleteEmailApi(location.state || "");
    alert("이메일디자인이 삭제되었습니다.");
    navigate("/user/maildesign", { state: { page: 2, serviceNo: serviceNo } });
  };
  useEffect(() => {
    getDetail();
  }, []);
  useEffect(() => {
    getHelpMail();
  }, [serviceNo]);

  return (
    <>
      <div id="autoFix">
        <InputRowSelect
          dataList={serviceList}
          id="service"
          name="분류"
          setValue={setServiceNo}
          value={serviceNo}
        />
        <InputText name="발신메일" text={supportMail} />
        <InputRow
          id="title"
          name="제목"
          setValue={setTitle}
          value={title}
          placeholder="제목을 입력하세요."
        />
        <InputRowImg
          id="banner_img"
          name="브랜드 배너"
          setValue={setBannerFile}
          imgUrl={imgUrl}
          setImgUrl={setImgUrl}
          originName={originName}
          placeholder="450 x 100 사이즈 권장"
          noImg="450x100"
          height="h-100"
        />

        <LabelQuill
          id="footer"
          name="푸터"
          setText={setFooter}
          text={footer}
          require={false}
          placeHolder="Footer(하단바) 텍스트는 메일 하단에 표시되는 텍스트 정보입니다. 
주소, 대표 전화, 대표 이메일 등 기업의 주요 정보 및 공지사항을 Footer에 입력해보세요.
"
        />
        <InputRowBannerButton
          id="banner"
          name="배너 광고"
          Buttonidx={3}
          data={fileInputs}
        />
        <div className="relative">
          <InputText name="미리보기" text="" />
          <div className="absolute flex top-13 left-143">
            <Button
              btnText="미리보기"
              size="md"
              onClick={() => dispatch(modalToggle(4))}
            />
            <Space x={20} />
            <Button
              btnText="작성예시"
              size="md"
              onClick={() => dispatch(modalToggle(5))}
            />
          </div>
        </div>
        <InputRowRadio
          data={RADIO_TYPE}
          name="상태"
          value={state}
          setValue={setState}
        />
        <div className="flex justify-end gap-10 mt-10 ">
          <Button btnText={LANG[getLang()].change} onClick={putEmailDetail} />
          <Button
            btnText={LANG[getLang()].list}
            onClick={() =>
              navigate("/user/maildesign", {
                state: { page: 2, serviceNo: serviceNo },
              })
            }
          />
          <Button
            btnText={LANG[getLang()].delete}
            onClick={deleteEmail}
            color="delete"
          />
        </div>
      </div>
      <AddBannerModal
        modalNum={1}
        setFile={setAddFile1}
        setLink={setAddLink1}
        link={addLink1}
        addName={addName1}
        setAddName={setAddName1}
        serviceNo={serviceNo}
        imgName={addOrigin1}
        mail_ad_no={bannerList[0]?.mail_ad_no || -1}
        setImgName={setOrigin1}
        file={addFile1}
        setImgUrl={setAddImgUrl1}
      />
      <AddBannerModal
        modalNum={2}
        setFile={setAddFile2}
        setLink={setAddLink2}
        link={addLink2}
        addName={addName2}
        setAddName={setAddName2}
        serviceNo={serviceNo}
        imgName={addOrigin2}
        mail_ad_no={bannerList[1]?.mail_ad_no || -1}
        setImgName={setOrigin2}
        file={addFile2}
        setImgUrl={setAddImgUrl2}
      />
      <AddBannerModal
        modalNum={3}
        setFile={setAddFile3}
        setLink={setAddLink3}
        link={addLink3}
        addName={addName3}
        setAddName={setAddName3}
        serviceNo={serviceNo}
        imgName={addOrigin3}
        mail_ad_no={bannerList[2]?.mail_ad_no || -1}
        setImgName={setOrigin3}
        file={addFile3}
        setImgUrl={setAddImgUrl3}
      />
      <PreviewEmailModal
        footer={footer}
        imgUrl={imgUrl}
        modalNum={4}
        serviceName={serviceName}
        addImgUrl1={addImgUrl1}
        addImgUrl2={addImgUrl2}
        addImgUrl3={addImgUrl3}
        url1={addLink1}
        url2={addLink2}
        url3={addLink3}
        title={title}
      />
      <ExampleEmailModal modalNum={5} />
    </>
  );
};

export default EmailFix;
