import React from "react";
import { Notice } from "types/interface";
import Space from "../Space/space";

/**
 * 문의페이지 공지사항
 * @param  noticeList 공지사항 리스트
 * @returns
 */
const InquiryNotice = ({ noticeList }: { noticeList: Notice[] }) => {
  return (
    <>
      {noticeList.map((notice: Notice) => (
        <div
          key={notice.notice_no}
          className="flex justify-between mb-16 border-b-1"
        >
          <a
            className="text-sm ell flex-[1]"
            target="_blank"
            rel="noreferrer"
            href={notice.contents}
          >
            {notice.title}
          </a>
          <Space x={8} />
          <span className="text-sm">{notice.date.split(" ")[0]}</span>
        </div>
      ))}
    </>
  );
};

export default InquiryNotice;
