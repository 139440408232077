import axios, { AxiosResponse } from "axios";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { delToken, getToken } from "Util/function";

const v1 = process.env.REACT_APP_API_V1;

// 에러status
const DUPLICATE_LOGIN = "DUPLICATE_LOGIN"; // 중복로그인

// 변수로 작성시 getToken함수를 매번 실행하지 않음(최초 진입시 빈값)
const instance = (checkcode?: string) =>
  axios.create({
    baseURL: v1,
    headers: { Authorization: `Bearer ${getToken()}`, checkcode: checkcode },
  });

const errorHandleApi = async (
  api: () => Promise<AxiosResponse<any, any>>,
): Promise<any> => {
  return await api().catch((err: any) => {
    // 에러안의 데이터 접근
    // || {} 안쓰면 err.response.data 없을때 로직 끝남
    const {
      details = null,
      status = null,
      description = null,
    } = err.response.data || {};
    details && alert(details);
    getTelegramApi(
      "5570420560:AAFvy7WYk9qDQ3HFyulYSNaFHxCOY8fUS1g",
      "-967629910",
      `${err.request.responseURL} - ${details || description}`,
    );

    if (status === DUPLICATE_LOGIN) {
      delToken();
      window.location.reload();
    }
    console.log(err);
    throw err;
  });
};

// get api
export const getApi = async (url: string, checkcode?: string) =>
  errorHandleApi(() => instance(checkcode).get(url));

// post api
export const postApi = async (url: string, body?: any, checkcode?: string) =>
  errorHandleApi(() => instance(checkcode).post(url, body));

// put api
export const putApi = async (url: string, body?: any, checkcode?: string) =>
  errorHandleApi(() => instance(checkcode).put(url, body));

// delete api
export const deleteApi = async (url: string, checkcode?: string) =>
  errorHandleApi(() => instance(checkcode).delete(url));

// ip체크
export const getIpApi = () => {
  // const data = axios.get(`https://api.ipify.org?callback=?`, {});
  const data = axios.get(`https://jsonip.com`, {});
  return data;
};

// 텔레그램 메세지
export const getTelegramApi = async (
  token: string,
  roomId: string,
  text: string | React.ReactNode,
) => {
  await axios.get(
    `https://api.telegram.org/bot${token}/sendMessage?chat_id=${roomId}&parse_mode=markdown&text=${text}`,
  );
};

// 결제
export const postPay = (body: any) => {
  const data = axios.post(
    `https://stgstdpay.inicis.com/stdjs/INIStdPay.js`,
    body,
  );
  // const data = axios.post(`https://stdpay.inicis.com/stdjs/INIStdPay.js`, body);
  return data;
};

// ================================================ react-query ================================================
// ================================================ react-query ================================================
// ================================================ react-query ================================================

export const useGetQuery = (key: any, url: string, option?: any) => {
  return useQuery(key, () => getApi(url).then(({ data }) => data), option);
};
export const usePostQuery = (key: any, url: string, body?: any) => {
  return useQuery(key, () => postApi(url, body).then(({ data }) => data));
};

export const usePutQuery = (key: any, url: string, body: any) => {
  const queryClient = useQueryClient();
  const data = putApi(url, body);
  queryClient.invalidateQueries(key);
  return data;
};
