import { useState, useEffect, useContext } from "react";

import { delTempleteApi, putTempleteApi } from "Apis/apis";
import Background from "Components/Atoms/Background/background";
import H2 from "Components/Atoms/H2/h2";
import Space from "Components/Atoms/Space/space";
import Button from "Components/Atoms/Button/button";
import { modalToggle } from "Store/Slices/modal";
import { ReducerType, useAppDispatch } from "Store/store";
import { RadioForm, TemplateApiBody } from "types/interface";
import { ReactComponent as ModalX } from "Util/svg/modalX.svg";
import InputRow from "../InputRow/inputRow";
import InputRowRadio from "../InputRowRadio/inputRowRadio";
import LabelQuill from "../LabelQuill/labelQuill";
import useServiceNo from "Util/Hooks/useServiceIdx";
import { useSelector } from "react-redux";
import InputRowSelect from "../InputRowSelect/inputRowSelect";
import { tpContext } from "Util/Hooks/useTPContext";
import InputText from "../InputText/inputText";
import LANG, { getLang } from "Util/lang";

const stateRadio: RadioForm[] = [
  { idx: 0, label: LANG[getLang()].communal, id: "public" },
  { idx: 1, label: LANG[getLang()].individual, id: "private" },
];

/**
 * 모달 molecule
 *
 */
const PutTemplateModal = ({
  onClickTemplate,
}: {
  onClickTemplate: (contents: string) => void;
}) => {
  const dispatch = useAppDispatch();
  const { TP } = useContext(tpContext);

  const [title, setTitle] = useState(TP.title);
  const [state, setState] = useState(TP.state);
  const [contents, setContents] = useState(TP.contents);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (title === "") {
      alert("제목을 입력 해주세요");
      return;
    }
    if (contents === "<p><br></p>" || contents === "") {
      alert("내용을 입력 해주세요");
      return;
    }

    const confirm = window.confirm("수정 하시겠습니까?");
    if (!confirm) return;

    const body: TemplateApiBody = {
      title,
      contents,
      state,
    };
    await putTempleteApi(TP.template_no, body);
    dispatch(modalToggle(1));
  };
  const onDelete = async () => {
    const confirm = window.confirm("삭제 하시겠습니까?");
    if (!confirm) return;

    await delTempleteApi(TP.template_no);
    dispatch(modalToggle(1));
  };

  useEffect(() => {
    setTitle(TP.title);
    setState(TP.state);
    setContents(TP.contents);
  }, [TP]);

  return (
    <Background modalNum={4}>
      <div style={{ width: "1000px" }} className="p-32 bg-f2f3f8">
        <div className="flex justify-between items-center">
          <H2 isPrimary={true} name="템플릿 수정" />
          <div
            className="inline-block cursor-pointer"
            onClick={() => dispatch(modalToggle(4))}
          >
            <ModalX />
          </div>
        </div>
        <Space y={20} />
        <form onSubmit={onSubmit}>
          <InputText name={LANG[getLang()].service} text={TP.service_name} />
          <InputRow
            id="title"
            name={LANG[getLang()].title}
            value={title || ""}
            setValue={setTitle}
          />
          <InputRowRadio
            data={stateRadio}
            name={LANG[getLang()].authority}
            value={state}
            setValue={setState}
          />
          <LabelQuill
            id="contents"
            name={LANG[getLang()].content}
            text={contents}
            setText={setContents}
          />

          <Space y={20} />
          <div className="flex justify-end">
            <Button
              onClick={() => onClickTemplate(contents)}
              type="button"
              btnText="적용"
            />
            <Space x={20} />
            <Button
              onClick={() => dispatch(modalToggle(1))}
              type="button"
              btnText="목록"
            />
            <Space x={20} />
            <Button type="submit" btnText={LANG[getLang()].change} />
            <Space x={20} />
            <Button
              type="button"
              onClick={onDelete}
              btnText={LANG[getLang()].delete}
              color="delete"
            />
          </div>
        </form>
      </div>
    </Background>
  );
};

export default PutTemplateModal;
