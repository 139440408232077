import Background from "Components/Atoms/Background/background";
import H2 from "Components/Atoms/H2/h2";
import Space from "Components/Atoms/Space/space";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { modalToggle } from "Store/Slices/modal";
import { ReactComponent as ModalX } from "Util/svg/modalX.svg";
import Button from "Components/Atoms/Button/button";
import { QHistoryList, UserList } from "types/interface";
import { ReducerType } from "Store/store";
import { Collapse, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { getQnaHistoryApi } from "Apis/apis";
import LANG, { getLang } from "Util/lang";
import DOMPurify from "dompurify";

const VipInquiryModal = ({ obj }: { obj: UserList[] }) => {
  const dispatch = useDispatch();
  const serviceList = useSelector((state: ReducerType) => state.serviceList);
  const serviceName = serviceList.filter((el) => el.idx === obj[0].service_no);
  const { Panel } = Collapse;
  const [qnaList, setQnaList] = useState<QHistoryList[]>([]);
  const getHistory = async () => {
    const {
      data: { qnaPast },
    } = await getQnaHistoryApi(obj[0].user_id, obj[0].service_no);
    setQnaList(qnaPast);
  };

  useEffect(() => {
    getHistory();
  }, [obj]);
  return (
    <Background modalNum={2}>
      <div style={{ width: "940px" }} className="p-32 bg-f2f3f8 ">
        <div className="flex justify-between">
          <H2 isPrimary={true} name={`${obj[0].user_id} 문의건수`} />
          <div
            onClick={() => dispatch(modalToggle(2))}
            className="inline-block cursor-pointer"
          >
            <ModalX />
          </div>
        </div>
        <Space y={8} />
        <div
          className={`max-h-500 
          h-${
            qnaList.length * 110
          } mx-auto mt-20 text-center overflow-y-scroll`}
        >
          <Collapse className="border-none">
            {qnaList.map((el: any) => (
              <Panel
                style={{
                  borderRadius: "0px",
                  backgroundColor: "#E5E6ED",
                }}
                className="mb-10 text-left"
                header={
                  <p>
                    [{serviceName[0].label}] &nbsp; {el.title} &nbsp;({el.date})
                  </p>
                }
                key={el.qna_no}
              >
                <p
                  className=""
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(el.contents),
                  }}
                ></p>
              </Panel>
            ))}
          </Collapse>
        </div>
        <div className="flex justify-end mt-10">
          <Button onClick={() => dispatch(modalToggle(2))} btnText="닫기" />
        </div>
      </div>
    </Background>
  );
};

export default VipInquiryModal;
