import { useState, useEffect } from "react";
import Table, { ColumnsType } from "antd/es/table";
import Button from "Components/Atoms/Button/button";

import ServiceIdAddModal from "Components/Molecules/ServiceIdModal/serviceIdAddModal";
import ServiceIdFixModal from "Components/Molecules/ServiceIdModal/serviceIdFixModal";
import SearchInput from "Components/Molecules/SearchInput/searchInput";

import { modalToggle } from "Store/Slices/modal";

import { getServiceCategoryApi, getServiceIdListApi } from "Apis/apis";
import SearchResult from "Components/Atoms/SearchResult/searchResult";
import Space from "Components/Atoms/Space/space";
import { ClickInputForm, ServiceIdList, User } from "types/interface";

import { useLocation, useNavigate } from "react-router-dom";
import Tag from "Components/Atoms/Tag/tag";
import { ReducerType, useAppDispatch } from "Store/store";
import LANG, { getLang } from "Util/lang";
import { order, toCheckboxForm } from "Util/function";
import { MServiceCategory } from "types/interface";
import { useSelector } from "react-redux";

const Service = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { limit_service }: User = useSelector(
    (state: ReducerType) => state.user,
  );

  const [serviceAllList, setServiceAllList] = useState<ServiceIdList[]>([]);
  const [selectServiceIdx, setselectServiceIdx] = useState(0);
  const [search, setSearch] = useState("");

  const [registBCode, setRegistBCode] = useState(-1);
  const [registMCode, setRegistMCode] = useState(-1);
  const [fixBCode, setFixBCode] = useState(-1);
  const [fixMCode, setFixMCode] = useState(-1);
  const getServiceIdList = async () => {
    const {
      data: { serviceList },
    } = await getServiceIdListApi(location.state || "");

    setServiceAllList(serviceList.filter((el) => el.state !== 2));
  };

  const onSubmit = () => {
    navigate("", {
      state: search,
    });
  };

  const columns: ColumnsType<ServiceIdList> = [
    {
      title: LANG[getLang()].index,
      dataIndex: "service_no",
      align: "center",
      render: (now, row) => {
        return row.state === 1 ? (
          <span className="text-15 text-969696">
            {order(serviceAllList, row.service_no, "service_no")}
          </span>
        ) : (
          <span className="text-15">
            {order(serviceAllList, row.service_no, "service_no")}
          </span>
        );
      },
    },
    {
      title: LANG[getLang()].serviceId,
      dataIndex: "service_no",
      align: "center",
      render: (now, row) => {
        return row.state === 1 ? (
          <span className="text-15 text-969696">{now}</span>
        ) : (
          <span className="text-15">{now}</span>
        );
      },
    },
    {
      title: "서비스 로고",
      dataIndex: "service_logo",
      align: "center",
      width: 140,
      render: (now) => {
        const defalut = "../imgs/common/defaultService.png";
        return (
          <div className="flex justify-center">
            {now ? (
              <img src={now} className="w-80 h-80" alt="서비스 로고" />
            ) : (
              <img src={defalut} alt="서비스 로고" />
            )}
          </div>
        );
      },
    },
    {
      title: "서비스 명",
      dataIndex: "service_name",
      align: "center",
      render: (now, row) => {
        return row.state === 1 ? (
          <span
            className="cursor-pointer text-15 text-969696"
            onClick={() => {
              setselectServiceIdx(row.service_no);
              return dispatch(modalToggle(2));
            }}
          >
            {now}
          </span>
        ) : (
          <span
            className="cursor-pointer text-15"
            onClick={() => {
              setselectServiceIdx(row.service_no);
              return dispatch(modalToggle(2));
            }}
          >
            {now}
          </span>
        );
      },
    },
    {
      title: LANG[getLang()].state,
      dataIndex: "state",
      align: "center",
      render: (now) => {
        if (now === 0) return <Tag name="승인" />;
        else if (now === 1)
          return <Tag type="ready" name={LANG[getLang()].waiting} />;
      },
    },
    {
      title: LANG[getLang()].date,
      dataIndex: "date",
      align: "center",
      render: (now, row) => {
        return row.state === 1 ? (
          <span className="text-15 text-969696">{now}</span>
        ) : (
          <span className="text-15">{now}</span>
        );
      },
    },
  ];

  useEffect(() => {
    getServiceIdList();
    setSearch(location.state || "");
  }, [location.state]);

  return (
    <div id="account" className="w-[1200px]">
      <SearchInput
        placeholder="서비스 명 검색"
        size="xl"
        value={search}
        setValue={setSearch}
        onSubmit={onSubmit}
      />
      <Space y={16} />
      <div className="flex items-center justify-between mb-20">
        <span className="text-16 font-bold self-end text-[#1c274c]">
          등록된 서비스 ({serviceAllList.length})개 / 현재 승인된 서비스 (
          {serviceAllList.filter((el) => el.state === 0).length})개 / 승인
          가능한 서비스 ({limit_service})개
        </span>
        <Button
          onClick={() => dispatch(modalToggle(1))}
          btnText={LANG[getLang()].serviceNameRegisteration}
        />
      </div>

      <Table
        rowKey="service_no"
        columns={columns}
        dataSource={serviceAllList}
        pagination={false}
      />

      <ServiceIdAddModal
        setServiceAllList={setServiceAllList}
        bCode={registBCode}
        setBCode={setRegistBCode}
        mCode={registMCode}
        setMCode={setRegistMCode}
      />
      <ServiceIdFixModal
        setServiceAllList={setServiceAllList}
        selectServiceIdx={selectServiceIdx}
        setselectServiceIdx={setselectServiceIdx}
        bCode={fixBCode}
        setBCode={setFixBCode}
        mCode={fixMCode}
        setMCode={setFixMCode}
      />
    </div>
  );
};
export default Service;
