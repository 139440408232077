import React from "react";
import Quill from "Components/Atoms/Quill/quill";
import Label from "Components/Atoms/Label/label";
import Space from "Components/Atoms/Space/space";
import { ReactComponent as Require } from "Util/svg/require.svg";

const LabelQuill = ({
  id,
  name,
  text,
  placeHolder,
  setText,
  require = true,
  width = "w-130",
  readOnly = false,
  toolbar = true,
}: {
  id: string;
  name: string;
  text: string;
  placeHolder?: string;
  setText: React.Dispatch<React.SetStateAction<string>>;
  require?: boolean;
  width?: string;
  readOnly?: boolean;
  toolbar?: boolean;
}) => {
  return (
    <div className="flex items-center">
      <div
        className={`flex self-stretch px-16 py-20 bg-e5e6ed ${width} items-center`}
      >
        <Label labelfor={id} name={name} fontSize={16} />
        <Space x={4} />
        {require ? <Require /> : null}
      </div>
      <div className="flex-1 h-full bg-white p-13">
        <div className="w-full">
          <Quill
            value={text}
            setValue={setText}
            placeHolder={placeHolder}
            readOnly={readOnly}
            toolbar={toolbar}
          />
        </div>
      </div>
    </div>
  );
};

export default LabelQuill;
