import { ReactComponent as Require } from "Util/svg/require.svg";
import ConsultantForm from "Components/Organisms/ConsultantForm/ConsultantFrom";
import { useState, useEffect } from "react";
import Button from "Components/Atoms/Button/button";
import { Link, useNavigate } from "react-router-dom";
import { PostConsultant } from "types/interface";
import { postConsultantApi } from "Apis/apis";
import LANG, { getLang } from "Util/lang";

const AccountAdd = () => {
  const [csId, setCsId] = useState("");
  const [password, setPassword] = useState("");
  const [pwCheck, setPwCheck] = useState("");
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [serviceList, setServiceList] = useState<number[]>([]);
  const [level, setLevel] = useState(1);
  const [auth, setAuth] = useState<number[]>([]);
  const [img, setImg] = useState<File>({} as File);
  const [imgUrl, setImgUrl] = useState("");

  const [isidCheck, setIsIdCheck] = useState(false);

  const navigate = useNavigate();

  const pwReg =
    /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=\-<>?./:;'"[\]{}])(?=.*[0-9]).{8,32}$/;
  const pass = (): boolean => {
    if (!isidCheck) {
      alert("이메일 중복체크를 해주세요");
      return false;
    } else if (password.match(pwReg) === null) {
      alert("(문자, 숫자, 특수문자 조합(최소 8자, 최대 32자)입니다.");
      return false;
    } else if (password !== pwCheck) {
      alert("비밀번호 확인이 맞지 않습니다.");
      return false;
    } else if (name === "") {
      alert("이름을 작성 해주세요");
      return false;
    } else if (phoneNumber === "") {
      alert("핸드폰 번호를 작성 해주세요");
      return false;
    } else if (serviceList.length === 0) {
      alert("담당 서비스를 선택 해주세요");
      return false;
    }
    return true;
  };

  const enrollment = async () => {
    const isPass: boolean = pass();
    if (!isPass) return;
    const confirm = window.confirm("등록 하시겠습니까?");
    if (!confirm) return;

    let sum = 0;
    for (let i = 0; i < auth.length; i++) {
      sum += auth[i];
    }
    const formData: any = new FormData();
    formData.append("csId", csId);
    formData.append("password", password);
    formData.append("name", name);
    formData.append("phoneNumber", phoneNumber);
    for (let i = 0; i < serviceList.length; i++) {
      formData.append("serviceList[]", serviceList[i]);
    }
    formData.getAll("item");
    formData.append("level", level);
    formData.append("cs", img);
    formData.append("authority", sum);
    formData.append("state", 1);

    await postConsultantApi(formData);
    navigate("/account/accountsetting", {
      state: 1,
    });
  };
  return (
    <div>
      <div className="flex items-center justify-end mb-10">
        <Require />
        <span className="ml-5">표기된 사항은 필수 입력 사항입니다.</span>
      </div>

      <ConsultantForm
        id={csId}
        setId={setCsId}
        pw={password}
        setPw={setPassword}
        pwCheck={pwCheck}
        setPwCheck={setPwCheck}
        name={name}
        setName={setName}
        phone={phoneNumber}
        setPhone={setPhoneNumber}
        setImg={setImg}
        level={level}
        setLevel={setLevel}
        service={serviceList}
        setService={setServiceList}
        auth={auth}
        setAuth={setAuth}
        isidCheck={isidCheck}
        setIsIdCheck={setIsIdCheck}
        imgUrl={imgUrl}
        setImgUrl={setImgUrl}
      />
      <div className="flex justify-end gap-10 mt-10">
        <Link to="/account/accountsetting">
          <Button btnText={LANG[getLang()].list} />
        </Link>
        <Button btnText={LANG[getLang()].register} onClick={enrollment} />
      </div>
    </div>
  );
};

export default AccountAdd;
