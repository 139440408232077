import DatePick from "Components/Atoms/DatePick/datePick";
import { useState, useEffect } from "react";
import useDatePicker from "Util/Hooks/useDatePicker";
import SearchInput from "Components/Molecules/SearchInput/searchInput";
import Space from "Components/Atoms/Space/space";

import { AccountData, AccountLog, TOTAL_TYPE } from "types/interface";
import { getAccountLogApi } from "Apis/apis";
import ExcelButton from "Components/Molecules/ExcelButton/excelButton";
import LANG, { getLang } from "Util/lang";
import Select from "Components/Atoms/Select/select";
import { useDispatch, useSelector } from "react-redux";
import { ReducerType } from "Store/store";
import useServiceNo from "Util/Hooks/useServiceIdx";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

import CTTable from "Components/Atoms/Table/CTTable";
import CTPieChart from "Components/Atoms/Chart/CTPieChart";
import CTBar from "Components/Atoms/Bar/CTBar";
import { modifyAllText } from "Util/function";
import Button from "Components/Atoms/Button/button";
import { levelCheck } from "Store/Slices/user";
ChartJS.register(ArcElement, Tooltip, Legend);
const EXCEL_OBJ = {
  cs_id: LANG[getLang()].assistantID,
  sum: "전체문의",
  reply_complete: "답변완료",
  reply_no: "미처리",
};

const ChartAccount = ({
  dateRange,
  setDateRange,
}: {
  dateRange: string[];
  setDateRange: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  const dispatch = useDispatch();
  const serviceList = useSelector((state: ReducerType) => state.serviceList);

  const [serviceNo, setServiceNo] = useServiceNo();
  const [search, setSearch] = useState("");
  const [accountLog, setAccountLog] = useState<AccountData>({} as AccountData);
  const { product_level } = useSelector((state: ReducerType) => state.user);

  const getChartAccount = async () => {
    if (serviceNo === -1) {
      alert("서비스를 선택 해주세요");
      return;
    }
    const firstDay = dateRange[0];
    const secondDay = dateRange[1];

    const {
      data: { statistic },
    } = await getAccountLogApi(firstDay, secondDay, serviceNo, search);
    setAccountLog(statistic);
  };

  const onSubmit = () => {
    getChartAccount();
  };

  return (
    <div className="w-[1200px]">
      <div id="chartAccount">
        <div className="flex">
          <DatePick dateRange={dateRange} setDateRange={setDateRange} />
          <Space x={9} />
          <Select
            dataList={modifyAllText(serviceList)}
            val={serviceNo}
            setVal={setServiceNo}
          />
          <Space x={9} />
          <SearchInput
            size="md"
            value={search}
            setValue={setSearch}
            onSubmit={() =>
              dispatch(
                levelCheck({
                  sl: "re02",
                  fuc: onSubmit,
                }),
              )
            }
            placeholder={LANG[getLang()].assistantID}
            standardItem="re02"
          />

          <Space x={10} />
          {product_level !== 5 ? (
            <Button btnText="엑셀다운로드" color="ready" size="md" />
          ) : (
            <ExcelButton
              data={accountLog.data}
              obj={EXCEL_OBJ}
              fileName={`${dateRange}문의계정`}
            />
          )}
        </div>
        <Space y={40} />

        <CTBar data={accountLog.data} />
        <Space y={40} />
        <div className="max-w-3xl mx-auto">
          <CTPieChart
            data={accountLog.data}
            date={`${dateRange[0]} ~ ${dateRange[1]} `}
          />
          <Space y={40} />
        </div>
        <CTTable data={accountLog} />
      </div>
    </div>
  );
};

export default ChartAccount;
