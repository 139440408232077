import Button from "Components/Atoms/Button/button";
import Select from "Components/Atoms/Select/select";
import Space from "Components/Atoms/Space/space";
import React from "react";
import { ClickInputForm } from "types/interface";
import LANG, { getLang } from "Util/lang";

const SearchSelect = ({
  dataList,
  value,
  setValue,
  onClick,
  standardItem,
}: {
  dataList: ClickInputForm[];
  value: number;
  setValue: React.Dispatch<React.SetStateAction<number>>;
  onClick: () => void;
  standardItem?: string;
}) => {
  return (
    <div className="flex">
      <Select dataList={dataList} val={value} setVal={setValue} />
      <Space x={8} />
      <Button
        standardItem={standardItem}
        btnText={LANG[getLang()].search}
        onClick={onClick}
      />
    </div>
  );
};

export default SearchSelect;
