import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import FaqForm from "Components/Organisms/FaqForm/faqForm";

import { postFaQApi } from "Apis/apis";

import useServiceNo from "Util/Hooks/useServiceIdx";

const FaqAdd = () => {
  const navigate = useNavigate();

  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [state, setState] = useState(1);
  const [serviceNo, setServiceNo] = useServiceNo();

  const [bCode, setBCode] = useState(-1);
  const [mCode, setMCode] = useState(-1);

  const [file, setFile] = useState<File[]>([]);

  const pass = (): boolean => {
    if (title === "") {
      alert("제목을 입력해주세요.");
      return false;
    } else if (serviceNo === 0) {
      alert("서비스를 선택해주세요.");
      return false;
    } else if (bCode === -1) {
      alert("대 카테고리를 선택해주세요.");
      return false;
    } else if (mCode === -1) {
      alert("중 카테고리를 선택해주세요.");
      return false;
    } else if (text === "") {
      alert("내용을 입력해주세요.");
      return false;
    }
    return true;
  };

  const postFaqAdd = async () => {
    const isPass: boolean = pass();
    if (!isPass) return;
    const confirm = window.confirm("등록 하시겠습니까?");
    if (!confirm) return;
    const formData: any = new FormData();
    formData.append("serviceNo", serviceNo);
    formData.append("bigCategoryCode", bCode);
    formData.append("middleCategoryCode", mCode);
    formData.append("title", title);
    formData.append("contents", text);
    formData.append("state", state);
    file.forEach((list) => formData.append("faq", list));

    await postFaQApi(formData);
    navigate("/service/potalboard", {
      state: { pageNo: 1, selectServiceNo: serviceNo },
    });
  };
  useEffect(() => {
    setBCode(-1);
    setMCode(-1);
  }, [serviceNo]);
  return (
    <FaqForm
      title={title}
      setTitle={setTitle}
      text={text}
      setText={setText}
      state={state}
      setState={setState}
      serviceNo={serviceNo}
      setServiceNo={setServiceNo}
      bCode={bCode}
      setBCode={setBCode}
      mCode={mCode}
      setMCode={setMCode}
      onClick={postFaqAdd}
      setFile={setFile}
      file={file}
    />
  );
};

export default FaqAdd;
