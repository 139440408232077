import React, { useState, useEffect } from "react";
import useDatePicker, { useMonthPicker } from "Util/Hooks/useDatePicker";
import { useSelector } from "react-redux";
import { ReducerType } from "Store/store";

import Select from "Components/Atoms/Select/select";
import Space from "Components/Atoms/Space/space";
import Button from "Components/Atoms/Button/button";
import Table, { ColumnsType } from "antd/es/table";

import { DailyLog } from "types/interface";

import { getDailyLogApi } from "Apis/apis";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useLocation, useNavigate } from "react-router";
import useServiceNo from "Util/Hooks/useServiceIdx";
import ExcelButton from "Components/Molecules/ExcelButton/excelButton";
import LANG, { getLang } from "Util/lang";
import dayjs from "dayjs";
import DatePick from "Components/Atoms/DatePick/datePick";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { modifyAllText, sum } from "Util/function";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);
const EXCEL_OBJ = {
  day: "날짜",
  count_sum: "전체문의",
  count_reply_complete: "답변완료",
  count_reply_no: "미처리",
};
const ChartDaily = ({
  dateRange,
  setDateRange,
}: {
  dateRange: string[];
  setDateRange: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const serviceList = useSelector((state: ReducerType) => state.serviceList);
  const { product_level } = useSelector((state: ReducerType) => state.user);

  const [serviceNo, setServiceNo] = useServiceNo();
  const [dailyLog, setDailyLog] = useState<DailyLog[]>([]);

  const serviceName =
    serviceList.find((item) => item.idx === serviceNo)?.label || "";
  const graphTitle = `${serviceName} (${dateRange[0]} ~ ${dateRange[1]})`;

  const getDailyLog = async () => {
    if (serviceNo === -1) return;
    const {
      data: { statistic },
    } = await getDailyLogApi(serviceNo, dateRange[0], dateRange[1]);

    setDailyLog(statistic);
  };
  const onSubmit = () => {
    if (serviceNo === -1) return alert("서비스를 선택해주세요.");
    navigate("", { state: { main: 1, side: 1, date: dateRange } });
    getDailyLog();
  };

  // 테이블
  const columns: ColumnsType<DailyLog> = [
    {
      title: LANG[getLang()].date,
      dataIndex: "day",
      align: "center",
    },
    {
      title: LANG[getLang()].allInquiries,
      dataIndex: "count_sum",
      align: "center",
    },
    {
      title: LANG[getLang()].replyComplete,
      dataIndex: "count_reply_complete",
      align: "center",
    },
    {
      title: LANG[getLang()].outStanding,
      dataIndex: "count_reply_no",
      align: "center",
    },
  ];

  const options = {
    maxBarThickness: 50, // 막대 크기
    plugins: {
      title: {
        display: true,
        text: graphTitle,
        font: { size: 30 },
        padding: 50,
      },
      datalabels: {
        display: true,
        color: "black",
        font: (context: any) => {
          if (context.dataset.label === "전체문의") {
            return { size: 22, weight: "bold", color: "blue" };
          }
          return { size: 14, weight: "normal" };
        },
        anchor: (context: any) => {
          if (context.dataset.label === "전체문의") {
            return "start";
          }
          return "center";
        },
        align: (context: any) => {
          if (context.dataset.label === "전체문의") {
            return "top";
          }
          return "center";
        },
        formatter: (value: number, context: any) => {
          if (context.dataset.label === "전체문의") {
            if (value === 0) {
              return null;
            }
            return `${value}`;
          } else if (value === 0) {
            // Exclude data labels for zero values
            return null;
          } else {
            return value.toString();
          }
        },
      } as any,
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        min: 0,
        max:
          sum(dailyLog.map((el) => el.count_reply_complete)) +
          sum(dailyLog.map((el) => el.count_reply_no)) +
          2,
        // max: Math.max(...dailyLog.map((el) => el.count_sum)),
      },
    },
  };

  const labels = [...dailyLog.map((el) => el.day), "합계"];

  const data = {
    plugins: [ChartDataLabels],
    labels,
    datasets: [
      {
        label: LANG[getLang()].replyComplete,
        data: [
          ...dailyLog.map((el) => el.count_reply_complete),
          sum(dailyLog.map((el) => el.count_reply_complete)),
        ],
        backgroundColor: "rgb(37, 142, 228)",
        order: 0,
      },
      {
        label: "미처리",
        data: [
          ...dailyLog.map((el) => el.count_reply_no),
          sum(dailyLog.map((el) => el.count_reply_no)),
        ],
        backgroundColor: "rgb(235, 96, 53)",
        order: 0,
      },
      {
        label: "전체문의",
        data: [
          ...dailyLog.map((el) => el.count_sum),
          sum(dailyLog.map((el) => el.count_sum)),
        ],
        backgroundColor: "rgba(0, 0, 0, 0)", // Transparent background for sum bars
        order: 1,
      },
    ],
  };

  return (
    <div id="chart" className="w-[1200px]">
      <div className="flex">
        <DatePick dateRange={dateRange} setDateRange={setDateRange} />
        <Space x={20} />
        <Select
          dataList={modifyAllText(serviceList)}
          val={serviceNo}
          setVal={setServiceNo}
        />
        <Space x={20} />
        <Button btnText={LANG[getLang()].search} size="md" onClick={onSubmit} />
        <Space x={10} />
        {product_level !== 5 ? (
          <Button btnText="엑셀다운로드" color="ready" size="md" />
        ) : (
          <ExcelButton data={dailyLog} obj={EXCEL_OBJ} fileName={`문의일별`} />
        )}
      </div>
      <Space y={20} />
      {dailyLog.length > 1 ? (
        <Bar options={options} data={data} plugins={data.plugins} />
      ) : null}
      <Space y={20} />
      <Table
        rowKey="day"
        columns={columns}
        dataSource={dailyLog}
        pagination={false}
        summary={(pageData) => {
          let totalSum = 0;
          let totalReplyComplete = 0;
          let totalReplyNo = 0;
          pageData.forEach(
            ({
              count_sum,

              count_reply_complete,
              count_reply_no,
              count_hidden,
            }) => {
              totalSum += count_sum;
              totalReplyComplete += count_reply_complete;
              totalReplyNo += count_reply_no;
            },
          );

          return (
            <>
              <Table.Summary.Row className="font-bold">
                <Table.Summary.Cell index={0} className="text-center">
                  {LANG[getLang()].sum}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} className="text-center">
                  <span>{totalSum}</span>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2} className="text-center">
                  <span>{totalReplyComplete}</span>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3} className="text-center">
                  <span>{totalReplyNo}</span>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
    </div>
  );
};

export default ChartDaily;
