import { ReducerType } from "Store/store";
import { HTMLAttributes, ReactNode } from "react";
import { useSelector } from "react-redux";
import { User } from "types/interface";

const sizeStyle = {
  xl: "px-24 leading-46 text-16 font-bold",
  md: "px-18 leading-38 text-14",
  sm: "px-20 leading-24 text-14",
};

const typeStyle = {
  primary: "bg-primary text-white",
  ready: "bg-e2e2e2 text-969696",
  normal: "bg-white text-black",
  delete: "bg-error text-white",
};

/**
 * 버튼 atomic
 * @param {string} name 텍스트
 * @param {'xl'|'sm'} size 사이즈 (xl)
 * @param {boolean} full Width 100% (false)
 * @param {boolean} type 백그라운드 배경색상(primary)
 * @param {boolean} grow flex-grow 여부(false)
 * @param {boolean} onClick 클릭시 실행할 함수
 * @param {number} standardLevel 기준레벨
 */

type ButtonProps = {
  btnText: string | ReactNode;
  size?: "xl" | "md" | "sm";
  full?: boolean;
  color?: "primary" | "ready" | "normal" | "delete";
  standardItem?: string;
} & React.ComponentProps<"button">;

const Button = ({
  btnText,
  size = "xl",
  full = false,
  color = "primary",
  standardItem,
  ...props
}: ButtonProps) => {
  const { check_code }: User = useSelector((state: ReducerType) => state.user);
  const itemNo = check_code?.findIndex((el) => el === standardItem) !== -1;
  const btnColor = () => {
    if (!standardItem || itemNo) {
      return typeStyle[color];
    }
    return typeStyle["ready"];
  };
  return (
    <button
      {...props}
      className={`${sizeStyle[size]} ${
        full && "w-full"
      } bold rounded ${btnColor()}`}
    >
      {btnText}
    </button>
  );
};

export default Button;
