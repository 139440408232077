import {
  getQnaImage,
  getValidationServiceApi,
  useGetFaQListApi,
} from "Apis/apis";
import Button from "Components/Atoms/Button/button";
import H2 from "Components/Atoms/H2/h2";
import Space from "Components/Atoms/Space/space";
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { logoutMode } from "Store/Slices/layouts";
import { useAppDispatch } from "Store/store";
import { useState } from "react";
import Label from "Components/Atoms/Label/label";
import parse from "html-react-parser";

const Menu = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const serviceNo = +(params.idx || -1);

  const [pageTitle, setPageTitle] = useState("");
  const [pageImg, setPageImg] = useState("");
  const [screen, setScreen] = useState(1);
  const [footer, setFooter] = useState("");
  const [valid, setValid] = useState<number>(-1); // 서비스 유효성 체크

  const subDomain = window.location.host.split(".")[0];
  const domainIdx = window.location.pathname.split("/")[2];
  const isDevelop = process.env.NODE_ENV === "development";

  const getValidation = async () => {
    const body = [subDomain, domainIdx];
    const {
      data: { service_valid },
    } = await getValidationServiceApi(body);
    setValid(service_valid);

    if (service_valid === 2 && !isDevelop) {
      alert("서비스가 존재하지않습니다.");
      return navigate(-1);
    }
  };
  const MENU_LIST = [
    { name: "1:1 문의", link: `/report/${serviceNo}` },
    { name: "약관/정책", link: `/policy/${serviceNo}` },
  ];

  const { data: faqList } = useGetFaQListApi(serviceNo);

  const getImage = async () => {
    const {
      data: { image },
    } = await getQnaImage(serviceNo);
    setPageTitle(image.page_title);
    setPageImg(image.banner || "/imgs/common/service_banner.jpg");
    setScreen(image.screen);
    setFooter(image.footer);
  };

  const layoutRender = () => {
    dispatch(logoutMode());
  };

  useEffect(() => {
    getImage();
    layoutRender();
    getValidation();
  }, []);

  if (!faqList) return null;
  return (
    <div className="h-[100vh] login-bg relative">
      <div className="absolute max-w-full bg-white w-460 p-center">
        {pageImg &&
          (screen ? (
            <img src={pageImg} alt="서비스배너" />
          ) : (
            <img
              className="object-cover w-full h-260"
              src={pageImg || ""}
              alt="서비스배너"
            />
          ))}
        <div className="px-40 py-48">
          <H2 name={pageTitle || ""} />
          <Space y={32} />
          <div className="flex flex-col gap-16">
            <Link to={`/faq/${serviceNo}`}>
              <div className="flex justify-between">
                <Label
                  name="FAQ 자주 묻는 질문"
                  labelfor="d"
                  fontSize={16}
                  fontBold="bold"
                />
                <span>전체보기</span>
              </div>
              <Space y={8} />
              <ul className="pt-8 border-gray-500 border-y-5">
                {faqList
                  .filter((_, idx) => idx < 5)
                  .map((faq) => (
                    <li className="mb-10 bold text-14">{faq.title}</li>
                  ))}
              </ul>
            </Link>
            {MENU_LIST.map((menu) => (
              <Link key={menu.name} to={menu.link}>
                <Button btnText={menu.name} full />
              </Link>
            ))}
          </div>

          <Space y={32} />
          <p className="text-center text-black text-12 ">
            {parse(footer || "")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Menu;
