import ChartDataLabels from "chartjs-plugin-datalabels";
import { useEffect, useState } from "react";
import { AccountLog } from "types/interface";
import { Bar } from "react-chartjs-2";
import { concatSum } from "Util/function";

const CTBar = ({ data }: { data: AccountLog[] }) => {
  const [labels, setLabels] = useState<string[]>([]);
  const [doneList, setDoneList] = useState<number[]>([]);
  const [noList, setNoList] = useState<number[]>([]);
  const [sumList, setSumList] = useState<number[]>([]);

  const options = {
    maxBarThickness: 100, // 막대 크기

    plugins: {
      title: {
        display: true,
        text: "상담원별 문의 현황",
        font: { size: 30 },
        padding: 50,
      },
      datalabels: {
        display: true,
        color: "black",
        font: (context: any) => {
          if (context.dataset.label === "전체문의") {
            return { size: 22, weight: "bold", color: "blue" };
          }
          return { size: 14, weight: "normal" };
        },
        anchor: (context: any) => {
          if (context.dataset.label === "전체문의") {
            return "start";
          }
          return "center";
        },
        align: (context: any) => {
          if (context.dataset.label === "전체문의") {
            return "top";
          }
          return "center";
        },
        formatter: (value: number, context: any) => {
          if (context.dataset.label === "전체문의") {
            if (value === 0) {
              return null;
            }
            // Format sum value
            return `${value}`;
          } else if (value === 0) {
            // Exclude data labels for zero values
            return null;
          } else {
            // Display other data values
            return value.toString();
          }
        },
      } as any,
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        min: 0,
        max: sumList.reduce((cur, acc) => cur + acc, 0),
      },
    },
  };
  const dataInfo = {
    labels,
    datasets: [
      {
        label: "답변완료",
        data: concatSum(doneList),
        backgroundColor: "rgb(37, 142, 228)",
        order: 0,
      },
      {
        label: "미처리",
        data: concatSum(noList),
        backgroundColor: "rgb(235, 96, 53)",
        order: 0,
      },
      {
        label: "전체문의",
        data: concatSum(sumList),
        backgroundColor: "rgba(0, 0, 0, 0)", // Transparent background for sum bars
        order: 1,
      },
    ],
  };

  const init = () => {
    const _labelList: string[] = [];
    const _doneList: number[] = [];
    const _noList: number[] = [];
    const _sumList: number[] = [];

    data?.forEach((el: AccountLog) => {
      _labelList.push(el.cs_name);
      _doneList.push(el.reply_complete);
      _noList.push(el.reply_no);
      _sumList.push(el.sum);
    });

    setLabels([..._labelList, "합계"]);
    setDoneList(_doneList);
    setNoList(_noList);
    setSumList(_sumList);
  };

  useEffect(() => {
    init();
  }, [data]);

  if (data?.length === undefined) return null;
  return <Bar options={options} data={dataInfo} plugins={[ChartDataLabels]} />;
};

export default CTBar;
