import React, { useState, useEffect } from "react";
import parse from "html-react-parser";
import { useDispatch } from "react-redux";

import { ReactComponent as LeftArrow } from "Util/svg/leftArrow.svg";
import { ReactComponent as SelectArrow } from "Util/svg/selectArrow.svg";
import { modalToggle } from "Store/Slices/modal";
import Background from "Components/Atoms/Background/background";
import { getServiceIdDetailApi } from "Apis/apis";
import Space from "Components/Atoms/Space/space";
import H2 from "Components/Atoms/H2/h2";
import dayjs from "dayjs";
const NOTICE = [...Array(3)].map((el, idx) => ({
  idx: idx + 1,
  content: "이것은 미리보기 공지사항",
}));
const CATEGORY = [
  {
    idx: 1,
    name: "대 카테고리",
  },
  {
    idx: 2,
    name: "중 카테고리",
  },
];
const PreviewLogoModal = ({
  modalNum,
  content,
  serviceNo,
  pageNmae,
  logoUrl,
}: {
  modalNum: number;
  content: string;
  pageNmae: string;
  serviceNo: number;
  logoUrl: string;
}) => {
  const dispatch = useDispatch();

  const date = dayjs().format("YYYY.MM.DD");

  useEffect(() => {}, [serviceNo]);
  return (
    <Background modalNum={modalNum}>
      <div
        style={{ width: "600px", height: "870px" }}
        className="p-32 overflow-auto login-bg pretty-scrollbar relative"
      >
        <div className="flex justify-end h-26">
          <div
            onClick={() => dispatch(modalToggle(1))}
            className="inline-block cursor-pointer"
          >
            <div className="brightness-[10] flex">
              <LeftArrow /> <span className="text-white">뒤로가기</span>
            </div>
          </div>
        </div>
        <div className="h-[100vh] relative  overflow-auto max-h-[80vh] bg-white rounded-lg w-460 p-center  pretty-scrollbar">
          <div className=" px-40 py-48">
            <div className="flex items-end ">
              <img
                src={logoUrl}
                alt="로고이미지"
                className=" h-48 w-48 rounded-lg"
              ></img>
              <Space x={8} />
              <div className="flex flex-col ">
                <span className="text-14 text-primary font-bold">
                  {pageNmae}
                </span>
                <span className="text-12 text-primary">{content}</span>
              </div>
            </div>
            <Space y={16} />
            <H2 name="1:1 문의" />
            <Space y={20} />
            {NOTICE.map((el) => (
              <div className="flex justify-between mb-16 border-b-1">
                <div className="text-sm ell flex-[1]">{el.content}</div>
                <Space x={8} />
                <span className="text-sm">{date}</span>
              </div>
            ))}
            <Space y={20} />
            <div className="flex gap-8">
              {CATEGORY.map((el) => (
                <div className="border-1 border-black rounded-md items-center gap-8 flex px-18 leading-[36px] font-bold text-sm">
                  {el.name}
                  <SelectArrow />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Background>
  );
};

export default PreviewLogoModal;
