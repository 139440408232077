import {
  getDetailNoitceApi,
  putBoardNoticeApi,
  delBoardNoticeApi,
} from "Apis/apis";
import Background from "Components/Atoms/Background/background";
import Button from "Components/Atoms/Button/button";
import H2 from "Components/Atoms/H2/h2";
import Space from "Components/Atoms/Space/space";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import modal, { modalToggle } from "Store/Slices/modal";
import LANG, { getLang } from "Util/lang";
import { ReactComponent as ModalX } from "Util/svg/modalX.svg";
import InputRow from "../InputRow/inputRow";

const BoardNoticeFixModal = ({
  getNotice,
  noticeNo,
}: {
  getNotice: () => void;
  noticeNo: number;
}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [contents, setContents] = useState("");
  const pass = (): boolean => {
    if (title === "") {
      alert("제목을 입력해주세요.");
      return false;
    } else if (contents === "") {
      alert("링크를 입력해주세요.");
      return false;
    }
    return true;
  };
  // 상세정보
  const getDetailNotice = async () => {
    const {
      data: { notice },
    } = await getDetailNoitceApi(noticeNo);
    setTitle(notice.title);
    setContents(notice.contents);
  };

  // 수정
  const putNotice = async () => {
    const body = {
      title,
      contents,
    };
    await putBoardNoticeApi(body, noticeNo);

    dispatch(modalToggle(2));
    getNotice();
  };

  // 삭제
  const delNotice = async () => {
    await delBoardNoticeApi(noticeNo);

    dispatch(modalToggle(2));
    getNotice();
  };

  useEffect(() => {
    getDetailNotice();
  }, [noticeNo]);

  return (
    <Background modalNum={2}>
      <div style={{ width: "640px" }} className="p-32 bg-f2f3f8">
        <div className="flex justify-between items-center">
          <H2 isPrimary={true} name="공지사항 수정" />

          <div
            className="inline-block cursor-pointer"
            onClick={() => dispatch(modalToggle(2))}
          >
            <ModalX />
          </div>
        </div>
        <Space y={8} />
        <InputRow
          id="title"
          name={LANG[getLang()].title}
          value={title}
          setValue={setTitle}
        />
        <InputRow
          id="link"
          name={LANG[getLang()].link}
          value={contents}
          setValue={setContents}
          placeholder="https:// 를 함께 기입바랍니다."
        />
        <div className="flex justify-end mt-10">
          <Button btnText={LANG[getLang()].change} onClick={putNotice} />
          <Space x={10} />
          <Button
            color="delete"
            onClick={() => delNotice()}
            btnText={LANG[getLang()].delete}
          />
        </div>
      </div>
    </Background>
  );
};

export default BoardNoticeFixModal;
