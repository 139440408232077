import React, { useState, useEffect } from "react";
import Select from "Components/Atoms/Select/select";
import Space from "Components/Atoms/Space/space";
import { useSelector } from "react-redux";
import { ReducerType } from "Store/store";
import { ClickInputForm, MiddleData, User } from "types/interface";
import { toCheckboxForm } from "Util/function";

import { getCategoryListApi } from "Apis/apis";
import LANG, { getLang } from "Util/lang";
import useDidMountEffect from "Util/Hooks/useDidMountEffect";

const DEFAULT_CT_IDX = -1;
const initBigCategory: ClickInputForm[] = [
  { idx: DEFAULT_CT_IDX, label: LANG[getLang()].lCategory },
];
const initMidCategory: ClickInputForm[] = [
  {
    idx: DEFAULT_CT_IDX,
    label: LANG[getLang()].mCategory,
  },
];

/**
 *
 * @param {number} serviceNo 서비스넘버 - 카테고리api에 사용
 * @param {number} bCode 선택된 빅카테고리 인덱스
 * @param {number} mCode 선택된 미드카테고리 인덱스
 * @param {number} activeOnly 대기상태도 보여줄건가
 * @returns
 */
const ServiceCategory = ({
  serviceNo,
  setServiceNo,
  bCode,
  setBCode,
  mCode,
  setMCode,
  service = true,
  border = false,
  activeOnly = false,
}: {
  serviceNo: number;
  setServiceNo?: React.Dispatch<React.SetStateAction<number>>;
  bCode: number;
  setBCode: React.Dispatch<React.SetStateAction<number>>;
  mCode: number;
  setMCode: React.Dispatch<React.SetStateAction<number>>;
  service?: boolean;
  border?: boolean;
  activeOnly?: boolean;
}) => {
  const serviceList = useSelector((state: ReducerType) =>
    state.serviceList
      .filter((el) => el.state !== 2 && el.state !== 1)
      .map((el) => (el.idx === -1 ? { ...el, label: "서비스 선택" } : el)),
  );

  const [bCodeList, setBCodeList] = useState<ClickInputForm[]>(initBigCategory); // 서비스별 대카테고리
  const [mCodeList, setMCodeList] = useState<ClickInputForm[]>(initMidCategory); //  대카테고리별 중카테고리
  const [allMCodeList, setAllMCodeList] = useState<MiddleData[]>([]); // 서비스별 중카테고리

  const getCategoryList = async () => {
    setBCodeList(initBigCategory);
    if (serviceNo !== -1) {
      const {
        data: {
          category: { big_category, middle_category },
        },
      } = await getCategoryListApi(serviceNo);

      const filter_bigCategory = big_category.filter(
        (bCode) => bCode.state === 0 || (bCode.state === 1 && !activeOnly),
      );

      const bigCategory: ClickInputForm[] = toCheckboxForm(
        filter_bigCategory,
        "big_category_code",
        "big_category_name",
      );
      setBCodeList((bCodeList) => bCodeList.concat(bigCategory));
      setAllMCodeList(middle_category);
      setMidCt(middle_category, bCode);
    } else {
      setMCodeList(initMidCategory);
      setMCode(-1);
    }
  };

  // 중카테고리
  const setMidCt = (allMCodeList: MiddleData[], bcode: number) => {
    setMCodeList(initMidCategory);

    if (bcode !== -1) {
      const nowMidCt = allMCodeList.filter(
        (mCode) =>
          (mCode.big_category_code === bcode && mCode.state === 0) ||
          (mCode.state === 1 && !activeOnly),
      );

      const midCategory: ClickInputForm[] = toCheckboxForm(
        nowMidCt,
        "middle_category_code",
        "middle_category_name",
      );
      setMCodeList((mCodeList) => mCodeList.concat(midCategory));
    }
  };

  useEffect(() => {
    getCategoryList();
  }, [serviceNo]);

  useDidMountEffect(() => {
    setMidCt(allMCodeList, bCode);
  }, [bCode]);

  return (
    <>
      {service && (
        <>
          <Select
            border={border}
            dataList={serviceList}
            val={serviceNo}
            onChange={(key) => {
              setServiceNo && setServiceNo(key);
              setBCode(-1);
            }}
          />
          <Space x={20} />
        </>
      )}
      <Select
        border={border}
        dataList={bCodeList}
        val={bCode}
        setVal={(el) => {
          setMCode(-1);
          setBCode(el);
        }}
      />

      <Space x={20} />
      <Select
        border={border}
        dataList={mCodeList}
        val={mCode}
        setVal={setMCode}
      />
    </>
  );
};

export default ServiceCategory;
