import Input from "Components/Atoms/Input/input";
import Label from "Components/Atoms/Label/label";
import Space from "Components/Atoms/Space/space";
import { ReactComponent as Require } from "Util/svg/require.svg";
import { ReactComponent as Check } from "Util/svg/check.svg";
import { ReactComponent as Fail } from "Util/svg/fail.svg";
import Button from "Components/Atoms/Button/button";
import { ChangeEvent, useRef, useState } from "react";

/**
 * 로그인페이지 : ID PW 인풋창
 */
const InputRowFile = ({
  id,
  name,
  value = "",
  setValue,
  require = true,
  width = "w-130",
  placeholder = "",
  setImgUrl,
}: {
  id: string;
  name: string;
  value?: string;
  setValue: React.Dispatch<React.SetStateAction<File>>;
  setImgUrl?: React.Dispatch<React.SetStateAction<string>>;
  require?: boolean;
  width?: string;
  placeholder?: string;
}) => {
  const [imgName, setImgName] = useState("");

  const imgUpload = useRef<HTMLInputElement>(null); // 파일업로드 input

  const getfile = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files!;
    const maxSize = 10 * 1024 * 1024;
    const fileSize = files[0].size;
    if (fileSize > maxSize)
      return alert("10MB를 초과하는 파일은 지원하지않습니다.");

    // const TYPES = ["png", "jpg", "jpeg", "hwp", "pdf", "xlsx", "xlx", "txt"];
    // const filesType = files[0].name.split(".")[1];

    // if (TYPES.findIndex((el) => el === filesType) === -1)
    //   return alert("파일확장자를 지원하지 않습니다.");
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = function (files: any) {
      setImgUrl && setImgUrl(files.target.result);
    };
    setValue(files[0]);
    setImgName(files[0].name);
  };

  return (
    <div className="flex items-center">
      <div className={`flex items-center px-16 py-20 bg-e5e6ed ${width}`}>
        <Label labelfor={id} name={name} fontSize={16} />
        <Space x={4} />
        {require && <Require />}
      </div>
      <div className="flex-1 h-full bg-white p-13">
        <div className="flex items-center">
          <div className="w-300">
            <Input
              id={id}
              value={imgName ? imgName : value}
              placeholder={placeholder}
              readOnly={true}
            />
          </div>
          <Space x={15} />
          <Button
            onClick={() => imgUpload.current?.click()}
            size="md"
            btnText="파일선택"
          />
          <input
            className="hidden opacity-0"
            onChange={(e) => getfile(e)}
            ref={imgUpload}
            type="file"
          />
        </div>
      </div>
    </div>
  );
};

export default InputRowFile;
