import { useState, useEffect } from "react";

import Background from "Components/Atoms/Background/background";
import H2 from "Components/Atoms/H2/h2";
import Space from "Components/Atoms/Space/space";
import Button from "Components/Atoms/Button/button";
import { modalToggle } from "Store/Slices/modal";
import { ReducerType, useAppDispatch } from "Store/store";
import { ReactComponent as ModalX } from "Util/svg/modalX.svg";
import InputText from "../InputText/inputText";
import InputRow from "../InputRow/inputRow";
import { PutAlarm } from "types/interface";
import { useSelector } from "react-redux";
import { putAlarmApi, useGetAlarmListApi } from "Apis/apis";
import { useQueryClient } from "@tanstack/react-query";
import LANG, { getLang } from "Util/lang";

const TelegramModal = ({ serviceNo }: { serviceNo: number }) => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const modalNum = useSelector((state: ReducerType) => state.modal);
  const { data } = useGetAlarmListApi();
  const service = data?.find((el) => el.service_no === serviceNo);

  const [adminToken, setAdminToken] = useState("");
  const [allRoomId, setAllRoomId] = useState("");
  const [vipRoomId, setVipRoomId] = useState("");
  const [keywordRoomId, setKeywordRoomId] = useState("");
  const [smsKey, setSmsKey] = useState("");
  const [identifier, setIdentifier] = useState("");
  const [sender, setSender] = useState("");

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const confirm = window.confirm("수정 하시겠습니까?");
    if (!confirm) return;

    const body: PutAlarm = {
      serviceNo: serviceNo,
      adminToken,
      allRoomId,
      vipRoomId,
      keywordRoomId,
      smsKey,
      identifier,
      sender,
    };

    await putAlarmApi(body);
    queryClient.invalidateQueries(["useGetAlarmListApi"]);
    dispatch(modalToggle(2));
  };

  const init = () => {
    if (!service) return;
    setAdminToken(service.admin_token);
    setAllRoomId(service.all_room_id);
    setVipRoomId(service.vip_room_id);
    setKeywordRoomId(service.keyword_room_id);
    setSmsKey(service.sms_key);
    setIdentifier(service.identifier);
    setSender(service.sender);
  };

  useEffect(() => {
    init();
    // 입력 잘못해서 다시 돌이시키고 싶을때 모달 껏다 키면 초기화되게끔 구현
  }, [modalNum]);

  if (!service) return null;

  return (
    <Background modalNum={2}>
      <div style={{ width: "640px" }} className="p-32 bg-f2f3f8">
        <div className="flex justify-between items-center">
          <H2 isPrimary={true} name="텔레그램 정보 관리" />
          <div
            className="inline-block cursor-pointer"
            onClick={() => dispatch(modalToggle(2))}
          >
            <ModalX />
          </div>
        </div>
        <Space y={20} />
        <form action="" onSubmit={onSubmit}>
          <InputText name="서비스" text={service.service_name} />
          <InputRow
            id="telegramToken"
            name="텔레그램 토큰"
            value={adminToken}
            setValue={setAdminToken}
            require={false}
          />
          <InputRow
            id="allChatId"
            name="전체알림 ID"
            value={allRoomId}
            setValue={setAllRoomId}
            require={false}
          />
          <InputRow
            id="vipChatId"
            name="vip알림 ID"
            value={vipRoomId}
            setValue={setVipRoomId}
            require={false}
          />
          <InputRow
            id="keywordChatId"
            name="키워드알림 ID"
            value={keywordRoomId}
            setValue={setKeywordRoomId}
            require={false}
          />
          <InputRow
            id="sms"
            name="알리고 api 키"
            value={smsKey}
            setValue={setSmsKey}
            require={false}
          />
          <InputRow
            id="sms"
            name="알리고 유저 ID"
            value={identifier}
            setValue={setIdentifier}
            require={false}
          />
          <InputRow
            id="sms"
            name="발신번호"
            value={sender}
            setValue={setSender}
            require={false}
          />
          <Space y={20} />
          <div className="flex justify-end">
            <Button btnText={LANG[getLang()].change} type="submit" />
          </div>
        </form>
      </div>
    </Background>
  );
};

export default TelegramModal;
