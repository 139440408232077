import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: string = "";

export const imgModalUrl = createSlice({
  name: "imgModalUrl",
  initialState,
  reducers: {
    setUrl: (state, action: PayloadAction<string>) => {
      return action.payload;
    },
  },
});

export const { setUrl } = imgModalUrl.actions;
export default imgModalUrl.reducer;
