import QnaLabel from "Components/Atoms/QnaLabel/qnaLabel";
import Space from "Components/Atoms/Space/space";
import { useAppDispatch } from "Store/store";
import { modalToggle } from "Store/Slices/modal";
import FileLIst from "Components/Atoms/FileList/fileList";
import { AnswerHistory } from "types/interface";
import { setUrl } from "Store/Slices/imgModalUrl";

const QnaInputTextBtn = ({
  name,
  fileList,
  fileNameList,
  serviceNo,
}: {
  name: string;
  fileList: string[];
  fileNameList: string[];
  serviceNo: number;
}) => {
  const dispatch = useAppDispatch();

  const onClickBtn = (url: string) => {
    dispatch(setUrl(url));
    dispatch(modalToggle(9));
  };
  return (
    <div className={`flex items-center border-b-1 `}>
      <QnaLabel name={name} />
      <Space x={20} />
      <FileLIst
        onClick={onClickBtn}
        fileList={fileList}
        fileNameList={fileNameList}
        type={0}
        serviceNo={serviceNo}
      />
    </div>
  );
};

export default QnaInputTextBtn;
