import React, { useState } from "react";
import Input from "Components/Atoms/Input/input";
import Label from "Components/Atoms/Label/label";
import { ReactComponent as Require } from "Util/svg/require.svg";
import Space from "Components/Atoms/Space/space";
// import "./loginInput.scss";

/**
 * 로그인페이지 : ID PW 인풋창
 * @param {string} id 라벨 및 인풋 같이 사용할 아이디
 * @param {string} type Input 타입
 * @param {string} name 라벨 이름
 * @param {string} placeholder 정의어
 * @param {string|number} value input 상태값
 * @param {React.Dispatch<React.SetStateAction<number>>} setValue input 상태값 변경함수
 */
const LoginInput = ({
  id,
  type,
  placeholder,
  name,
  value,
  setValue,
  required = false,
}: {
  id: string;
  type: string;
  name: string;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  placeholder?: string;
  required?: boolean;
}) => {
  const inputBox = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  return (
    <div className="user_input ">
      <div className="flex">
        <Label labelfor={id} name={name} />
        {required && <Require />}
      </div>
      <Space y={4} />
      <Input
        id={id}
        type={type}
        bottom={false}
        placeholder={placeholder}
        value={value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => inputBox(e)}
        required={required}
      />
    </div>
  );
};

export default LoginInput;
