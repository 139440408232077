import { useState, useEffect, ReactFragment } from "react";
import InputRowFile from "../InputRowFile/inputRowFile";
import Background from "Components/Atoms/Background/background";
import InputRow from "../InputRow/inputRow";
import H2 from "Components/Atoms/H2/h2";
import { ReactComponent as ModalX } from "Util/svg/modalX.svg";
import InputRowRadio from "../InputRowRadio/inputRowRadio";
import Space from "Components/Atoms/Space/space";
import Button from "Components/Atoms/Button/button";
import { modalToggle } from "Store/Slices/modal";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Require } from "Util/svg/require.svg";
import {
  ClickInputForm,
  MServiceCategory,
  PostServiceId,
  RadioForm,
  ServiceIdList,
} from "types/interface";
import {
  getServiceCategoryApi,
  getServiceIdListApi,
  postServiceIdApi,
} from "Apis/apis";
import { addServiceList } from "Store/Slices/serviceList";
import LANG, { getLang } from "Util/lang";
import { useLocation } from "react-router-dom";
import InputText from "../InputText/inputText";
import Label from "Components/Atoms/Label/label";
import Input from "Components/Atoms/Input/input";
import InputRowSelect from "../InputRowSelect/inputRowSelect";
import useDidMountEffect from "Util/Hooks/useDidMountEffect";
import { toCheckboxForm } from "Util/function";
const EVERYTOWN = "everytown";

const initBigCategory: ClickInputForm[] = [{ idx: -1, label: "대분류" }];
const initMidCategory: ClickInputForm[] = [
  {
    idx: -1,
    label: "소분류",
  },
];
/**
 * 모달 molecule
 *
 */
const ServiceIdAddModal = ({
  bCode,
  setBCode,
  mCode,
  setMCode,
  setServiceAllList,
}: {
  bCode: number;
  setBCode: React.Dispatch<React.SetStateAction<number>>;
  mCode: number;
  setMCode: React.Dispatch<React.SetStateAction<number>>;
  setServiceAllList: React.Dispatch<React.SetStateAction<ServiceIdList[]>>;
}) => {
  const dispatch = useDispatch();
  const [serviceName, setServiceName] = useState("");
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState(1);
  const [file, setFile] = useState<File>({} as File);
  const [BCodeList, setBCodeList] = useState<ClickInputForm[]>(initBigCategory); // 서비스별 대카테고리
  const [MCodeList, setMCodeList] = useState<ClickInputForm[]>(initMidCategory); //  대카테고리별 중카테고리
  const [allMCodeList, setAllMCodeList] = useState<MServiceCategory[]>([]); // 서비스별 중카테고리
  const subDomain = localStorage.getItem("subDomain")!;
  const supportMail = `csbye.com`;
  const game = window.location.host.split(".")[0];
  const hostUrl = game.includes("localhost") ? EVERYTOWN : game;

  const STATE_DATA: RadioForm[] = [
    { idx: 1, label: LANG[getLang()].waiting, id: "wait" },
    { idx: 0, label: LANG[getLang()].approval, id: "ok" },
  ];
  const pass = (): boolean => {
    if (serviceName === "") {
      alert("서비스명을 입력 해주세요");
      return false;
    }
    if (bCode === -1) {
      alert("대분류를 선택해주세요.");
      return false;
    }
    if (mCode === -1) {
      alert("소분류를 선택해주세요.");
      return false;
    }
    return true;
  };
  const enrollment = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const isPass: Boolean = pass();
    if (!isPass) return;

    const formData: any = new FormData();
    formData.append("serviceName", serviceName);
    formData.append("state", status);
    formData.append("supportMail", `${email}.${subDomain}@${supportMail}`);
    formData.append("slogo", file);
    formData.append("bigCategoryCode", bCode);
    formData.append("middleCategoryCode", mCode);

    const { data } = await postServiceIdApi(formData);

    const {
      data: { serviceList },
    } = await getServiceIdListApi("");
    setTimeout(
      () => setServiceAllList(serviceList.filter((el) => el.state !== 2)),
      700,
    );

    setServiceName("");
    setStatus(1);
    setEmail("");
    setBCode(-1);
    setMCode(-1);
    dispatch(modalToggle(1));

    const addReduxService = [{ idx: data.service_id, label: serviceName }];
    dispatch(addServiceList(addReduxService));
  };
  const getAllCategory = async () => {
    setBCodeList(initBigCategory);

    const {
      data: {
        service_category: { big_category, middle_category },
      },
    } = await getServiceCategoryApi();

    const bigCategory: ClickInputForm[] = toCheckboxForm(
      big_category.filter((el) => el.state === 0),
      "big_category_code",
      "big_category_name",
    );

    setBCodeList((bCodeList) => bCodeList.concat(bigCategory));
    setAllMCodeList(middle_category);
    setMidCt(middle_category, bCode);

    // setMCodeList(initMidCategory);
    // setBCode(-1);
    // setMCode(-1);
  };

  // 중카테고리
  const setMidCt = (allMCodeList: MServiceCategory[], bcode: number) => {
    setMCodeList(initMidCategory);

    if (bcode !== -1) {
      const nowMidCt = allMCodeList.filter(
        (mCode) => mCode.big_category_code === bcode && mCode.state === 0,
      );

      const midCategory: ClickInputForm[] = toCheckboxForm(
        nowMidCt,
        "middle_category_code",
        "middle_category_name",
      );
      setMCodeList((mCodeList) => mCodeList.concat(midCategory));
    }
  };

  useEffect(() => {
    getAllCategory();
  }, []);
  useDidMountEffect(() => {
    setMidCt(allMCodeList, bCode);
  }, [bCode]);
  return (
    <Background>
      <form onSubmit={enrollment}>
        <div style={{ width: "640px" }} className="p-32 bg-f2f3f8">
          <div className="flex justify-between items-center">
            <H2 isPrimary={true} name="서비스 명 등록" />
            <div
              className="inline-block cursor-pointer"
              onClick={() => dispatch(modalToggle(1))}
            >
              <ModalX />
            </div>
          </div>
          <Space y={20} />
          <InputRow
            id="service"
            name="서비스 명"
            value={serviceName}
            setValue={setServiceName}
            placeholder="서비스명을 입력해주세요."
            width="w-145"
          />
          <InputRowSelect
            dataList={BCodeList}
            dataList2={MCodeList}
            id="category"
            name="분류"
            setValue={(el) => {
              setMCode(-1);
              setBCode(el);
            }}
            value={bCode}
            setValue2={setMCode}
            value2={mCode}
            width="w-145"
          />
          <InputText
            name="계정명"
            text={`${hostUrl}.${supportMail}`}
            width="w-145"
          />
          <InputRowFile
            id="file"
            name="서비스 로고"
            setValue={setFile}
            width="w-145"
            placeholder="정사각형 픽셀 Ex) (140 * 140)"
            require={false}
          />
          <InputRowRadio
            data={STATE_DATA}
            name={LANG[getLang()].state}
            value={status}
            setValue={setStatus}
            width="w-145"
          />
          <div className="relative flex items-center bg-white">
            <InputRow
              id="email"
              name="고객지원 이메일"
              value={email}
              setValue={setEmail}
              type="text"
              require
              width="w-145"
              inputWidth="w-200"
              placeholder="영어 및 숫자만 입력 가능합니다"
              emailReg={true}
            />
            <span className="-ml-20">{`.${hostUrl} @ ${supportMail}`}</span>
          </div>
          <span className="text-">
            고객지원 이메일은 브랜드 문의 관련 발신 전용 이메일입니다.
          </span>
          <div className="flex justify-end mt-10">
            <Button
              onClick={() => dispatch(modalToggle(1))}
              btnText={LANG[getLang()].cancel}
            />
            <Space x={10} />
            <Button type="submit" btnText={LANG[getLang()].register} />
          </div>
        </div>
      </form>
    </Background>
  );
};

export default ServiceIdAddModal;
