import { ChangeEvent, useRef, useState } from "react";
import Label from "Components/Atoms/Label/label";
import Space from "Components/Atoms/Space/space";
import { ReactComponent as Require } from "Util/svg/require.svg";
import Input from "Components/Atoms/Input/input";
import Button from "Components/Atoms/Button/button";

const InputRowImg = ({
  id,
  name,
  setValue,
  imgUrl,
  setImgUrl,
  require = false,
  originName = "",
  placeholder = "",
  height = "h-150",
  width = "w-130",
  noImg = `150`,
}: {
  id: string;
  name: string;
  setValue: React.Dispatch<React.SetStateAction<File>>;
  imgUrl: string;
  setImgUrl: React.Dispatch<React.SetStateAction<string>>;
  require?: boolean;
  originName?: string;
  placeholder?: string;
  height?: string;
  width?: string;
  noImg?: string;
}) => {
  const imgUpload = useRef<HTMLInputElement>(null); // 파일업로드 input
  const [imgName, setImgName] = useState("");

  const getfile = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files!;

    const fileList = e.target.files!;
    const fileSize = fileList[0].size;
    const maxSize = 10 * 1024 * 1024;
    if (fileSize > maxSize)
      return alert("10MB를 초과하는 파일은 지원하지않습니다.");

    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = function (files: any) {
      setImgUrl(files.target.result);
    };

    setValue(files[0]);
    setImgName(files[0].name);
  };

  return (
    <div className="flex items-center">
      <div
        className={`flex items-center self-stretch px-16 py-20 bg-e5e6ed ${width}`}
      >
        <Label labelfor={id} name={name} fontSize={16} />
        <Space x={4} />
        {require ? <Require /> : ""}
      </div>
      <div className="flex-1 h-full bg-white p-13 ">
        <div className="relative flex items-center">
          <div className="w-300">
            <Input
              id={id}
              value={imgName ? imgName : originName}
              readOnly={true}
              placeholder={placeholder}
            />
          </div>
          <Space x={15} />
          <Button
            onClick={() => imgUpload.current?.click()}
            size="md"
            btnText="파일선택"
          />
          {imgUrl ? (
            <img
              src={imgUrl}
              alt="이미지"
              className={`${height} absolute bottom-0 left-[414px] cursor-pointer`}
              onClick={() => imgUpload.current?.click()}
            />
          ) : (
            <></>
          )}
        </div>
        <input
          className="hidden"
          onChange={(e) => getfile(e)}
          ref={imgUpload}
          type="file"
          accept="image/*"
        />
      </div>
    </div>
  );
};

export default InputRowImg;
