import React, { useRef } from "react";
import parse from "html-react-parser";
import { ReactComponent as Copy } from "Util/svg/copy.svg";
import { htmlToText } from "Util/function";

const Transe = ({ type, text }: { type: 0 | 1; text: string }) => {
  const textColor = type === 0 ? "bg-primary text-white" : "bg-e5e6ed";
  const divRef = useRef<HTMLDivElement>(null);

  const copyContent = async () => {
    if (!divRef.current) return;
    const texts = divRef.current.innerHTML.replaceAll("</p>", "\n");
    const parsed = htmlToText(texts || "");
    await navigator.clipboard.writeText(parsed);
  };

  return (
    <div className="bg-white rounded-b-md">
      <div
        className={`${textColor} p-16 rounded-t-md flex justify-between w-full`}
      >
        {type === 0 ? "원본" : "번역"}
        <button onClick={copyContent}>
          <Copy />
        </button>
      </div>
      <div ref={divRef} className="p-16 overflow-auto max-h-250">
        {parse(text)}
      </div>
    </div>
  );
};

export default Transe;
