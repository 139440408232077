import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useEffect, useState } from "react";
import { CategoryQnaList } from "types/interface";
import { Bar } from "react-chartjs-2";
import LIST from "./color";

const CategoryBar = ({ data }: { data: CategoryQnaList[] }) => {
  const [labels, setLabels] = useState<string[]>([]);
  const [doneList, setDoneList] = useState<number[]>([]);
  const [noList, setNoList] = useState<number[]>([]);
  const [sumList, setSumList] = useState<number[]>([]);

  const options = {
    maxBarThickness: 80, // 막대 크기
    plugins: {
      title: {
        display: true,
        text: "카테고리 별 통계차트",
        font: { size: 30 },
        padding: 50,
      },
      datalabels: {
        display: true,
        color: "black",
        font: (context: any) => {
          if (context.dataset.label === "전체문의") {
            return { size: 22, weight: "bold", color: "blue" };
          }
          return { size: 14, weight: "normal" };
        },
        anchor: (context: any) => {
          if (context.dataset.label === "전체문의") {
            return "start";
          }
          return "center";
        },
        align: (context: any) => {
          if (context.dataset.label === "전체문의") {
            return "top";
          }
          return "center";
        },
        formatter: (value: number, context: any) => {
          if (context.dataset.label === "전체문의") {
            if (value === 0) {
              return null;
            }
            // Format sum value
            return `${value}`;
          } else if (value === 0) {
            // Exclude data labels for zero values
            return null;
          } else {
            // Display other data values
            return value.toString();
          }
        },
      } as any,
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        min: 0,
        max: Math.max(...sumList.map((item) => item)) + 10,
      },
    },
  };
  const dataInfo = {
    labels,
    datasets: [
      {
        label: "답변완료",
        data: doneList,
        backgroundColor: "rgb(37, 142, 228)",
        order: 0,
      },
      {
        label: "미처리",
        data: noList,
        backgroundColor: "rgb(235, 96, 53)",
        order: 0,
      },
      {
        label: "전체문의",
        data: sumList,
        backgroundColor: "rgba(0, 0, 0, 0)",
        order: 1,
      },
    ],
  };

  const init = () => {
    const doneObj: { [index: string]: number } = {};
    const noObj: { [index: string]: number } = {};
    const sumObj: { [index: string]: number } = {};

    const labels = [];
    const _doneList = [];
    const _sumList = [];
    const _noList = [];

    const datas = data
      .map((dailyData) => dailyData.data.map((el) => el.middle))
      .flat()
      .flat()
      .filter((el) => el.mcode !== -1);
    datas.forEach((el) => {
      doneObj[el.mname] = doneObj[el.mname]
        ? el.reply_complete + doneObj[el.mname]
        : el.reply_complete;

      noObj[el.mname] = noObj[el.mname]
        ? el.reply_no + noObj[el.mname]
        : el.reply_no;

      sumObj[el.mname] = sumObj[el.mname] ? el.sum + sumObj[el.mname] : el.sum;
    });

    for (const key in doneObj) {
      labels.push(key);
      _doneList.push(doneObj[key]);
      _noList.push(noObj[key]);
      _sumList.push(sumObj[key]);
    }
    setLabels(labels);
    setDoneList(_doneList);
    setSumList(_sumList);
    setNoList(_noList);
  };

  useEffect(() => {
    init();
  }, [data]);

  if (data.length === 0) return null;
  return <Bar options={options} data={dataInfo} plugins={[ChartDataLabels]} />;
};

export default CategoryBar;
