import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useEffect, useState } from "react";
import { AccountLog, CategoryQnaList, TOTAL_TYPE } from "types/interface";
import LIST from "./color";
import { sum } from "Util/function";

const CTPieChart = ({ data, date }: { data: AccountLog[]; date: string }) => {
  const [labelList, setLabelList] = useState<string[]>([]);
  const [dataList, setDataList] = useState<number[]>([]);

  // 서비스 카테고리별 원형 문의 현황
  const options3 = {
    responsive: true,
    plugins: {
      legend: {
        labels: {
          filter: (el: any, now: any) => {
            return el.text !== "전체";
          },
          font: { size: 12, weight: "bold" },
        } as any,
        position: "bottom" as const,
      },
      title: {
        display: true,
        text: "상담원별 답변완료 비율",
        font: { size: 20 },
      },
      datalabels: {
        display: (el: any) => el.dataset.data[el.dataIndex] !== 0,
        color: "black",
        textAlign: "center",
        formatter: (value: any, context: any) => {
          const all = sum(context.dataset.data);
          const name = labelList[context.dataIndex];
          const percent = `${Math.floor((value / all) * 100)}%`;
          return [name, percent];
        },
      } as any,
    },
    scale: {
      x: {},
      y: {},
    },
  };

  const init = () => {
    // 필요한데이터
    // 1. 라벨 - string배열
    // 2. 데이터 - 라벨과 같은 길이의 number배열
    const _labelList: string[] = [];
    const _dataList: number[] = [];

    data.forEach((el: AccountLog) => {
      _labelList.push(el.cs_name);
      _dataList.push(el.reply_complete);
    });

    setLabelList(_labelList);
    setDataList(_dataList);
  };

  useEffect(() => {
    if (!data) return;
    init();
  }, [data]);

  const data3 = {
    plugins: [ChartDataLabels],
    labels: labelList,
    datasets: [
      {
        data: dataList,
        backgroundColor: LIST,
        datalabels: {
          font: {
            size: 30,
            weight: "bold",
          } as any,
        } as any,
      },
    ],
  };

  if (!data) return null;
  return (
    <>
      <div className="w-[800px] mx-auto my-0">
        <Pie options={options3} data={data3} plugins={[ChartDataLabels]} />
      </div>
    </>
  );
};

export default CTPieChart;
