import { Table } from "antd";
import Button from "Components/Atoms/Button/button";
import SearchSelect from "Components/Molecules/SearchSelect/searchSelect";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReducerType } from "Store/store";
import { ColumnsType } from "antd/es/table";
import useServiceNo from "Util/Hooks/useServiceIdx";
import { useLocation, useNavigate } from "react-router-dom";
import Space from "Components/Atoms/Space/space";
import { indexMode } from "Store/Slices/layouts";
import { DailyLog, GetProduct, GetSubscribeState, ProductInfo, TicketLog } from "types/interface";
import LANG, { getLang } from "Util/lang";
import useDatePicker from "Util/Hooks/useDatePicker";
import {
  getCompanyProductApi,
  getCsLogApi,
  getDailyLogApi,
  getSubscribeStateApi,
  getTicketLogApi,
} from "Apis/apis";
import dayjs from "dayjs";
import { comma, order } from "Util/function";
import H2 from "Components/Atoms/H2/h2";
import ReceiptStatus from "Components/Atoms/ReceiptStatus/receiptStatus";
import H4 from "Components/Atoms/H4/h4";



const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  //전일기준
  const DATE_FORMAT = "YYYY-MM-DD";
  const today = dayjs(new Date()).add(0, "day").format(DATE_FORMAT); // 오늘
  const yesterDay = dayjs(new Date()).add(-1, "day").format(DATE_FORMAT); // 어제/
  const beforeYesterDay = dayjs(new Date()).add(-2, "day").format(DATE_FORMAT); // 모레전

  const [dailyLog, setDailyLog] = useState<DailyLog[]>([]);
  const [ticketLog, setTicketLog] = useState<TicketLog[]>([]);
  const [serviceNo, setServiceNo] = useServiceNo();

  const [subcribeState, setSubscribeState] = useState<GetSubscribeState>({} as GetSubscribeState); // 상품 상태

  const serviceList = useSelector(
    (state: ReducerType) => state.serviceList
  ).filter((el) => el.state !== 2 && el.state !== 1);

  const { product_level } = useSelector((state: ReducerType) => state.user);
  const { check_code } = useSelector((state: ReducerType) => state.user);
  const isBoolean = check_code.findIndex((el) => el === "se03") !== -1;
  const [productData, setProductData] = useState<GetProduct>({} as GetProduct);
  const getDailyApi = async () => {
    const { service = serviceNo } = location.state || {};
    setServiceNo(service);

    const {
      data: { statistic },
    } = await getDailyLogApi(service, beforeYesterDay, today);
    const total: DailyLog = {
      day: "계",
      count_hidden: 0,

      count_reply_complete: 0,
      count_reply_no: 0,
      count_sum: 0,
    };
    statistic.forEach((el) => {
      total.count_hidden += el.count_hidden;
      total.count_reply_complete += el.count_reply_complete;
      total.count_reply_no += el.count_reply_no;
      total.count_sum += el.count_sum;
    });
    const _today = statistic.find((el: DailyLog) => el.day === today)!;
    const _yesterDay = statistic.find((el: DailyLog) => el.day === yesterDay)!;
    const _beforeYesterDay = statistic.find(
      (el: DailyLog) => el.day === beforeYesterDay
    )!;

    setDailyLog([total, _today, _yesterDay, _beforeYesterDay]);
  };
  const getCompanyProduct = async () => {
    const {
      data: { product },
    } = await getCompanyProductApi();
    setProductData(product);
  };
  const getTicketApi = async () => {
    const { service = serviceNo } = location.state || {};
    setServiceNo(service);

    const {
      data: { cs },
    } = await getTicketLogApi(service);
    setTicketLog(cs);
  };

  const getSubscribeState = async () => {
    const {
      data: { subscribe_state },
    } = await getSubscribeStateApi();
    setSubscribeState(subscribe_state);
  };

  useEffect(() => {
    dispatch(indexMode());
  }, []);

  useEffect(() => {
    getDailyApi();
    getCompanyProduct();
    if (isBoolean) {
      getTicketApi();
    }
  }, [location.state]);

  useEffect(() => {
    getSubscribeState();
  }, []);

  const onSubmit = () => {
    navigate("", {
      state: serviceNo,
    });
  };

  const columns: ColumnsType<TicketLog> = [
    {
      title: LANG[getLang()].index,
      dataIndex: "cs_no",
      align: "center",
      width: 70,
      render: (now, row) => order(ticketLog, row.cs_no, "cs_no"),
    },
    {
      title: "성명",
      dataIndex: "cs_name",
      align: "center",
      render: (now) => now,
    },

    {
      title: "상담원ID",
      dataIndex: "cs_id",
      align: "center",
    },
    {
      title: "서비스 명",
      dataIndex: "service_name",
      align: "center",
    },
    {
      title: "할당된 문의량",
      dataIndex: "count_sum",
      align: "center",
      width: 110,
      render: (now) => `${now}건`,
    },
    {
      title: "답변",
      dataIndex: "count_reply_complete",
      align: "center",
      width: 80,
      render: (now) => `${now}건`,
    },
    {
      title: "미처리",
      dataIndex: "count_reply_no",
      align: "center",
      width: 80,
      render: (now) => `${now}건`,
    },
  ];

  return (
    <div>
      <div className="flex flex-col">
        <div className="flex flex-col">
          <span className="text-20 font-bold text-[#1c274c]">결제상품</span>
          <Space y={8} />
          <div className="flex justify-between h-auto py-16 bg-white px-18">
            <div className="flex">
              <img
                src={productData.product_image}
                className="w-100 h-100"
                alt="결제아이콘"
              />
              <Space x={16} />
              <div className="flex flex-col">
                <div className="flex items-center">
                  <H4 name={`${productData.product_name}상품`} />
                  <span className="font-bold text-14 text-primary">
                    {productData.product_no === 2
                      ? ""
                      : subcribeState.state === 2
                      ? `( ${productData.next_date} 까지 이용 가능 )`
                      : `( 다음 결제일 : ${productData.next_date} )`}
                  </span>
                </div>
                <div className="flex-col gap-10">
                  <div>
                    <span className="font-bold text-14 text-primary">
                      서비스 ID {productData.limit_service}개 이용가능
                    </span>
                    <span>/</span>
                    <span className="font-bold text-14 text-primary">
                      시트당 {comma(+productData.price)}원
                    </span>
                  </div>
                  <div className="flex flex-col self-end">
                    <span className="font-bold text-18 text-181818 text-start">
                      현재 시트
                    </span>
                    <span className="font-bold text-14 text-primary text-start">{`상담원 수 ( ${productData.cs_count} 명) / 시트 수 ( ${productData.sheet_count} 석)`}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="self-end">
              <Button
                btnText="결제현황"
                size="md"
                onClick={() => navigate("/infomation/pay")}
              />
            </div>
          </div>
        </div>
        <Space y={24} />
        <SearchSelect
          dataList={serviceList}
          setValue={setServiceNo}
          onClick={onSubmit}
          value={serviceNo}
        />
        <Space y={16} />
        <span className="text-20 font-bold text-[#1c274c]">문의 접수 현황</span>
        <Space y={8} />
        <ReceiptStatus statusList={dailyLog} />
      </div>
      <Space y={24} />
      <span className="text-20 font-bold text-[#1c274c]">
        팀원별 티켓 처리 현황
      </span>
      <Space y={16} />
      <Table
        columns={columns}
        dataSource={ticketLog}
        rowKey="cs_no"
        pagination={{ defaultPageSize: 10 }}
      />
    </div>
  );
};

export default Dashboard;
