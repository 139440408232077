import React, { useState, useEffect } from "react";

import InputRow from "Components/Molecules/InputRow/inputRow";
import LabelQuill from "Components/Molecules/LabelQuill/labelQuill";
import InputRowRadio from "Components/Molecules/InputRowRadio/inputRowRadio";
import Space from "Components/Atoms/Space/space";
import Button from "Components/Atoms/Button/button";
import InputRowSelect from "Components/Molecules/InputRowSelect/inputRowSelect";
import InputText from "Components/Molecules/InputText/inputText";

import { useDispatch, useSelector } from "react-redux";
import { ReducerType } from "Store/store";

import useServiceNo from "Util/Hooks/useServiceIdx";

import { ADDBanner, RadioForm, PostBanner } from "types/interface";
import LANG, { getLang } from "Util/lang";
import {
  getServiceIdDetailApi,
  postAutoMailApi,
  postEmailBannerApi,
} from "Apis/apis";
import { useNavigate } from "react-router-dom";
import { modalToggle } from "Store/Slices/modal";
import InputRowImg from "Components/Molecules/InputRowImg/inputRowImg";
import PreviewAutoMailModal from "Components/Molecules/PreviewModal/previewAutoMailModal";
import ExampleAutoMailModal from "Components/Molecules/PreviewModal/ExampleAutoMailModal";
import InputRowBannerButton from "Components/Molecules/InputRowBannerButton/inputRowBannerButton";
import AddBannerModal from "Components/Molecules/AddBannerModal/addBannerModal";
const RADIO_TYPE: RadioForm[] = [
  {
    idx: 1,
    label: "OFF",
    id: "off",
  },
  {
    idx: 0,
    label: "ON",
    id: "on",
  },
];

const AutoAnswerAdd = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const serviceList = useSelector((state: ReducerType) =>
    state.serviceList.filter((el) => el.state !== 2 && el.state !== 1),
  );
  const [supportMail, setSupportMail] = useState("");
  const [serviceNo, setServiceNo] = useServiceNo();
  const [bannerFile, setBannerFile] = useState<File>({} as File);

  const [addFile1, setAddFile1] = useState<File>({} as File);
  const [addLink1, setAddLink1] = useState("");
  const [addName1, setAddName1] = useState("");
  const [addImgUrl1, setAddImgUrl1] = useState("");
  const [addFile2, setAddFile2] = useState<File>({} as File);
  const [addLink2, setAddLink2] = useState("");
  const [addName2, setAddName2] = useState("");
  const [addImgUrl2, setAddImgUrl2] = useState("");
  const [addFile3, setAddFile3] = useState<File>({} as File);
  const [addLink3, setAddLink3] = useState("");
  const [addName3, setAddName3] = useState("");
  const [addImgUrl3, setAddImgUrl3] = useState("");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [footer, setFooter] = useState("");
  const [state, setState] = useState(1);
  const [imgUrl, setImgUrl] = useState("");
  const fileInputs = [
    {
      type: 0,
      ad_name: addName1,
      url: addLink1,
      order: 1,
      madbanner: addFile1,
    },
    {
      type: 0,
      ad_name: addName2,
      url: addLink2,
      order: 2,
      madbanner: addFile2,
    },
    {
      type: 0,
      ad_name: addName3,
      url: addLink3,
      order: 3,
      madbanner: addFile3,
    },
  ];

  const getHelpMail = async () => {
    const {
      data: {
        service: { support_mail },
      },
    } = await getServiceIdDetailApi(serviceNo);
    setSupportMail(support_mail);
  };

  const pass = (): boolean => {
    if (serviceNo === -1) {
      alert("서비스를 선택해주세요.");
      return false;
    } else if (title === "") {
      alert("제목을 입력해주세요.");
      return false;
    } else if (content === "") {
      alert("내용을 입력해주세요.");
      return false;
    } else if (footer === "") {
      alert("푸터를 입력해주세요.");
      return false;
    }
    return true;
  };
  const postAutoMail = async () => {
    const isPass: boolean = pass();
    if (!isPass) return;

    const mailData: any = new FormData();
    mailData.append("serviceNo", serviceNo);
    mailData.append("title", title);
    mailData.append("contents", content);
    mailData.append("footer", footer);
    mailData.append("state", state);
    mailData.append("receive", bannerFile);
    const {
      data: { mail_auto_no },
    } = await postAutoMailApi(mailData);

    for (let i = 0; i < fileInputs.length; i++) {
      const { type, ad_name, url, order, madbanner } = fileInputs[i];
      if (ad_name === "" && url === "") continue;
      const bannerData: any = new FormData();
      bannerData.append("loadNo", mail_auto_no);
      bannerData.append("type", type);
      bannerData.append("order", order);
      bannerData.append("adName", ad_name);
      bannerData.append("url", url);
      bannerData.append("madbanner", madbanner);
      await postEmailBannerApi(bannerData);
    }

    alert("자동답변을 등록하셨습니다.");
    navigate("/user/maildesign", { state: { page: 1, serviceNo: serviceNo } });
  };

  useEffect(() => {
    getHelpMail();
  }, [serviceNo]);

  return (
    <>
      <div id="autoAdd">
        <InputRowSelect
          dataList={serviceList}
          id="service"
          name="분류"
          setValue={setServiceNo}
          value={serviceNo}
        />
        <InputText name="발신메일" text={supportMail} />
        <InputRowImg
          id="banner_img"
          name="브랜드 배너"
          setValue={setBannerFile}
          imgUrl={imgUrl}
          setImgUrl={setImgUrl}
          placeholder="450 x 100 사이즈 권장"
          noImg="450x100"
          height="h-100"
        />
        <InputRow
          id="title"
          name={LANG[getLang()].title}
          setValue={setTitle}
          value={title}
        />
        <LabelQuill
          id="content"
          name={LANG[getLang()].content}
          setText={setContent}
          text={content}
        />
        <LabelQuill
          id="footer"
          name="푸터"
          setText={setFooter}
          text={footer}
          placeHolder="Footer(하단바) 텍스트는 메일 하단에 표시되는 텍스트 정보입니다. 
주소, 대표 전화, 대표 이메일 등 기업의 주요 정보 및 공지사항을 Footer에 입력해보세요.
"
        />
        <InputRowBannerButton
          id="banner"
          name="배너 광고"
          Buttonidx={3}
          data={fileInputs}
        />
        <div className="relative">
          <InputText name="미리보기" text="" />
          <div className="absolute flex top-13 left-143">
            <Button
              btnText="미리보기"
              size="md"
              onClick={() => dispatch(modalToggle(4))}
            />
            <Space x={20} />
            <Button
              onClick={() => dispatch(modalToggle(5))}
              btnText="작성예시"
              size="md"
            />
          </div>
        </div>
        <InputRowRadio
          data={RADIO_TYPE}
          name="자동응답"
          value={state}
          setValue={setState}
        />
        ON으로 설정하면 고객 문의과 접수됨과 동시에 문의 접수 자동 답변이
        발송됩니다.
        <div className="flex justify-end gap-10 mt-10 ">
          <Button
            btnText={LANG[getLang()].list}
            onClick={() =>
              navigate("/user/maildesign", {
                state: { page: 1, serviceNo: serviceNo },
              })
            }
          />
          <Button btnText={LANG[getLang()].register} onClick={postAutoMail} />
        </div>
      </div>
      <AddBannerModal
        modalNum={1}
        setFile={setAddFile1}
        setLink={setAddLink1}
        link={addLink1}
        addName={addName1}
        setAddName={setAddName1}
        serviceNo={serviceNo}
        file={addFile1}
        setImgUrl={setAddImgUrl1}
      />
      <AddBannerModal
        modalNum={2}
        setFile={setAddFile2}
        setLink={setAddLink2}
        link={addLink2}
        addName={addName2}
        setAddName={setAddName2}
        serviceNo={serviceNo}
        file={addFile2}
        setImgUrl={setAddImgUrl2}
      />
      <AddBannerModal
        modalNum={3}
        setFile={setAddFile3}
        setLink={setAddLink3}
        link={addLink3}
        addName={addName3}
        setAddName={setAddName3}
        serviceNo={serviceNo}
        file={addFile3}
        setImgUrl={setAddImgUrl3}
      />
      <PreviewAutoMailModal
        modalNum={4}
        title={title}
        imgUrl={imgUrl}
        content={content}
        footer={footer}
        addImgUrl1={addImgUrl1}
        addImgUrl2={addImgUrl2}
        addImgUrl3={addImgUrl3}
        url1={addLink1}
        url2={addLink2}
        url3={addLink3}
      />
      <ExampleAutoMailModal modalNum={5} />
    </>
  );
};

export default AutoAnswerAdd;
