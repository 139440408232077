import { useState, useEffect } from "react";

import Background from "Components/Atoms/Background/background";
import H2 from "Components/Atoms/H2/h2";
import Space from "Components/Atoms/Space/space";
import Button from "Components/Atoms/Button/button";
import { modalToggle } from "Store/Slices/modal";
import { useAppDispatch } from "Store/store";
import { ReactComponent as ModalX } from "Util/svg/modalX.svg";
import LANG, { getLang } from "Util/lang";
import { payCancleApi } from "Apis/apis";

const OutModal = ({ date }: { date: string }) => {
  const dispatch = useAppDispatch();
  const year = date.split("-")[0];
  const month = date.split("-")[1];
  const day = date.split("-")[2];
  const payCancle = async () => {
    await payCancleApi();
    alert("플랜 자동결제 해지하였습니다");
    dispatch(modalToggle(1));
  };
  return (
    <Background modalNum={1}>
      <div style={{ width: "640px" }} className="p-32 bg-f2f3f8">
        <div className="flex justify-between items-center">
          <H2 isPrimary={true} name="플랜 자동 결제 해지" />
          <div
            className="inline-block cursor-pointer"
            onClick={() => dispatch(modalToggle(1))}
          >
            <ModalX />
          </div>
        </div>
        <Space y={20} />
        <p>
          서비스는 최근 결제일의 한 달 후인 {year}년 {month}월 {day}일까지
          사용할 수 있습니다. 구독 서비스 이용일 종료 후 계정 삭제 처리 절차가
          진행됩니다.
        </p>
        <Space y={16} />
        <div className="flex justify-end">
          <Button btnText="해지하기" onClick={payCancle} />
          <Space x={8} />
          <Button
            btnText={LANG[getLang()].cancel}
            type="button"
            onClick={() => dispatch(modalToggle(1))}
          />
        </div>
      </div>
    </Background>
  );
};

export default OutModal;
