import React, { useEffect, useState } from "react";
import Space from "Components/Atoms/Space/space";
import QnaTemplate from "Components/Molecules/QnaTemplate/qnaTemplate";
import Quill from "Components/Atoms/Quill/quill";
import QnaFile from "Components/Molecules/QnaFile/qnaFile";
import {
  putAnswerApi,
  putQuestionApi,
  putReservationAnswerApi,
  putUnHideApi,
  useGetQDetailApi,
} from "Apis/apis";
import {
  GetQnAListApi,
  GetQnAListApiBody,
  ReduxQnAOption,
} from "types/interface";
import TranceBtnList from "Components/Molecules/TranceBtn/tranceBtn";
import Button from "Components/Atoms/Button/button";
import TPContext from "Util/Hooks/useTPContext";
import RotateElement from "Components/Atoms/RotateElement/rotateElement";
import { ReactComponent as ArrowSVG } from "Util/svg/arrow.svg";
import TimeModal from "Components/Molecules/TimeModal/timeModal";
import { ReducerType, useAppDispatch } from "Store/store";
import { modalToggle } from "Store/Slices/modal";
import BlackListModal from "Components/Molecules/BlackListModal/blackListModal";
import MemoModal from "Components/Molecules/MemoModal/memoModal";
import QnaInputLabel from "Components/Molecules/QnaInputLabel/qnaInputLabel";
import IconBtn from "Components/Atoms/Button/iconBtn";
import LANG, { getLang } from "Util/lang";
import { useQueryClient } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import FileModal from "Components/Molecules/FileModal/fileModal";

import { levelCheck } from "Store/Slices/user";
import { setQIdx } from "Store/Slices/qIdx";

const QnaConsultForm = ({
  serviceNo,
  reply,
  setReply,
}: {
  serviceNo: number;
  reply: string;
  setReply: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const user = useSelector((state: ReducerType) => state.user);
  const qIdx = useSelector((state: ReducerType) => state.qIdx);

  const { date, ...searchoption }: ReduxQnAOption = useSelector(
    (state: ReducerType) => state.qnaSearchOption,
  );

  const { data } = useGetQDetailApi(qIdx);
  const title = data ? `[Re:] ${data.qna.title}` : "";
  const assignmentCT = data?.qna.cs_name;
  const userId = data?.qna.user_id || "";
  const userEmail = data?.qna.email || "";
  const qState = data?.qna.state || 0;

  const reduxBody: GetQnAListApiBody = {
    startDate: date[0],
    endDate: date[1],
    ...searchoption,
  };

  const [fileList, setFileList] = useState<File[]>([]);
  const [scheduledTime, setScheduledTime] = useState("");

  const authCheck = () => {
    const idx = user.clone_idx || user.idx;
    if (!data || data.qna.cs_no !== idx) return false;
    return true;
  };

  // 답변 및 임시답변
  const postAnswer = async (type: "post" | "save") => {
    const auth = authCheck();
    if (user.level !== 1 && !auth) {
      alert(`${assignmentCT}에게 할당된 문의입니다.`);
      return;
    }
    const typeWord = type === "post" ? "답변" : "임시답변";

    const confirm = window.confirm(`${typeWord} 하시겠습니까?`);
    if (!confirm) return;

    const apiState = type === "post" ? "0" : "1"; // 승인 : 0, 임시답변 : 1

    const formData: FormData = new FormData();
    formData.append("contents", reply);
    formData.append("state", apiState);
    for (let i = 0; i < fileList.length; i++) {
      formData.append("reply", fileList[i]);
    }

    await putAnswerApi(qIdx, formData);
    queryClient.invalidateQueries(["QnaListApi", reduxBody]);
    queryClient.invalidateQueries(["useGetQDetailApi", qIdx]);
    alert(`답변이 완료 되었습니다.`);
  };

  // 답변예약
  const onClickReservation = async () => {
    const confirm = window.confirm(`예약 하시겠습니까?`);
    if (!confirm) return;

    const formData: FormData = new FormData();
    formData.append("contents", reply);
    formData.append("scheduledTime", scheduledTime);
    for (let i = 0; i < fileList.length; i++) {
      formData.append("reply", fileList[i]);
    }

    await putReservationAnswerApi(qIdx, formData);
    dispatch(modalToggle(8));
    queryClient.invalidateQueries(["useGetQDetailApi", qIdx]);
    queryClient.invalidateQueries(["QnaListApi", reduxBody]);
  };

  // 숨김
  const onClickHidden = async () => {
    const msg = "숨김 처리 후 목록에서 사라집니다. 정말로 하시겠습니까?";
    const confirm = window.confirm(msg);
    if (!confirm) return;

    if (qState === 6) {
      alert("예약중인 답변은 숨김이 불가합니다.");
      return;
    }
    const body = {
      state: 3,
    };
    await putQuestionApi(qIdx, body);
    queryClient.invalidateQueries(["QnaListApi", reduxBody]);
  };

  // 숨김 해제
  const onClickUnHide = async () => {
    const msg = "숨김 해제 하시겠습니까?";
    const confirm = window.confirm(msg);
    if (!confirm) return;
    await putUnHideApi(qIdx);
    queryClient.invalidateQueries(["QnaListApi", reduxBody]);
  };

  // 기타처리
  const onClickEtc = async () => {
    const confirm = window.confirm("기타처리 하시겠습니까?");
    if (!confirm) return;

    const body = {
      state: 5,
    };
    await putQuestionApi(qIdx, body);
    queryClient.invalidateQueries(["QnaListApi", reduxBody]);
  };
  // 권한체크 후 경고
  const authAlert = () => {
    if (!authCheck()) {
      alert(`${assignmentCT}에게 할당된 문의입니다.`);
    }
  };

  useEffect(() => {
    setFileList([]);
  }, [qIdx]);

  return (
    <div className="p-16 rounded bg-ffffff">
      <div className="flex">
        <div className="flex-[1] flex items-center">
          <div className="flex-[1]">
            <QnaInputLabel
              name={LANG[getLang()].recipient}
              readonly
              id="consultSender"
              value={userEmail}
            />
          </div>
        </div>

        <Space x={20} />
        <div className="flex-[1]">
          <QnaInputLabel
            name={LANG[getLang()].title}
            id="consultMenu"
            value={title}
            readonly
          />
        </div>
      </div>
      <Space y={27} />

      <div className="flex justify-between">
        <TPContext>
          <div className="flex">
            <QnaTemplate
              name={LANG[getLang()].tamplate}
              setContents={setReply}
              serviceNo={serviceNo}
            />
            <Space x={10} />
            <Button
              btnText={LANG[getLang()].delete}
              onClick={() => setReply("")}
              size="sm"
              color="normal"
            />
          </div>
        </TPContext>
        <div className="flex">
          <QnaInputLabel
            name={LANG[getLang()].attachedFile}
            id="attachedFile"
            value={`${fileList.length}개`}
            readonly
          />
          <Space x={20} />
          <Button
            btnText="파일 선택"
            size="sm"
            onClick={() => dispatch(modalToggle(16))}
          />
        </div>
        <Button
          btnText="티켓 메모"
          size="sm"
          onClick={() => dispatch(modalToggle(12))}
        />
      </div>
      <Space y={20} />
      <div onClick={authAlert}>
        <Quill value={reply} setValue={setReply} readOnly={!authCheck()} />
      </div>
      <TranceBtnList text={reply} onClick={setReply} />
      <Space y={20} />
      <div className="flex justify-between">
        <div className="flex gap-20">
          <Button
            btnText={LANG[getLang()].preview}
            size="md"
            onClick={() => dispatch(modalToggle(10))}
          />
          <Button
            btnText={LANG[getLang()].temporarySave}
            size="md"
            type="button"
            onClick={() =>
              dispatch(
                levelCheck({
                  sl: "q09",
                  fuc: () => postAnswer("save"),
                }),
              )
            }
            standardItem="q09"
          />
          <div className="flex">
            <Button
              btnText={LANG[getLang()].sendReply}
              size="md"
              type="button"
              onClick={() => postAnswer("post")}
            />
            <IconBtn
              name={<RotateElement svg={<ArrowSVG />} isRotate={true} />}
              onClick={() => dispatch(modalToggle(8))}
            />
          </div>
          <Button
            btnText={LANG[getLang()].etc}
            size="md"
            onClick={onClickEtc}
          />
        </div>
        <div className="flex gap-20">
          {qState !== 3 ? (
            <Button
              btnText={LANG[getLang()].hide}
              size="md"
              color="delete"
              onClick={() => {
                dispatch(setQIdx(-1));
                return onClickHidden();
              }}
            />
          ) : (
            <Button
              btnText="숨김해제"
              size="md"
              color="delete"
              onClick={() => {
                dispatch(setQIdx(-1));
                return onClickUnHide();
              }}
            />
          )}
          <Button
            color="delete"
            btnText="BLACKLIST"
            onClick={() => dispatch(modalToggle(11))}
          />
        </div>
      </div>
      <TimeModal setTime={setScheduledTime} onSubmit={onClickReservation} />
      <BlackListModal serviceNo={serviceNo} userId={userId} />
      <MemoModal qIdx={qIdx} />
      <FileModal fileList={fileList} setFileList={setFileList} />
    </div>
  );
};

export default QnaConsultForm;
