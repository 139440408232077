import { Route, Routes, useLocation } from "react-router-dom";
import Layout from "./Layout/layout";
import routes from "routes";

// 뜯어질것들
import Login from "Pages/Login/login";
import Menu from "Pages/Inquiry/menu";
import Faq from "Pages/Inquiry/faq";
import Inquiry from "Pages/Inquiry/inquiry";
import Policy from "Pages/Inquiry/policy";
import CTQna from "Pages/ServiceCenter/qna";
import CsInfo from "Pages/UserInfo/csInfo";
import Out from "Pages/UserInfo/out";
import Pay from "Pages/Pay/pay";

import { useEffect } from "react";
import { useSelector } from "react-redux";
import { ReducerType, useAppDispatch } from "Store/store";
import { logout, setUser } from "Store/Slices/user";
import { User, ClickInputForm, ServiceListForm } from "types/interface";
import {
  getAddupNoticeApi,
  GetLabelApi,
  getServiceIdListApi,
  getTokenCheckApi,
} from "Apis/apis";
import { toCheckboxForm, getToken } from "Util/function";
import { setServiceList } from "Store/Slices/serviceList";

import Dashboard from "Pages/Index/dashboard";
import { logoutMode } from "Store/Slices/layouts";
import { ctMode } from "Store/Slices/layouts";
import { indexMode } from "Store/Slices/layouts";
import { init } from "Store/Slices/layouts";
import Index from "Pages/Index";
import ChartTest from "Pages/Examples/chartTest";
import Labeling from "Pages/UserInfo/labeling";
import Regist from "Pages/Inquiry/regist";
import Mail from "Pages/Inquiry/mail";
import { setNotice } from "Store/Slices/addupNotice";
const EVERYTOWN = "everytown";

const LOGOUT = [
  "login",
  "report",
  "menu",
  "/faq/",
  "/policy/",
  "regist",
  "sendmail",
];
const CT = ["ctqna", "csinfo", "labeling"];
// const COLOR_LIST = ["#D3D5EA", "#FFF", "#E7FDFF", "#DCF9F0", "#FFE5F2"];

const App = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const user: User = useSelector((state: ReducerType) => state.user);
  const { data: labelList } = GetLabelApi();
  const game = window.location.host.split(".")[0];
  const subDomain = game.includes("localhost") ? EVERYTOWN : game;
  // 로그인 체크
  const loginCheck = async () => {
    if (!getToken()) {
      dispatch(logout());
    } else {
      const data = await checkToken();
      dispatch(setUser(data.token));
    }
  };
  // 서비스아이디 리스트
  const getServiceIdList = async () => {
    const {
      data: { serviceList },
    } = await getServiceIdListApi("");
    const ListFilter = serviceList;
    const checkboxForm: ClickInputForm[] = toCheckboxForm(
      ListFilter,
      "service_no",
      "service_name",
    );

    const colorForm: ServiceListForm[] = checkboxForm.map((el, idx) => {
      const _colorService = {
        ...el,
        color: labelList?.find((label) => el.idx === label.service_no)?.label,
      } as ServiceListForm;

      const _stateService = {
        ..._colorService,
        state: ListFilter[idx].state,
      } as ServiceListForm;

      return _stateService;
    });

    const list: ServiceListForm[] = [
      { idx: -1, label: "서비스 선택" },
      ...colorForm,
    ];

    dispatch(setServiceList(list));
  };
  // CSBYE 공지사항
  const getNotice = async () => {
    const {
      data: { admin_notice },
    } = await getAddupNoticeApi();
    dispatch(setNotice(admin_notice.new));
  };

  const checkToken = async () => {
    const { data } = await getTokenCheckApi(subDomain);
    return data;
  };

  const sideFuc = () => {
    const url = location.pathname;

    const isLogoutMode = LOGOUT.findIndex((el) => url.includes(el)) !== -1;
    const isCTMode = CT.findIndex((el) => url.includes(el)) !== -1;
    const isIndexMode = url.includes("dashboard");

    if (isLogoutMode) {
      dispatch(logoutMode());
    } else if (isCTMode) {
      dispatch(ctMode());
    } else if (isIndexMode) {
      dispatch(indexMode());
    } else {
      dispatch(init());
    }
  };

  useEffect(() => {
    loginCheck();
  }, []);

  useEffect(() => {
    if (user.idx < 1) return;
    getServiceIdList();
  }, [user, labelList]);

  useEffect(() => {
    sideFuc();
  }, [location]);

  useEffect(() => {
    if (user.idx < 1) return;
    setTimeout(() => getNotice(), 150);
  }, [location.pathname, location.state]);

  if (user.idx === -1) {
    return <div></div>;
  } else {
    return (
      <div id="App">
        <Layout>
          <Routes>
            {/* 유저 */}
            <Route path="*" element={<Index />} />
            <Route path="/menu/:idx" element={<Menu />} />
            <Route path="/faq/:idx" element={<Faq />} />
            <Route path="/report/:idx" element={<Inquiry />} />
            <Route path="/policy/:idx" element={<Policy />} />

            {/* 비로그인 */}
            {user.idx <= 0 ? (
              <>
                {/* <Route path="/" element={<Login />} /> */}
                <Route path="/login" element={<Login />} />
              </>
            ) : (
              <>
                <Route path="/regist" element={<Regist />} />
                <Route path="/sendmail" element={<Mail />} />
                user.level === 1 ? (
                <>
                  <Route path="*" element={<Dashboard />} />
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/infomation/out" element={<Out />} />
                  <Route path="/pay" element={<Pay />} />
                  <Route path="/charttest" element={<ChartTest />} />
                  {routes.map((route: any) => (
                    <Route
                      key={route.path}
                      path={route.path}
                      element={<route.element />}
                    />
                  ))}
                </>
                ) : ( // 상담원
                <>
                  <Route path="*" element={<CTQna />} />
                  <Route path="/user/ctqna" element={<CTQna />} />
                  <Route path="/infomation/csinfo" element={<CsInfo />} />
                  <Route path="/infomation/labeling" element={<Labeling />} />
                </>
                )
              </>
            )}
          </Routes>
        </Layout>
      </div>
    );
  }
};

export default App;
