import { postDownLogApi } from "Apis/apis";

const IMG_EXTENTIONS = ["jpg", "jpeg", "png"];

const FileLIst = ({
  serviceNo,
  type,
  fileList,
  fileNameList,
  onClick,
}: {
  serviceNo?: number;
  type: 0 | 1;
  fileList: string[];
  fileNameList: string[];
  onClick: (url: string) => void;
}) => {
  const downLog = (originFilename: string) => {
    if (!serviceNo) return;
    const body = {
      serviceNo,
      type,
      originFilename,
    };
    postDownLogApi(body);
  };

  return (
    <div className={`flex items-center  `}>
      {fileList?.map((file: string, idx: number) => {
        const splitedUrl = file.split("?")[0].split(".");
        const extension = splitedUrl[splitedUrl.length - 1].toLowerCase();
        const isImg = IMG_EXTENTIONS.indexOf(extension) !== -1;

        if (isImg) {
          return (
            <button className="mr-20" key={idx} onClick={() => onClick(file)}>
              파일{idx + 1}.{extension}
            </button>
          );
        } else {
          return (
            <a
              href={file}
              onClick={() => downLog(fileNameList[idx])}
              className="mr-20"
              key={idx}
              download
            >
              파일{idx + 1}.{extension}
            </a>
          );
        }
      })}
    </div>
  );
};

export default FileLIst;
