import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ServiceListForm } from "types/interface";

const initialState: ServiceListForm[] = [];

export const serviceList = createSlice({
  name: "serviceList",
  initialState,
  reducers: {
    setServiceList: (
      state: ServiceListForm[],
      action: PayloadAction<ServiceListForm[]>
    ) => [...action.payload],

    addServiceList: (
      state: ServiceListForm[],
      action: PayloadAction<ServiceListForm[]>
    ) => {
      return [...state, ...action.payload];
    },

    deleteServiceList: () => {
      return initialState;
    },
  },
});

export const { setServiceList, deleteServiceList, addServiceList } =
  serviceList.actions;
export default serviceList.reducer;
