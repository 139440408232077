import Background from "Components/Atoms/Background/background";
import Button from "Components/Atoms/Button/button";
import H2 from "Components/Atoms/H2/h2";
import Space from "Components/Atoms/Space/space";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { modalToggle } from "Store/Slices/modal";
import { ReactComponent as ModalX } from "Util/svg/modalX.svg";
import InputRow from "../InputRow/inputRow";
import LANG, { getLang } from "Util/lang";
import { useQueryClient } from "@tanstack/react-query";
import { GetShortCutListApi, delShortCuts, putShortCuts } from "Apis/apis";
import { levelCheck } from "Store/Slices/user";

const ShortcutsPutModal = ({ idx }: { idx: number }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { data: shortCutList } = GetShortCutListApi();
  const shortCut = shortCutList?.find((el) => el.shortcut_no === idx);

  const [title, setTitle] = useState("");
  const [url, setUrl] = useState("");

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    const body = {
      title,
      url,
    };
    await putShortCuts(idx, body);
    queryClient.invalidateQueries(["GetShortCutListApi"]);
    alert("수정이 완료 되었습니다.");
    dispatch(modalToggle(-2));
  };

  const onDelete = async () => {
    const confirm = window.confirm("삭제 하시겠습니까?");
    if (!confirm) return;

    await delShortCuts(idx);
    queryClient.invalidateQueries(["GetShortCutListApi"]);
    dispatch(modalToggle(-2));
  };

  const init = () => {
    if (!shortCut) return;
    setTitle(shortCut.title);
    setUrl(shortCut.url);
  };

  useEffect(() => {
    init();
  }, [shortCut]);

  return (
    <Background modalNum={-4}>
      <div style={{ width: "640px" }} className="p-32 bg-f2f3f8">
        <div className="flex justify-between items-center">
          <H2 isPrimary={true} name="바로가기 수정" />
          <div
            className="inline-block cursor-pointer"
            onClick={() => dispatch(modalToggle(-2))}
          >
            <ModalX />
          </div>
        </div>
        <Space y={16} />
        <form
          onSubmit={(e) => {
            e.preventDefault();
            dispatch(
              levelCheck({
                sl: "q06",
                fuc: onSubmit,
              }),
            );
          }}
        >
          <InputRow
            id="title"
            name={LANG[getLang()].title}
            value={title}
            setValue={setTitle}
          />
          <InputRow
            id="link"
            name={LANG[getLang()].link}
            value={url}
            setValue={setUrl}
          />
          <div className="flex justify-end mt-10">
            <Button
              btnText={LANG[getLang()].delete}
              color="delete"
              onClick={() =>
                dispatch(
                  levelCheck({
                    sl: "q06",
                    fuc: onDelete,
                  }),
                )
              }
              type="button"
            />
            <Space x={10} />
            <Button
              btnText={LANG[getLang()].change}
              type="submit"
              standardItem="q06"
            />
          </div>
        </form>
      </div>
    </Background>
  );
};

export default ShortcutsPutModal;
