import CompanyUserInfo from "Pages/UserInfo/userInfo";
import Service from "Pages/Account/service";
import ServiceSetting from "Pages/PageSetting/serviceSetting";
import ServiceFix from "Pages/PageSetting/serviceFix";

import CTQnA from "Pages/ServiceCenter/qna";
import Telegram from "Pages/ServiceCenter/QnaCenterSetting/telegram";
import UserList from "Pages/ServiceCenter/QnaCenterSetting/vipPage";

import Category from "Pages/ServiceCenter/category";
import PotalBoard from "Pages/PotalBoard/potalBoard";
import FaqAdd from "Pages/Board/faqAdd";
import FaqFix from "Pages/Board/faqFix";
import PolicyAdd from "Pages/Board/policyAdd";
import PolicyFix from "Pages/Board/policyFix";
import AccountSetting from "Pages/Account/AccountSetting";
import Log from "Pages/Account/log";
import mailDesign from "Pages/ServiceCenter/QnaCenterSetting/mailDesign";
import AutoAnswerAdd from "Pages/ServiceCenter/QnaCenterSetting/autoAnswerAdd";
import AutoAnswerFix from "Pages/ServiceCenter/QnaCenterSetting/autoAnswerFix";
import EmailAdd from "Pages/ServiceCenter/QnaCenterSetting/emailAdd";
import EmailFix from "Pages/ServiceCenter/QnaCenterSetting/emailFix";
import ChartSetting from "Pages/Chart/chartSetting";
import AccountFix from "Pages/Account/accountFix";
import UserInfo from "Pages/UserInfo/userInfo";
import Labeling from "Pages/UserInfo/labeling";

import thirdCrumb from "Components/Atoms/ThirdCrumb/thirdCrumb";

import { Menu, MenuChild } from "types/interface";

import { ReactComponent as ManSVG } from "Util/svg/man.svg";
import { ReactComponent as MessegeSVG } from "Util/svg/messege.svg";
import { ReactComponent as ChartSVG } from "Util/svg/chart.svg";
import { ReactComponent as DocumentSVG } from "Util/svg/document.svg";
import { ReactComponent as CrownSVG } from "Util/svg/crown.svg";
import { ReactComponent as SettingSVG } from "Util/svg/setting.svg";
import { ReactComponent as InfomationSVG } from "Util/svg/infomation.svg";

import AddupNotice from "Pages/Infomation/addupNotice";
import DetailNotice from "Pages/Infomation/detailNotice";
import Pay from "Pages/Infomation/pay";
import AccountAdd from "Pages/Account/accountAdd";
import PayChange from "Pages/Infomation/payChange";
import Out from "Pages/UserInfo/out";

export const MENU: Menu[] = [
  {
    path: "service",
    svg: ManSVG,
    name: "서비스 설정",
    child: [
      {
        name: "서비스 등록",
        path: "service",
        element: Service,
        sidebar: true,
      },
      {
        name: "고객 포털 생성",
        path: "potalsetting",
        element: ServiceSetting,
        sidebar: true,
      },
      {
        name: thirdCrumb("고객 포털 생성", "설정"),
        path: "servicefix",
        element: ServiceFix,
        sidebar: false,
      },
      {
        name: thirdCrumb("고객 포털 생성", "카테고리"),
        path: "category",
        element: Category,
        sidebar: false,
      },
      {
        name: "포털 게시판",
        path: "potalboard",
        element: PotalBoard,
        sidebar: true,
      },
      {
        name: thirdCrumb("포털 게시판", "FAQ 등록"),
        path: "faqadd",
        element: FaqAdd,
        sidebar: false,
      },
      {
        name: thirdCrumb("포털 게시판", "FAQ 수정"),
        path: "faqfix/:idx",
        element: FaqFix,
        sidebar: false,
      },
      {
        name: thirdCrumb("포털 게시판", "약관/정책 등록"),
        path: "policyadd",
        element: PolicyAdd,
        sidebar: false,
      },
      {
        name: thirdCrumb("포털 게시판", "약관/정책 수정"),
        path: "policyfix/:idx",
        element: PolicyFix,
        sidebar: false,
      },
    ],
  },
  {
    path: "account",
    svg: MessegeSVG,
    name: "상담원 계정",
    child: [
      {
        name: "상담원 설정",
        path: "accountsetting",
        element: AccountSetting,
        sidebar: true,
      },
      {
        name: "상담원 등록",
        path: "accountadd",
        element: AccountAdd,
        sidebar: false,
      },
      {
        name: "상담원 수정",
        path: "accountfix/:idx",
        element: AccountFix,
        sidebar: false,
      },
      {
        name: "상담원 Log",
        path: "log",
        element: Log,
        sidebar: true,
      },
    ],
  },
  {
    path: "user",
    svg: InfomationSVG,
    name: "고객상담센터",
    child: [
      {
        name: "문의/답변",
        path: "ctqna",
        element: CTQnA,
        sidebar: true,
      },
      {
        name: "알림 설정",
        path: "telegram",
        element: Telegram,
        sidebar: true,
      },
      {
        name: "고객 리스트",
        path: "userlist",
        element: UserList,
        sidebar: true,
      },
      {
        name: "메일 디자인",
        path: "maildesign",
        element: mailDesign,
        sidebar: true,
      },
      {
        name: thirdCrumb("접수 메일", "등록"),
        path: "autoadd",
        element: AutoAnswerAdd,
        sidebar: false,
      },
      {
        name: thirdCrumb("접수 메일", "수정"),
        path: "autofix",
        element: AutoAnswerFix,
        sidebar: false,
      },
      {
        name: thirdCrumb("답변 메일", "등록"),
        path: "emailadd",
        element: EmailAdd,
        sidebar: false,
      },
      {
        name: thirdCrumb("답변 메일", "수정"),
        path: "emailfix",
        element: EmailFix,
        sidebar: false,
      },
    ],
  },
  {
    path: "chart",
    svg: ChartSVG,
    name: "리포트",
    child: [
      {
        name: "통계 페이지",
        path: "chartsetting",
        element: ChartSetting,
        sidebar: true,
      },
    ],
  },
  {
    path: "infomation",
    svg: SettingSVG,
    name: "정보",
    child: [
      { name: "결제 현황", path: "pay", element: Pay, sidebar: true },
      {
        name: thirdCrumb("결제 현황", "상품 변경"),
        path: "paychange",
        element: PayChange,
        sidebar: false,
      },
      {
        name: "개인정보 수정",
        path: "userinfo",
        element: UserInfo,
        sidebar: false,
      },
      {
        name: "회원 탈퇴",
        path: "out",
        element: Out,
        sidebar: false,
      },
      {
        name: "라벨링 설정",
        path: "labeling",
        element: Labeling,
        sidebar: false,
      },
      {
        name: "CSBYE 공지사항",
        path: "addupnotice",
        element: AddupNotice,
        sidebar: true,
      },
      {
        name: "공지사항 확인",
        path: "detailnotice",
        element: DetailNotice,
        sidebar: false,
      },
      {
        name: "상담원 정보 수정",
        path: "csinfo",
        sidebar: false,
      },
    ],
  },
];

const routes: MenuChild[] = MENU.map((menu: Menu) => {
  const newMenu = menu.child.map((child: MenuChild) => {
    const _child = { ...child };
    _child.path = `${menu.path}/${child.path}`;
    return _child;
  });
  return newMenu;
})
  .reduce((acc: MenuChild[], cur: MenuChild[]) => [...acc, ...cur])
  .filter((el: MenuChild) => {
    return el.element;
  });

export default routes;
