import Background from "Components/Atoms/Background/background";
import Button from "Components/Atoms/Button/button";
import H2 from "Components/Atoms/H2/h2";
import Space from "Components/Atoms/Space/space";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { modalToggle } from "Store/Slices/modal";
import { ReactComponent as ModalX } from "Util/svg/modalX.svg";
import InputRow from "../InputRow/inputRow";
import LANG, { getLang } from "Util/lang";
import InputText from "../InputText/inputText";
import { postUserMemoApi } from "Apis/apis";
import { setMemoListInfo } from "Store/Slices/memoListInfo";
import { useQueryClient } from "@tanstack/react-query";

import { User } from "types/interface";
import { ReducerType } from "Store/store";
import { levelCheck } from "Store/Slices/user";

const UserInfoModal = ({
  userId,
  serviceName,
  serviceNo,
}: {
  userId: string;
  serviceName: string;
  serviceNo: number;
}) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [memo, setMemo] = useState("");
  const { product_level }: User = useSelector(
    (state: ReducerType) => state.user,
  );
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const body = {
      userId,
      serviceNo,
      memo,
    };
    await postUserMemoApi(body);
    alert("등록이 완료 되었습니다.");
    setMemo("");
    queryClient.invalidateQueries(["GetMemoApi", serviceNo, userId]);
  };

  const onCickHistory = () => {
    dispatch(setMemoListInfo({ serviceNo, userId: userId }));
    dispatch(modalToggle(-5));
  };

  return (
    <Background modalNum={15}>
      <div style={{ width: "640px" }} className="p-32 bg-f2f3f8">
        <div className="flex items-center justify-between">
          <H2 isPrimary={true} name="고객 정보 확인" />
          <div
            className="inline-block cursor-pointer"
            onClick={() => dispatch(modalToggle(15))}
          >
            <ModalX />
          </div>
        </div>
        <Space y={16} />
        <form
          onSubmit={(e) => {
            e.preventDefault();
            dispatch(
              levelCheck({
                sl: "c03",
                fuc: () => onSubmit(e),
              }),
            );
          }}
        >
          <InputText text={userId} name="고객UID" />
          <InputText text={serviceName} name="서비스 명" />
          <InputRow
            id="link"
            name="고객 메모"
            value={memo}
            setValue={setMemo}
            placeholder="고객 메모는 고객에게 보이지 않습니다."
          />
          <div className="flex justify-end mt-10">
            <Button
              type="button"
              btnText="내역"
              onClick={() =>
                dispatch(
                  levelCheck({
                    sl: "c03",
                    fuc: onCickHistory(),
                  }),
                )
              }
              standardItem="c03"
            />

            <Space x={10} />
            <Button
              btnText={LANG[getLang()].register}
              type="submit"
              standardItem="c03"
            />
          </div>
        </form>
      </div>
    </Background>
  );
};

export default UserInfoModal;
