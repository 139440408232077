import { useState, useRef } from "react";
import { Collapse, Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { getQnaHistoryApi, QnaListApi } from "Apis/apis";

import DatePick from "Components/Atoms/DatePick/datePick";
import parse from "html-react-parser";
import Radio from "Components/Atoms/Radio/radio";
import Space from "Components/Atoms/Space/space";
import Tag from "Components/Atoms/Tag/tag";
import ServiceCategory from "Components/Molecules/ServiceCategory/serviceCategory";
import LANG, { getLang } from "Util/lang";
import { ReactComponent as Q } from "Util/svg/q.svg";

import {
  ClickInputForm,
  GetQnAListApi,
  GetQnAListApiBody,
  QHistoryList,
  RadioForm,
  ReduxQnAOption,
} from "types/interface";
import { ReducerType, useAppDispatch } from "Store/store";
import Select from "Components/Atoms/Select/select";
import { useSelector } from "react-redux";
import { setOption } from "Store/Slices/qnaSearchOption";
import QuerySearchInput from "Components/Atoms/Input2/querySearchInput";
import Button from "Components/Atoms/Button/button";
import TranceBtnList from "Components/Molecules/TranceBtn/tranceBtn";
import { setTrans } from "Store/Slices/translate";
import { modalToggle } from "Store/Slices/modal";
import { setQIdx } from "Store/Slices/qIdx";
import Quill from "Components/Atoms/Quill/quill";
import QuestionHistoryLIst from "Components/Molecules/QuestionHistoryLIst/questionHistoryLIst";

const SEARCH_TYPE: ClickInputForm[] = [
  { idx: -1, label: LANG[getLang()].all },
  { idx: 0, label: "사용자 ID" },
  { idx: 1, label: "닉네임" },
  { idx: 2, label: LANG[getLang()].eMail },
  { idx: 3, label: LANG[getLang()].title },
  { idx: 4, label: LANG[getLang()].content },
  { idx: 5, label: "제목+내용" },
  { idx: 6, label: "전화번호" },
];
const STATERADIO: RadioForm[] = [
  { idx: 0, label: "접수", id: "receipt" },
  { idx: -1, label: LANG[getLang()].all, id: "all" },
  { idx: 2, label: LANG[getLang()].outStanding, id: "nope" },
  { idx: 1, label: LANG[getLang()].replyComplete, id: "done" },
  { idx: 4, label: LANG[getLang()].temporarySave, id: "save" },
  { idx: 6, label: "예약중", id: "reserve" },
  { idx: 3, label: LANG[getLang()].hide, id: "hidden" },
];
const VIPRADIO: RadioForm[] = [
  { idx: -1, label: LANG[getLang()].all, id: "all_vip" },
  { idx: 0, label: "vip", id: "vip" },
  { idx: 1, label: "블랙리스트", id: "black" },
  { idx: 2, label: "일반", id: "normal" },
];
const VIP: { [index: number]: string } = {
  0: "⭐",
  1: "🚫",
};

const QuestionList = () => {
  const dispatch = useAppDispatch();
  const ref = useRef<HTMLInputElement>(null);
  const serviceList = useSelector((state: ReducerType) => state.serviceList);
  const qIdx = useSelector((state: ReducerType) => state.qIdx);

  const {
    date,
    serviceNo,
    bCode,
    mCode,
    stateType,
    searchType,
    searchKeyword,
    sort,
    vip,
  }: ReduxQnAOption = useSelector(
    (state: ReducerType) => state.qnaSearchOption
  );

  const [userHistory, setUserHistory] = useState<QHistoryList[]>([]);

  const body: GetQnAListApiBody = {
    startDate: date[0],
    endDate: date[1],
    serviceNo,
    bCode,
    mCode,
    stateType,
    searchType,
    searchKeyword,
    sort,
    vip,
  };

  const { data, refetch } = QnaListApi(body);
  const qna = data?.qna;

  const onSubmit = (e: React.ChangeEvent<HTMLFormElement>): void => {
    e.preventDefault();
    ref.current && setsearchOption("searchKeyword")(ref.current.value);
    refetch();
  };

  const getHistory = async (userId: string, serviceNo: number) => {
    const {
      data: { qnaPast },
    } = await getQnaHistoryApi(userId, serviceNo);
    setUserHistory(qnaPast);
  };
  const onClickRow = (q: GetQnAListApi) => {
    getHistory(q.user_id, q.service_no);
    dispatch(setQIdx(q.qna_no));
  };
  const serviceColor = (idx: number) => {
    const color =
      serviceList.find((service) => service.idx === idx)?.color || "";
    return color;
  };

  const columns: ColumnsType<GetQnAListApi> = [
    {
      title: LANG[getLang()].index,
      dataIndex: "qna_no",
      align: "center",
      width: 80,
      render: (now, row) => {
        return (
          <div
            className="flex items-center justify-center p-8 h-62"
            style={{ backgroundColor: serviceColor(row.service_no) }}
          >
            {now}
          </div>
        );
      },
    },
    {
      title: LANG[getLang()].title,
      dataIndex: "title",
      width: "2000",
      render: (now, row) => (
        <p
          style={{ backgroundColor: serviceColor(row.service_no) }}
          className={`cursor-pointer text-16 p-18 h-62 ell ${
            row.qna_no === qIdx ? "font-bold" : ""
          }`}
          onClick={() => onClickRow(row)}
        >
          {now}
        </p>
      ),
    },
    {
      title: LANG[getLang()].category,
      dataIndex: "big_category_name",
      align: "center",
      ellipsis: true,
      render: (now: string, row: GetQnAListApi) => (
        <div
          className="p-8 h-62"
          style={{ backgroundColor: serviceColor(row.service_no) }}
        >
          {serviceList.find((service) => service.idx === row.service_no)?.label}
          <br />
          {`${now} > ${row.middle_category_name}`}
        </div>
      ),
    },
    {
      title: "연락처",
      dataIndex: "phone_number",
      align: "center",
      render: (now, row) => {
        return (
          <div
            className="flex items-center justify-center p-8 h-62"
            style={{ backgroundColor: serviceColor(row.service_no) }}
          >
            {now}
          </div>
        );
      },
    },
    {
      title: LANG[getLang()].userUID,
      dataIndex: "user_id",
      align: "center",
      render: (now, row) => {
        return (
          <div
            className="flex items-center justify-center p-8 h-62"
            style={{ backgroundColor: serviceColor(row.service_no) }}
          >
            {now}
          </div>
        );
      },
    },
    {
      title: LANG[getLang()].vip,
      dataIndex: "vip",
      align: "center",
      width: 60,

      render: (now, row) => {
        return (
          <div
            className="flex items-center justify-center p-8 h-62"
            style={{ backgroundColor: serviceColor(row.service_no) }}
          >
            {VIP[now]}
          </div>
        );
      },
    },
    {
      title: (
        <button
          className="select-none"
          onClick={() =>
            setsearchOption("sort")(sort === "ASC" ? "DESC" : "ASC")
          }
        >
          {LANG[getLang()].date}
        </button>
      ),
      dataIndex: "date",
      align: "center",
      sorter: true,
      showSorterTooltip: false,
      sortOrder: sort === "ASC" ? "ascend" : "descend",
      width: 111,
      render: (now, row) => {
        return (
          <div
            className="p-8 h-62"
            style={{ backgroundColor: serviceColor(row.service_no) }}
          >
            {now}
          </div>
        );
      },
    },
    {
      title: <span className="select-none">{LANG[getLang()].state}</span>,
      dataIndex: "state",
      width: 90,
      align: "center",
      render: (now, row) => {
        switch (now) {
          case 1:
            return (
              <div
                className="flex items-center justify-center p-8 h-62"
                style={{ backgroundColor: serviceColor(row.service_no) }}
              >
                <Tag type="success" name={LANG[getLang()].replyComplete} />
              </div>
            );
          case 2:
            return (
              <div
                className="flex items-center justify-center p-8 h-62"
                style={{ backgroundColor: serviceColor(row.service_no) }}
              >
                <Tag type="error" name={LANG[getLang()].outStanding} />
              </div>
            );
          case 3:
            return (
              <div
                className="flex items-center justify-center p-8 h-62"
                style={{ backgroundColor: serviceColor(row.service_no) }}
              >
                <Tag type="error" name={LANG[getLang()].hide} />
              </div>
            );
          case 4:
            return (
              <div
                className="flex items-center justify-center p-8 h-62"
                style={{ backgroundColor: serviceColor(row.service_no) }}
              >
                <Tag type="ready" name={LANG[getLang()].temporarySave} />
              </div>
            );
          case 5:
            return (
              <div
                className="flex items-center justify-center p-8 h-62"
                style={{ backgroundColor: serviceColor(row.service_no) }}
              >
                <Tag type="ready" name={LANG[getLang()].etc} />
              </div>
            );
          case 6:
            return (
              <div
                className="flex items-center justify-center p-8 h-62"
                style={{ backgroundColor: serviceColor(row.service_no) }}
              >
                <Tag type="ready" name="예약중" />
              </div>
            );

          default:
            return (
              <div
                className="flex items-center justify-center p-8 h-62"
                style={{ backgroundColor: serviceColor(row.service_no) }}
              >
                <Tag type="ready" name={LANG[getLang()].register} />
              </div>
            );
        }
      },
    },
  ];

  const setsearchOption =
    (key: string) =>
    <U extends {}>(val: U) => {
      dispatch(setOption({ [key]: val }));
    };

  const onTranslation = (original: string) => (transed: string) => {
    dispatch(modalToggle(13));
    dispatch(setTrans({ original, transed }));
  };

  return (
    <div
      id="qna"
      className="max-h-[calc(100vh-56px-24px-44px-24px)] overflow-y-scroll pretty-scrollbar"
    >
      <div className="flex">
        <DatePick dateRange={date} setDateRange={setsearchOption("date")} />
        <Space x={20} />
        <ServiceCategory
          serviceNo={serviceNo}
          setServiceNo={setsearchOption("serviceNo")}
          bCode={bCode}
          setBCode={setsearchOption("bCode")}
          mCode={mCode}
          setMCode={setsearchOption("mCode")}
        />
      </div>
      <Space y={20} />
      <div className="relative inline-block">
        <Radio
          value={stateType}
          setValue={setsearchOption("stateType")}
          name="qnq_state"
          dataList={STATERADIO}
        />
        <span className="absolute top-0 left-212">
          <Tooltip
            placement="top"
            title="아직 답변 되지 않은 모든 상담원의 문의입니다"
          >
            <Q />
          </Tooltip>
        </span>
      </div>

      <Space y={16} />
      <Radio
        value={vip}
        setValue={setsearchOption("vip")}
        name="qnq_vip"
        dataList={VIPRADIO}
      />

      <Space y={20} />
      <form onSubmit={onSubmit} className="flex">
        <Select
          val={searchType}
          setVal={setsearchOption("searchType")}
          dataList={SEARCH_TYPE}
        />
        <Space x={20} />

        <QuerySearchInput refInput={ref} placeholder="검색어 입력" />
        <Space x={20} />
        <Button btnText={LANG[getLang()].search} type="submit" />
      </form>
      <Space y={50} />
      <div id="addup-table">
        <Table
          rowClassName={(service) => serviceColor(service.service_no)}
          columns={columns}
          dataSource={qna}
          pagination={{
            showSizeChanger: true,
            defaultPageSize: 5,
            pageSizeOptions: [5, 10, 15, 20],
            position: ["bottomCenter"],
          }}
          rowKey="qna_no"
        />
      </div>
      <Space y={50} />
      {qna?.findIndex((el) => el.qna_no === qIdx) !== -1 && (
        <QuestionHistoryLIst userHistory={userHistory} />
      )}
    </div>
  );
};

export default QuestionList;
