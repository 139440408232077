import { useState, useEffect } from "react";

import Background from "Components/Atoms/Background/background";
import H2 from "Components/Atoms/H2/h2";
import Space from "Components/Atoms/Space/space";
import Button from "Components/Atoms/Button/button";
import { modalToggle } from "Store/Slices/modal";
import { useAppDispatch } from "Store/store";
import { ReactComponent as ModalX } from "Util/svg/modalX.svg";
import InputText from "../InputText/inputText";
import InputRowSelect from "../InputRowSelect/inputRowSelect";
import { comma } from "Util/function";
import Checkbox from "Components/Atoms/Checkbox/checkbox";
import { ClickInputForm } from "types/interface";
import { useLocation } from "react-router-dom";
import InputRow from "../InputRow/inputRow";
const AgreeCheckBox: ClickInputForm[] = [
  { idx: 1, label: "결제 동의 사항 [필수]" },
];
const ChangeModal = ({
  sitCount,
  setSitCount,
  onClickPay,
  agree,
  setAgree,
}: {
  sitCount: string;
  setSitCount: React.Dispatch<React.SetStateAction<string>>;
  onClickPay: (sitCount: number) => {};
  agree: number[];
  setAgree: React.Dispatch<React.SetStateAction<number[]>>;
}) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { idx, name, pay, product } = location.state || {};

  const modalOn = () => {
    if (agree.length !== 0) return dispatch(modalToggle(10));
  };

  useEffect(() => {
    modalOn();
  }, [agree.length]);

  return (
    <Background modalNum={idx}>
      <div style={{ width: "640px" }} className="p-32 bg-f2f3f8">
        <div className="flex items-center justify-between">
          <H2 isPrimary={true} name="상품 변경 신청" />
          <div
            className="inline-block cursor-pointer"
            onClick={() => dispatch(modalToggle(idx))}
          >
            <ModalX />
          </div>
        </div>
        <Space y={10} />
        <span className="font-bold text-14 text-primary">
          * 동일 상품의 시트를 추가할 수 있습니다.
        </span>
        <Space y={20} />
        <InputText name="회사명" text={product.company} />
        <InputText
          name="기존 상품명"
          text={`${product.product_name} 상품 (시트 ${product.sheet_count}석)`}
        />
        <InputText name="변경 상품" text={`${name} 상품`} />
        <InputRow
          id="sit"
          name="시트 수 "
          setValue={setSitCount}
          value={sitCount}
        />
        <InputText
          name="가격"
          text={`${comma(pay * +sitCount * 1.1).split(".")[0]}원 (VAT 포함)`}
        />
        <Space y={20} />
        <div className="flex items-center justify-end">
          <Checkbox
            dataList={AgreeCheckBox}
            setValue={setAgree}
            value={agree}
          />
          <Space x={16} />
          <Button
            btnText="결제"
            onClick={() =>
              agree.length
                ? onClickPay(+sitCount)
                : alert("결제 동의사항을 체크해주세요.")
            }
            size="xl"
          />
        </div>
      </div>
    </Background>
  );
};

export default ChangeModal;
