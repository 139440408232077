import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useEffect, useState } from "react";
import { CategoryQnaList } from "types/interface";
import LIST from "./color";
import { sum } from "Util/function";

const CategoryChart = ({ data }: { data: CategoryQnaList[] }) => {
  const [labelList, setLabelList] = useState<string[]>([]);
  const [dataList, setDataList] = useState<number[]>([]);

  // 서비스 카테고리별 원형 문의 현황
  const options3 = {
    responsive: true,
    plugins: {
      legend: {
        labels: {
          filter: (el: any, now: any) => {
            return el.text !== "전체";
          },
          font: { size: 13, weight: "bold" },
        } as any,
        position: "bottom" as const,
      },
      // title: {
      //   display: true,
      //   text: "카테고리별 문의 수",
      //   font: { size: 20 },
      // },
      datalabels: {
        display: (el: any) => {
          return el.dataset.data[el.dataIndex] !== 0;
        },
        color: "black",
        align: "center",
      } as any,
    },
    scale: {
      x: {},
      y: {},
    },
  };

  const init = () => {
    const obj: { [index: string]: number } = {};
    const _labelList = [];
    const _dataList = [];

    const datas = data
      .map((dailyData) => dailyData.data.map((el) => el.middle))
      .flat()
      .flat()
      .filter((el) => el.mcode !== -1);
    datas.forEach((el) => {
      obj[el.mname] = obj[el.mname] ? el.sum + obj[el.mname] : el.sum;
    });

    for (const key in obj) {
      _labelList.push(key);
      _dataList.push(obj[key]);
    }
    const total = sum(_dataList);
    const percent = _dataList.map((el) => Math.floor((el / total) * 100));

    setLabelList(_labelList);
    setDataList(percent);
  };

  useEffect(() => {
    init();
  }, [data]);

  const data3 = {
    plugins: [ChartDataLabels],
    labels: labelList,
    datasets: [
      {
        data: dataList,
        backgroundColor: LIST,
        datalabels: {
          font: {
            size: 30,
            weight: "bold",
          } as any,

          formatter: (value: any, context: any) => value + "%",
        } as any,
      },
    ],
  };
  return (
    <>
      <div className="w-[500px] mx-auto my-0">
        <Pie options={options3} data={data3} plugins={[ChartDataLabels]} />
      </div>
    </>
  );
};

export default CategoryChart;
