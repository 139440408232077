import { useState, useEffect } from "react";

import Background from "Components/Atoms/Background/background";
import H2 from "Components/Atoms/H2/h2";
import InputRow from "../InputRow/inputRow";
import InputRowRadio from "../InputRowRadio/inputRowRadio";
import Button from "Components/Atoms/Button/button";
import Space from "Components/Atoms/Space/space";
import InputRowSelect from "Components/Molecules/InputRowSelect/inputRowSelect";

import { ReactComponent as ModalX } from "Util/svg/modalX.svg";

import { useDispatch } from "react-redux";

import { modalToggle } from "Store/Slices/modal";
import LANG, { getLang } from "Util/lang";

import { RadioForm, PostCategory, ClickInputForm } from "types/interface";
import { postCategoryApi } from "Apis/apis";
import { useNavigate } from "react-router-dom";

const MiddleCategoryAddModal = ({
  bigCategoryList,
  onSubmit,
  serviceNo,
}: {
  bigCategoryList: ClickInputForm[];
  onSubmit: () => void;
  serviceNo: number;
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [middleName, setMiddleName] = useState("");
  const [status, setStatus] = useState(1);
  const [value, setValue] = useState(-1);

  const STATE_DATA: RadioForm[] = [
    { idx: 1, label: LANG[getLang()].waiting, id: "wait" },
    { idx: 0, label: LANG[getLang()].approval, id: "ok" },
  ];

  const postMiddleCategory = async () => {
    if (value === -1) {
      return alert("대 카테고리 선택해주세요.");
    }

    const body: PostCategory = {
      serviceNo: serviceNo,
      bigCategoryCode: value,
      categoryName: middleName,
      state: status,
    };
    await postCategoryApi(body);
    alert("등록이 완료되었습니다.");
    dispatch(modalToggle(3));
    onSubmit();
    setValue(-1);
    setStatus(1);
    setMiddleName("");
  };

  const resetMiddleName = () => {
    dispatch(modalToggle(3));
    setValue(-1);
    setStatus(1);
    setMiddleName("");
  };

  return (
    <Background modalNum={3}>
      <div style={{ width: "640px" }} className="p-32 bg-f2f3f8">
        <div className="flex justify-between items-center">
          <H2 isPrimary={true} name="중 카테고리 등록" />
          <div
            className="inline-block cursor-pointer"
            onClick={() => dispatch(modalToggle(3))}
          >
            <ModalX />
          </div>
        </div>
        <Space y={8} />
        <div className="">
          <InputRowSelect
            id="middleSelect"
            name={LANG[getLang()].lCategory}
            value={value}
            setValue={setValue}
            dataList={bigCategoryList}
            zindex={1}
          />
        </div>
        <InputRow
          id="service"
          name={LANG[getLang()].categoryName}
          value={middleName}
          setValue={setMiddleName}
        />
        <InputRowRadio
          data={STATE_DATA}
          name={LANG[getLang()].state}
          value={status}
          setValue={setStatus}
        />
        <div className="flex justify-end mt-10">
          <Button onClick={resetMiddleName} btnText={LANG[getLang()].cancel} />
          <Space x={10} />
          <Button
            onClick={postMiddleCategory}
            btnText={LANG[getLang()].register}
          />
        </div>
      </div>
    </Background>
  );
};

export default MiddleCategoryAddModal;
