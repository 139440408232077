import { ReactComponent as Require } from "Util/svg/require.svg";
import { useState, useEffect } from "react";
import Button from "Components/Atoms/Button/button";
import UserInfoForm from "Components/Organisms/UserInfoForm/userInfoForm";
import { getUserInfoApi, putAuthorityApi, putUserInfoApi } from "Apis/apis";
import { Link, useNavigate } from "react-router-dom";
import LANG, { getLang } from "Util/lang";
import { useQueryClient } from "@tanstack/react-query";
import Space from "Components/Atoms/Space/space";
import { useDispatch } from "react-redux";
import { modalToggle } from "Store/Slices/modal";
import AuthorityModal from "Components/Molecules/OutModal/authorityModal";

const CompanyUserInfo = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [comId, setComId] = useState("");
  const [password, setPassword] = useState("");

  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [department, setDepartment] = useState("");
  const [position, setPosition] = useState("");
  const [imgName, setImgName] = useState("");
  const [img, setImg] = useState<File>({} as File);

  const pass = (): boolean => {
    if (password === "") {
      alert("비밀번호를 입력해주세요.");
      return false;
    } else if (name === "") {
      alert("이름을 입력 해주세요");
      return false;
    } else if (phoneNumber === "") {
      alert("핸드폰 번호를 입력 해주세요");
      return false;
    } else if (companyName === "") {
      alert("회사명을 입력 해주세요");
      return false;
    } else if (department === "") {
      alert("부서명을 입력 해주세요");
      return false;
    } else if (position === "") {
      alert("직급을 입력 해주세요");
      return false;
    }
    return true;
  };

  const getUserInfo = async () => {
    const { data } = await getUserInfoApi();
    setComId(data.company_id);
    setName(data.name);
    setPhoneNumber(data.phone_number);
    setCompanyName(data.company_name);
    setDepartment(data.department);
    setPosition(data.position);
    setImgName(data.original_filename);
  };
  const putUserInfo = async () => {
    const isPass: boolean = pass();
    if (!isPass) return;

    const confirm = window.confirm("수정 하시겠습니까?");
    if (!confirm) return;

    const formData: any = new FormData();
    formData.append("password", password);
    formData.append("name", name);
    formData.append("companyName", companyName);
    formData.append("department", department);
    formData.append("phoneNumber", phoneNumber);
    formData.append("position", position);
    formData.append("enterprise", img);
    await putUserInfoApi(formData);
    queryClient.invalidateQueries(["usePostDomainLoginApi"]);
    navigate("/");
  };

  useEffect(() => {
    getUserInfo();
  }, []);
  return (
    <div>
      <div className="flex items-center justify-end mb-10">
        <Require />
        <span className="ml-5">표기된 사항은 필수 입력 사항입니다.</span>
      </div>
      <UserInfoForm
        comId={comId}
        setComId={setComId}
        password={password}
        setPassword={setPassword}
        name={name}
        setName={setName}
        phone={phoneNumber}
        setPhone={setPhoneNumber}
        company={companyName}
        setCompany={setCompanyName}
        department={department}
        setDepartment={setDepartment}
        imgName={imgName}
        setImg={setImg}
        position={position}
        setPosition={setPosition}
      />

      <div className="flex justify-between gap-10 mt-10">
        <div className="flex">
          <Link to="/infomation/out">
            <Button btnText="회원탈퇴" color="ready" />
          </Link>
          <Space x={20} />
          <Button
            btnText="권한 위임"
            color="ready"
            onClick={() => dispatch(modalToggle(2))}
          />
        </div>
        <Button onClick={putUserInfo} btnText={LANG[getLang()].changeInfo} />
      </div>
      <AuthorityModal />
    </div>
  );
};

export default CompanyUserInfo;
