import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface MemoListInfoRedux {
  userId: string;
  serviceNo: number;
}

const initialState: MemoListInfoRedux = {
  userId: "",
  serviceNo: -1,
};

export const memoListInfo = createSlice({
  name: "memoListInfo",
  initialState,
  reducers: {
    setMemoListInfo: (state, action: PayloadAction<MemoListInfoRedux>) => {
      return action.payload;
    },
  },
});

export const { setMemoListInfo } = memoListInfo.actions;
export default memoListInfo.reducer;
