import Background from "Components/Atoms/Background/background";
import H2 from "Components/Atoms/H2/h2";
import Space from "Components/Atoms/Space/space";
import { modalToggle } from "Store/Slices/modal";
import { useAppDispatch } from "Store/store";
import { ReactComponent as ModalX } from "Util/svg/modalX.svg";
import FileButton from "Components/Atoms/Button/fileButton";
import FileNameList from "Components/Atoms/FileNameList/fileNameList";

const FileModal = ({
  fileList,
  setFileList,
}: {
  fileList: File[];
  setFileList: React.Dispatch<React.SetStateAction<File[]>>;
}) => {
  const dispatch = useAppDispatch();

  return (
    <Background modalNum={16}>
      <div style={{ width: "640px" }} className="p-32 bg-f2f3f8">
        <div className="flex justify-between items-center">
          <H2 isPrimary={true} name="답변 파일 첨부" />

          <div
            className="inline-block cursor-pointer"
            onClick={() => dispatch(modalToggle(16))}
          >
            <ModalX />
          </div>
        </div>
        <Space y={20} />
        <FileButton value={fileList} setValue={setFileList} max={3} />
        <Space y={24} />
        <FileNameList fileList={fileList} setFileList={setFileList} />
      </div>
    </Background>
  );
};

export default FileModal;
