import Space from "../Space/space";

const PhoneNumberList = ({
  phoneNumberList,
  setphoneNumberList,
}: {
  phoneNumberList: string[];
  setphoneNumberList: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  const onClickNumber = (idx: number) => {
    const newPhoneNumberList = phoneNumberList.filter(
      (phoneNumber: string, _idx: number) => _idx !== idx
    );
    setphoneNumberList(newPhoneNumberList);
  };

  return (
    <ul className="px-20 font-medium bg-white py-18 ">
      {phoneNumberList.map((phoneNumber: string, idx: number) => (
        <li key={phoneNumber} className="flex mb-4 leading-26 last:mb-0">
          &#183;
          <Space x={4} />
          <button onClick={() => onClickNumber(idx)}>{phoneNumber}</button>
        </li>
      ))}
    </ul>
  );
};

export default PhoneNumberList;
