import Table, { ColumnsType } from "antd/es/table";
import { getNormalCategoryApi, postNormalCategoryApi } from "Apis/apis";

import Background from "Components/Atoms/Background/background";
import Button from "Components/Atoms/Button/button";
import H2 from "Components/Atoms/H2/h2";
import Input from "Components/Atoms/Input/input";
import Space from "Components/Atoms/Space/space";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { modalToggle } from "Store/Slices/modal";
import { CategoryItem, GetNormalCategory } from "types/interface";
import LANG, { getLang } from "Util/lang";
import { ReactComponent as ModalX } from "Util/svg/modalX.svg";

const CategoryLoadModal = ({ onSubmit }: { onSubmit: () => void }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [data, setData] = useState<GetNormalCategory[]>([]);
  const { serviceNo } = location.state || {};
  const getCategory = async () => {
    const {
      data: { load_category },
    } = await getNormalCategoryApi();
    setData(load_category);
  };

  const postCategory = async (idx: number) => {
    const body = {
      categoryStorageNo: idx,
      serviceNo: serviceNo,
    };
    await postNormalCategoryApi(body);
    alert("카테고리가 생성되었습니다");
    onSubmit();
    dispatch(modalToggle(6));
  };
  useEffect(() => {
    getCategory();
  }, []);

  const columns: ColumnsType<GetNormalCategory> = [
    {
      title: "카테고리 목록",
      dataIndex: "storage_name",
      align: "center",
      render: (now, row) => {
        return (
          <a href={row.category_file} download={row.storage_name}>
            {now}
          </a>
        );
      },
    },
    {
      title: "선택",
      dataIndex: "category_storage_no",
      align: "center",
      width: "130px",
      render: (now) => (
        <Button btnText={"선택"} size="sm" onClick={() => postCategory(now)} />
      ),
    },
  ];

  return (
    <Background modalNum={6}>
      <div style={{ width: "640px" }} className="p-32 bg-f2f3f8">
        <div className="flex justify-between items-center">
          <H2 isPrimary={true} name="카테고리 불러오기" />
          <div
            className="inline-block cursor-pointer"
            onClick={() => dispatch(modalToggle(6))}
          >
            <ModalX />
          </div>
        </div>
        <Space y={20} />
        <Table
          rowKey="tel_key_no"
          columns={columns}
          dataSource={data}
          pagination={{ defaultPageSize: 5 }}
        />
      </div>
    </Background>
  );
};

export default CategoryLoadModal;
