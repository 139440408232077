import Button from "Components/Atoms/Button/button";
import H2 from "Components/Atoms/H2/h2";
import Space from "Components/Atoms/Space/space";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import LoginInput from "Components/Molecules/Logininput/loginInput";
import { useAppDispatch } from "Store/store";
import {
  ClickInputForm,
  Item,
  TelegramDetail,
} from "types/interface";
import Checkbox from "Components/Atoms/Checkbox/checkbox";

import Label from "Components/Atoms/Label/label";

import { setFormData } from "Util/function";
import { logoutMode } from "Store/Slices/layouts";
import { getTelegramApi } from "Apis/baseApis";
import {
  useGetAlarmDetailApi,
  getCategoryItemApi,
  getQnaImage,
  getValidationServiceApi,
  postCustomQna,
} from "Apis/apis";
import Input from "Components/Atoms/Input/input";

import InqueryPolicyModal from "Components/Molecules/InqueryPolicyModal/inqueryPolicyModal";
import { modalToggle } from "Store/Slices/modal";
import CustomServiceCategory from "Components/Molecules/ServiceCategory/customServiceCategory";


const AGREE: ClickInputForm[] = [{ idx: 1, label: "개인정보 수집 동의" }];


const CustomInquiry = () => {
      const dispatch = useAppDispatch();
      const navigate = useNavigate();
      const params = useParams();
      const serviceNo = +(params.idx || -1);
      const location = useLocation();
      const { data } = useGetAlarmDetailApi(serviceNo);
      const setServiceNo = () => {
        const obj: { [index: string]: string } = {};
        location.search
          .substr(1)
          .split("&")
          .map((el) => el.split("="))
          .forEach((el) => {
            const key = decodeURI(el[0]);
            const value = decodeURI(el[1]);
            obj[key] = value;
          });
        return obj;
      };
      const {
        device_info: deviceInfo = "",
        user_name: userName = "",
        opt1 = "",
        opt2 = "",
        opt3 = "",
        user_id = "",
        user_email = "",
      } = setServiceNo();

      const {
        admin_token = "",
        all_room_id = "",
        keyword_room_id = "",
        vip_room_id = "",
        all_alarm = -1,
        keyword_alarm = -1,
        vip_alarm = -1,
        alarm_keyword = [],
      } = data ? data.telegram : ({} as TelegramDetail);

      const [userId, setUserId] = useState(user_id);
      const [email, setEmail] = useState(user_email);
      const [title, setTitle] = useState("");
      const [contents, setContents] = useState("");
     
      const [agree, setAgree] = useState<number[]>([]);
      const [itemList, setItemList] = useState<Item[]>([]);
      const [bigCategoryCode, setBigCategoryCode] = useState(-1);
      const [middleCategoryCode, setMiddleCategoryCode] = useState(-1);
      const [phoneNumber, setPhoneNumber] = useState("");


      const [valid, setValid] = useState<number>(-1); // 서비스 유효성 체크
      const [serviceName, setServiceName] = useState("");
      const [prolicy, setProlicy] = useState("");
      const subDomain = window.location.host.split(".")[0];
      const domainIdx = window.location.pathname.split("/")[2];

      const [isBarOpen, setIsBarOpen] = useState(false);


      const isDevelop = process.env.NODE_ENV === "development";

      const getValidation = async () => {
        const body = [subDomain, domainIdx];
        const {
          data: { service },
        } = await getValidationServiceApi(body);

        setValid(service.service_valid);
        setServiceName(service.service_name);
        if (service.service_valid === 2 && !isDevelop) {
          alert("서비스가 존재하지 않습니다.");
          return navigate(-1);
        }
      };

           // 문자열 byte 체크
      const getBytes = (str: string) => {
        let character;
        let charBytes = 0;

        for (let i = 0; i < str.length; i += 1) {
          character = str.charAt(i);

          if (escape(character).length > 4) charBytes += 2;
          else charBytes += 1;
        }

        return charBytes;
      };

      // form 확인
      const pass = (): boolean => {
        if (bigCategoryCode === -1) {
          alert("수강권 종류를 선택 해주세요");
          return false;
        } 
        else if (middleCategoryCode === -1) {
          alert("희망 상담시간을 선택 해주세요");
          return false;
        }
        //  else if (title === "") {
        //   alert("제목을 입력 해주세요");
        //   return false;
        // }
        //  else if (contents === "") {
        //   alert("내용을 입력 해주세요");
        //   return false;
        // }
         else if (phoneNumber === "") {
          alert("연락처를 입력 해주세요");
          return false;
        }

        return true;
      };

      // 카테고리별 인풋
      const getItemList = async () => {
        if (!middleCategoryCode) return;
        const {
          data: { category_item },
        } = await getCategoryItemApi(middleCategoryCode);
        const _itemlist = category_item.map((el) => {
          el.value = "";

          return el;
        });

        setItemList(_itemlist);
      };

      // 카테고리별 인풋 onchange
      const onChangeItemValue = (text: string, idx: number) => {
        const inputItemsCopy: Item[] = JSON.parse(JSON.stringify(itemList));
        inputItemsCopy[idx].value = text;
        setItemList(inputItemsCopy);
      };

      const sendTelegram = (vip: number) => {
        const wordList = alarm_keyword.map((el) => el.keyword) || [];
        const checkWord = wordList.findIndex((el) =>
          (contents + title).includes(el)
        );
        if (vip_alarm) {
          if (vip === 0) {
            getTelegramApi(
              admin_token,
              vip_room_id,
              `${userId} 님의 문의가 등록 되었습니다.`
            );
          }
        }

        if (keyword_alarm) {
          if (checkWord !== -1) {
            getTelegramApi(
              admin_token,
              keyword_room_id,
              `${wordList[checkWord]} 관련 문의가 등록되었습니다.`
            );
            getTelegramApi(admin_token, keyword_room_id, `문의제목 : ${title}`);
          }
        }

        if (all_alarm) {
          getTelegramApi(
            admin_token,
            all_room_id,
            `${serviceName} 서비스에 문의가 등록되었습니다.`
          );
        }
      };

      // 문의
      const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!data) return;

        const isPass: boolean = pass();
        if (!isPass) return;
        if (!agree[0]) {
          alert("개인정보 동의 수집에 동의 해주세요");
          return;
        }

        const contentsLength = getBytes(contents);
        if (contentsLength > 65530) {
          alert("문의내용의 길이가 65530byte를 초과 하였습니다.");
          return;
        }

        const item = itemSetting();
        const { userAgent } = navigator;

        // prettier-ignore
        const body = { title: "에드핏", contents: "문의", userId: phoneNumber, serviceNo, bigCategoryCode, middleCategoryCode, item, email, phoneNumber, userAgent, deviceInfo, opt1, opt2, opt3, userName};
        const formData: FormData = new FormData();

        setFormData(formData, body);


        const {
          data: { vip },
        } = await postCustomQna(formData);
        alert("문의가 접수되었습니다.");
        sendTelegram(vip);

      };



      // api쏠때 inputList값 정형화
      const itemSetting = () => {
        const item: { [index: string]: string } = {};
        itemList.forEach((el) => (item[el.item_name] = el.value || ""));
        return JSON.stringify(item);
      };

      // 로고 및 서비스소개
      const getImage = async () => {
        const {
          data: { image },
        } = await getQnaImage(serviceNo);


        setProlicy(image.prolicy);
      };

      useEffect(() => {
        getItemList();
      }, [bigCategoryCode, middleCategoryCode]);

      useEffect(() => {
        getValidation();

        getImage();
        dispatch(logoutMode());
      }, []);


  return (
    <div className="h-auto relative bg-sky-100 flex flex-col justify-start items-center">
      <img src="../imgs/common/lending2024_01_1.jpg" alt="ADDFIT_1" className="" />
      <a
        href="https://www.add-fit.com/svoucher"
        target="_blank"
        rel="noopener noreferrer">
        <img src="../imgs/common/lending2.gif" alt="ADDFIT_GIF" className="" />
      </a>
      <img src="../imgs/common/lending2024_01_3.jpg" alt="ADDFIT_2" className="" />
      <div className="h-50 flex items-center">
        <p>스포츠바우처 ( 스포츠강좌이용권 ), 장애인스포츠바우처 ( 장애인스포츠강좌이용권 ) 인증 사용처</p>
      </div>
      <img src="../imgs/common/footer.png" alt="ADDFIT_FOOTER" className="mb-75" />

      <button
        style={{
          position: "fixed",
          bottom: "0",
          zIndex: 1,
          transform: isBarOpen ? "translateY(calc(-100% - 260px))" : "none",
          width: "100%",
          height: "75px",
          backgroundColor: "#377dff",
          color: "white",
          fontSize: "30px",
          fontWeight: "600",
        }}
        onClick={() => setIsBarOpen(!isBarOpen)}>
        {isBarOpen ? "혜택 받기 ▼" : "혜택 받기 ▲"}
      </button>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          transform: isBarOpen ? "translateY(0)" : "translateY(100%)",
          transition: "transform 0.3s ease-in-out",
          position: "fixed",
          bottom: 0,
          width: "100%",
          maxHeight: "50vh",
          zIndex: 0,
        }}>
        <form
          onSubmit={onSubmit}
          className="px-40 py-48 overflow-auto bg-sky-300 bottom-0 rounded-lg w-full max-w-full max-h-[95vh] pretty-scrollbar">
          <H2 name="에드핏 문의" />
          <Space y={20} />

          <LoginInput
            id="number"
            type="text"
            placeholder="ex) 01012345678"
            value={phoneNumber}
            setValue={setPhoneNumber}
            name="연락처"
            required
          />
          <Space y={20} />

          <div className="flex">
            <CustomServiceCategory
              serviceNo={serviceNo}
              bCode={bigCategoryCode}
              setBCode={setBigCategoryCode}
              mCode={middleCategoryCode}
              setMCode={setMiddleCategoryCode}
              service={false}
              border
            />
          </div>

          {/* <LoginInput
          id="title"
          type="text"
          placeholder="제목을 입력해주세요"
          value={title}
          setValue={setTitle}
          name={LANG[getLang()].title}
        />
        <Space y={8} />
        <Quill
          placeHolder={`내용을 입력해주세요`}
          value={contents}
          setValue={setContents}
          toolbar={false}
        /> */}

          <Space y={8} />
          {itemList.map((el, idx: number) => (
            <div key={el.category_item_no}>
              <Label labelfor={el.item_name} name={el.item_name} />
              <Input
                id={el.item_name}
                type="text"
                bottom={false}
                placeholder={`${el.item_name} 입력`}
                value={el.value || ""}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onChangeItemValue(e.currentTarget.value, idx)
                }
              />
              <Space y={16} />
            </div>
          ))}

          <Space y={20} />
          <div className="flex gap-8">
            <Checkbox value={agree} setValue={setAgree} dataList={AGREE} />
            <Button
              type="button"
              onClick={() => dispatch(modalToggle(1))}
              btnText="더보기"
              size="sm"
            />
          </div>
          <Space y={20} />
          <div
            className={`${
              valid === 0 && prolicy !== "" ? "" : "pointer-events-none"
            }`}>
            <Button
              type="submit"
              btnText="보내기"
              color={valid === 0 && prolicy !== "" ? "primary" : "ready"}
              full
            />
          </div>
        </form>
      </div>
      <InqueryPolicyModal policy={prolicy} />
    </div>
  );
};

export default CustomInquiry;
