import React from "react";
import { Chart as ChartJS } from "chart.js/auto"; //미사용하지만 안적어주면 오류남
import { Bar } from "react-chartjs-2";
import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Space from "Components/Atoms/Space/space";
const a = [
  {
    label: "환불",
    data: "1",
    backgroundColor: "red",
  },
  {
    label: "취소",
    data: "1",
    backgroundColor: "red",
  },
  {
    label: "탈퇴",
    data: "1",
    backgroundColor: "red",
  },
];
const test = [
  {
    date: "2022-01-01",
    all: 700,
    on: 400,
    off: 300,
  },
  {
    date: "2022-01-02",
    all: 500,
    on: 400,
    off: 100,
  },
  {
    date: "2022-01-03",
    all: 300,
    on: 200,
    off: 100,
  },
  {
    date: "2022-01-04",
    all: 400,
    on: 200,
    off: 200,
  },
];
const DATA = [
  {
    date: "2023-01-01",
    data: [
      {
        big_name: "결제",
        mid_data: [
          {
            mid_name: "환불",
            all: 100,
            done: 100,
            no: 0,
          },
          {
            mid_name: "취소",
            all: 200,
            done: 200,
            no: 0,
          },
          {
            mid_name: "계",
            all: 300,
            done: 300,
            no: 0,
          },
        ],
      },
      {
        big_name: "계정",
        mid_data: [
          {
            mid_name: "탈퇴",
            all: 100,
            done: 80,
            no: 30,
          },
          {
            mid_name: "계",
            all: 100,
            done: 100,
            no: 0,
          },
        ],
      },
    ],
  },
  {
    date: "2023-01-02",
    data: [
      {
        big_name: "결제",
        mid_data: [
          {
            mid_name: "환불",
            all: 300,
            done: 300,
            no: 0,
          },
          {
            mid_name: "취소",
            all: 200,
            done: 200,
            no: 0,
          },
          {
            mid_name: "계",
            all: 100,
            done: 100,
            no: 0,
          },
        ],
      },
      {
        big_name: "계정",
        mid_data: [
          {
            mid_name: "탈퇴",
            all: 100,
            done: 80,
            no: 30,
          },
          {
            mid_name: "계",
            all: 100,
            done: 100,
            no: 0,
          },
        ],
      },
    ],
  },
];

const ChartTest = () => {
  // 일별문의현황
  const labels = test.map((el) => el.date);
  const options = {
    maxBarThickness: 100, // 막대 크기
    plugins: {
      legend: {
        labels: {
          filter: (el: any, now: any) => {
            return el.text !== "전체";
          },
          font: { size: 13, weight: "bold" },
        } as any,
        position: "bottom" as const,
      },
      title: {
        display: true,
        text: "일별 문의 현황",
        font: { size: 20 },
      },
      datalabels: {
        display: true,
        color: "black",
        align: "top",
        anchor: "start", //start , end
        formatter: function (value: any, context: any) {
          //데이터 값이 0 이면 출력 안함
          if (context.dataset.label !== "전체") {
            return null;
          } else {
            return value;
          }
        },
      } as any,
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        min: 0,
        max: Math.max(...test.map((el) => el.all)) + 100,
      },
    },
  };
  const data = {
    plugins: [ChartDataLabels],
    labels,
    datasets: [
      {
        label: "답변완료",
        data: test.map((el) => el.on),
        backgroundColor: "rgb(37, 142, 228)",
        order: 0,
      },
      {
        label: "미처리",
        data: test.map((el) => el.off),
        backgroundColor: "rgb(235, 96, 53)",
        order: 0,
      },
      {
        label: "전체", //라인차트 데이터
        data: test.map((el) => el.all), //날짜 label데이터 순서 출력할 라인 차트의 데이터 리스트
        order: 1,
        backgroundColor: "transparent",
        borderColor: "transparent",
        datalabels: {
          font: {
            size: 15,
            weight: "bold",
          } as any,
        } as any,
      },
    ],
  };

  // 서비스 카테고리별
  const arrayTest = DATA.map((el) =>
    el.data
      .map((big) => big.mid_data.map((mid) => mid))
      .flat()
      .filter((filt) => filt.mid_name !== "계")
  );
  const labelArray = arrayTest.reduce((el: any, idx: any) => {
    return [...el, ...idx];
  });
  const serviceLabel = new Set(labelArray.map((el) => el.mid_name));
  // 서비스 카테고리별 바 문의 현황
  const labels2 = DATA.map((el) => el.date);
  const options2 = {
    maxBarThickness: 100, // 막대 크기
    plugins: {
      legend: {
        labels: {
          filter: (el: any, now: any) => {
            return el.text !== "전체";
          },
          font: { size: 13, weight: "bold" },
        } as any,
        position: "bottom" as const,
      },
      title: {
        display: true,
        text: "일별 문의 현황",
        font: { size: 20 },
      },
      datalabels: {
        display: true,
        color: "black",
        align: "top",
        anchor: "start", //start , end
        formatter: function (value: any, context: any) {
          //데이터 값이 0 이면 출력 안함
          if (context.dataset.label !== "전체") {
            return null;
          } else {
            return value;
          }
        },
      } as any,
    },
    responsive: true,
  };
  const data2 = {
    plugins: [ChartDataLabels],
    labels2,
    // datasets: DATA.map((el) =>
    //   el.data
    //     .map((big) => big.mid_data.map((mid) => mid))
    //     .flat()
    //     .filter((filt) => filt.mid_name !== "계")
    //     .map((last) => {
    //       return {
    //         label: last.mid_name,
    //         data: 1,
    //         backgroundColor: "rgb(37, 142, 228)",
    //         order: 0,
    //       };
    //     }),
    // ),
    // datasets: DATA.map((el: any) => {
    //   return {
    //     label: el.data
    //       .map((big: any) => big.mid_data.map((mid: any) => mid.mid_name))
    //       .flat()
    //       .filter((filt: any) => filt !== "계"),
    //     data: test.map((el) => el.on),
    //     backgroundColor: "rgb(37, 142, 228)",
    //     order: 0,
    //   };
    // }),
    datasets: DATA.map((el: any, idx: any) => {
      return {
        label: labelArray[idx].mid_name,
        data: test.map((el) => el.on),
        backgroundColor: "rgb(37, 142, 228)",
        order: 0,
      };
    }),
  };

  // 서비스 카테고리별 원형 문의 현황
  let newData: any = [];
  const options3 = {
    responsive: true,
    plugins: {
      legend: {
        labels: {
          filter: (el: any, now: any) => {
            return el.text !== "전체";
          },
          font: { size: 13, weight: "bold" },
        } as any,
        position: "bottom" as const,
      },
      title: {
        display: true,
        text: DATA[0].date + " ~ " + DATA[DATA.length - 1].date,
        font: { size: 20 },
      },
      datalabels: {
        display: true,
        color: "black",
        align: "center",
        formatter: function (value: any, context: any) {
          const datapoints = context.chart.data.datasets[0].data;
          function totalSum(total: any, datapoint: any) {
            return total + datapoint;
          }
          const totalValue = datapoints.reduce(totalSum, 0);
          const percetageValue = ((value / totalValue) * 100).toFixed(1);
          const display = [`${value}`, `${percetageValue}%`];
          return display;
        },
      } as any,
    },
    scale: {
      x: {},
      y: {},
    },
  };

  for (let i = 0; i < arrayTest.length; i++) {
    for (let j = 0; j < arrayTest[i].length; j++) {
      if (i === 0) {
        newData.push(arrayTest[i][j]);
      } else {
        newData[j].all += arrayTest[i][j].all;
        newData[j].done += arrayTest[i][j].done;
        newData[j].no += arrayTest[i][j].no;
      }
    }
  }

  const data3 = {
    plugins: [ChartDataLabels],
    labels: Array.from(serviceLabel),
    datasets: [
      {
        label: "전체문의",
        data: newData.map((el: any) => el.all),
        backgroundColor: [
          "rgb(255, 99, 132)",
          "rgb(54, 162, 235)",
          "rgb(255, 205, 86)",
        ],
        datalabels: {
          font: {
            size: 30,
            weight: "bold",
          } as any,
        } as any,
      },
    ],
  };
  return (
    <>
      {/* 일별 문의 현황 */}
      <div className="w-[1000px] mx-auto my-0">
        <Bar options={options} data={data} plugins={data.plugins} />
      </div>
      {/* <Space y={32} /> */}
      {/* 카테고리 바 차트 */}
      {/* <div className="w-[1000px] mx-auto my-0"> */}
      {/* <Bar options={options2} data={data2} plugins={data2.plugins} /> */}
      {/* </div> */}
      <Space y={64} />
      {/* 카테고리 원형 차트 */}
      <div className="w-[800px] mx-auto my-0">
        <Doughnut options={options3} data={data3} plugins={data3.plugins} />
      </div>
    </>
  );
};

export default ChartTest;
