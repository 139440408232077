import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: number = 0;

export const addupNotice = createSlice({
  name: "addupNotice",
  initialState,
  reducers: {
    setNotice: (state, action: PayloadAction<number>) => {
      return action.payload;
    },
  },
});

export const { setNotice } = addupNotice.actions;
export default addupNotice.reducer;
