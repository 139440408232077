import { Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { getTicketApi, putTicketApi } from "Apis/apis";
import Button from "Components/Atoms/Button/button";
import Select from "Components/Atoms/Select/select";
import Space from "Components/Atoms/Space/space";
import Tag from "Components/Atoms/Tag/tag";
import SearchSelect from "Components/Molecules/SearchSelect/searchSelect";
import react, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { ReducerType } from "Store/store";
import { ClickInputForm, CT } from "types/interface";

import useServiceNo from "Util/Hooks/useServiceIdx";
import LANG, { getLang } from "Util/lang";
import { ReactComponent as Q } from "Util/svg/q.svg";

const Ticket = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { check_code } = useSelector((state: ReducerType) => state.user);
  const Percent: ClickInputForm[] = [...Array(11)].map((el, idx) => ({
    idx: idx * 10,
    label: `${idx * 10}`,
  }));
  const [csList, setCsList] = useState<CT[]>([]);
  const [csSum, setCsSum] = useState(0);
  const [serviceNo, setServiceNo] = useServiceNo();
  const serviceList = useSelector((state: ReducerType) =>
    state.serviceList.filter((el) => el.state !== 2 && el.state !== 1),
  ).map((el) => (el.idx === -1 ? { ...el, label: "서비스 선택" } : el));
  const getCs = async () => {
    const { serviceNo = -1 } = location.state || {};
    setServiceNo(serviceNo);
    if (serviceNo === -1) return;
    const {
      data: { cs },
    } = await getTicketApi(serviceNo);
    setCsList(cs.cs_ticket.filter((el: CT) => el.work_state !== 2));
    setCsSum(cs.ticket_sum);
  };

  const onChange = (selectCs: CT) => async (percent: number) => {
    setCsList(
      csList.map((el) => {
        if (el.cs_service_no !== selectCs.cs_service_no) return el;

        const _el = { ...el };
        _el.ticket_percent = percent;
        return _el;
      }),
    );

    const body = {
      ticketPercent: percent,
    };

    await putTicketApi(body, selectCs.cs_service_no!);

    const {
      data: { cs },
    } = await getTicketApi(serviceNo);
    setCsSum(cs.ticket_sum);
  };

  const onSubmit = () => {
    if (serviceNo === -1) return alert("서비스를 선택해주세요.");
    navigate("", {
      state: { pageNo: 2, serviceNo: serviceNo },
    });
  };

  useEffect(() => {
    getCs();
  }, [location.state]);
  useEffect(() => {
    navigate("", {
      state: { pageNo: 2, serviceNo: serviceNo },
    });
  }, [serviceNo]);
  const columns: ColumnsType<CT> = [
    {
      title: "서비스명",
      dataIndex: "service_name",
      align: "center",
      width: "150px",
    },
    {
      title: "상담원명",
      dataIndex: "cs_name",
      align: "center",
    },
    {
      title: LANG[getLang()].assistantID,
      dataIndex: "cs_id",
      align: "center",
      width: "300px",
    },
    {
      title: LANG[getLang()].state,
      dataIndex: "work_state",
      align: "center",

      render: (now) =>
        now ? (
          now === 1 ? (
            <Tag
              name="휴가 (휴가 기간 동안 자동 할당이 제외됩니다)"
              type="ready"
            ></Tag>
          ) : (
            <Tag name="퇴사" type="error"></Tag>
          )
        ) : (
          <Tag name="근무" type="primary"></Tag>
        ),
    },
    {
      title: (
        <div className="flex justify-center">
          <span>{`비율 (합계 : ${csSum})`}</span>
          <span>
            <Tooltip
              placement="top"
              title={`예시) 비율(합계: 60) 근무중인 상담원의 비율이 각각 10, 20, 30 이면 총 60중에서 1/6, 2/6, 3/6 으로 티켓할당이 이루어집니다. `}
            >
              <Q />
            </Tooltip>
          </span>
        </div>
      ),
      dataIndex: "ticket_percent",
      align: "center",
      render: (now, row, idx) => {
        return (
          <div className="flex items-center justify-center">
            <div>
              <Select
                dataList={Percent}
                onChange={onChange(row)}
                val={now}
                zindex={1}
              />
            </div>
            <span className="font-bold "> / {csSum}</span>
          </div>
        );
      },
    },
  ];

  return (
    <div id="ticket">
      <div className="flex">
        <Select dataList={serviceList} val={serviceNo} setVal={setServiceNo} />
        <Space y={46} />
      </div>
      <Space y={20} />
      <div className="max-w-7xl">
        <Table rowKey="cs_service_no" dataSource={csList} columns={columns} />
      </div>
    </div>
  );
};

export default Ticket;
