import { Table } from "antd";
import { ColumnsType } from "antd/es/table";

import Background from "Components/Atoms/Background/background";
import H2 from "Components/Atoms/H2/h2";
import Space from "Components/Atoms/Space/space";
import Button from "Components/Atoms/Button/button";
import { modalToggle } from "Store/Slices/modal";
import { ReducerType, useAppDispatch } from "Store/store";
import { ReactComponent as ModalX } from "Util/svg/modalX.svg";
import { ShortCut } from "types/interface";
import { GetShortCutListApi } from "Apis/apis";
import { useSelector } from "react-redux";

const ShortcutsModal = ({
  setChoicedStortCut,
}: {
  setChoicedStortCut: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const dispatch = useAppDispatch();

  const { data: shortCutList } = GetShortCutListApi();

  const columns: ColumnsType<ShortCut> = [
    {
      title: "제목",
      dataIndex: "title",
      align: "center",
      render: (now, row) => (
        <button onClick={() => onClickShortCut(row.shortcut_no)}>{now}</button>
      ),
    },
    {
      title: "바로가기",
      dataIndex: "url",
      align: "center",
      width: "130px",
      render: (now) => (
        <a href={now} target="_blank" rel="noreferrer">
          바로가기
        </a>
      ),
    },
  ];

  const onClickShortCut = (idx: number) => {
    setChoicedStortCut(idx);
    dispatch(modalToggle(-4));
  };

  return (
    <Background modalNum={-2}>
      <div style={{ width: "640px" }} className="p-32 bg-f2f3f8">
        <div className="flex items-center justify-between">
          <H2 isPrimary={true} name="바로가기 관리" />

          <div
            className="inline-block cursor-pointer"
            onClick={() => dispatch(modalToggle(0))}
          >
            <ModalX />
          </div>
        </div>
        <Space y={32} />
        <div className="flex justify-end">
          <Button btnText="등록" onClick={() => dispatch(modalToggle(-3))} />
        </div>
        <Space y={16} />
        <Table
          rowKey="shortcut_no"
          columns={columns}
          dataSource={shortCutList}
          pagination={false}
        />
      </div>
    </Background>
  );
};

export default ShortcutsModal;
