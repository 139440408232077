import Background from "Components/Atoms/Background/background";
import { ReactComponent as ModalX } from "Util/svg/modalX.svg";
import { modalToggle } from "Store/Slices/modal";
import { postSendMailApi } from "Apis/apis";
import Button from "Components/Atoms/Button/button";
import H2 from "Components/Atoms/H2/h2";
import Space from "Components/Atoms/Space/space";
import { useState } from "react";
import LoginInput from "Components/Molecules/Logininput/loginInput";
import { useAppDispatch } from "Store/store";

import Label from "Components/Atoms/Label/label";
import FileButton from "Components/Atoms/Button/fileButton";
import FileNameList from "Components/Atoms/FileNameList/fileNameList";
import { setFormData } from "Util/function";
import Quill from "Components/Atoms/Quill/quill";
import LANG, { getLang } from "Util/lang";

const MailModal = () => {
  const dispatch = useAppDispatch();

  const [to, setTo] = useState("");
  const [cc, setCc] = useState("");
  const [title, setTitle] = useState("");
  const [contents, setContents] = useState("");
  const [attachments, setAttachments] = useState<File[]>([]);
  const [from, setFrom] = useState("");

  // form 확인
  const pass = (): boolean => {
    if (to === "") {
      alert("이메일을 입력 해주세요");
      return false;
    } else if (title === "") {
      alert("제목을 입력 해주세요");
      return false;
    } else if (contents === "") {
      alert("내용을 입력 해주세요");
      return false;
    }

    return true;
  };

  const reset = () => {
    setTo("");
    setCc("");
    setTitle("");
    setContents("");
    setAttachments([]);
    setFrom("");
  };

  // 메일 전송
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const isPass: boolean = pass();
    if (!isPass) return;

    const body = {
      title,
      from,
      contents,
      to,
      cc,
      attachments,
    };
    const formData: FormData = new FormData();

    setFormData(formData, body);
    for (let i = 0; i < attachments.length; i++) {
      formData.append("attatchments", attachments[i]);
    }

    await postSendMailApi(formData);
    alert("메일 전송완료");
    reset();
    dispatch(modalToggle(-7));
  };

  return (
    <Background modalNum={-7}>
      <form
        onSubmit={onSubmit}
        className="absolute px-40 py-48 overflow-auto bg-white rounded-lg w-460 p-center max-h-[95vh] pretty-scrollbar"
      >
        <div
          onClick={() => dispatch(modalToggle(-7))}
          className="flex justify-end cursor-pointer"
        >
          <ModalX />
        </div>
        <H2 name="새메일 쓰기" />
        <span className="text-sm text-gray-400">
          창을 닫아도 내용은 사라지지 않습니다.
        </span>
        <Space y={20} />
        <LoginInput
          id="title"
          type="text"
          placeholder="제목을 입력해주세요"
          value={title}
          setValue={setTitle}
          name={LANG[getLang()].title}
        />
        <Space y={20} />
        <LoginInput
          id="email"
          type="text"
          placeholder="다수 입력시 컴마(,)로 구분"
          value={to}
          setValue={setTo}
          name="고객 이메일"
          required
        />
        <Space y={20} />
        <LoginInput
          id="ref"
          type="text"
          placeholder="다수 입력시 컴마(,)로 구분"
          value={cc}
          setValue={setCc}
          name="참조"
        />
        <Space y={20} />
        <LoginInput
          id="number"
          type="email"
          placeholder=""
          value={from}
          setValue={setFrom}
          name="보내는주소"
        />
        <Space y={20} />
        <Quill value={contents} setValue={setContents} toolbar={false} />
        <Space y={20} />

        <Label labelfor="img" name="첨부파일" />
        <Space y={4} />
        <FileButton
          value={attachments}
          setValue={setAttachments}
          size="md"
          max={3}
        />
        <Space y={8} />
        <div className="grid grid-cols-2 gap-8">
          <FileNameList fileList={attachments} setFileList={setAttachments} />
        </div>

        <Space y={20} />
        <Button type="submit" btnText="보내기" full />
      </form>
    </Background>
  );
};

export default MailModal;
