import Input from "Components/Atoms/Input/input";
import Label from "Components/Atoms/Label/label";
import Space from "Components/Atoms/Space/space";
import { ReactComponent as Require } from "Util/svg/require.svg";

/**
 * 로그인페이지 : ID PW 인풋창
 * @param {string} placeholder 정의어
 */
const InputRowNumber = ({
  id,
  placeholder,
  name,
  value,
  setValue,
  require = true,
  width = "w-130",
  inputWidth = "w-300",
}: {
  id: string;
  name: string;
  placeholder?: string;
  value: number;
  setValue: React.Dispatch<React.SetStateAction<number>>;
  require?: boolean;
  width?: string;
  inputWidth?: string;
}) => {
  return (
    <div className="flex items-center">
      <div className={`flex items-center px-16 py-20 bg-e5e6ed ${width}`}>
        <Label labelfor={id} name={name} fontSize={16} />
        <Space x={4} />
        {require && <Require />}
      </div>
      <div className="flex-1 h-full bg-white p-13">
        <div className="flex items-center">
          <div className={inputWidth}>
            <Input
              id={id}
              placeholder={placeholder}
              value={value}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setValue(+e.target.value)
              }
              type="number"
              required={require}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InputRowNumber;
