import React from "react";
/**
 * Label atomic
 * @param {string} labelfor label에 사용할 ID
 * @param {string} name 텍스트
 * @param {string} fontBold 텍스트
 * @param {number} fontSize 텍스트
 */
const Label = ({
  labelfor,
  name,
  fontBold = "bold",
  fontSize = 14,
}: {
  labelfor: string;
  name: string;
  fontBold?: string;
  fontSize?: number;
}) => {
  return (
    <label
      style={{ fontSize: `${fontSize}px` }}
      htmlFor={labelfor}
      className={`${fontBold} text-primary`}
    >
      {name}
    </label>
  );
};

export default Label;
