import { ReactComponent as Require } from "Util/svg/require.svg";

import { useState, useEffect } from "react";
import Button from "Components/Atoms/Button/button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  deleteHolidayApi,
  getConsultantDetailApi,
  putConsultantApi,
  putHolidayApi,
} from "Apis/apis";
import InputRowRadio from "Components/Molecules/InputRowRadio/inputRowRadio";
import AccountFixForm from "Components/Organisms/AccountFixForm/accountFixForm";
import ChangeFixModal from "Components/Molecules/ChangeFixModal/changeFixModal";

import InputRowCheckBox from "Components/Molecules/InputRowCheckBox/inputRowCheckBox";
import LANG, { getLang } from "Util/lang";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import HolidayTimeModal from "Components/Molecules/TimeModal/holidayTimeModal";
import { useDispatch } from "react-redux";
import modal, { modalToggle } from "Store/Slices/modal";
import useDidMountEffect from "Util/Hooks/useDidMountEffect";
const AUTH_DATA = [
  { idx: 2, label: "퇴사" },
  { idx: 1, label: "휴가" },
];
const AccountAdd = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [csId, setCsId] = useState("");
  const [csNo, setCsNo] = useState(0);
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [serviceList, setServiceList] = useState<number[]>([]);
  const [level, setLevel] = useState(0);
  const [auth, setAuth] = useState<number[]>([]);
  const [profile, setProfile] = useState<File>({} as File); // 이미지 파일 blob
  const [profileUrl, setProfileUrl] = useState(""); // 이미지 주소
  const [originName, setOriginName] = useState(""); // 이미지 이름
  const [state, setState] = useState<number>(1);
  const [isidCheck, setIsIdCheck] = useState(false);
  const [starValue, setStarValue] = useState(10); // 별점리뷰
  const [holiday, setHoliday] = useState<number[]>([]);
  const [holidayStartTime, setHolidayStartTime] = useState("");
  const [holidayEndTime, setHolidayEndTime] = useState("");
  //merge
  const pass = (): boolean => {
    if (name === "") {
      alert("이름을 작성 해주세요");
      return false;
    } else if (phoneNumber === "") {
      alert("핸드폰 번호를 작성 해주세요");
      return false;
    } else if (serviceList.length === 0) {
      alert("담당 서비스를 선택 해주세요");
      return false;
    }
    return true;
  };

  const getDetail = async () => {
    const { data } = await getConsultantDetailApi(location.state);
    setCsId(data.cs_id);
    setCsNo(data.cs_no);
    setName(data.cs_name);
    setPhoneNumber(data.phone_number);
    setServiceList(data.service_no_list);
    setAuth(data.authority === 3 ? [1, 2] : [data.authority]);
    setLevel(data.level);
    setState(data.state);
    setStarValue(data.star_percent);
    setProfileUrl(data.cs_profile);
    setOriginName(data.original_filename);
    setHoliday(data.work_state ? (data.work_state === 1 ? [1] : [2]) : []);
    setHolidayStartTime(data.leave.start_date_hour);
    setHolidayEndTime(data.leave.end_date_hour);
  };
  const postCs = async () => {
    const isPass: boolean = pass();
    if (!isPass) return;

    const confirm = window.confirm("수정 하시겠습니까?");
    if (!confirm) return;

    if (holiday.length > 1) return alert("근무상태의 중복선택이 안됩니다.");

    let sum = 0;
    for (let i = 0; i < auth.length; i++) {
      sum += auth[i];
    }

    const formData: any = new FormData();

    formData.append("name", name);
    formData.append("phoneNumber", phoneNumber);
    for (let i = 0; i < serviceList.length; i++) {
      formData.append("serviceList[]", serviceList[i]);
    }
    formData.append("level", level);
    formData.append("authority", sum);
    formData.append("state", state);
    formData.append("starPercent", starValue);
    formData.append("cs", profile);
    formData.append(
      "workState",
      holiday.length === 0 ? [0] : holiday[0] === 2 ? [2] : [0]
    );
    await putConsultantApi(formData, location.state);

    if (holidayStartTime) {
      const body = {
        startDateHour: holidayStartTime,
        endDateHour: holidayEndTime,
      };

      await putHolidayApi(csNo, body);
      alert("휴가설정이 완료됐습니다. ");
    }
    navigate("/account/accountsetting");
  };
  const deleteCs = async () => {
    const formData: any = new FormData();
    const confirm = window.confirm("상담원을 삭제 하시겠습니까?");
    if (!confirm) return;
    formData.append("state", 2);
    await putConsultantApi(formData, location.state);
    navigate("/account/accountsetting");
  };
  const deleteHoliday = async () => {
    await deleteHolidayApi(csNo);
    const { data } = await getConsultantDetailApi(csNo);
    setHolidayStartTime(data.leave.start_date_hour);
    setHolidayEndTime(data.leave.end_date_hour);
    alert("휴가삭제가 완료됐습니다.");
  };
  useEffect(() => {
    getDetail();
  }, []);

  const STATE_DATA = [
    { idx: 1, label: LANG[getLang()].waiting, id: "wait" },
    { idx: 0, label: LANG[getLang()].approval, id: "ok" },
  ];

  return (
    <div>
      <div className="flex items-center justify-end mb-10">
        <Require />
        <span className="ml-5">표기된 사항은 필수 입력 사항입니다.</span>
      </div>
      <div>
        <AccountFixForm
          id={csId}
          setId={setCsId}
          name={name}
          setName={setName}
          phone={phoneNumber}
          setPhone={setPhoneNumber}
          setProfile={setProfile}
          level={level}
          setLevel={setLevel}
          service={serviceList}
          setService={setServiceList}
          auth={auth}
          setAuth={setAuth}
          isidCheck={isidCheck}
          setIsIdCheck={setIsIdCheck}
          disable={true}
          profileUrl={profileUrl}
          setProfileUrl={setProfileUrl}
          originName={originName}
        />
        <InputRowRadio
          data={STATE_DATA}
          name={LANG[getLang()].state}
          value={state!}
          setValue={setState!}
        />
        <div className="relative">
          <InputRowCheckBox
            name="근무상태"
            data={AUTH_DATA}
            value={holiday}
            setValue={(val: any) =>
              setHoliday(() => {
                if (val[0] === 1) {
                  if (val[1] === 2) {
                    deleteHoliday();
                    return [2];
                  } else {
                    dispatch(modalToggle(2));
                  }
                } else if (val[0] === 2) {
                  if (val[1] === 1) {
                    alert("퇴사 상태인 상담원에게 휴가를 설정할 수 없습니다.");
                    return [2];
                  }
                } else {
                  if (holidayStartTime !== "") {
                    deleteHoliday();
                  }
                }
                return val;
              })
            }
            require={false}
          />
          {holidayStartTime && (
            <span className="absolute top-[50%] -translate-y-1/2 left-[280px]">
              {`${holidayStartTime}시   ~  ${holidayEndTime}시 `}
            </span>
          )}
        </div>
      </div>
      <div className="flex justify-end gap-10 mt-10">
        <Button
          color="delete"
          btnText={LANG[getLang()].delete}
          onClick={deleteCs}
        />

        <Link to="/account/accountsetting">
          <Button btnText={LANG[getLang()].list} />
        </Link>
        <Button onClick={postCs} btnText={LANG[getLang()].change} />
      </div>
      <ChangeFixModal csNo={csNo} />
      <HolidayTimeModal
        csNo={csNo}
        modalNum={2}
        setStartTime={setHolidayStartTime}
        setEndTime={setHolidayEndTime}
      />
    </div>
  );
};

export default AccountAdd;
