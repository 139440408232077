import Table, { ColumnsType } from "antd/es/table";
import { Label } from "types/interface";
import { GetLabelApi, putLabelCuts } from "Apis/apis";
import { useQueryClient } from "@tanstack/react-query";

const Labeling = () => {
  let timer: any;
  const queryClient = useQueryClient();
  const { data } = GetLabelApi();

  const onChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
    serviceNo: number
  ) => {
    const label = e.target.value;
    const body = {
      serviceNo,
      label,
    };

    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(async function () {
      await putLabelCuts(body);
      queryClient.invalidateQueries(["GetLabelApi"]);
    }, 200);
  };

  const columns: ColumnsType<Label> = [
    {
      title: "서비스 명",
      dataIndex: "service_name",
      align: "center",
    },
    {
      title: "라벨링",
      dataIndex: "label",
      align: "center",
      render: (now, row) => {
        return (
          <input
            value={now}
            onChange={(e) => onChange(e, row.service_no)}
            type="color"
          />
        );
      },
    },
  ];

  if (!data) return <></>;
  return (
    <div className="w-500">
      <Table
        columns={columns}
        dataSource={data}
        rowKey="cs_service_no"
        pagination={false}
      />
    </div>
  );
};

export default Labeling;
