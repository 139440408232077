import React from "react";

const QnaLabel = ({
  htmlFor,
  name,
}: {
  htmlFor?: string;
  name: string;
}) => {
  return (
    <label htmlFor={htmlFor} className={`text-787878 text-14 `}>
      {name}
    </label>
  );
};

export default QnaLabel;
