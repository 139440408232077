import { useState, useEffect } from "react";

import InputRow from "Components/Molecules/InputRow/inputRow";

import JoinRowId from "Components/Molecules/JoinRowId/joinRowId";
import InputRowFile from "Components/Molecules/InputRowFile/inputRowFile";
import ChangeFixModal from "Components/Molecules/ChangeFixModal/changeFixModal";
import InputRowChangePw from "Components/Molecules/InputRowChangePw/inputRowChangePw";
import LANG, { getLang } from "Util/lang";

const UserInfoForm = ({
  comId,
  password,
  name,
  phone,
  company,
  department,
  imgName,
  position,

  setComId,
  setPassword,
  setName,
  setPhone,
  setCompany,
  setDepartment,
  setImg,
  setPosition,
}: {
  comId: string;
  password: string;
  name: string;
  phone: string;
  company: string;
  department: string;
  imgName: string;
  position: string;

  setComId: React.Dispatch<React.SetStateAction<string>>;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  setName: React.Dispatch<React.SetStateAction<string>>;
  setPhone: React.Dispatch<React.SetStateAction<string>>;
  setCompany: React.Dispatch<React.SetStateAction<string>>;
  setDepartment: React.Dispatch<React.SetStateAction<string>>;
  setImg: React.Dispatch<React.SetStateAction<File>>;
  setPosition: React.Dispatch<React.SetStateAction<string>>;
}) => {
  return (
    <div>
      <JoinRowId
        id="id"
        name={LANG[getLang()].eMail}
        value={comId}
        setValue={setComId}
        disabled={true}
      />
      <InputRowChangePw
        id="pw"
        name={LANG[getLang()].passward}
        value={password}
        setValue={setPassword}
        placeholder="비밀번호 입력하세요."
      />
      <InputRow
        id="name"
        name={LANG[getLang()].name}
        value={name}
        setValue={setName}
      />
      <InputRow
        id="phone"
        name={LANG[getLang()].phone}
        value={phone}
        setValue={setPhone}
        placeholder="“-” 없이 입력, ex) 01071545574"
      />
      <InputRow
        id="company"
        name={LANG[getLang()].company}
        value={company}
        setValue={setCompany}
      />
      <InputRow
        id="team"
        name={LANG[getLang()].department}
        value={department}
        setValue={setDepartment}
      />
      <InputRow
        id="positon"
        name={"직급"}
        value={position}
        setValue={setPosition}
      />
      <InputRowFile
        require={false}
        id="profile_img"
        name={LANG[getLang()].corporateLogo}
        value={imgName}
        setValue={setImg}
      />
      <ChangeFixModal />
    </div>
  );
};

export default UserInfoForm;
