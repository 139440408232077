import { useState, useEffect } from "react";

import Background from "Components/Atoms/Background/background";
import H2 from "Components/Atoms/H2/h2";
import Space from "Components/Atoms/Space/space";

import { ReactComponent as ModalX } from "Util/svg/modalX.svg";

import { useDispatch, useSelector } from "react-redux";
import { modalToggle } from "Store/Slices/modal";
import Button from "Components/Atoms/Button/button";
import PhoneNumberList from "Components/Atoms/PhoneNumberList/phoneNumberList";
import { ReducerType } from "Store/store";
import { postSendSMSApi, putAnswerApi, useGetAlarmListApi } from "Apis/apis";
import Select from "Components/Atoms/Select/select";
import { Telegram } from "types/interface";
import { setSmsNumber } from "Store/Slices/smsNumber";
import { useQueryClient } from "@tanstack/react-query";
import Label from "Components/Atoms/Label/label";
import { levelCheck } from "Store/Slices/user";

const SmsModal = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const smsInfo = useSelector((state: ReducerType) => state.smsNumber);
  const qIdx = useSelector((state: ReducerType) => state.qIdx);

  const modalNum = useSelector((state: ReducerType) => state.modal);
  const servicsList = useSelector((state: ReducerType) => state.serviceList);
  const { data } = useGetAlarmListApi();

  const [serviceNo, setServiceNo] = useState(-1);
  const [msg, setMsg] = useState("");
  const [phoneNumber, setphoneNumber] = useState("");
  const [phoneNumberList, setphoneNumberList] = useState<string[]>([]);

  const checkNumber = (text: string) => {
    let isNumber: boolean = true;
    text.split("").forEach((text) => {
      const num = Number(text);
      if (isNaN(num)) {
        isNumber = false;
      }
    });
    return isNumber;
  };

  const addNumber = (e: any) => {
    e.preventDefault();
    if (phoneNumber === "") return;
    if (phoneNumberList.length >= 10) {
      alert("최대10개까지 추가 가능합니다.");
      return;
    }
    const isNumber = checkNumber(phoneNumber);
    if (!isNumber) {
      alert("숫자만 입력 해주세요");
      return;
    }
    const idx = phoneNumberList.findIndex((el) => el === phoneNumber);
    if (idx !== -1) {
      alert("중복된 번호입니다.");
      return;
    }
    setphoneNumberList(phoneNumberList.concat(phoneNumber));
    setphoneNumber("");
  };

  const onSendMsg = async () => {
    if (!data) return;
    if (serviceNo === -1) {
      alert("서비스를 선택 해주세요");
      return;
    }
    if (phoneNumberList.length === 0) {
      alert("받는사람의 번호를 입력 해주세요");
      return;
    }
    if (msg === "") {
      alert("내용을 입력 해주세요");
      return;
    }

    const { sms_key, identifier, sender } =
      data.find((el) => el.service_no === serviceNo) || ({} as Telegram);
    if (sms_key === "" || identifier === "" || sender === "") {
      alert("SMS정보가 입력되지 않았습니다. 관리자에게 문의해주세요");
      return;
    }
    const confirm = window.confirm("전송 하시겠습니까?");
    if (!confirm) return;

    const body = {
      serviceNo,
      msg,
      receiver: phoneNumberList.join(","),
    };
    await postSendSMSApi(body);

    // 문의상세에서 sms보내기 클릭한거면
    if (smsInfo.phoneNumber !== "") {
      await putAnswerApi(qIdx, {
        contents: msg,
        state: 2,
      });
      queryClient.invalidateQueries(["useGetQDetailApi", qIdx]);
    }
    setphoneNumberList([]);
    setMsg("");
    alert("전송 되었습니다.");
  };

  // redux에 번호 추가되면 받는사람에 반영
  useEffect(() => {
    if (smsInfo.phoneNumber !== "") {
      setphoneNumberList((numList) => numList.concat(smsInfo.phoneNumber));
      setServiceNo(smsInfo.serviceNo);
    }
  }, [smsInfo]);

  // 모달 닫으면 redux 초기화
  // 다른문의에 메모 남기는거 방지
  useEffect(() => {
    if (modalNum === 0) {
      dispatch(setSmsNumber({ serviceNo: -1, phoneNumber: "" }));
    }
  }, [modalNum]);

  return (
    <Background modalNum={-1}>
      <div style={{ width: "740px" }} className="p-32 bg-f2f3f8">
        <div className="flex items-center justify-between">
          <H2 isPrimary={true} name="SMS전송" />
          <div
            className="inline-block cursor-pointer"
            onClick={() => dispatch(modalToggle(-1))}
          >
            <ModalX />
          </div>
        </div>
        <Space y={24} />
        <div className="flex justify-between">
          <Select
            dataList={servicsList}
            val={serviceNo}
            setVal={setServiceNo}
          />
          <a
            href="https://smartsms.aligo.in/index.html"
            target="_blank"
            rel="noreferrer"
          >
            <Button btnText="알리고 바로가기" />
          </a>
        </div>
        <Space y={24} />
        <div className="grid grid-cols-2 gap-24">
          <div className="">
            <textarea
              style={{ height: "480px" }}
              className="w-full p-8 px-20 py-16 resize-none"
              value={msg}
              onChange={(e) => setMsg(e.target.value)}
              placeholder="문자 내용 입력"
            />
          </div>
          <div className="">
            <Label name="전송 대상" labelfor="d" />
            <Space y={8} />
            <PhoneNumberList
              phoneNumberList={phoneNumberList}
              setphoneNumberList={setphoneNumberList}
            />
            <Space y={26} />
            <form onSubmit={addNumber}>
              <Label name="번호 입력" labelfor="d" />
              <Space y={8} />
              <input
                value={phoneNumber}
                onChange={(e) => setphoneNumber(e.target.value)}
                className="w-full text-center leading-48"
                type="text"
                placeholder=" '-' 없이 입력"
              />
            </form>
            <Space y={26} />
            <Label
              name="번호 입력 후 'Enter'를 눌러야 등록됩니다."
              labelfor=""
            />
            <br />
            <Label
              name="등록된 번호를 클릭 시 대상에서 제외할 수 있습니다."
              labelfor=""
            />
            <br />
            <Label name="최대 10명까지 발송 가능합니다." labelfor="" />
            <Space y={8} />
            <Button
              full
              btnText="문자 발송하기"
              onClick={() =>
                dispatch(
                  levelCheck({
                    sl: "q10",
                    fuc: onSendMsg,
                  }),
                )
              }
            />
          </div>
        </div>
      </div>
    </Background>
  );
};

export default SmsModal;
