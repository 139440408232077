import { RotateElementT } from "types/interface";

/**
 * 90도 회전 atomic
 * @param {ReactNode} svg 돌릴 엘리먼트
 * @param {boolean} isRotate 상태값
 */

const RotateElement = ({ svg, isRotate }: RotateElementT) => {
  return (
    <div className={`transition03s ${isRotate && "rotate-90"}`}>{svg}</div>
  );
};

export default RotateElement;
