import { Layouts } from "types/interface";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: Layouts = {
  header: true,
  sidebar: true,
  bread_crmb: true,
  mode: "",
  now_path: ["account','service"],
};

export const layouts = createSlice({
  name: "layouts",
  initialState,
  reducers: {
    setLayouts(state, action: PayloadAction<Layouts>) {
      return { ...state, ...action.payload };
    },
    logoutMode(state) {
      return {
        ...state,
        sidebar: false,
        header: false,
        bread_crmb: false,
        mode: "logout",
      };
    },
    ctMode(state) {
      return {
        ...state,
        sidebar: false,
        header: true,
        bread_crmb: true,
        mode: "ct",
      };
    },

    init() {
      return initialState;
    },
    indexMode(state) {
      return {
        ...state,
        sidebar: true,
        header: true,
        bread_crmb: false,
        mode: "index",
      };
    },
  },
});

export const { setLayouts, logoutMode, ctMode, indexMode, init } =
  layouts.actions;
export default layouts.reducer;
