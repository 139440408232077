import { CategoryTotal, CategoryQnaList } from "types/interface";

const CategoryTable = ({
  data,
  total,
}: {
  data: CategoryQnaList[];
  total: CategoryTotal;
}) => {
  if (!data.length) return null;
  const result = data[0].data.reduce(
    (acc, cur) => (acc += cur.middle.length),
    0
  );
  return (
    <table id="sumTable">
      <thead>
        <tr>
          <th className="p-16 w-200">날짜</th>
          <th className="p-16 w-200">대카테고리</th>
          <th className="p-16 w-200">중카테고리</th>
          <th className="p-16 w-180">전체문의</th>
          <th className="p-16 w-180">답변완료</th>
          <th className="p-16 w-180">미처리</th>
        </tr>
      </thead>
      <tbody>
        {data.map((dailyData) => {
          return dailyData.data.map((bigData, bigIdx) => {
            return bigData.middle.map((midData, midIdx) => {
              const bigLength = bigData.middle.length;
              const isDate = midIdx === 0 && bigIdx === 0 && midIdx === 0;
              const idBig = midIdx === 0;
              const isTotal = bigLength === midIdx + 1;
              return (
                <tr key={midIdx}>
                  {isDate && <td rowSpan={result}>{dailyData.date}</td>}
                  {idBig && <td rowSpan={bigLength - 1}>{bigData.bname}</td>}
                  {isTotal ? (
                    <td className="gray" colSpan={2}>
                      계
                    </td>
                  ) : (
                    <td>{midData.mname}</td>
                  )}

                  <td className={`${isTotal && "gray"}`}>{midData.sum}</td>
                  <td className={`${isTotal && "gray"}`}>
                    {midData.reply_complete}
                  </td>
                  <td className={`${isTotal && "gray"}`}>{midData.reply_no}</td>
                </tr>
              );
            });
          });
        })}
        <tr>
          <td colSpan={3}>총계</td>
          <td>{total.sum || 0}</td>
          <td>{total.reply_complete || 0}</td>
          <td>{total.reply_no || 0}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default CategoryTable;
