import Label from "Components/Atoms/Label/label";
import Space from "Components/Atoms/Space/space";
import { ReactComponent as Require } from "Util/svg/require.svg";
import FileButton from "Components/Atoms/Button/fileButton";
import { useEffect } from "react";
/**
 * 로그인페이지 : ID PW 인풋창
 */
const InputRowMultiFile = ({
  id,
  name,
  value,
  setValue,
}: {
  id: string;
  name: string;
  value: File[];
  setValue: React.Dispatch<React.SetStateAction<File[]>>;
}) => {
  const sizeCheck = () => {
    let valueSize = 0;
    const maxSize = 30 * 1024 * 1024;
    for (let i = 0; i <= value.length - 1; i++) {
      valueSize += value[i].size;
    }

    if (valueSize > maxSize) {
      alert("파일용량이 최대용량의 30MB를 초과하셨습니다.");
      setValue([]);
    }
  };

  useEffect(() => {
    sizeCheck();
  }, [value]);
  return (
    <div className="flex items-center">
      <div className="flex items-center px-16 py-20 bg-e5e6ed w-130">
        <Label labelfor={id} name={name} fontSize={16} />
        <Space x={4} />
        {/* {require && <Require />} */}
      </div>
      <div className="flex-1 h-full bg-white p-13">
        <div className="flex items-center">
          <FileButton value={value} setValue={setValue} max={3} size="md" />
        </div>
      </div>
    </div>
  );
};

export default InputRowMultiFile;
