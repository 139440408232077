import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { MENU } from "routes";
import { ReducerType } from "Store/store";
import { Menu, MenuChild } from "types/interface";
import { ReactComponent as Arrow } from "Util/svg/arrow.svg";

const BreadCrumb = ({ nowPath }: { nowPath: string[] }) => {
  const { bread_crmb } = useSelector((state: ReducerType) => state.layouts);

  const [breadCrumb, setBreadCrumb] = useState(["", ""]);
  const checkPath = () => {
    if (!nowPath[1]) return;
    const big: Menu = MENU.find((menu: Menu) => menu.path === nowPath[0])!;
    if (!big) return;
    // url에 idx등이 파라미터로 있는 경우를 감안해서 split("/")[0] 적용
    const small = big.child.find(
      (child: MenuChild) => child.path.split("/")[0] === nowPath[1]
    )!;
    if (!small) {
      setBreadCrumb([big.name, ""]);
    } else {
      setBreadCrumb([big.name, small.name]);
    }
  };
  useEffect(() => {
    checkPath();
  }, [nowPath]);

  if (!bread_crmb) return <></>;
  return (
    <div id="breadCrumb" className="mb-20">
      <div className="flex text-primary">
        <h3>{breadCrumb[0]}</h3>
        {breadCrumb[1] && (
          <>
            <Arrow stroke="#969696" />
            <h3 className="flex font-semibold">{breadCrumb[1]}</h3>
          </>
        )}
      </div>
    </div>
  );
};

export default BreadCrumb;
