import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { setUser } from "Store/Slices/user";
import {
  searchCookie,
  setCookie,
  deleteCookie,
  getCookie,
  getToken,
  setToken,
} from "Util/function";
import { getTokenCheckApi, postLoginApi } from "Apis/apis";
import { ClickInputForm, User } from "types/interface";
import { useAppDispatch } from "Store/store";

import H2 from "Components/Atoms/H2/h2";
import Space from "Components/Atoms/Space/space";
import Button from "Components/Atoms/Button/button";
import LoginInput from "Components/Molecules/Logininput/loginInput";
import LoginJoin from "Components/Molecules/Loginjoin/loginJoin";
import Checkbox from "Components/Atoms/Checkbox/checkbox";
import Select from "Components/Atoms/Select/select";
import LANG, { getLang } from "Util/lang";
import LoginModal from "Components/Molecules/LoginModal/loginModal";
import { modalToggle } from "Store/Slices/modal";

export interface Language {
  idx: number;
  label: string;
}

const ID_DATA: ClickInputForm[] = [{ idx: 1, label: "아이디 기억하기" }];

const LoginForm = ({
  idValue,
  pwValue,
  checkValue,
  setIdValue,
  setPwValue,
  setCheckValue,
  languageChange,
  LANGUAGE,
  languageValue,
  setLanguageValue,
  logo,
  subDomain,
  company,
}: {
  idValue: string;
  pwValue: string;
  checkValue: number[];
  setIdValue: React.Dispatch<React.SetStateAction<string>>;
  setPwValue: React.Dispatch<React.SetStateAction<string>>;
  setCheckValue: React.Dispatch<React.SetStateAction<number[]>>;
  languageChange: React.ChangeEventHandler<HTMLSelectElement>;
  LANGUAGE: Language[];
  languageValue: number;
  setLanguageValue: React.Dispatch<React.SetStateAction<number>>;
  logo: string;
  subDomain: string;
  company: string;
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const idCookie = searchCookie("rememberID")!; // 쿠키에 저장된 값이 있는지 없는지
  const idKey = getCookie("rememberID"); // 쿠키에 저장된 id 값

  const pass = (): boolean => {
    if (idValue === "") {
      alert("아이디를 입력해주세요");
      return false;
    } else if (pwValue === "") {
      alert("비밀번호를 입력해주세요");
      return false;
    }
    return true;
  };
  const loginApi = async (
    e: React.FormEvent<HTMLFormElement>,
  ): Promise<void> => {
    e.preventDefault();
    const isPass: boolean = pass();
    if (!isPass) return;

    const { data } = await postLoginApi({
      companyId: idValue,
      password: pwValue,
      subDomain,
    });

    const dataToken = data.token;
    setToken(dataToken);
    const {
      data: { token: veryfiedToken },
    } = await getTokenCheckApi(subDomain);

    dispatch(setUser(veryfiedToken));
    localStorage.setItem(
      process.env.REACT_APP_LANG || "",
      String(languageValue),
    );

    localStorage.setItem("subDomain", subDomain);
    if (veryfiedToken.level === 1) {
      navigate("/");
    } else {
      navigate("/user/ctqna");
    }

    if (checkValue.length === 1) {
      if (idCookie === undefined || !idCookie || idValue !== idKey) {
        setCookie("rememberID", `${idValue}`);
      }
    } else {
      deleteCookie("rememberID");
    }
  };

  useEffect(() => {
    if (idCookie) {
      setIdValue(idKey!);
      setCheckValue([1]);
    } else {
      setCheckValue([]);
    }
  }, []);

  return (
    <>
      <form onSubmit={loginApi}>
        <div className="absolute login_page p-center">
          <img src={logo} alt="" className=" h-50" />

          <Space y={20} />
          <H2 name={`${company} 관리자 페이지`}></H2>
          <Space y={24} />
          <LoginInput
            id="userId"
            type="text"
            placeholder="이메일을 입력해주세요"
            value={idValue}
            setValue={setIdValue}
            name={LANG[getLang()].eMail}
          />
          <LoginInput
            id="userPw"
            type="password"
            placeholder="비밀번호를 입력해주세요"
            name={LANG[getLang()].passward}
            value={pwValue}
            setValue={setPwValue}
          />

          <div className="flex items-center justify-between bg-white">
            <Checkbox
              value={checkValue}
              setValue={setCheckValue}
              dataList={ID_DATA}
            />
            <span
              className="cursor-pointer text-14 bold text-primary"
              onClick={() => dispatch(modalToggle(1))}
            >
              비밀번호 찾기
            </span>
          </div>
          <Space y={24} />
          <Button btnText="로그인" full={true} type="submit" />
          <Space y={20} />

          <div className="text-center">
            <span className="text-12 text-e2e2e2">
              Copyright © ADD-UP Co., Ltd. All Rights Reserved.
            </span>
          </div>
        </div>
      </form>
      <LoginModal />
    </>
  );
};

export default LoginForm;
