import { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { putFaQApi, delFaQApi, getFAQDetailApi } from "Apis/apis";
import { FaqListApiBody } from "types/interface.d";
import InputRow from "Components/Molecules/InputRow/inputRow";
import Label from "Components/Atoms/Label/label";
import Space from "Components/Atoms/Space/space";
import { ReactComponent as Require } from "Util/svg/require.svg";
import LabelQuill from "Components/Molecules/LabelQuill/labelQuill";
import InputRowRadio from "Components/Molecules/InputRowRadio/inputRowRadio";
import Button from "Components/Atoms/Button/button";
import InputRowMultiFile from "Components/Molecules/InputRowMultiFile/inputRowMultiFile";
import FileNameList from "Components/Atoms/FileNameList/fileNameList";
import LANG, { getLang } from "Util/lang";
import ServiceCategory from "Components/Molecules/ServiceCategory/serviceCategory";

const RADIO_DATA = [
  {
    idx: 1,
    label: LANG[getLang()].waiting,
    id: "wait",
  },
  {
    idx: 0,
    label: LANG[getLang()].approval,
    id: "ok",
  },
];

const FaqFix = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { idx = "0" } = useParams();

  const [title, setTitle] = useState("");
  const [serviceNo, setServiceNo] = useState(-1);
  const [bCode, setBCode] = useState(-1);
  const [mCode, setMCode] = useState(-1);

  const [contents, setContents] = useState("");
  const [state, setState] = useState(0);
  const [file, setFile] = useState<File[]>([]);
  const [fileNameList, setFileNameList] = useState<string[]>([]);

  const pass = (): boolean => {
    if (title === "") {
      alert("제목을 입력해주세요.");
      return false;
    } else if (contents === "") {
      alert("내용을 입력해주세요.");
      return false;
    }
    return true;
  };
  const onSubmit = async () => {
    const isPass: boolean = pass();
    if (!isPass) return;
    const confirm = window.confirm("수정 하시겠습니까?");
    if (!confirm) return;
    const formData: any = new FormData();
    formData.append("title", title);
    formData.append("state", state);
    formData.append("contents", contents);
    formData.append("serviceNo", serviceNo);
    formData.append("bigCategoryCode", bCode);
    formData.append("middleCategoryCode", mCode);

    file.forEach((list) => formData.append("faq", list));
    await putFaQApi(+idx, formData);

    navigate("/service/potalboard", {
      state: { pageNo: 1, selectServiceNo: serviceNo },
    });
  };

  const deleteFAQ = async () => {
    const confirm = window.confirm("삭제 하시겠습니까?");
    if (!confirm) return;

    await delFaQApi(+idx);
    navigate("/service/potalboard", {
      state: { pageNo: 1, selectServiceNo: serviceNo },
    });
  };

  const getDetail = async () => {
    if (+idx === 0) {
      alert("잘못된 접근입니다.");
      navigate("/service/potalboard", {
        state: { pageNo: 1, selectServiceNo: serviceNo },
      });
      return;
    }
    const {
      data: { faq },
    } = await getFAQDetailApi(+idx);

    setTitle(faq.title);
    setContents(faq.contents);
    setState(faq.state);
    setFileNameList(faq.original_filename || []);
    setServiceNo(faq.service_no);
    setBCode(faq.big_category_code);
    setMCode(faq.middle_category_code);
  };

  useEffect(() => {
    getDetail();
  }, []);

  return (
    <>
      <div className="rounded bg-ffffff">
        <InputRow
          id="faqTitle"
          name={LANG[getLang()].title}
          value={title}
          setValue={setTitle}
        />
        <div className="flex items-center">
          <div className="flex items-center px-16 py-20 bg-e5e6ed w-130">
            <Label labelfor="select" name="분류" fontSize={16} />
            <Space x={4} />
            <Require />
          </div>
          <ServiceCategory
            serviceNo={serviceNo}
            setServiceNo={setServiceNo}
            bCode={bCode}
            setBCode={setBCode}
            mCode={mCode}
            setMCode={setMCode}
          />
        </div>
        <LabelQuill
          id="faqQuill"
          name={LANG[getLang()].content}
          text={contents}
          setText={setContents}
        />
        <div className="flex items-center">
          <InputRowMultiFile
            id="profile_img"
            name={LANG[getLang()].attachedFile}
            setValue={setFile}
            value={file}
          />
          {file.length > 0
            ? file.map((el) => el.name).join(", ")
            : fileNameList.map((el) => el).join(", ")}
        </div>
        <InputRowRadio
          data={RADIO_DATA}
          name={LANG[getLang()].state}
          value={state}
          setValue={setState}
        />
      </div>
      <div className="flex justify-end gap-10 mt-10 ">
        <Button onClick={onSubmit} btnText={LANG[getLang()].change} />
        <Button
          btnText={LANG[getLang()].list}
          onClick={() =>
            navigate("/service/potalboard", {
              state: { pageNo: 1, selectServiceNo: serviceNo },
            })
          }
        />
        <Button
          onClick={deleteFAQ}
          color="delete"
          btnText={LANG[getLang()].delete}
        />
      </div>
    </>
  );
};

export default FaqFix;
