import { useState } from "react";

import Background from "Components/Atoms/Background/background";
import H2 from "Components/Atoms/H2/h2";
import Space from "Components/Atoms/Space/space";
import Button from "Components/Atoms/Button/button";
import modal, { modalToggle } from "Store/Slices/modal";
import { ReducerType, useAppDispatch } from "Store/store";
import { ReactComponent as ModalX } from "Util/svg/modalX.svg";
import InputRow from "../InputRow/inputRow";
import InputRowFile from "../InputRowFile/inputRowFile";
import { delEmailBannerApi, postEmailBannerApi } from "Apis/apis";
import { useLocation } from "react-router-dom";
import user from "Store/Slices/user";
import { useSelector } from "react-redux";

const AddBannerModal = ({
  modalNum = 1,
  link,
  setFile,
  setLink,
  imgName,
  setImgName,
  addName,
  setAddName,
  serviceNo,
  mail_ad_no,
  file,
  setImgUrl,
  setAddMailNo,
}: {
  file: File;
  modalNum: number;
  link: string;
  addName: string;
  setFile: React.Dispatch<React.SetStateAction<File>>;
  setLink: React.Dispatch<React.SetStateAction<string>>;
  setAddName: React.Dispatch<React.SetStateAction<string>>;
  imgName?: string;
  setImgName?: React.Dispatch<React.SetStateAction<string>>;
  serviceNo: number;
  mail_ad_no?: number;
  setImgUrl?: React.Dispatch<React.SetStateAction<string>>;
  setAddMailNo?: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const dispatch = useAppDispatch();
  const user = useSelector((state: ReducerType) => state.user);

  const pass = (): boolean => {
    if (addName === "") {
      alert("광고명을 입력해주세요.");
      return false;
    } else if (!file.name) {
      alert("파일을 첨부해주세요.");
      return false;
    } else if (link === "") {
      alert("링크를 입력해주세요.");
      return false;
    } else if (user.limit_banner === 0) {
      alert("상품 업그레이드가 필요합니다.");
      return false;
    }
    return true;
  };
  const addBanner = () => {
    const isPass: boolean = pass();

    if (!isPass) return;
    dispatch(modalToggle(modalNum));
  };
  const resetData = () => {
    if (!imgName) {
      setLink("");
      setFile({} as File);
      setAddName("");
      return dispatch(modalToggle(modalNum));
    } else {
      return dispatch(modalToggle(modalNum));
    }
  };
  const deleteBanner = async () => {
    const confirm = window.confirm("정말 삭제하시겠습니까?");
    if (!confirm) return;
    if (mail_ad_no !== -1 && mail_ad_no) {
      await delEmailBannerApi(mail_ad_no);
    }
    if (setAddMailNo) {
      setAddMailNo(-1);
    }
    setLink("");
    setAddName("");
    setFile({} as File);
    if (setImgName) {
      setImgName("");
    }
    dispatch(modalToggle(modalNum));
  };

  return (
    <Background modalNum={modalNum}>
      <div style={{ width: "640px" }} className="p-32 bg-f2f3f8">
        <div className="flex items-center justify-between">
          <H2 isPrimary={true} name={`배너 광고`} />
          <div className="inline-block cursor-pointer" onClick={resetData}>
            <ModalX />
          </div>
        </div>
        <Space y={20} />
        <InputRow
          id="bannerName"
          name="광고명"
          setValue={setAddName}
          value={addName}
        />
        {!imgName ? (
          <InputRowFile
            id="bannerImg"
            name="배너 이미지"
            setValue={setFile}
            setImgUrl={setImgUrl}
          />
        ) : (
          <InputRowFile
            id="bannerImg"
            name="배너 이미지"
            setValue={setFile}
            value={imgName}
          />
        )}
        <InputRow
          id="link"
          name="링크"
          setValue={setLink}
          value={link}
          placeholder="https부터 입력 해주세요."
        />
        <Space y={20} />
        <div className="flex justify-end">
          {imgName !== "" && imgName ? (
            <Button btnText="삭제" color="delete" onClick={deleteBanner} />
          ) : (
            <Button btnText="등록" onClick={addBanner} />
          )}
        </div>
      </div>
    </Background>
  );
};

export default AddBannerModal;
