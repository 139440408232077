import Background from "Components/Atoms/Background/background";
import React from "react";
import { modalToggle } from "Store/Slices/modal";
import { ReactComponent as ModalX } from "Util/svg/modalX.svg";
import { useDispatch } from "react-redux";
import parse from "html-react-parser";

const ExampleAutoMailModal = ({ modalNum }: { modalNum: number }) => {
  const dispatch = useDispatch();

  return (
    <Background modalNum={modalNum}>
      <div
        style={{ width: "767px" }}
        className="p-32 max-h-[80vh] overflow-auto bg-white pretty-scrollbar"
      >
        <div
          onClick={() => dispatch(modalToggle(modalNum))}
          className="flex justify-end cursor-pointer"
        >
          <ModalX />
        </div>
        <div className="max-w-[767px] p-16">
          <img className="h-100" src="/imgs/common/logo.png" alt="서비스배너" />
          <hr className="mt-20 mb-20 border-[#EF7443] border-2" />
          <span className="font-bold text-24">CSBYE 고객센터 입니다.</span>
          <hr className="mt-20 mb-20" />
          <p className="font-bold">
            회원님의 소중한 문의 잘 확인하였습니다.
            <br /> 먼저 게임(서비스) 이용에 불편을 드려 정말로 죄송합니다.{" "}
            <br />
            <br />
            회원님께서 문의하신 접수건이 정상적으로 접수되었으며,
            <br /> 해당건에 대해서는 다음 기준에 맞춰 빠르게 확인 및 처리할 수
            있도록 하겠습니다.
            <br /> (문의 건의 처리 후 처리 완료 메일을 별도로 발송하겠습니다.)
            <br />
            <br />
            1. 결제 관련 문의 결제 후 미지급 & 환불 문의에 대한 처리 가능 시간은
            평일 오전 10시 ~ 18시까지 입니다. 순차적으로 문의 처리를 진행하므로
            조금 늦어질 수 있으나 가능한 빠르게 확인하여 처리해 드리겠습니다.{" "}
            <br />
            <br />
            2. 아이템 사라짐, 보상 미지급 등 로그 확인 문의 회원 님의 게임
            로그를 확인해야 하는 문의들은 정확한 처리가 되어야 하기에 처리
            시간이 조금 길어질 수 있습니다. 가능한 빠르고 정확하게 처리해드리기
            위해 최선을 다하겠습니다. <br />
            <br />
            3. 건의 주신 내용에 대해서는 잘 취합하여 개발팀으로 빠르게
            전달하도록 하여 함께 리뷰할 수 있도록 하겠습니다. 단, 건의 주신
            내용의 적용 여부 및 시점에 대해서는 별도의 안내가 어려우니 이해 부탁
            드립니다.
            <br />
            <br /> 4. 기타 간략한 문의 결제 문의,
            <br />
            <br /> 로그 확인이 필요한 문의를 제외한 문의들은 가능한 빠르게 답변
            드리겠습니다. 이벤트 일정 및 기타 문의에 대해서는 공식 커뮤니티의
            공지란에서도 확인이 가능하니 참고 부탁 드립니다. 감사합니다. <br />
            <br />※ 해당 메일은 발신 전용 메일입니다. <br />
            <br />
            메일 회신으로는 문의 접수가 불가능하니,
            <br />
            <br /> 번거로우시더라도, 1:1 문의로 접수 부탁드립니다.
          </p>
          <hr className="mt-20 mb-20 border-2 border-black" />
          <a href={"https://csbye.com/"}>
            <img
              src={"/imgs/index/bannerExample1.jpg"}
              alt="배너"
              className="mb-10 h-120"
            ></img>
          </a>
          <a href={"https://csbye.com/"}>
            <img
              src={"/imgs/index/bannerExample2.jpg"}
              alt="배너"
              className="mb-10 h-120"
            ></img>
          </a>
          <p className="text-[#989898] text-14">
            본 메일은 광고성 정보전송에 대한 정보통신망 발굴 등 관련규정에
            <br /> 의거하여 발송되었습니다.
          </p>
          <hr className="mt-20 mb-10" />
          <p>Copyright © ADD-UP Co., Ltd. All Rights Reserved.</p>
          <hr className="mt-20 mb-10" />
          <div className="flex items-center">
            <p>내 문의 내역과 진행사항을 확인하고 싶다면?</p>
            <div className="w-20"></div>
            <a
              className="text-white no-underline "
              href="${config.endpoint.web}/member/login?serviceNo=${serviceNo}&userId=${userId}"
            >
              <div className="w-130 h-40 bg-[#1c274c] rounded-lg text-center">
                <span className="font-bold leading-40">통합회원 가입 </span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </Background>
  );
};

export default ExampleAutoMailModal;
