import React, { useEffect, useState } from "react";
import Select from "Components/Atoms/Select/select";
import ChartDaily from "./chartDaily";
import ChartAccount from "./chartAccount";
import ChartCategory from "./chartCategory";
import Starpoint from "./starpoint";
import Space from "Components/Atoms/Space/space";
import Button from "Components/Atoms/Button/button";
import { useLocation, useNavigate } from "react-router-dom";
import LANG, { getLang } from "Util/lang";
import useDatePicker from "Util/Hooks/useDatePicker";
import dayjs from "dayjs";

const MAIN_STATS = [
  {
    big_idx: 1,
    label: "보고서",
    side: [
      {
        idx: 1,
        label: "서비스",
      },
      {
        idx: 2,
        label: "카테고리",
      },
    ],
  },
  {
    big_idx: 2,
    label: "Agent",
    side: [
      {
        idx: 1,
        label: "상담원",
      },
      {
        idx: 2,
        label: "별점리뷰",
      },
    ],
  },
];
const ChartSetting = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [mainStats, setMainStats] = useState<number>(1);
  const [sideStats, setSideStats] = useState<number>(1);
  const DATE_FORMAT = "YYYY-MM-DD";
  const today = dayjs(new Date()).format(DATE_FORMAT);
  const [dateRange, setDateRange] = useDatePicker();
  const [dateRange2, setDateRange2] = useDatePicker();

  const selectMainButton = (main: number) => {
    navigate("", {
      state: { main: main, side: sideStats },
    });
  };
  const selectSideButton = (side: number) => {
    navigate("", {
      state: { main: mainStats, side: side },
    });
  };

  const sideStatsPage = (stats: number) => {
    if (mainStats === 1) {
      if (stats === 1) {
        return <ChartDaily dateRange={dateRange} setDateRange={setDateRange} />;
      } else {
        return (
          <ChartCategory dateRange={dateRange} setDateRange={setDateRange} />
        );
      }
    } else if (mainStats === 2) {
      if (stats === 1) {
        return (
          <ChartAccount dateRange={dateRange2} setDateRange={setDateRange2} />
        );
      } else {
        return (
          <Starpoint dateRange={dateRange2} setDateRange={setDateRange2} />
        );
      }
    }
  };

  useEffect(() => {
    setMainStats(
      location.state?.main || navigate("", { state: { main: 1, side: 1 } }),
    );
    setDateRange([today, today]);
    setDateRange2([today, today]);
  }, [location.state?.main]);

  useEffect(() => {
    setSideStats(location.state?.side);
  }, [location.state?.side]);
  return (
    <div>
      <div>
        <div className="flex gap-19">
          {MAIN_STATS.map((item) => (
            <Button
              key={item.big_idx}
              btnText={item.label}
              size={"md"}
              color={item.big_idx === mainStats ? "primary" : "normal"}
              onClick={() => selectMainButton(item.big_idx)}
            />
          ))}
        </div>
        <Space y={16} />
        <div className="flex gap-19">
          {MAIN_STATS.filter((item: any) => item.big_idx === mainStats).map(
            (item: any) =>
              item.side.map((side: any) => (
                <Button
                  btnText={side.label}
                  key={side.idx}
                  size="md"
                  color={
                    item.big_idx === mainStats && side.idx === sideStats
                      ? "primary"
                      : "normal"
                  }
                  onClick={() => selectSideButton(side.idx)}
                />
              )),
          )}
        </div>
      </div>
      <Space y={20} />
      {sideStatsPage(sideStats)}
    </div>
  );
};

export default ChartSetting;
