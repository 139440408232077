import React from "react";
import parse from "html-react-parser";
import { useDispatch } from "react-redux";
import { ReactComponent as ModalX } from "Util/svg/modalX.svg";
import { modalToggle } from "Store/Slices/modal";
import Background from "Components/Atoms/Background/background";

const ExampleEmailModal = ({ modalNum }: { modalNum: number }) => {
  const dispatch = useDispatch();

  return (
    <Background modalNum={modalNum}>
      <div
        style={{ width: "767px" }}
        className="p-32 max-h-[80vh] overflow-auto bg-white pretty-scrollbar"
      >
        <div
          onClick={() => dispatch(modalToggle(modalNum))}
          className="flex justify-end cursor-pointer"
        >
          <ModalX />
        </div>
        <div className="max-w-[767px] p-16">
          <img className="h-100" src="/imgs/common/logo.png" alt="서비스배너" />

          <hr className="mt-20 mb-20 border-[#EF7443] border-2" />
          <span className="font-bold text-24">
            안녕하세요 CSBYE 고객센터입니다.
          </span>
          <hr className="mt-20 mb-20" />
          <div className="flex flex-col">
            <span className="font-bold text-20">유저 문의 제목</span>
            <span className="font-bold">유저 문의 내용</span>
          </div>

          <hr className="mt-20 mb-20" />
          <span className="font-bold text-20">상담원 답변</span>
          <br />
          <p className="font-bold">상담원 답변 내용</p>
          <hr className="mt-20 mb-20" />
          <div>
            <div className="flex">
              <p>회원님 문의 답변에 대한 만족도는 어떠하셨는지요?</p>
              <div className="w-8 "></div>
              <p className="text-primary font-bold">
                만족도 평가 시 포인트가 적립됩니다!
              </p>
            </div>
            <br />
            <div className="text-white no-underline inline-block">
              <div className="w-130 h-40 bg-[#1c274c] rounded-lg text-center">
                <span className="leading-40 font-bold"> 만족도 평가 </span>
              </div>
            </div>
          </div>
          <br />
          <a href={"https://csbye.com/"}>
            <img
              src={"/imgs/index/bannerExample1.jpg"}
              alt="배너"
              className="h-120 mb-10"
            ></img>
          </a>
          <a href={"https://csbye.com/"}>
            <img
              src={"/imgs/index/bannerExample2.jpg"}
              alt="배너"
              className="h-120 mb-10"
            ></img>
          </a>
          <p className="text-[#989898] text-14">
            본 메일은 광고성 정보전송에 대한 정보통신망 발굴 등 관련규정에
            <br /> 의거하여 발송되었습니다.
          </p>
          <hr className="mt-20 mb-10" />
          <p>Copyright © ADD-UP Co., Ltd. All Rights Reserved.</p>
          <hr className="mt-20 mb-10" />
          <div className="flex items-center">
            <p>내 문의 내역과 진행사항을 확인하고 싶다면?</p>
            <div className="w-20"></div>
            <a
              target="_blank"
              className="text-white no-underline "
              href="https://member.csbye.com/join"
            >
              <div className="w-130 h-40 bg-[#1c274c] rounded-lg text-center">
                <span className="leading-40 font-bold">통합회원 가입 </span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </Background>
  );
};

export default ExampleEmailModal;
