import Input from "Components/Atoms/Input/input";
import Label from "Components/Atoms/Label/label";
import Space from "Components/Atoms/Space/space";
import Button from "Components/Atoms/Button/button";
import { getCsOverLapApi } from "Apis/apis";

/**
 * 로그인페이지 : ID PW 인풋창
 */
const JoinRowId = ({
  id,
  name,
  value,
  setValue,
  disabled,
}: {
  id: string;
  name: string;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  disabled?: boolean;
}) => {
  const inputBox = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  return (
    <div className="flex items-center">
      <div className="flex items-center px-16 py-20 bg-e5e6ed w-130">
        <Label labelfor={id} name={name} fontSize={16} />
        <Space x={4} />
      </div>
      <div className="flex-1 h-full bg-white p-13">
        <div className="flex items-center">
          <div className="w-300">
            <Input
              id={id}
              value={value}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                inputBox(e)
              }
              disabled={disabled}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default JoinRowId;
