import Input from "Components/Atoms/Input/input";
import Label from "Components/Atoms/Label/label";
import Space from "Components/Atoms/Space/space";
import { ReactComponent as Require } from "Util/svg/require.svg";
import Button from "Components/Atoms/Button/button";

import { useDispatch } from "react-redux";
import { modalToggle } from "Store/Slices/modal";
import LANG, { getLang } from "Util/lang";

/**
 * 로그인페이지 : ID PW 인풋창
 * @param {string} placeholder 정의어
 */
const ChangePw = ({ id, name }: { id: string; name: string }) => {
  const dispatch = useDispatch();

  return (
    <div className="flex items-center">
      <div className="flex items-center px-16 py-20 bg-e5e6ed w-130">
        <Label labelfor={id} name={name} fontSize={16} />
        <Space x={4} />
        <Require />
      </div>
      <div className="flex-1 h-full bg-white p-13">
        <div className="flex items-center">
          <Button
            size="md"
            btnText={LANG[getLang()].changePW}
            onClick={() => dispatch(modalToggle(1))}
          />
        </div>
      </div>
    </div>
  );
};

export default ChangePw;
