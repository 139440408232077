import { Collapse } from "antd";

import parse from "html-react-parser";
import Space from "Components/Atoms/Space/space";
import LANG, { getLang } from "Util/lang";

import { QDetail, QHistoryList } from "types/interface";
import { ReducerType, useAppDispatch } from "Store/store";
import { useSelector } from "react-redux";
import TranceBtnList from "Components/Molecules/TranceBtn/tranceBtn";
import { setTrans } from "Store/Slices/translate";
import { modalToggle } from "Store/Slices/modal";
import Quill from "Components/Atoms/Quill/quill";
import { useState } from "react";
import { useGetQDetailApi } from "Apis/apis";
import AnswerHistoryList from "../AnswerHistory/answerHistory";

const QuestionHistoryLIst = ({
  userHistory,
}: {
  userHistory: QHistoryList[];
}) => {
  const dispatch = useAppDispatch();
  const serviceList = useSelector((state: ReducerType) => state.serviceList);
  const { Panel } = Collapse;

  const [qIdx, setQIdx] = useState(-1);

  const { data } = useGetQDetailApi(qIdx);
  const qDetail: QDetail = data?.qna || ({} as QDetail);

  const serviceColor = (idx: number) => {
    const color =
      serviceList.find((service) => service.idx === idx)?.color || "";
    return color;
  };

  const onTranslation = (original: string) => (transed: string) => {
    dispatch(modalToggle(13));
    dispatch(setTrans({ original, transed }));
  };

  return (
    <Collapse accordion onChange={(e: any) => setQIdx(e)}>
      {userHistory.map((qna) => {
        const state = qna.state ? "완료" : LANG[getLang()].waiting;
        const nowService = serviceColor(qna.service_no);
        return (
          <Panel
            className={nowService}
            header={
              <div className={`flex justify-between ${nowService}`}>
                <div className="max-w-xl ell">
                  [{state}] {qna.title}
                </div>
                <div className="flex">
                  <span>{qna.user_id}</span>
                  <Space x={40} />
                  <span>{qna.date}</span>
                </div>
              </div>
            }
            key={qna.qna_no}
          >
            <TranceBtnList
              text={qna.contents}
              onClick={onTranslation(qna.contents)}
            />

            <AnswerHistoryList qna={qDetail || ({} as QDetail)} />
          </Panel>
        );
      })}
    </Collapse>
  );
};

export default QuestionHistoryLIst;
