import { Switch, Table } from "antd";
import SearchSelect from "Components/Molecules/SearchSelect/searchSelect";
import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReducerType } from "Store/store";
import useServiceNo from "Util/Hooks/useServiceIdx";
import { ColumnsType } from "antd/es/table";
import Space from "Components/Atoms/Space/space";
import Button from "Components/Atoms/Button/button";
import { modalToggle } from "Store/Slices/modal";
import BoardNoticeModal from "Components/Molecules/BoardNoticeModal/boardNoticeModal";
import BoardNoticeFixModal from "Components/Molecules/BoardNoticeModal/boardNoticeModalFix";
import { getBoardNoticeApi, putBoardNoticeApi } from "Apis/apis";
import { CompanyNotice } from "types/interface";
import { order } from "Util/function";
import LANG, { getLang } from "Util/lang";
import { useLocation, useNavigate } from "react-router-dom";

const BoardNotice = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const serviceList = useSelector((state: ReducerType) =>
    state.serviceList
      .filter((el) => el.state !== 2 && el.state !== 1)
      .map((el) => (el.idx === -1 ? { ...el, label: "서비스 선택" } : el)),
  );
  const companyNo = useSelector((state: ReducerType) => state.user.idx);
  const [serviceNo, setServiceNo] = useServiceNo();
  const [noticeList, setNoticeList] = useState<CompanyNotice[]>([]);
  const [noticeNo, setNoticeNo] = useState(-1);
  const getNotice = async () => {
    const { selectServiceNo = -1 } = location.state || {};
    setServiceNo(selectServiceNo);
    if (selectServiceNo === -1) return;
    const {
      data: { notice },
    } = await getBoardNoticeApi(selectServiceNo, companyNo);
    setNoticeList(notice);
  };
  const onService = () => {
    if (serviceNo === -1) return alert("서비스를 선택해주세요.");
    navigate("", {
      state: { pageNo: 3, selectServiceNo: serviceNo },
    });
  };
  useEffect(() => {
    getNotice();
  }, [location.state]);

  const onClick = async (e: boolean, row: CompanyNotice) => {
    const state = e ? 0 : 1;

    const body = {
      state,
    };
    await putBoardNoticeApi(body, row.notice_no);

    setNoticeList((_noticeList: CompanyNotice[]) => {
      const newNotice = _noticeList.map((notice: any) => {
        if (notice.notice_no !== row.notice_no) return notice;

        const _noteice = { ...notice };
        _noteice.state = state;
        return _noteice;
      });
      return newNotice;
    });
  };
  const columns: ColumnsType<CompanyNotice> = [
    {
      title: LANG[getLang()].index,
      dataIndex: "idx",
      align: "center",
      render: (row, now) => order(noticeList, now.notice_no, "notice_no"),
    },
    {
      title: "서비스 로고",
      dataIndex: "service_logo",
      align: "center",
      width: 140,
      render: (now) => {
        const defalut = "../imgs/common/defaultService.png";
        return (
          <div className="flex justify-center">
            {now ? (
              <img src={now} className="w-80 h-80" alt="서비스 로고" />
            ) : (
              <img src={defalut} alt="서비스 로고" />
            )}
          </div>
        );
      },
    },
    {
      title: "서비스명",
      dataIndex: "service_no",
      align: "center",
      render: (row) => {
        const myService = serviceList.find((el) => el.idx === row);
        return myService?.label;
      },
    },
    {
      title: LANG[getLang()].noticeTitle,
      dataIndex: "title",
      align: "center",
      render: (now, row) => {
        return (
          <span
            className="cursor-pointer"
            onClick={() => {
              setNoticeNo(row.notice_no);
              return dispatch(modalToggle(2));
            }}
          >
            {now}
          </span>
        );
      },
    },
    {
      title: LANG[getLang()].link,
      dataIndex: "contents",
      align: "center",
      render: (now) => <a href={now}>자세히 보기</a>,
    },
    {
      title: "ON/OFF",
      dataIndex: "state",
      align: "center",
      render: (now, row) => {
        return (
          <Switch
            checked={now ? false : true}
            checkedChildren="ON"
            unCheckedChildren="OFF"
            onClick={(e) => onClick(e, row)}
          />
        );
      },
    },
  ];
  return (
    <div>
      <div className="flex justify-between">
        <SearchSelect
          dataList={serviceList}
          value={serviceNo}
          setValue={setServiceNo}
          onClick={onService}
        />
        <Button
          btnText={`+ ${LANG[getLang()].register}`}
          type="submit"
          onClick={() => dispatch(modalToggle(1))}
        />
      </div>
      <Space y={20} />
      <Table rowKey="notice_no" columns={columns} dataSource={noticeList} />
      <BoardNoticeModal getNotice={getNotice} />
      <BoardNoticeFixModal getNotice={getNotice} noticeNo={noticeNo} />
    </div>
  );
};

export default BoardNotice;
