import React from "react";
import { ReactComponent as ModalX } from "Util/svg/modalX.svg";
import Background from "Components/Atoms/Background/background";
import { useDispatch } from "react-redux";
import { modalToggle } from "Store/Slices/modal";
import H2 from "Components/Atoms/H2/h2";
import Space from "Components/Atoms/Space/space";
import Button from "Components/Atoms/Button/button";
import parse from "html-react-parser";
const PreviewExampleModal = ({ modalNum }: { modalNum: number }) => {
  const dispatch = useDispatch();
  return (
    <Background modalNum={modalNum}>
      <div style={{ width: "940px" }} className="p-32 bg-f2f3f8 ">
        <div className="flex justify-end h-26">
          <div
            onClick={() => dispatch(modalToggle(3))}
            className="inline-block cursor-pointer"
          >
            <ModalX />
          </div>
        </div>
        <Space y={8} />
        <div className="flex justify-center">
          <img src="../imgs/common/exampleLogo.jpg" />
        </div>
      </div>
    </Background>
  );
};

export default PreviewExampleModal;
