import {
  configureStore,
  combineReducers,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import logger from "redux-logger";

import modal from "./Slices/modal";
import user from "./Slices/user";
import layouts from "./Slices/layouts";
import serviceList from "./Slices/serviceList";
import qnaSearchOption from "./Slices/qnaSearchOption";
import translate from "./Slices/translate";
import smsNumber from "./Slices/smsNumber";
import qIdx from "./Slices/qIdx";
import memoListInfo from "./Slices/memoListInfo";
import imgModalUrl from "./Slices/imgModalUrl";
import addupNotice from "./Slices/addupNotice";
import langIdx from "./Slices/langIdx";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

const reducer = combineReducers({
  user,
  layouts,
  modal,
  serviceList,
  qnaSearchOption,
  translate,
  smsNumber,
  qIdx,
  memoListInfo,
  imgModalUrl,
  addupNotice,
  langIdx,
});

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});

export type AppDispatch = typeof store.dispatch;
export type ReducerType = ReturnType<typeof reducer>;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<ReducerType> = useSelector;

export default store;
