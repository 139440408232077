import { useSelector } from "react-redux";
import { ReducerType } from "Store/store";
import { User } from "types/interface";

const ProfileImg = () => {
  const user: User = useSelector((state: ReducerType) => state.user);

  const getLastTwoChars = (str: string) => {
    if (str.length < 2) {
      return str; // 문자열 길이가 2보다 작을 경우 그대로 반환
    }
    return str.slice(-2); // 문자열의 마지막 두 글자를 추출하여 반환
  };

  return (
    <>
      {user.profile ? (
        <img
          className="object-cover w-40 h-40 rounded-full"
          src={user.profile}
          alt="프사"
        />
      ) : (
        <div className="w-40 text-white rounded-full aspect-square bg-primary">
          <span className="leading-40">{getLastTwoChars(user.name || "")}</span>
        </div>
      )}
    </>
  );
};

export default ProfileImg;
