import { useState, useEffect } from "react";

import Background from "Components/Atoms/Background/background";
import H2 from "Components/Atoms/H2/h2";
import InputRow from "../InputRow/inputRow";
import InputRowRadio from "../InputRowRadio/inputRowRadio";
import Button from "Components/Atoms/Button/button";
import Space from "Components/Atoms/Space/space";

import { ReactComponent as ModalX } from "Util/svg/modalX.svg";

import { useDispatch } from "react-redux";
import { modalToggle } from "Store/Slices/modal";

import { RadioForm, PostCategory, ClickInputForm } from "types/interface";
import { postCategoryApi } from "Apis/apis";
import InputRowSelect from "Components/Molecules/InputRowSelect/inputRowSelect";
import LANG, { getLang } from "Util/lang";

const BigCategoryAddModal = ({
  onSubmit,
  serviceNo,
}: {
  onSubmit: () => void;
  serviceNo: number;
}) => {
  const dispatch = useDispatch();

  const [bigName, setBigName] = useState("");
  const [smallName, setSmallName] = useState("");
  const [status, setStatus] = useState(1);

  const STATE_DATA: RadioForm[] = [
    { idx: 1, label: LANG[getLang()].waiting, id: "wait" },
    { idx: 0, label: LANG[getLang()].approval, id: "ok" },
  ];

  const postBigCategory = async () => {
    if (!smallName) {
      alert("중카테고리 입력해주세요.");
      return;
    }

    const bigBody: PostCategory = {
      serviceNo: serviceNo,
      categoryName: bigName,
      state: status,
    };
    const { data } = await postCategoryApi(bigBody);

    const smallBody: PostCategory = {
      serviceNo: serviceNo,
      bigCategoryCode: data.category_code,
      categoryName: smallName,
      state: status,
    };

    await postCategoryApi(smallBody);

    alert("등록이 완료되었습니다.");
    dispatch(modalToggle(1));
    onSubmit();
    setBigName("");
    setSmallName("");
    setStatus(1);
  };
  const resetBigName = () => {
    dispatch(modalToggle(1));
    setBigName("");
    setSmallName("");
    setStatus(1);
  };

  return (
    <Background>
      <div style={{ width: "640px" }} className="p-32 bg-f2f3f8">
        <div className="flex justify-between items-center">
          <H2 isPrimary={true} name="대 카테고리 등록" />
          <div
            className="inline-block cursor-pointer"
            onClick={() => dispatch(modalToggle(1))}
          >
            <ModalX />
          </div>
        </div>
        <Space y={8} />
        <InputRow
          id="bigService"
          name={LANG[getLang()].lCategory}
          value={bigName}
          setValue={setBigName}
        />
        <InputRow
          id="smallService"
          name={LANG[getLang()].mCategory}
          value={smallName}
          setValue={setSmallName}
        />
        <InputRowRadio
          data={STATE_DATA}
          name={LANG[getLang()].state}
          value={status}
          setValue={setStatus}
        />
        <div className="flex justify-end mt-10">
          <Button onClick={resetBigName} btnText={LANG[getLang()].cancel} />
          <Space x={10} />
          <Button
            onClick={postBigCategory}
            btnText={LANG[getLang()].register}
          />
        </div>
      </div>
    </Background>
  );
};

export default BigCategoryAddModal;
