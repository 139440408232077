import React from "react";
import QnaInput from "Components/Atoms/QnaInput/qnaInput";
import QnaLabel from "Components/Atoms/QnaLabel/qnaLabel";
import Space from "Components/Atoms/Space/space";

const QnaInputLabel = ({
  name,
  id,
  value,
  setValue,
  readonly = false,
}: {
  name: string;
  id: string;
  value: string;
  setValue?: React.Dispatch<React.SetStateAction<string>>;
  readonly?: boolean;
}) => {
  return (
    <div className={`flex border-b-1 `}>
      <QnaLabel htmlFor={id} name={name} />
      <QnaInput id={id} setValue={setValue} value={value} readonly={readonly} />
    </div>
  );
};

export default QnaInputLabel;
