import React, { useState, useEffect, useRef } from "react";
import Space from "Components/Atoms/Space/space";
import { Table } from "antd";
import type { ColumnsType, TableProps } from "antd/es/table";
import Tag from "Components/Atoms/Tag/tag";
import { comma, getToken, order } from "Util/function";
import Button from "Components/Atoms/Button/button";
import LANG, { getLang } from "Util/lang";
import H4 from "Components/Atoms/H4/h4";
import { useDispatch, useSelector } from "react-redux";
import { FilterValue } from "antd/es/table/interface";
import CancleModal from "Components/Molecules/PayModal/cancleModal";
import { modalToggle } from "Store/Slices/modal";
import SitChangeModal from "Components/Molecules/PayModal/sitChangeModal";
import { useNavigate } from "react-router-dom";
import ReceiptModal from "Components/Molecules/PayModal/receiptModal";
import {
  getCompanyProductApi,
  getPayApi,
  getSubscribeStateApi,
  getTokenCheckApi,
} from "Apis/apis";
import { GetPay, GetProduct, GetSubscribeState } from "types/interface";
import { ReducerType } from "Store/store";
import AgreeModal from "Components/Molecules/PayModal/agreeModal";

const Pay = () => {
  const dispatch = useDispatch();
  const popup = useRef<any>(null);
  const navigate = useNavigate();
  const [payData, setPayData] = useState<GetPay[]>([]);
  const [productData, setProductData] = useState<GetProduct>({} as GetProduct);
  const [nextDate, setNextDate] = useState(""); // 정식 회원탈퇴일

  const [subcribeState, setSubscribeState] = useState<GetSubscribeState>(
    {} as GetSubscribeState,
  ); // 상품 상태

  const [dateSort, setDateSort] = useState("DESC");
  const [sitCount, setSitCount] = useState("1");
  const [filteredInfo, setFilteredInfo] = useState<
    Record<string, FilterValue | null>
  >({});

  const getPay = async () => {
    const {
      data: { payment_log },
    } = await getPayApi(dateSort);
    setPayData(payment_log);
  };

  const handleChange: TableProps<GetPay>["onChange"] = (
    pagination,
    filters,
    sorter,
  ) => {
    setFilteredInfo(filters);
  };
  const getProduct = async () => {
    const {
      data: { product },
    } = await getCompanyProductApi();
    setProductData(product);
    setNextDate(product.next_date);
    setSitCount(`${product.sheet_count}`);
  };

  const getSubscribeState = async () => {
    const {
      data: { subscribe_state },
    } = await getSubscribeStateApi();
    setSubscribeState(subscribe_state);
  };

  const columns: ColumnsType<GetPay> = [
    {
      title: LANG[getLang()].index,
      dataIndex: "payment_log_no",
      align: "center",
      render: (now, row) =>
        order(payData, row.payment_log_no, "payment_log_no"),
    },
    {
      title: "구성",
      dataIndex: "contents",
      align: "center",
    },
    {
      title: "금액",
      dataIndex: "price",
      align: "center",
      render: (now) => {
        return <span>{comma(+now)}원</span>;
      },
    },
    {
      title: (
        <button
          onClick={() =>
            dateSort === "DESC" ? setDateSort("ASC") : setDateSort("DESC")
          }
        >
          {LANG[getLang()].date}
        </button>
      ),
      dataIndex: "pay_date",
      align: "center",
      sorter: true,
      showSorterTooltip: false,
      sortOrder: dateSort === "ASC" ? "ascend" : "descend",
      onHeaderCell: () => {
        return {
          onClick: () => setDateSort(dateSort === "ASC" ? "DESC" : "ASC"),
        };
      },
    },
    {
      title: LANG[getLang()].state,
      dataIndex: "result",
      key: "result",
      align: "center",
      filters: [
        { text: "승인", value: "성공" },
        { text: "실패", value: "실패" },
      ],
      filteredValue: filteredInfo.state,
      onFilter: (value: any, record) => record.result.includes(value),

      ellipsis: true,
      render: (now) => {
        switch (now) {
          case "성공":
            return <Tag name="승인" />;
          case "실패":
            return <Tag name="실패" type="error" />;
        }
      },
    },
    {
      title: "내역",
      dataIndex: "payment",
      align: "center",
      render: (now, row) => (
        <Button
          btnText="상세"
          size="md"
          onClick={() => {
            navigate("", {
              state: {
                price: row.price,
                sheet_count: row.sheet_count,
                date: row.duration,
              },
            });
            dispatch(modalToggle(3));
          }}
        />
      ),
    },
  ];

  const onClickPay = async (sheet: number) => {
    const token = getToken();
    window.name = "kgpay";
    popup.current = window.open(
      `https://api.csbye.com/v1/payments?token=${token}&productNo=${productData.product_no}&sheet=${sheet}`,
      "pop01",
      "location=no,top=10,left=10,width=850,height=600,status=no,menubar=no,toolbar=no,resizable=no",
    );
  };

  useEffect(() => {
    getPay();
  }, [dateSort]);
  useEffect(() => {
    getProduct();
    getSubscribeState();
  }, []);

  return (
    <div onClick={() => popup.current?.close()} className="w-[1200px]">
      <H4 name="현재 사용 중인 상품 " />
      <Space y={16} />
      <div>
        <div className="flex justify-between h-auto py-16 bg-white px-18">
          <div className="flex">
            <img
              src={productData.product_image}
              className="w-100 h-100"
              alt="결제아이콘"
            />
            <Space x={16} />
            <div className="flex flex-col">
              <div className="flex items-center">
                <H4 name={`${productData.product_name}상품`} />
                <span className="font-bold text-14 text-primary">
                  {productData.product_no === 2
                    ? ""
                    : subcribeState.state === 2
                    ? `( ${productData.next_date} 까지 이용 가능 )`
                    : `( 다음 결제일 : ${productData.next_date} )`}
                </span>
              </div>
              <div className="flex gap-10">
                <span className="font-bold text-14 text-primary">
                  서비스 ID {productData.limit_service}개 이용 가능
                </span>
                <span>/</span>
                <span className="font-bold text-14 text-primary">
                  시트당 {comma(+productData.price)}원
                </span>
              </div>
            </div>
          </div>
          <div className="flex flex-col self-end">
            <span className="font-bold text-14 text-primary text-end">
              현재 시트
            </span>
            <span className="font-bold text-14 text-primary text-end">{`상담원 수 ( ${productData.cs_count} 명) / 시트 수 ( ${productData.sheet_count} 석)`}</span>
          </div>
        </div>
      </div>
      <Space y={8} />
      <div className="flex justify-between">
        <Button
          btnText="상품 구독 취소"
          color="delete"
          size="md"
          onClick={() => dispatch(modalToggle(1))}
        />
        <div className="flex">
          <Button
            btnText="시트 변경"
            size="md"
            onClick={() => {
              navigate("", {
                state: {
                  company: productData.company,
                  product: `${productData.product_name} 상품 (시트 ${
                    productData.sheet_count || 0
                  }석)`,
                  pay: productData.price,
                },
              });
              return dispatch(modalToggle(2));
            }}
          />
          <Space x={8} />
          <Button
            btnText="상품 변경"
            size="md"
            onClick={() =>
              navigate("/infomation/paychange", {
                state: { product: productData },
              })
            }
          />
        </div>
      </div>
      <Space y={16} />
      <hr />
      <Space y={20} />
      <H4 name="상품 결제 내역" />
      <Space y={20} />
      <Table
        columns={columns}
        dataSource={payData}
        onChange={handleChange}
        rowKey={"payment_log_no"}
      />
      <CancleModal date={nextDate} />
      <SitChangeModal
        sitCount={sitCount}
        setSitCount={setSitCount}
        onClickPay={onClickPay}
      />
      <ReceiptModal />
      <AgreeModal />
    </div>
  );
};

export default Pay;
