import Button from "Components/Atoms/Button/button";
import Space from "Components/Atoms/Space/space";
import AgreeModal from "Components/Molecules/PayModal/agreeModal";
import ChangeModal from "Components/Molecules/PayModal/changeModal";
import { modalToggle } from "Store/Slices/modal";
import { ReducerType } from "Store/store";
import { comma, getToken } from "Util/function";
import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

const PRODUCT = [
  {
    idx: 3,
    name: "BASIC+",
    service: 1,
    pay: 19000,
  },
  {
    idx: 4,
    name: "Premium",
    service: 3,
    pay: 29000,
  },
  {
    idx: 5,
    name: "Special",
    service: 6,
    pay: 39000,
  },
  {
    idx: -1,
    name: "Customize",
    service: 0,
    pay: 0,
  },
];
const PayChange = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const popup = useRef<any>(null);
  const [sitCount, setSitCount] = useState("1");
  const [productNo, setProductNo] = useState(-1);
  const { product } = location.state || {};
  const [agree, setAgree] = useState<number[]>([]);

  const modalNo = useSelector((state: ReducerType) => state.modal);

  const modalOn = (idx: number, name: string, pay: number) => {
    if (idx !== -1) {
      setProductNo(idx);
      navigate("", {
        state: {
          idx: idx,
          name: name,
          pay: pay,
          product: product,
        },
      });
      return dispatch(modalToggle(idx));
    } else {
      return navigate("/");
    }
  };
  useEffect(() => {
    setAgree([]);
    setSitCount("1");
  }, [modalNo === 0]);
  const onClickPay = async (sheet: number) => {
    const token = getToken();
    window.name = "kgpay";
    popup.current = window.open(
      `https://api.csbye.com/v1/payments?token=${token}&productNo=${productNo}&sheet=${sheet}`,
      "pop01",
      "location=no,top=10,left=10,width=850,height=600,status=no,menubar=no,toolbar=no,resizable=no"
    );
  };

  return (
    <div onClick={() => popup.current?.close()}>
      {PRODUCT.map((item) => {
        return (
          <div key={item.idx}>
            <div className="bg-white py-16 px-18 h-auto flex justify-between rounded-md">
              <div className="flex items-center gap-30 w-211 justify-between">
                <span className="font-bold text-primary text-24">
                  {item.name}
                </span>
                <span className="self-cen text-14">{`서비스 ${item.service} 개`}</span>
              </div>
              <div className="flex items-center font-bold">
                <span>{`${
                  item.pay === 0 ? "상담후 결정" : `${comma(item.pay)}원`
                } (시트 수 / 한달)`}</span>
                <Space x={26} />
                {item.idx !== -1 ? (
                  <Button
                    btnText="선택 "
                    size="md"
                    onClick={() => modalOn(item.idx, item.name, item.pay)}
                    key={item.idx}
                  />
                ) : (
                  <a href="https://add_up.csbye.com/menu/275" target="_blank">
                    <Button btnText="문의하기" size="md" />
                  </a>
                )}
              </div>
            </div>
            <Space y={32} />
          </div>
        );
      })}
      <span className="font-bold text-primary">결제 유의사항</span>
      <Space y={16} />
      <span className="text-14 text-[#828282]">
        상품 다운 그레이드 및 시트 삭제 시 일부 기능이 아래와 같이
        비활성화되므로 결제 이후 꼭 확인해주세요.
      </span>
      <Space y={16} />
      {/* prettier-ignore */}
      <p className="text-14 text-[#828282] whitespace-pre">
        {
`상품 다운 그레이드 시 최초 등록한 1개 서비스를 제외한 모든 서비스가 대기 상태로 변경됩니다.
시트 감소 시 기업관리자를 제외한 모든 상담원이 대기 상태로 변경됩니다.
키워드 알림이 자동 비활성화 됩니다. 알림 개수 전체 삭제됩니다.
광고 배너는 변경된 상품에 지원되는 개수만큼 설정이 유지되고, 기존에 등록되어 있던 광고 배너가 삭제됩니다.`
        }
      </p>
      <ChangeModal
        sitCount={sitCount}
        setSitCount={setSitCount}
        onClickPay={onClickPay}
        agree={agree}
        setAgree={setAgree}
      />
      <AgreeModal />
    </div>
  );
};

export default PayChange;
