import SearchSelect from "Components/Molecules/SearchSelect/searchSelect";
import { Table } from "antd";

import { getCategoryListApi } from "Apis/apis";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReducerType } from "Store/store";
import { modalToggle } from "Store/Slices/modal";
import { toCheckboxForm } from "Util/function";

import Button from "Components/Atoms/Button/button";
import Space from "Components/Atoms/Space/space";
import Tag from "Components/Atoms/Tag/tag";

import { MiddleData, BigData, ClickInputForm } from "types/interface.d";
import BigCategoryAddModal from "Components/Molecules/BigCategoryAddModal/bigCategoryAddModal";
import BigCategoryFixModal from "Components/Molecules/BigCategoryFixModal/bigCategoryFixModal";
import MiddleCategoryAddModal from "Components/Molecules/MiddleCategoryAddModal/middleCategoryAddModal";
import MiddleCategoryFixModal from "./../../Components/Molecules/MiddleCategoryFixModal/middleCategoryFixModal";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import useServiceNo from "Util/Hooks/useServiceIdx";
import LANG, { getLang } from "Util/lang";
import SmallCategoryModal from "Components/Molecules/SmallCategoryModal/smallCategoryModal";
import CategoryLoadModal from "Components/Molecules/CategoryLoadModal/categoryLoadModal";
import H2 from "Components/Atoms/H2/h2";

const Category = () => {
  const dispatch = useDispatch();

  const location = useLocation();
  const { serviceNo, serviceName } = location.state || {};

  const [bigCategoryCode, setBigCategoryCode] = useState(0);
  const [bigCategoryName, setBigCategoryName] = useState("");
  const [middleCategoryCode, setMmiddleCategoryCode] = useState(0);
  const [middleCategoryName, setMmiddleCategoryName] = useState("");
  const [bigCategoryState, setBigCategoryState] = useState(1);
  const [middleCategoryState, setMiddleCategoryState] = useState(1);
  const [bigCategoryList, setBigCategoryList] = useState<ClickInputForm[]>([
    { idx: -1, label: LANG[getLang()].lCategory },
  ]);
  const [bigCategory, setBigcategory] = useState<BigData[]>([]);
  const [middleCategoryList, setMiddleCategoryList] = useState<MiddleData[]>(
    [],
  );
  const [selectMiddle, setSelectMiddle] = useState(0);
  const [bOrder, setBOrder] = useState(0);
  const [mOrder, setMOrder] = useState(0);

  const stateFuc = (a: number, b: number): number => (a > b ? a : b);

  const getCategoryList = async () => {
    const {
      data: {
        category: { middle_category, big_category },
      },
    } = await getCategoryListApi(serviceNo);

    setBigcategory(big_category);
    const bigList: BigData[] = big_category.filter((el) => el.state !== 2);
    const middleList: MiddleData[] = middle_category.filter(
      (el) =>
        el.state !== 2 &&
        bigList.findIndex(
          (el2) => el2.big_category_code === el.big_category_code,
        ) !== -1,
    );
    if (big_category.length !== 0) {
      const checkboxForm: ClickInputForm[] = toCheckboxForm(
        bigList,
        "big_category_code",
        "big_category_name",
      );
      setBigCategoryList([
        { idx: -1, label: LANG[getLang()].lCategory },
        ...checkboxForm,
      ]);
    } else {
      setBigCategoryList([{ idx: -1, label: LANG[getLang()].lCategory }]);
    }

    let big = 0;

    const _middle_category: MiddleData[] = middleList.map((el: MiddleData) => {
      const _el = { ...el };

      if (_el.big_category_code !== big) {
        big = _el.big_category_code;
        _el.span = middleList.filter(
          (item: MiddleData) => big === item.big_category_code,
        ).length;
      }

      return _el;
    });

    setMiddleCategoryList(_middle_category);
  };

  const onSubmit = () => {
    getCategoryList();
  };
  const bigOrderFilter = (bigIdx: number) => {
    const bigOrder = bigCategory.filter(
      (el) => el.big_category_code === bigIdx,
    );
    setBOrder(bigOrder[0].order);
  };
  useEffect(() => {
    getCategoryList();
  }, []);

  const columns: any = [
    {
      title: LANG[getLang()].lCategory,
      dataIndex: "big_category_name",
      align: "center",
      render: (now: string, row: MiddleData) => {
        return (
          <span
            onClick={() => {
              const _bigCategory = bigCategory.find(
                (el) => el.big_category_code === row.big_category_code,
              );
              const _bigState = _bigCategory!.state;
              setBigCategoryState(_bigState);
              setBigCategoryCode(row.big_category_code);
              setBigCategoryName(row.big_category_name);
              bigOrderFilter(row.big_category_code);
              return dispatch(modalToggle(2));
            }}
            className="cursor-pointer"
          >
            {now}
          </span>
        );
      },
      onCell: (now: MiddleData) => {
        const rowSpan = now.span || 0;
        return { rowSpan };
      },
    },
    {
      title: LANG[getLang()].mCategory,
      dataIndex: "middle_category_name",
      align: "center",
      render: (now: string, row: MiddleData) => {
        return (
          <span
            onClick={() => {
              setMiddleCategoryState(row.state);
              setBigCategoryCode(row.big_category_code);
              setBigCategoryName(row.big_category_name);
              setMmiddleCategoryCode(row.middle_category_code);
              setMmiddleCategoryName(row.middle_category_name);
              setMOrder(row.order);
              return dispatch(modalToggle(4));
            }}
            className="cursor-pointer"
          >
            {now}
          </span>
        );
      },
    },
    {
      title: "세부 항목",
      dataIndex: "middle_category_code",
      width: "200px",
      align: "center",
      render: (now: number) => {
        return (
          <Button
            size="sm"
            color="primary"
            onClick={() => {
              setSelectMiddle(now);
              return dispatch(modalToggle(5));
            }}
            btnText="관리"
          />
        );
      },
    },
    {
      title: LANG[getLang()].state,
      dataIndex: "big_category_code",
      align: "center",
      render: (now: number, row: MiddleData) => {
        const bigState =
          bigCategory.find((el) => el.big_category_code === now) ||
          ({ state: 0 } as BigData);
        const smallState = row;

        if (stateFuc(bigState.state, smallState.state) === 1) {
          return <Tag type="ready" name={LANG[getLang()].waiting} />;
        } else if (stateFuc(bigState.state, smallState.state) === 2) {
          return <Tag type="ready" name={LANG[getLang()].hide} />;
        } else {
          return <Tag name="승인" />;
        }
      },
    },
  ];
  return (
    <>
      <div id="category_table">
        <div className="flex items-center justify-between mb-20">
          <div>
            <H2 name={serviceName} />
          </div>
          <div className="flex">
            <Button
              btnText="카테고리 불러오기"
              onClick={() => dispatch(modalToggle(6))}
            />
            <Space x={8} />
            <Button
              btnText="+ 대 카테고리 등록"
              onClick={() => dispatch(modalToggle(1))}
            />
            <Space x={8} />
            <Button
              btnText="+ 중 카테고리 등록"
              onClick={() => dispatch(modalToggle(3))}
            />
          </div>
        </div>

        <Table
          rowKey={"category_no"}
          columns={columns}
          dataSource={middleCategoryList}
          pagination={false}
        />
      </div>

      <BigCategoryAddModal serviceNo={serviceNo} onSubmit={onSubmit} />

      <BigCategoryFixModal
        categoryCode={bigCategoryCode}
        bigCategoryName={bigCategoryName}
        categoryState={bigCategoryState}
        onSubmit={onSubmit}
        cateOrder={bOrder}
      />

      <MiddleCategoryAddModal
        bigCategoryList={bigCategoryList}
        onSubmit={onSubmit}
        serviceNo={serviceNo}
      />

      <MiddleCategoryFixModal
        categoryCode={middleCategoryCode}
        bigCategoryCode={bigCategoryCode}
        bigCategoryName={bigCategoryName}
        middleCategoryName={middleCategoryName}
        categoryState={middleCategoryState}
        cateOrder={mOrder}
        onSubmit={onSubmit}
      />
      <SmallCategoryModal selectMiddle={selectMiddle} />
      <CategoryLoadModal onSubmit={onSubmit} />
    </>
  );
};

export default Category;
