import React from "react";

const SearchResult = ({ max }: { max: number }) => {
  return (
    <span>
      검색결과 <span className="font-bold text-primary">{max} </span>
      건
    </span>
  );
};

export default SearchResult;
