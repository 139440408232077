import Background from "Components/Atoms/Background/background";
import H2 from "Components/Atoms/H2/h2";
import Space from "Components/Atoms/Space/space";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { modalToggle } from "Store/Slices/modal";
import { ReactComponent as ModalX } from "Util/svg/modalX.svg";
import InputRow from "../InputRow/inputRow";
import InputText from "../InputText/inputText";
import InputRowRadio from "../InputRowRadio/inputRowRadio";
import { RadioForm, User } from "types/interface";
import Button from "Components/Atoms/Button/button";
import { Vip, UserList } from "types/interface";
import { ReducerType } from "Store/store";
import { postUserMemoApi, putVipApi } from "Apis/apis";
import LANG, { getLang } from "Util/lang";
import { setMemoListInfo } from "Store/Slices/memoListInfo";
import { useQueryClient } from "@tanstack/react-query";
import { async } from "q";

import { levelCheck } from "Store/Slices/user";

const USER_RADIO: RadioForm[] = [
  { idx: 2, label: "일반", id: "fixnormal" },
  { idx: 0, label: "VIP", id: "fixvip" },
  { idx: 1, label: "블랙리스트", id: "fixblack" },
];

const VipModal = ({ obj, getVip }: { obj: UserList[]; getVip: () => void }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const serviceList = useSelector((state: ReducerType) => state.serviceList);
  const serviceName = serviceList.filter((el) => el.idx === obj[0].service_no);

  const { user_id: userId, service_no: serviceNo } = obj[0];
  const [memo, setMemo] = useState(obj[0].memo);
  const [state, setState] = useState(obj[0].state);
  const { product_level }: User = useSelector(
    (state: ReducerType) => state.user,
  );
  const postUserMemo = async () => {
    const body = {
      userId,
      serviceNo,
      memo,
    };
    await postUserMemoApi(body);
    setMemo("");
    queryClient.invalidateQueries(["GetMemoApi", serviceNo, userId]);
  };

  const putVip = async () => {
    const body = {
      serviceNo: serviceName[0].idx,
      userId: userId,
      state,
    };
    await putVipApi(body);
    memo !== "" && (await postUserMemo());
    getVip();
    dispatch(modalToggle(1));
  };

  const onClickHistory = () => {
    dispatch(setMemoListInfo({ serviceNo, userId }));
    dispatch(modalToggle(-5));
  };
  useEffect(() => {
    setState(obj[0].state);
    setMemo(obj[0].memo);
  }, [obj]);

  return (
    <Background>
      <div style={{ width: "640px" }} className="p-32 bg-f2f3f8">
        <div className="flex justify-between items-center">
          <H2 isPrimary={true} name="고객정보 확인" />
          <div
            className="inline-block cursor-pointer"
            onClick={() => dispatch(modalToggle(1))}
          >
            <ModalX />
          </div>
        </div>
        <Space y={8} />
        <InputText name={LANG[getLang()].userUID} text={userId} width="w-150" />
        <InputText
          name="서비스 명"
          text={serviceName[0]?.label}
          width="w-150"
        />
        <div className="relative">
          <InputRow
            id="comment"
            name="고객 메모"
            setValue={setMemo}
            value={memo}
            width="w-150"
            require={false}
            placeholder="고객 메모는 고객에게 보이지 않습니다."
          />
          <div className="absolute top-9 right-20">
            <Button
              btnText="내역"
              onClick={onClickHistory}
              standardItem="c03"
            />
          </div>
        </div>
        <InputRowRadio
          data={USER_RADIO}
          name={LANG[getLang()].state}
          setValue={setState}
          value={state}
          width="w-150"
        />
        <div className="flex justify-end mt-10">
          <Button btnText={LANG[getLang()].register} onClick={putVip} />
          <Space x={10} />
          <Button onClick={() => dispatch(modalToggle(1))} btnText="닫기" />
        </div>
      </div>
    </Background>
  );
};

export default VipModal;
