import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useEffect, useState } from "react";
import { StarPoint } from "types/interface";
import LIST from "./color";
import { sum } from "Util/function";

const StarPieChart = ({ data }: { data: StarPoint[] }) => {
  const [labelList, setLabelList] = useState<string[]>([]);
  const [dataList, setDataList] = useState<number[]>([]);

  // 서비스 카테고리별 원형 문의 현황
  const options3 = {
    responsive: true,
    plugins: {
      legend: {
        labels: {
          filter: (el: any, now: any) => {
            return el.text !== "전체";
          },
          font: { size: 12, weight: "bold" },
        } as any,
        position: "right" as const,
      },
      title: {
        display: true,
        text: "별점 비율",
        font: { size: 20 },
      },
      datalabels: {
        color: "black",
        textAlign: "center",
        formatter: (value: any, context: any) => {
          const all = sum(context.dataset.data);
          const name = labelList[context.dataIndex];
          const percent = Math.floor((value / all) * 100) || 0;

          return `${percent}%`;
        },
      } as any,
    },
    scale: {
      x: {},
      y: {},
    },
  };

  const init = () => {
    // 필요한데이터
    // 1. 라벨 - string배열
    // 2. 데이터 - 라벨과 같은 길이의 number배열
    const _labelList: string[] = [];
    const _dataList: number[] = [];
    const obj: { [index: string]: number } = {};
    const onlyPointArr: { [index: string]: number }[] = data.map((el) => {
      return {
        one: el.one,
        two: el.two,
        three: el.three,
        four: el.four,
        five: el.five,
      };
    });
    onlyPointArr.forEach((el) => {
      for (const key in el) {
        obj[key] = obj[key] ? el[key] + obj[key] : el[key];
      }
    });

    let num = 1;
    for (const key in obj) {
      _labelList.push("⭐".repeat(num++));
      _dataList.push(obj[key]);
    }
    setLabelList(_labelList);
    setDataList(_dataList);
  };

  useEffect(() => {
    init();
  }, [data]);

  const data3 = {
    plugins: [ChartDataLabels],
    labels: labelList,
    datasets: [
      {
        data: dataList,
        backgroundColor: LIST,
        datalabels: {
          font: {
            size: 30,
            weight: "bold",
          } as any,
        } as any,
      },
    ],
  };

  if (!data || sum(dataList) === 0) return null;

  return (
    <>
      <div className="w-[800px] mx-auto my-0">
        <Pie options={options3} data={data3} plugins={[ChartDataLabels]} />
      </div>
    </>
  );
};

export default StarPieChart;
