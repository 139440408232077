import { useState, useEffect } from "react";

import Background from "Components/Atoms/Background/background";

import H2 from "Components/Atoms/H2/h2";
import { ReactComponent as ModalX } from "Util/svg/modalX.svg";
import Space from "Components/Atoms/Space/space";
import Button from "Components/Atoms/Button/button";
import { modalToggle } from "Store/Slices/modal";
import { useDispatch } from "react-redux";
import InputRowPw from "Components/Molecules/InputRowPw/inputRowPw";
import InputRow from "Components/Molecules/InputRow/inputRow";
import { putCsPasswordApi, putUserPasswordApi } from "Apis/apis";
import LANG, { getLang } from "Util/lang";

const ChangeFixModal = ({ csNo = 0 }: { csNo?: number }) => {
  const dispatch = useDispatch();

  const [password, setPassword] = useState("");
  const [changePassword, setChangePassword] = useState("");
  const [changePasswordCheck, setChangePasswordCheck] = useState("");
  const pwReg =
    /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=\-<>?./:;'"[\]{}])(?=.*[0-9]).{8,32}$/;

  const pass = (): boolean => {
    if (changePassword === "") {
      alert("변경할 비밀번호를 입력해주세요");
      return false;
    } else if (changePasswordCheck !== changePassword) {
      alert("변경할 비밀번호와 알맞지 않습니다.");
      return false;
    } else if (changePassword.match(pwReg) === null) {
      alert("(문자, 숫자, 특수문자 조합(최소 8자, 최대 32자)입니다.");
      return false;
    }
    return true;
  };

  const putPassword = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const isPass: boolean = pass();
    if (!isPass) return;
    const body = {
      password: changePassword,
      currentPassword: password,
    };
    const api = !csNo ? putUserPasswordApi : putCsPasswordApi;
    await api(body, csNo);
    alert("비밀번호 수정이 완료됐습니다.");
    setPassword("");
    setChangePassword("");
    setChangePasswordCheck("");
    dispatch(modalToggle(1));
  };

  return (
    <Background>
      <div style={{ width: "640px" }} className="p-32 bg-f2f3f8">
        <div className="flex justify-between items-center">
          <H2 isPrimary={true} name="비밀번호 변경" />
          <div
            className="inline-block cursor-pointer"
            onClick={() => dispatch(modalToggle(1))}
          >
            <ModalX />
          </div>
        </div>
        <Space y={8} />
        <form onSubmit={putPassword}>
          {!csNo ? (
            <InputRow
              id="service"
              name="현재 비밀번호"
              value={password}
              setValue={setPassword}
              type="password"
            />
          ) : null}

          <InputRowPw
            id="service"
            name="변경 비밀번호"
            value={changePassword}
            setValue={setChangePassword}
            condition={changePassword.match(pwReg) === null ? false : true}
          />
          <InputRowPw
            id="service"
            name={LANG[getLang()].passwordConfirmation}
            value={changePasswordCheck}
            setValue={setChangePasswordCheck}
            condition={
              changePasswordCheck !== "" &&
              changePassword === changePasswordCheck
            }
          />
          <div className="flex justify-end mt-10">
            <Button
              onClick={() => dispatch(modalToggle(1))}
              btnText={LANG[getLang()].cancel}
            />
            <Space x={10} />
            <Button type="submit" btnText={LANG[getLang()].change} />
          </div>
        </form>
      </div>
    </Background>
  );
};

export default ChangeFixModal;
