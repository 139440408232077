import { ReactComponent as Require } from "Util/svg/require.svg";

import { useState, useEffect } from "react";
import Button from "Components/Atoms/Button/button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  deleteHolidayApi,
  getConsultantDetailApi,
  getServiceIdListApi,
  getTokenCheckApi,
  putConsultantApi,
  putConsultantInfoApi,
  useGetConsultantDetailApi,
} from "Apis/apis";
import InputRowRadio from "Components/Molecules/InputRowRadio/inputRowRadio";
import AccountFixForm from "Components/Organisms/AccountFixForm/accountFixForm";
import ChangeFixModal from "Components/Molecules/ChangeFixModal/changeFixModal";

import InputRowCheckBox from "Components/Molecules/InputRowCheckBox/inputRowCheckBox";
import LANG, { getLang } from "Util/lang";
import { ClickInputForm, RadioForm, User } from "types/interface";
import { useSelector } from "react-redux";
import { ReducerType, useAppDispatch } from "Store/store";
import { ctMode, init } from "Store/Slices/layouts";
import InputRowId from "Components/Molecules/InputRowId/inputRowId";
import ChangePw from "Components/Molecules/ChangePw/changePw";
import InputRow from "Components/Molecules/InputRow/inputRow";
import InputRowFile from "Components/Molecules/InputRowFile/inputRowFile";
import InputRowSelect from "Components/Molecules/InputRowSelect/inputRowSelect";
import { toCheckboxForm } from "Util/function";
import InputText from "Components/Molecules/InputText/inputText";
import Space from "Components/Atoms/Space/space";
import HolidayTimeModal from "Components/Molecules/TimeModal/holidayTimeModal";
import { modalToggle } from "Store/Slices/modal";
import { setUser } from "Store/Slices/user";

const HOLIDAY_DATA = [{ idx: 1, label: "휴가" }];
const RADIO_DATA: RadioForm[] = [
  { idx: 0, label: "관리자", id: "admin" },
  { idx: 1, label: LANG[getLang()].assistant, id: "consultant" },
];
const AUTH_DATA: {
  [index: number]: string;
  1: string;
  2: string;
} = {
  1: "읽기",
  2: "쓰기",
};
const STATE_DATA: {
  [index: number]: string;
  1: string;
  2: string;
} = {
  0: LANG[getLang()].approval,
  1: LANG[getLang()].waiting,
  2: "정지(퇴사)",
};

const AccountAdd = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const user: User = useSelector((state: ReducerType) => state.user);

  const { data } = useGetConsultantDetailApi(user.idx);

  const [csId, setCsId] = useState("");
  const [csNo, setCsNo] = useState(0);

  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [serviceList, setServiceList] = useState<number[]>([]);
  const [level, setLevel] = useState(0);
  const [auth, setAuth] = useState<number[]>([]);
  const [img, setImg] = useState<File>({} as File);
  const [imgName, setImgName] = useState("");
  const [state, setState] = useState<number>(1);
  const [isidCheck, setIsIdCheck] = useState(false);
  const [starValue, setStarValue] = useState(10); // 별점리뷰
  const [holiday, setHoliday] = useState<number[]>([]);
  const [service_data, setservice_data] = useState<ClickInputForm[]>([]);
  const [holidayStartTime, setHolidayStartTime] = useState("");
  const [holidayEndTime, setHolidayEndTime] = useState("");

  const pass = (): boolean => {
    if (name === "") {
      alert("이름을 작성 해주세요");
      return false;
    } else if (phoneNumber === "") {
      alert("핸드폰 번호를 작성 해주세요");
      return false;
    } else if (serviceList.length === 0) {
      alert("담당 서비스를 선택 해주세요");
      return false;
    }
    return true;
  };

  const getDetail = async () => {
    if (!data) return;

    setCsId(data.cs_id);
    setCsNo(data.cs_no);
    setName(data.cs_name);
    setPhoneNumber(data.phone_number);
    setServiceList(data.service_no_list);
    setAuth(data.authority === 3 ? [1, 2] : [data.authority]);
    setLevel(data.level);
    setState(data.state);
    setStarValue(data.star_percent);
    setImgName(data.original_filename);
    setHoliday(data.work_state === 1 ? [1] : []);
    setHolidayStartTime(data.leave.start_date_hour);
    setHolidayEndTime(data.leave.end_date_hour);
  };
  const EVERYTOWN = "everytown";
  const game = window.location.host.split(".")[0];
  const subDomain = game.includes("localhost") ? EVERYTOWN : game;

  const checkToken = async () => {
    const { data } = await getTokenCheckApi(subDomain);
    return data;
  };
  const enrollment = async () => {
    const isPass: boolean = pass();
    if (!isPass) return;

    const confirm = window.confirm("수정 하시겠습니까?");
    if (!confirm) return;

    let sum = 0;
    for (let i = 0; i < auth.length; i++) {
      sum += auth[i];
    }

    const formData: any = new FormData();

    formData.append("name", name);
    formData.append("phoneNumber", phoneNumber);

    formData.append("cs", img);
    formData.append("workState", holiday.length);

    await putConsultantInfoApi(formData);

    const data = await checkToken();
    dispatch(setUser(data.token));
    navigate("/user/ctqna");
  };
  const getServiceIdList = async () => {
    const {
      data: { serviceList },
    } = await getServiceIdListApi("");
    const checkboxForm: ClickInputForm[] = toCheckboxForm(
      serviceList,
      "service_no",
      "service_name",
    );

    setservice_data(checkboxForm);
  };
  const deleteHoliday = async () => {
    await deleteHolidayApi(csNo);
    const { data } = await getConsultantDetailApi(csNo);
    setHolidayStartTime(data.leave.start_date_hour);
    setHolidayEndTime(data.leave.end_date_hour);
    alert("휴가삭제가 완료됐습니다.");
  };
  useEffect(() => {
    if (user.level === 3) {
      dispatch(ctMode());
    }
  }, []);
  useEffect(() => {
    getDetail();
    getServiceIdList();
  }, [user, data]);

  return (
    <div>
      <div className="flex items-center justify-end mb-10">
        <Require />
        <span className="ml-5">표기된 사항은 필수 입력 사항입니다.</span>
      </div>
      <div>
        <InputRowId
          id="id"
          name={LANG[getLang()].eMail}
          value={csId}
          setValue={setCsId}
          check={isidCheck}
          setCheck={setIsIdCheck}
          disable={true}
        />
        <ChangePw id="pw" name={LANG[getLang()].passward} />
        <InputRow
          id="name"
          name={LANG[getLang()].name}
          value={name}
          setValue={setName}
        />
        <InputRow
          id="phone"
          name={LANG[getLang()].phone}
          value={phoneNumber}
          setValue={setPhoneNumber}
          placeholder="“-” 없이 입력, ex) 01071545574"
        />
        <InputRowFile
          require={false}
          id="profile_img"
          name={LANG[getLang()].profileImage}
          setValue={setImg}
          value={imgName || ""}
        />
        <InputText
          name="담당서비스"
          text={service_data.map((el) => el.label).join(" , ")}
        />
        <InputText
          name="level"
          text={RADIO_DATA.find((el) => el.idx === level)?.label || ""}
        />
        <InputText name="별점리뷰" text={`${starValue}%`} />
        <InputText
          name="권한"
          text={auth.map((el) => AUTH_DATA[el]).join(", ")}
        />
        <InputText name={LANG[getLang()].state} text={STATE_DATA[state]} />
        <div className="relative">
          <InputRowCheckBox
            name="근무상태"
            data={HOLIDAY_DATA}
            value={holiday}
            setValue={(val: any) =>
              setHoliday(() => {
                if (val.length === 1) {
                  dispatch(modalToggle(2));
                } else if (val.length === 0) {
                  deleteHoliday();
                }
                return val;
              })
            }
            require={false}
          />
          {holidayStartTime && (
            <span className="absolute top-[50%] -translate-y-1/2 left-[280px]">
              {`${holidayStartTime}시   ~  ${holidayEndTime}시 `}
            </span>
          )}
        </div>
      </div>
      <div className="flex justify-end gap-10 mt-10">
        <div className="flex">
          <Link to="/user/ctqna">
            <Button btnText="돌아가기" color="normal" />
          </Link>
          <Space x={8} />
          <Button onClick={enrollment} btnText={LANG[getLang()].change} />
        </div>
      </div>
      <ChangeFixModal csNo={user.idx} />
      <HolidayTimeModal
        csNo={csNo}
        modalNum={2}
        setStartTime={setHolidayStartTime}
        setEndTime={setHolidayEndTime}
      />
    </div>
  );
};

export default AccountAdd;
