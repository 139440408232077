import Label from "Components/Atoms/Label/label";
import Space from "Components/Atoms/Space/space";
import { ReactComponent as Require } from "Util/svg/require.svg";
import Checkbox from "Components/Atoms/Checkbox/checkbox";
import React, { ReactNode } from "react";
import { ClickInputForm } from "types/interface";

/**
 * 로그인페이지 : ID PW 인풋창
 * @param {string} placeholder 정의어
 */
const InputRowCheckBox = ({
  data,
  value,
  setValue,
  name,
  require = true,
}: {
  data: ClickInputForm[];
  value: number[];
  setValue: React.Dispatch<React.SetStateAction<number[]>>;
  name: string;
  require?: boolean;
}) => {
  return (
    <div className="flex items-center">
      <div className="flex items-center px-16 py-20 bg-e5e6ed w-130">
        <Label labelfor="dummy" name={name} fontSize={16} />
        <Space x={4} />
        {require && <Require />}
      </div>
      <div className="flex items-center flex-1 h-64 gap-10 bg-white p-13">
        <Checkbox value={value} setValue={setValue} dataList={data} />
      </div>
    </div>
  );
};

export default InputRowCheckBox;
