import { useState } from "react";

import Background from "Components/Atoms/Background/background";
import H2 from "Components/Atoms/H2/h2";
import Space from "Components/Atoms/Space/space";
import Button from "Components/Atoms/Button/button";
import { modalToggle } from "Store/Slices/modal";
import { useAppDispatch } from "Store/store";
import { ReactComponent as ModalX } from "Util/svg/modalX.svg";
import { useLocation } from "react-router-dom";
import { ReactComponent as LeftArrow } from "Util/svg/leftArrow.svg";
// import Table, { ColumnsType } from "antd/es/table";

const AgreeModal = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const { idx } = location.state || {};

  return (
    <Background modalNum={10}>
      <div style={{ width: "1000px" }} className="p-32 h-[540px] bg-f2f3f8">
        <div className="flex items-center justify-between">
          <H2 isPrimary={true} name="결제 동의 사항" />
          <div
            className="inline-block cursor-pointer"
            onClick={() => dispatch(modalToggle(idx || 2))}
          >
            <div className="flex">
              <div className="brightness-[.3]">{/* <LeftArrow /> */}</div>
              <Button btnText="확인" />
            </div>
          </div>
        </div>
        <Space y={20} />
        <div className="overflow-auto whitespace-pre-wrap h-400">
          {`
서비스 이용 및 환불 규정

1. 서비스 이용 기간 및 플랜 변경

1) 서비스 결제일로부터 결제내역에 명시된 기간 내 서비스 이용에 대한 권리가 유지됩니다.

2) 이용자에 의한 서비스 플랜 업/다운그레이드
 ① 이용자는 처음 구매한 서비스 플랜에서 현 계정 상태를 유지한 채로 상위 플랜으로 업그레이드를 할 수 있습니다. 다운그레이드가 필요한 경우 변경 신청 전 사전문의를 통해 서비스 변경이 진행되어야 하며, 사전문의 없이 진행된 다운그레이드로 인해 발생 되는 (서비스 내용, 기능 또는 용량의 손실 등의) 이용자의 손실에 대해 회사는 책임을 지지 않습니다.
 ② 이용자는 서비스 사용 기간 언제든지 사용하는 플랜별 제한 범위 내에서 ‘서비스 ID’ 및 ‘시트‘를 추가하거나 감소시킬 수 있습니다. ‘시트’의 경우 추가 구매가 가능하며, ‘서비스 ID’의 경우에는 플랜 업그레이드를 통해 추가해야 합니다.
 ③ 서비스 플랜 업그레이드 및 플랜별 제한 범위 내에서 ‘서비스 ID’ 및 ‘시트’ 추가신청은 즉시 적용되며, 다운그레이드의 경우도 동일합니다.
 ④ 무료 체험 중 기능 사용은 서비스 플랜에 따른 범위 내에서 자유롭게 사용 가능합니다.
 ⑤ 무료 체험 기간 중 가입한 계정으로 서비스 결제 시점에서 서비스 플랜 업그레이드 사용이 가능합니다.

3) 회사에 의한 서비스 변경
 ① 이용자는 이 약관에 동의함으로써 CSbye 서비스 이용 기간 동안 회사가 CSbye의 기능을 수정할 수 있음을 인정합니다.
 ② 회사가 CSbye의 서비스 이행 수준을 포함한 기존 서비스 기능을 변경할 내용에 대해서는 사후 알릴 의무가 있습니다. 하지만 변경하고자 하는 내용이 이용자에게 불리하거나 이용자의 업무에 미치는 영향이 큰 경우에는 변경 14일 이전에 고지하고 동의를 구할 것입니다.

2. 서비스 해지 및 이용 중지

1) 플랜 해지
 ① 플랜 해지는 사용 중인 서비스를 정지하는 행위를 의미합니다.
 ② 청구 관리자는 플랜 해지 메뉴를 통해 요금제를 해지할 수 있습니다.
 ③ 다음 결제 예정일 전 중도에 해지한 경우 ‘CSbye 반환기준‘에 따라 요청일 기준 결제일로부터 사용한 일수에 따라 부분 환불을 진행할 수 있습니다
 * 이벤트 할인가로 진행 중 중도 해지한 경우 해당 플랜의 이벤트 전 이용금액을 기준으로 ‘CSbye 반환기준‘에 따라 환불이 진행되어, 추가 납부가 있을 수 있습니다. 
 * 이벤트 할인가 진행 시 환불 요금 계산법 : 결제금액 - ’환불 요청일 기준’ 기존 플랜 (이벤트 혜택 전 금액) 사용 일수 별 반환금액
 ④ 요금제 해지 진행 시 사용 중인 모든 “이용자”가 해당 계정 정보를 통한 서비스 이용이 제한됩니다.
 ⑤ 요금제를 해지할 경우 등록된 데이터는 1년간 보존되며, 1년 안에 요금제 해지를 철회하지 않을 경우 모든 데이터는 영구 삭제되어 소멸되므로 "이용자" 측에서 사전에 데이터 백업을 진행하여야 합니다.

2) 회사가 이용자의 접근을 제한할 권리
 ① 이용자 또는 이용자의 고객이 전자적 침해행위로 데이터의 손상, 서버 정지 등을 초래하거나 이 외에 본 약관 규정을 위반하여 회사의 서비스 운영 및 업무 수행에 현저한 지장을 주거나, 줄 수 있는 우려가 있는 경우에 회사는 이용자의 서비스 이용을 제한할 수 있습니다.
 ② 결제일에 결제가 이루어지지 않는 경우, 회사는 서비스 이용을 제한 할 수 있으며, 해당 내용을 고지한 기간 내에 결제가 진행되지 않는 경우 서비스를 해지할 수도 있습니다.
 ③ 회사와 사전 서면 동의가 있는 경우를 제외하고 이용자가 회사와 직접적인 경쟁 위치에 있는 경우 서비스 접근을 제한할 수 있습니다.
 ④ 이용 제한의 사유가 발견된 경우, 회사가 이용자에게 일정 기간 내에 해당 사유를 해소하기를 요구할 수 있으며, 해당 사유가 해소되지 않을 때에 이용자의 계정 및 서비스를 해지 및 데이터를 삭제 할 수 있습니다.

3. 서비스 환불규정

1) "서비스" 이용요금의 환불 가능 사유는 아래와 같습니다.
 ① 다음 결제일이 도래하기 전 플랜을 변경하는 경우
 ② 다음 결제일이 도래하기 전 라이선스를 변경하는 경우
 ③ 다음 결제일이 도래하기 전 서비스를 정지 또는 탈퇴하는 경우
 ④ 회사의 귀책 사유로 인해 잘못된 금액이 결제되었거나 초과 과금이 발생한 경우
 
 2) 환불시에는 요청일을 기준으로 “CSBYE“의 반환기준을 적용합니다.
`}
          <Space y={20} />
          <table id="agreeTable">
            <th>환불 요청 발생시점</th>
            <th>반환금액</th>
            <tr>
              <td>
                <p>결제일 포함 7일 이내 (서비스 미사용)</p>
                <p>결제일 포함 3일 이내 (서비스 사용)</p>
              </td>
              <td>
                <p>전액 환불</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>결제일 포함 4일~7일 이내 (서비스 사용)</p>
              </td>
              <td>플랜별 서비스 이용금액의 3분의 1</td>
            </tr>
            <tr>
              <td>
                <p>결제일 포함 14일 이내 (서비스 미사용 포함)</p>
              </td>
              <td>플랜별 서비스 이용금액의 2분의 1</td>
            </tr>
            <tr>
              <td>
                <p>결제일 포함 14일 이후 (서비스 미사용 포함)</p>
              </td>
              <td>
                <p>환불 불가 (남은 기간 서비스 이용 가능)</p>
              </td>
            </tr>
            <th>회사 귀책사유 시</th>
            <th>반환금액</th>
            <tr>
              <td>
                <p>잘못된 금액 결제로 인한 초과 과금 발생</p>
              </td>
              <td>
                <p>과금된 부분에 대한 금액 반환</p>
              </td>
            </tr>
            <th>환불 요청 발생시점 (이벤트진행가)</th>
            <th>반환금액</th>
            <tr>
              <td>
                <p>결제일 포함 7일 이내 (서비스 미사용)</p>
                <p>결제일 포함 3일 이내 (서비스 사용)</p>
              </td>
              <td>
                <p>전액 환불</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>결제일 포함 4일~7일 이내 (서비스 사용)</p>
              </td>
              <td>
                <p>(결제금액 - 기존 플랜 서비스 이용금액 3분의 1)</p>
                <p>*추가 결제가 있을 수 있음</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>결제일 포함 14일 이내 (서비스 미사용 포함)</p>
              </td>
              <td>
                <p>(결제금액 - 기존 플랜 서비스 이용금액 2분의 1)</p>
                <p>*추가 결제가 있을 수 있음</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>결제일 포함 14일 이후 (서비스 미사용 포함)</p>
              </td>
              <td>
                <p>환불 불가 (남은 기간 서비스 이용 가능)</p>
              </td>
            </tr>
          </table>

          {`
3) 정상적인 규정에 의해 진행된 결제 취소는 즉시 처리되며, 결제한 카드사의 환불 규정에 따라 환불 처리시간이 소요됩니다.

4) 결제 승인금액에 이의가 있는 경우에는 이용자와 회사가 협의한 후 환불이 필요하다고 판단 될 경우 정산 후 환불을 진행합니다.

5) 이용자가 ‘서비스 이용 및 환불 규정’ 내 ‘2.서비스 접근 제한규정’을 위반하여 회사가 서비스 이용을 제한하거나 이용자가 일방적으로 본 계약을 해지하는 경우 회사는 이용자에게 환불을 진행하지 않아도 이용자는 이의를 제기할 수 없습니다.
`}
        </div>
      </div>
    </Background>
  );
};

export default AgreeModal;
