import Button from "Components/Atoms/Button/button";
import Select from "Components/Atoms/Select/select";
import Space from "Components/Atoms/Space/space";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AutoAnswer from "./autoAnswer";
import Email from "./email";
const PAGE_TYPE = [
  {
    idx: 1,
    label: "접수 메일",
  },
  {
    idx: 2,
    label: "답변 메일",
  },
];
const MailDesign = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [page, setPage] = useState(1);
  const selectButton = (e: number) => {
    navigate("", {
      state: { page: e, serviceNo: -1 },
    });
  };

  const selectPage = (page: number) => {
    switch (page) {
      case 1:
        return <AutoAnswer />;
      case 2:
        return <Email />;
    }
  };

  useEffect(() => {
    setPage(location.state?.page || 1);
  }, [location.state]);
  return (
    <div>
      <div className="relative z-20 flex">
        <div className="flex gap-19">
          {PAGE_TYPE.map((item) => {
            return (
              <Button
                key={item.idx}
                btnText={item.label}
                size={"md"}
                color={item.idx === page ? "primary" : "normal"}
                onClick={() => selectButton(item.idx)}
              />
            );
          })}
        </div>
      </div>
      <Space y={20} /> {selectPage(page)}
    </div>
  );
};

export default MailDesign;
