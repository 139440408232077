import React from "react";
import { ReactComponent as X } from "Util/svg/modalX.svg";

/**
 * 전체화면 백그라운드
 * @param  child 안에 보일 엘리먼트
 * @returns
 */
const FileNameList = ({
  fileList,
  setFileList,
}: {
  fileList: File[];
  setFileList: React.Dispatch<React.SetStateAction<File[]>>;
}) => {
  const onClickX = (idx: number) => {
    const newFileList = fileList.filter((_, fileIdx) => fileIdx !== idx);
    setFileList(newFileList);
  };
  return (
    <>
      {fileList.map((file, idx) => (
        <div className="flex" key={idx}>
          <span className="ell">{file.name}</span>
          <button onClick={() => onClickX(idx)} type="button">
            <X />
          </button>
        </div>
      ))}
    </>
  );
};

export default FileNameList;
