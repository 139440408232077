import Background from "Components/Atoms/Background/background";
import Button from "Components/Atoms/Button/button";
import H2 from "Components/Atoms/H2/h2";
import Space from "Components/Atoms/Space/space";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { modalToggle } from "Store/Slices/modal";
import { ReactComponent as ModalX } from "Util/svg/modalX.svg";
import InputRow from "../InputRow/inputRow";
import { postBoardNoticeApi } from "Apis/apis";
import InputRowSelect from "../InputRowSelect/inputRowSelect";
import useServiceNo from "Util/Hooks/useServiceIdx";
import { ReducerType } from "Store/store";
import LANG, { getLang } from "Util/lang";
import { useNavigate } from "react-router-dom";

const BoardNoticeModal = ({ getNotice }: { getNotice: () => void }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [title, setTitle] = useState("");
  const [contents, setContents] = useState("");
  const [serviceNo, setServiceNo] = useServiceNo();
  const serviceList = useSelector((state: ReducerType) =>
    state.serviceList
      .filter((el) => el.state !== 2 && el.state !== 1)
      .map((el) => (el.idx === -1 ? { ...el, label: "서비스 선택" } : el)),
  );
  const pass = (): boolean => {
    if (serviceNo === -1) {
      alert("서비스를 선택해주세요.");
      return false;
    } else if (title === "") {
      alert("제목을 입력해주세요.");
      return false;
    } else if (contents === "") {
      alert("링크를 입력해주세요.");
      return false;
    }
    return true;
  };

  const postBoardNotice = async () => {
    const isPass: boolean = pass();
    if (!isPass) return;
    const body = {
      serviceNo,
      title,
      contents,
      state: 1,
    };
    await postBoardNoticeApi(body);
    setTitle("");
    setContents("");
    setServiceNo(-1);
    navigate("", {
      state: { pageNo: 3, selectServiceNo: serviceNo },
    });
    dispatch(modalToggle(1));
    getNotice();
  };

  const modalClose = () => {
    setTitle("");
    setContents("");
    return dispatch(modalToggle(1));
  };
  return (
    <Background>
      <div style={{ width: "640px" }} className="p-32 bg-f2f3f8">
        <div className="flex justify-between items-center">
          <H2 isPrimary={true} name="공지사항 등록" />

          <div
            className="inline-block cursor-pointer"
            onClick={() => modalClose()}
          >
            <ModalX />
          </div>
        </div>
        <Space y={20} />
        <InputRowSelect
          id="service"
          name="서비스 목록"
          value={serviceNo}
          setValue={setServiceNo}
          dataList={serviceList}
        />
        <InputRow
          id="title"
          name={LANG[getLang()].title}
          value={title}
          setValue={setTitle}
        />
        <InputRow
          id="link"
          name={LANG[getLang()].link}
          value={contents}
          setValue={setContents}
          placeholder="https:// 를 함께 기입바랍니다."
        />
        <div className="flex justify-end mt-10">
          <Button
            btnText={LANG[getLang()].register}
            onClick={postBoardNotice}
          />
          <Space x={10} />
        </div>
      </div>
    </Background>
  );
};

export default BoardNoticeModal;
