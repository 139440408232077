import { Switch, Table } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ColumnsType } from "antd/es/table";
import Space from "Components/Atoms/Space/space";
import Button from "Components/Atoms/Button/button";
import { modalToggle } from "Store/Slices/modal";
import KeywordModal from "Components/Molecules/KeywordModal/keywordModal";
import TelegramModal from "Components/Molecules/TelegramModal/telegramModal";
import { putServiceId, Telegram, User } from "types/interface";
import { putServiceApi, useGetAlarmListApi } from "Apis/apis";
import { useQueryClient } from "@tanstack/react-query";
import { modifyObj, order } from "Util/function";
import LANG, { getLang } from "Util/lang";
import { ReducerType } from "Store/store";
import { levelCheck } from "Store/Slices/user";
import Label from "Components/Atoms/Label/label";
import { ReactComponent as QuestionMark } from "Util/svg/questionMark.svg";

const HELPLIST = [
  {
    text: "알림서비스 설정",
    link: "https://www.notion.so/9c2b922fb89942da87aecd7c1528c861",
  },
  {
    text: "텔레그램",
    link: "https://www.notion.so/ID-95c281e43c364bb1a88ff54ab44d158d",
  },
  {
    text: "알리고",
    link: "https://www.notion.so/01d63321eab54a019335f1fc7be13f7d",
  },
];

const AlarmSetting = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { check_code }: User = useSelector((state: ReducerType) => state.user);
  const { limit_keyword } = useSelector((state: ReducerType) => state.user);
  const [serviceNo, setServiceNo] = useState(0);
  const { data: telegramList } = useGetAlarmListApi();

  const onClickService = (idx: number, modalNum: number) => {
    setServiceNo(idx);
    dispatch(modalToggle(modalNum));
  };

  const onClickSwitch = async (
    now: boolean,
    service: Telegram,
    type: "allAlarm" | "vipAlarm" | "keywordAlarm"
  ) => {
    if (!telegramList) return;

    const alarm = { [type]: now ? 0 : 1 };
    const body: putServiceId = alarm;

    // 타입에 따른 키값
    const returnAlarmKey: {
      [index: string]: "all_alarm" | "vip_alarm" | "keyword_alarm";
    } = {
      allAlarm: "all_alarm",
      vipAlarm: "vip_alarm",
      keywordAlarm: "keyword_alarm",
    };

    // prettier-ignore
    const newTelegramList = modifyObj(telegramList, "service_no", returnAlarmKey[type], service.service_no, now ? 0 : 1 );

    try {
      queryClient.setQueryData(["useGetAlarmListApi"], newTelegramList); // 일단 상태바꿈 => 이거없으면 api기다렸다가 바뀜
      await putServiceApi(service.service_no, body); // api요청함
      queryClient.invalidateQueries(["useGetAlarmListApi"]); // 성공하면 그대로
    } catch (error) {
      queryClient.setQueryData(["useGetAlarmListApi"], telegramList); // 실패하면 다시 돌아감
    }
  };

  const columns: ColumnsType<Telegram> = [
    {
      title: LANG[getLang()].index,
      dataIndex: "service_no",
      align: "center",
      render: (now, row) => order(telegramList, row.service_no, "service_no"),
    },
    {
      title: "서비스명",
      dataIndex: "service_name",
      align: "center",
      render: (now, row) => {
        return <span>{now}</span>;
      },
    },
    {
      title: "전체알림",
      dataIndex: "all_alarm",
      align: "center",
      render: (now, row) => {
        return (
          <Switch
            checked={check_code.findIndex((el) => el === "a02") !== -1 && now}
            checkedChildren="ON"
            unCheckedChildren="OFF"
            onChange={() =>
              dispatch(
                levelCheck({
                  sl: "a02",
                  fuc: () => onClickSwitch(now, row, "allAlarm"),
                })
              )
            }
          />
        );
      },
    },
    {
      title: "vip알림",
      dataIndex: "vip_alarm",
      align: "center",
      render: (now, row) => {
        return (
          <Switch
            checked={check_code.findIndex((el) => el === "a01") !== -1 && now}
            checkedChildren="ON"
            unCheckedChildren="OFF"
            onChange={() =>
              dispatch(
                levelCheck({
                  sl: "a01",
                  fuc: onClickSwitch(now, row, "vipAlarm"),
                })
              )
            }
          />
        );
      },
    },
    {
      title: "키워드 알림",
      dataIndex: "keyword_alarm",
      align: "center",
      render: (now, row) => {
        return (
          <Switch
            checked={limit_keyword !== 0 && now}
            checkedChildren="ON"
            unCheckedChildren="OFF"
            onChange={() => {
              if (limit_keyword !== 0) {
                onClickSwitch(now, row, "keywordAlarm");
                return;
              }
              alert("상품을 업그레이드 해주세요.");
            }}
          />
        );
      },
    },
    {
      title: "텔레그램 정보",
      dataIndex: "telegram",
      align: "center",
      render: (now, row) => (
        <Button
          btnText="설정"
          size="sm"
          onClick={() => onClickService(row.service_no, 2)}
        />
      ),
    },
    {
      title: "키워드 등록",
      dataIndex: "alarm_keyword",
      align: "center",
      render: (now, row) => (
        <Button
          btnText="등록"
          size="sm"
          color={limit_keyword !== 0 ? "primary" : "ready"}
          onClick={() => {
            if (limit_keyword !== 0) {
              onClickService(row.service_no, 1);
              return;
            }
            alert("상품을 업그레이드 해주세요.");
          }}
        />
      ),
    },
  ];

  return (
    <div>
      <Space y={20} />
      <div className="flex justify-end gap-12">
        {HELPLIST.map((help) => (
          <a
            href={help.link}
            target="_blank"
            rel="noreferrer"
            className="flex items-center cursor-pointer "
          >
            <QuestionMark />
            <Space x={4} />
            <Label name={help.text} labelfor={help.text} />
          </a>
        ))}
      </div>
      <Space y={20} />

      <Table
        rowKey="service_no"
        columns={columns}
        dataSource={telegramList}
        pagination={false}
      />
      <TelegramModal serviceNo={serviceNo} />
      <KeywordModal serviceNo={serviceNo} />
    </div>
  );
};

export default AlarmSetting;
