import Select from "Components/Atoms/Select/select";
import { useState, useEffect } from "react";

import { useSelector } from "react-redux";
import { ReducerType } from "Store/store";
import { PolicyApiBody, RadioForm } from "types/interface";
import { ReactComponent as Require } from "Util/svg/require.svg";
import Label from "Components/Atoms/Label/label";
import Space from "Components/Atoms/Space/space";
import LabelQuill from "Components/Molecules/LabelQuill/labelQuill";
import InputRowRadio from "Components/Molecules/InputRowRadio/inputRowRadio";
import Button from "Components/Atoms/Button/button";
import { useNavigate } from "react-router-dom";
import { postPolicyApi } from "Apis/apis";
import InputRow from "Components/Molecules/InputRow/inputRow";
import useServiceNo from "Util/Hooks/useServiceIdx";
import LANG, { getLang } from "Util/lang";

const PolicyAdd = () => {
  const navigate = useNavigate();

  const serviceList = useSelector((state: ReducerType) =>
    state.serviceList
      .filter((el) => el.state !== 2 && el.state !== 1)
      .map((el) => (el.idx === -1 ? { ...el, label: "서비스 선택" } : el)),
  );
  const STATE_DATA: RadioForm[] = [
    { idx: 1, label: LANG[getLang()].waiting, id: "wait" },
    { idx: 0, label: LANG[getLang()].approval, id: "ok" },
  ];

  const [text, setText] = useState("");
  const [state, setState] = useState(1);
  const [serviceNo, setServiceNo] = useServiceNo();
  const [title, setTitle] = useState("");
  const pass = (): boolean => {
    if (serviceNo === -1) {
      alert("서비스를 선택해주세요.");
      return false;
    } else if (title === "") {
      alert("제목을 입력해주세요.");
      return false;
    } else if (text === "") {
      alert("내용을 입력해주세요.");
      return false;
    }
    return true;
  };

  const postPolicyAdd = async () => {
    const isPass: boolean = pass();
    if (!isPass) return;
    const confirm = window.confirm("등록 하시겠습니까?");
    if (!confirm) return;

    const body: PolicyApiBody = {
      serviceNo,
      title,
      contents: text,
      state: +state,
    };
    await postPolicyApi(body);
    navigate("/service/potalboard", {
      state: { pageNo: 2, selectServiceNo: serviceNo },
    });
  };

  return (
    <>
      <div className="rounded bg-ffffff">
        <div className="flex items-center">
          <div className="flex items-center px-16 py-20 bg-e5e6ed w-130">
            <Label labelfor="select" name="분류" fontSize={16} />
            <Space x={4} />
            <Require />
          </div>
          <div className="flex-1 h-full bg-white p-13">
            <div className="flex w-full">
              <Select
                dataList={serviceList}
                val={serviceNo}
                setVal={setServiceNo}
              />
            </div>
          </div>
        </div>
        <InputRow
          id="policyAdd"
          value={title}
          setValue={setTitle}
          name={LANG[getLang()].title}
        />
        <LabelQuill
          id="faqQuill"
          name={LANG[getLang()].content}
          text={text}
          setText={setText}
        />
        <InputRowRadio
          data={STATE_DATA}
          name={LANG[getLang()].state}
          value={state}
          setValue={setState}
        />
      </div>
      <div className="flex justify-end gap-10 mt-10 ">
        <Button
          btnText={LANG[getLang()].list}
          onClick={() =>
            navigate(`/service/potalboard`, {
              state: { pageNo: 2, selectServiceNo: -1 },
            })
          }
        />
        <Button btnText={LANG[getLang()].register} onClick={postPolicyAdd} />
      </div>
    </>
  );
};

export default PolicyAdd;
