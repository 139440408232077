import Button from "Components/Atoms/Button/button";
import H2 from "Components/Atoms/H2/h2";
import Space from "Components/Atoms/Space/space";
import { useEffect, useState } from "react";
import LoginInput from "Components/Molecules/Logininput/loginInput";
import { useAppDispatch } from "Store/store";

import Label from "Components/Atoms/Label/label";
import FileButton from "Components/Atoms/Button/fileButton";
import FileNameList from "Components/Atoms/FileNameList/fileNameList";
import { setFormData } from "Util/function";
import { logoutMode } from "Store/Slices/layouts";
import Quill from "Components/Atoms/Quill/quill";
import LANG, { getLang } from "Util/lang";

const Regist = () => {
  const dispatch = useAppDispatch();

  const [email, setEmail] = useState("");
  const [title, setTitle] = useState("");
  const [answer, setAnswer] = useState("");
  const [fileList, setFileList] = useState<File[]>([]);
  const [phoneNumber, setPhoneNumber] = useState("");

  // form 확인
  const pass = (): boolean => {
    if (email === "") {
      alert("이메일을 입력 해주세요");
      return false;
    } else if (title === "") {
      alert("제목을 입력 해주세요");
      return false;
    } else if (answer === "") {
      alert("내용을 입력 해주세요");
      return false;
    }

    return true;
  };

  // 메일 전송
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const isPass: boolean = pass();
    if (!isPass) return;

    const body = {};
    const formData: FormData = new FormData();

    setFormData(formData, body);

    // await sendMailApi();
    // alert("메일 전송완료");
    // postAnswer()
  };

  useEffect(() => {
    dispatch(logoutMode());
  }, []);

  return (
    <div className="h-[100vh] login-bg relative">
      <form
        onSubmit={onSubmit}
        className="absolute px-40 py-48 overflow-auto bg-white rounded-lg w-460 p-center max-h-[95vh] pretty-scrollbar"
      >
        <H2 name="새메일 쓰기" />
        <Space y={20} />
        <LoginInput
          id="title"
          type="text"
          placeholder="제목을 입력해주세요"
          value={title}
          setValue={setTitle}
          name={LANG[getLang()].title}
        />
        <LoginInput
          id="number"
          type="email"
          placeholder=""
          value={phoneNumber}
          setValue={setPhoneNumber}
          name="보내는주소"
        />
        <LoginInput
          id="email"
          type="email"
          placeholder="이메일 주소를 입력해주세요"
          value={email}
          setValue={setEmail}
          name="받는 주소"
          required
        />
        <LoginInput
          id="ref"
          type="text"
          placeholder="다수 입력시 컴마(,)로 구분"
          value={email}
          setValue={setEmail}
          name="참조"
        />
        <Quill value={answer} setValue={setAnswer} toolbar={false} />
        <Space y={20} />

        <Label labelfor="img" name="첨부파일" />
        <Space y={4} />
        <FileButton value={fileList} setValue={setFileList} size="md" max={3} />
        <Space y={8} />
        <div className="grid grid-cols-2 gap-8">
          <FileNameList fileList={fileList} setFileList={setFileList} />
        </div>

        <Space y={20} />
        <Button type="submit" btnText="보내기" full />
      </form>
    </div>
  );
};

export default Regist;
