import QnaConsultForm from "Components/Organisms/QnaConsultForm/qnaConsultForm";
import QnaLecordForm from "Components/Organisms/QnaLecordForm/qnaLecordForm";
import { useState } from "react";

const FULL_HEIGHT = 780;

const Answer = () => {
  const [serviceNo, setserviceNo] = useState(-1); // 선택한 문의의 서비스No
  const [reply, setReply] = useState("");
  const [topHeight, setTopHeight] = useState(315);
  const [bottomHeight, setBottomHeight] = useState(445);
  const [nowWitch, setNowWitch] = useState(0);
  const [moveVal, setmoveVal] = useState(0);

  const onDrag = (val: number) => {
    if (val === 0) return;
    setmoveVal(nowWitch - val);
  };
  const heigth = () => {
    setTopHeight(topHeight - moveVal);
    setmoveVal(0);
  };

  return (
    <div
      style={{ height: `${FULL_HEIGHT}px` }}
      id="answer"
      className="relative"
    >
      <div
        style={{ height: `${topHeight - moveVal}px` }}
        className="absolute w-full overflow-auto bg-white h-325 pretty-scrollbar"
      >
        <QnaLecordForm
          serviceNo={serviceNo}
          setserviceNo={setserviceNo}
          reply={reply}
          setReply={setReply}
        />
      </div>

      {/* <p
        style={{ top: `${topHeight - moveVal}px` }}
        draggable={true}
        onMouseDown={(e) => setNowWitch(e.screenY)}
        onDrag={(e) => onDrag(e.screenY)}
        onDragEnd={heigth}
        className="absolute w-full h-30 cursor-row-resize move"
      ></p> */}

      <div
        style={{ height: `${FULL_HEIGHT - (topHeight - moveVal + 20)}px` }}
        className="absolute bottom-0 w-full overflow-auto pretty-scrollbar"
      >
        <QnaConsultForm
          serviceNo={serviceNo}
          reply={reply}
          setReply={setReply}
        />
      </div>
    </div>
  );
};

export default Answer;
