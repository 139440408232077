import Background from "Components/Atoms/Background/background";
import H2 from "Components/Atoms/H2/h2";
import { ReactComponent as ModalX } from "Util/svg/modalX.svg";
import Space from "Components/Atoms/Space/space";
import { modalToggle } from "Store/Slices/modal";
import Button from "Components/Atoms/Button/button";
import { useEffect, useState } from "react";
import LoginInput from "Components/Molecules/Logininput/loginInput";
import { ReducerType, useAppDispatch } from "Store/store";
import { ClickInputForm, Item, MiddleData } from "types/interface";

import Label from "Components/Atoms/Label/label";
import FileButton from "Components/Atoms/Button/fileButton";
import FileNameList from "Components/Atoms/FileNameList/fileNameList";
import { setFormData } from "Util/function";
import { logoutMode } from "Store/Slices/layouts";
import Quill from "Components/Atoms/Quill/quill";
import LANG, { getLang } from "Util/lang";
import {
  getCategoryItemApi,
  getCategoryListApi,
  postQna,
  putAnswerApi,
  putAssignmentApi,
} from "Apis/apis";
import Input from "Components/Atoms/Input/input";
import Select from "Components/Atoms/Select/select";
import { useSelector } from "react-redux";

const RegistModal = () => {
  const dispatch = useAppDispatch();
  const service = useSelector((state: ReducerType) => state.serviceList).filter(
    (el) => el.state === 0 || el.state === undefined,
  );
  const user = useSelector((state: ReducerType) => state.user);

  const [serviceNo, setServiceNo] = useState(-1);
  const [userId, setUserId] = useState("");
  const [email, setEmail] = useState("");
  const [title, setTitle] = useState("");
  const [userAgent, setUserAgent] = useState("");
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [fileList, setFileList] = useState<File[]>([]);
  const [itemList, setItemList] = useState<Item[]>([]);
  const [middleCategoryList, setMiddleCategoryList] = useState<MiddleData[]>(
    [],
  );
  const [middleCategoryCode, setMiddleCategoryCode] = useState(-1);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [CGList, setCGList] = useState<ClickInputForm[]>([
    { idx: -1, label: "서비스를 선택 해주세요" },
  ]);

  const getCategory = async () => {
    if (serviceNo === -1) return;
    const {
      data: {
        category: { big_category, middle_category },
      },
    } = await getCategoryListApi(serviceNo);
    setMiddleCategoryList(middle_category);
    const cateList: ClickInputForm[] = middle_category
      .filter((el) => el.state === 0) // 승인된 중카테고리
      .filter((el) => {
        // 승인된 대카테고리
        const bCG = big_category.find(
          (bc) => bc.big_category_code === el.big_category_code,
        );

        return bCG?.state === 0;
      })
      .map((el) => {
        const bCGName = big_category.find(
          (bc) => bc.big_category_code === el.big_category_code,
        )?.big_category_name;
        return {
          idx: el.middle_category_code,
          label: `${bCGName} : ${el.middle_category_name}`,
        };
      });
    setCGList([
      {
        idx: -1,
        label: "문의 유형",
      },
      ...cateList,
    ]);
  };
  // form 확인
  const pass = (): boolean => {
    if (userId === "") {
      alert("회원번호를 입력 해주세요");
      return false;
    } else if (middleCategoryCode === -1) {
      alert("문의 유형을 선택 해주세요");
      return false;
    } else if (title === "") {
      alert("제목을 입력 해주세요");
      return false;
    } else if (question === "") {
      alert("질문요약을 입력 해주세요");
      return false;
    } else if (answer === "") {
      alert("답변요약을 입력 해주세요");
      return false;
    }

    return true;
  };

  // 값 초기화
  const reset = () => {
    setServiceNo(-1);
    setUserId("");
    setEmail("");
    setTitle("");
    setUserAgent("");
    setQuestion("");
    setAnswer("");
    setFileList([]);
    setItemList([]);
    setMiddleCategoryList([]);
    setMiddleCategoryCode(-1);
    setPhoneNumber("");
    setCGList([{ idx: -1, label: "서비스를 선택 해주세요" }]);
  };
  // 카테고리별 인풋
  const getItemList = async () => {
    if (!middleCategoryCode) return;
    const {
      data: { category_item },
    } = await getCategoryItemApi(middleCategoryCode);
    const _itemlist = category_item.map((el) => {
      el.value = "";

      return el;
    });

    setItemList(_itemlist);
  };
  // 카테고리별 인풋 onchange
  const onChangeItemValue = (text: string, idx: number) => {
    const inputItemsCopy: Item[] = JSON.parse(JSON.stringify(itemList));
    inputItemsCopy[idx].value = text;
    setItemList(inputItemsCopy);
  };
  // 문의
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const isPass: boolean = pass();
    if (!isPass) return;

    const item = itemSetting();
    const bigCategoryCode = middleCategoryList.find(
      (el) => el.middle_category_code === middleCategoryCode,
    )?.big_category_code;
    const reportBody = {
      title,
      contents: question,
      userId,
      serviceNo,
      bigCategoryCode,
      middleCategoryCode,
      item,
      ...(email && { email: email }),
      phoneNumber,
      userAgent,
    };
    const formData: FormData = new FormData();

    setFormData(formData, reportBody);
    const body = {
      csNo: user.level === 1 ? (user.clone_idx || 0) : (user.level > 1 ? (user.idx || 0) : 0)
    };
    const { data } = await postQna(formData);
    await putAssignmentApi(data.qna_no, body);

    postAnswer(data.qna_no);
  };

  // 답변 및 임시답변
  const postAnswer = async (qIdx: number) => {
    const formData: FormData = new FormData();
    formData.append("contents", answer);
    formData.append("state", "0");
    for (let i = 0; i < fileList.length; i++) {
      formData.append("reply", fileList[i]);
    }
    await putAnswerApi(qIdx, formData);

    alert(`등록이 완료 되었습니다.`);
    reset();
    dispatch(modalToggle(-6));
  };

  // api쏠때 inputList값 정형화
  const itemSetting = () => {
    const item: { [index: string]: string } = {};
    itemList.forEach((el) => (item[el.item_name] = el.value || ""));
    return JSON.stringify(item);
  };

  useEffect(() => {
    getItemList();
    getCategory();
  }, [middleCategoryCode, serviceNo]);

  return (
    <Background modalNum={-6}>
      <form
        onSubmit={onSubmit}
        className="px-40 py-48 overflow-auto bg-white rounded-lg w-460  max-h-[95vh] pretty-scrollbar"
      >
        <div
          onClick={() => dispatch(modalToggle(-6))}
          className="flex justify-end cursor-pointer"
        >
          <ModalX />
        </div>
        <H2 name="등록하기" />
        <span className="text-sm text-gray-400">
          창을 닫아도 내용은 사라지지 않습니다.
        </span>
        <Space y={20} />
        <div className="relative z-50">
          <Select
            dataList={service}
            val={serviceNo}
            setVal={setServiceNo}
            border
          />
        </div>
        <Space y={20} />
        <div>
          <Label labelfor="category" name="문의 유형" />
          <div className="flex justify-start">
            <Select
              dataList={CGList}
              val={middleCategoryCode}
              setVal={setMiddleCategoryCode}
              border
              full
            />
          </div>
        </div>
        <Space y={20} />
        <LoginInput
          id="number"
          type="text"
          placeholder="ex) 01012345678"
          value={phoneNumber}
          setValue={setPhoneNumber}
          name="연락처"
        />
        <Space y={20} />
        <LoginInput
          id="userId"
          type="text"
          placeholder="회원번호(또는 닉네임)"
          value={userId}
          setValue={setUserId}
          name="회원번호"
        />
        <Space y={20} />
        <LoginInput
          id="email"
          type="email"
          placeholder="이메일 주소를 입력해주세요"
          value={email}
          setValue={setEmail}
          name="고객 이메일"
        />
        <Space y={20} />
        <LoginInput
          id="title"
          type="text"
          placeholder="제목을 입력해주세요"
          value={title}
          setValue={setTitle}
          name={LANG[getLang()].title}
        />
        <Space y={20} />
        <Space y={20} />
        <Label labelfor="q" name="질문요약" />
        <Quill value={question} setValue={setQuestion} toolbar={false} />
        <Space y={20} />
        <Label labelfor="a" name="답변요약" />
        <Quill value={answer} setValue={setAnswer} toolbar={false} />
        <Space y={20} />
        <LoginInput
          id="env"
          type="text"
          value={userAgent}
          setValue={setUserAgent}
          name="환경"
        />
        <Space y={20} />
        {itemList.map((el, idx: number) => (
          <div key={el.category_item_no}>
            <Label labelfor={el.item_name} name={el.item_name} />
            <Input
              id={el.item_name}
              type="text"
              bottom={false}
              placeholder={`${el.item_name} 입력`}
              value={el.value || ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onChangeItemValue(e.currentTarget.value, idx)
              }
            />
            <Space y={16} />
          </div>
        ))}

        <Label labelfor="img" name="첨부파일" />
        <Space y={4} />
        <FileButton value={fileList} setValue={setFileList} size="md" max={3} />
        <Space y={8} />
        <div className="grid grid-cols-2 gap-8">
          <FileNameList fileList={fileList} setFileList={setFileList} />
        </div>

        <Space y={20} />
        <Button type="submit" btnText="보내기" full />
      </form>
    </Background>
  );
};

export default RegistModal;
