import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { ColumnsType } from "antd/es/table";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import { GetAddupNotice } from "types/interface";
import { getAddupNoticeApi } from "Apis/apis";
import { order } from "Util/function";
import LANG, { getLang } from "Util/lang";
import { setNotice } from "Store/Slices/addupNotice";
import { ReducerType } from "Store/store";

const AddupNotice = () => {
  const navigate = useNavigate();

  const [noticeList, setNoticeList] = useState<GetAddupNotice[]>([]);
  const notice = useSelector((state: ReducerType) => state.addupNotice);

  const getNotice = async () => {
    const {
      data: { admin_notice },
    } = await getAddupNoticeApi();
    setNoticeList(admin_notice.data.filter((el) => el.target !== 2));
  };

  useEffect(() => {
    getNotice();
  }, []);

  const columns: ColumnsType<GetAddupNotice> = [
    {
      title: LANG[getLang()].index,
      dataIndex: "admin_notice_no",
      align: "center",
      render: (now: number, row: GetAddupNotice) => {
        return order(noticeList, row.admin_notice_no, "admin_notice_no");
      },
    },
    {
      title: "제목",
      dataIndex: "title",
      align: "center",
      render: (now, row) => {
        return row.new_check === 0 ? (
          <div className="flex justify-center gap-10">
            <span
              className="cursor-pointer"
              onClick={() =>
                navigate("/infomation/detailnotice", {
                  state: row.admin_notice_no,
                })
              }
            >
              {now}
            </span>
            <div className=" min-w-24 h-24 rounded-[50%] bg-[#ff4947] text-white">
              N
            </div>
          </div>
        ) : (
          <span
            className="cursor-pointer"
            onClick={() =>
              navigate("/infomation/detailnotice", {
                state: row.admin_notice_no,
              })
            }
          >
            {now}
          </span>
        );
      },
    },
    {
      title: LANG[getLang()].date,
      dataIndex: "date",
      align: "center",
    },
  ];
  return (
    <div className="w-4/6">
      <Table
        columns={columns}
        dataSource={noticeList}
        rowKey="admin_notice_no"
      />
    </div>
  );
};

export default AddupNotice;
