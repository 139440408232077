import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { StarPoint } from "types/interface";
import { sum } from "Util/function";
import LIST from "./color";

const StarBar = ({ data }: { data: StarPoint[] }) => {
  const [dataList, setDataList] = useState<number[]>([]);

  const options = {
    indexAxis: "y" as const,
    maintainAspectRatio: false, // 캔버스의 종횡비를 유지하지 않음

    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        labels: {
          font: { size: 15, weight: "bold" },
        } as any,
        position: "bottom" as const,
      },
      title: {
        display: true,
        text: "별점 리뷰 현황",
        font: { size: 30 },
        padding: 30,
      },
      datalabels: {
        display: true,
        color: "black",
        align: "right",

        font: {
          size: 14,
        },
        formatter: (value: number) => {
          //데이터 값이 0 이면 출력 안함
          if (value === 0) {
            return null;
          }
        },
      } as any,
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  const labels = data.map((el) => el.cs_name);

  const dataInfo = {
    labels,
    datasets: [
      {
        label: "⭐",
        data: data.map((el) => el.one),
        backgroundColor: "red", // 첫 번째 라벨의 색상을 여기에 지정합니다.
      },
      {
        label: "⭐⭐",
        data: data.map((el) => el.two),
        backgroundColor: "green", // 두 번째 라벨의 색상을 여기에 지정합니다.
      },
      {
        label: "⭐⭐⭐",
        data: data.map((el) => el.three),
        backgroundColor: "blue", // 세 번째 라벨의 색상을 여기에 지정합니다.
      },
      {
        label: "⭐⭐⭐⭐",
        data: data.map((el) => el.four),
        backgroundColor: "orange", // 네 번째 라벨의 색상을 여기에 지정합니다.
      },
      {
        label: "⭐⭐⭐⭐⭐",
        data: data.map((el) => el.five),
        backgroundColor: "purple", // 다섯 번째 라벨의 색상을 여기에 지정합니다.
      },
    ],
  };
  const init = () => {
    const _dataList: number[] = [];
    const obj: { [index: string]: number } = {};

    const onlyPointArr: { [index: string]: number }[] = data.map((el) => {
      return {
        one: el.one,
        two: el.two,
        three: el.three,
        four: el.four,
        five: el.five,
      };
    });
    onlyPointArr.forEach((el) => {
      for (const key in el) {
        obj[key] = obj[key] ? el[key] + obj[key] : el[key];
      }
    });

    for (const key in obj) {
      _dataList.push(obj[key]);
    }
    setDataList(_dataList);
  };
  useEffect(() => {
    init();
  }, [data]);
  if (!data || sum(dataList) === 0) return null;
  return (
    <div id="starBar">
      <div style={{ height: `${200 * data.length}px` }}>
        <Bar options={options} data={dataInfo} plugins={[ChartDataLabels]} />
      </div>
    </div>
  );
};

export default StarBar;
