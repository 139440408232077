import { Dispatch, SetStateAction, useState } from "react";
import dayjs from "dayjs";

const useDatePicker = (
  start?: string
): [string[], Dispatch<SetStateAction<string[]>>] => {
  const DATE_FORMAT = "YYYY-MM-DD";
  const today = dayjs(new Date()).format(DATE_FORMAT);
  const startDate = dayjs(start).format(DATE_FORMAT) || "";
  const [dateRange, setDateRange] = useState<string[]>([
    startDate || today,
    today,
  ]);

  return [dateRange, setDateRange];
};

export const useMonthPicker = (): [
  string,
  Dispatch<SetStateAction<string>>
] => {
  const DATE_FORMAT = "YYYY-MM";
  const today = dayjs(new Date()).format(DATE_FORMAT);

  const [selectDay, setSelectDay] = useState<string>(today);
  return [selectDay, setSelectDay];
};

export const useYearPicker = (): [string, Dispatch<SetStateAction<string>>] => {
  const DATE_FORMAT = "YYYY";
  const today = dayjs(new Date()).format(DATE_FORMAT);

  const [selectDay, setSelectDay] = useState<string>(today);
  return [selectDay, setSelectDay];
};

export default useDatePicker;
