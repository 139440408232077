import Table, { ColumnsType } from "antd/es/table";
import {
  delCategoryItemApi,
  getCategoryItemApi,
  postCategoryItemApi,
} from "Apis/apis";
import Background from "Components/Atoms/Background/background";
import Button from "Components/Atoms/Button/button";
import H2 from "Components/Atoms/H2/h2";
import Input from "Components/Atoms/Input/input";
import Space from "Components/Atoms/Space/space";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { modalToggle } from "Store/Slices/modal";
import { CategoryItem } from "types/interface";
import LANG, { getLang } from "Util/lang";
import { ReactComponent as ModalX } from "Util/svg/modalX.svg";

export interface TESTTYPE {
  item: string;
  idx: number;
}

const test = [
  {
    item: "주문게정",
    idx: 1,
  },
  {
    item: "주문번호 TXID",
    idx: 2,
  },
];
const SmallCategoryModal = ({ selectMiddle }: { selectMiddle: number }) => {
  const dispatch = useDispatch();
  const [itemKeyword, setItmeKeyword] = useState("");
  const [categoryItem, setCategoryItem] = useState<CategoryItem[]>([]);

  const getCategoryItem = async () => {
    const {
      data: { category_item },
    } = await getCategoryItemApi(selectMiddle);
    setCategoryItem(category_item);
  };

  const postCategoryItem = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const body = {
      middleCategoryCode: selectMiddle,
      itemName: itemKeyword,
    };
    await postCategoryItemApi(body);
    setItmeKeyword("");
    getCategoryItem();
  };

  const deleteItem = async (itemNo: number) => {
    await delCategoryItemApi(itemNo);
    getCategoryItem();
  };

  useEffect(() => {
    getCategoryItem();
  }, [selectMiddle]);

  const columns: ColumnsType<CategoryItem> = [
    {
      title: "소 카테고리",
      dataIndex: "item_name",
      align: "center",
    },
    {
      title: "소 카테고리 삭제",
      dataIndex: "category_item_no",
      align: "center",
      width: "130px",
      render: (now) => (
        <Button
          btnText={LANG[getLang()].delete}
          size="sm"
          color="delete"
          onClick={() => deleteItem(now)}
        />
      ),
    },
  ];

  return (
    <Background modalNum={5}>
      <div style={{ width: "640px" }} className="p-32 bg-f2f3f8">
        <div className="flex justify-between items-center">
          <H2 isPrimary={true} name="소 카테고리 관리" />
          <div
            className="inline-block cursor-pointer"
            onClick={() => dispatch(modalToggle(5))}
          >
            <ModalX />
          </div>
        </div>
        <Space y={20} />
        <form className="flex " onSubmit={postCategoryItem}>
          <Input
            id="telegramKeyword"
            value={itemKeyword}
            onChange={(e) => {
              setItmeKeyword(e.target.value);
            }}
            width={70}
            placeholder="소 카테고리 입력"
          />
          <Space x={20} />
          <Button btnText={LANG[getLang()].register} type="submit" />
        </form>
        <Space y={32} />
        <Table
          rowKey="tel_key_no"
          columns={columns}
          dataSource={categoryItem}
          pagination={{ defaultPageSize: 5 }}
        />
      </div>
    </Background>
  );
};

export default SmallCategoryModal;
