import Button from "Components/Atoms/Button/button";
import Label from "Components/Atoms/Label/label";
import RequireText from "Components/Atoms/RequireText/requireText";
import Space from "Components/Atoms/Space/space";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import InputText from "Components/Molecules/InputText/inputText";
import { getAddupNoticeDetailApi } from "Apis/apis";
import { GetDetailAddupNotice } from "types/interface";
import parse from "html-react-parser";
import LANG, { getLang } from "Util/lang";
import FileLIst from "Components/Atoms/FileList/fileList";
import { useSelector } from "react-redux";
import { ReducerType, useAppDispatch } from "Store/store";
import { setUrl } from "Store/Slices/imgModalUrl";
import { modalToggle } from "Store/Slices/modal";
import ImgModal from "Components/Molecules/ImgModal/imgModal";
import LabelQuill from "Components/Molecules/LabelQuill/labelQuill";

const DetailNotice = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const imgModalUrl = useSelector((state: ReducerType) => state.imgModalUrl);
  const [text, setText] = useState("");
  const [notice, setNotice] = useState<GetDetailAddupNotice>(
    {} as GetDetailAddupNotice,
  );

  const detailNotice = async () => {
    const {
      data: { admin_notice },
    } = await getAddupNoticeDetailApi(location.state);
    setNotice(admin_notice);
  };

  const onClickBtn = (url: string) => {
    dispatch(setUrl(url));
    dispatch(modalToggle(9));
  };

  useEffect(() => {
    detailNotice();
  }, [location.state]);
  return (
    <>
      <div className="rounded bg-ffffff">
        <InputText text={notice.title} name={LANG[getLang()].title} />
        <div>
          <LabelQuill
            id="label"
            name="내용"
            setText={setText}
            text={notice.contents}
            readOnly={true}
            toolbar={false}
          />
        </div>
        <div className="flex items-center bg-white">
          <div className="flex items-center self-stretch px-16 py-20 bg-e5e6ed w-130">
            <Label labelfor="label" name="첨부파일" fontSize={16} />
          </div>
          <div className="flex-1 h-full bg-white p-13 ">
            <FileLIst
              onClick={onClickBtn}
              type={0}
              fileList={notice.attached_file}
              fileNameList={notice.original_filename}
            />
          </div>
        </div>
      </div>
      <div className="flex justify-end gap-10 mt-10 ">
        <Button
          btnText={LANG[getLang()].list}
          onClick={() => navigate("/infomation/addupnotice")}
        />
      </div>

      <ImgModal url={imgModalUrl} />
    </>
  );
};

export default DetailNotice;
