import Button from "Components/Atoms/Button/button";
import Select from "Components/Atoms/Select/select";
import Space from "Components/Atoms/Space/space";

import React, { useEffect, useState } from "react";
import LANG, { getLang } from "Util/lang";
import { useLocation, useNavigate } from "react-router-dom";
import Account from "./account";
import Ticket from "Pages/ServiceCenter/QnaCenterSetting/ticket";
import StarPointReview from "./starPointReview";

const PAGE_TYPE = [
  {
    idx: 1,
    label: "등록",
  },
  {
    idx: 2,
    label: "티켓 할당",
  },
  {
    idx: 3,
    label: "별점 리뷰",
  },
];
const AccountSetting = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [page, setPage] = useState(1);
  const selectButton = (e: number) => {
    navigate("", {
      state: { pageNo: e, serviceNo: -1 },
    });
  };
  const selectPage = (page: number) => {
    switch (page) {
      case 1:
        return <Account />;
      case 2:
        return <Ticket />;
      case 3:
        return <StarPointReview />;
    }
  };
  useEffect(() => {
    setPage(location.state?.pageNo || 1);
  }, [location.state]);
  return (
    <div>
      <div className="relative z-20 flex">
        <div className="flex gap-19">
          {PAGE_TYPE.map((item) => {
            return (
              <Button
                key={item.idx}
                btnText={item.label}
                size={"md"}
                color={item.idx === page ? "primary" : "normal"}
                onClick={() => selectButton(item.idx)}
              />
            );
          })}
        </div>
      </div>
      <Space y={20} /> {selectPage(page)}
    </div>
  );
};

export default AccountSetting;
