import { ReactComponent as StarSVG } from "Util/svg/star.svg";

const StarPoin = ({ starPoint }: { starPoint: number }) => {
  return (
    <>
      {Array.from(Array(5)).map((el: any, idx: number) => (
        <StarSVG key={idx} fill={starPoint > idx ? "#1C274C" : "#fff"} />
      ))}
    </>
  );
};

export default StarPoin;
