import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReducerType } from "Store/store";
import { useLocation, useNavigate } from "react-router-dom";
import { DailyLog, TicketLog, User } from "types/interface";

const Index = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user: User = useSelector((state: ReducerType) => state.user);

  useEffect(() => {
    if (user.level === 1) {
      navigate("/dashboard");
      return;
    }

    if (user.level > 1) {
      navigate("/user/ctqna");
      return;
    }

    navigate("/login");
  }, []);

  if (user.idx === -1) return null;
  return <div></div>;
};

export default Index;
