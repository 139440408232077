import { useEffect, useState } from "react";
import { ReducerType, useAppDispatch } from "Store/store";
import Answer from "./answer";
import { ctMode, init } from "Store/Slices/layouts";
import QuestionList from "Components/Organisms/QuestionLIst/questionList";
import { User } from "types/interface";
import Button from "Components/Atoms/Button/button";
import Space from "Components/Atoms/Space/space";
import { useSelector } from "react-redux";
import LANG, { getLang } from "Util/lang";
import { useGetConsultantDetailApi } from "Apis/apis";
import TextModal from "Components/Molecules/TextModal/textModal";
import { ReactComponent as MessegeSVG } from "Util/svg/messege.svg";
import { ReactComponent as EditSVG } from "Util/svg/edit.svg";
import { ReactComponent as PaperPlaneSVG } from "Util/svg/paperPlane.svg";
import { modalToggle } from "Store/Slices/modal";

const QnA = () => {
  const dispatch = useAppDispatch();

  const user: User = useSelector((state: ReducerType) => state.user);
  const qIdx = useSelector((state: ReducerType) => state.qIdx);

  const { data } = useGetConsultantDetailApi(user.idx);

  const [mode, setmode] = useState(0); // 0: 기본, 1: 질문리스트접음
  useEffect(() => {
    dispatch(ctMode());

    return () => {
      if (user.level === 1) {
        dispatch(init());
      }
    };
  }, []);

  return (
    <div id="qna">
      <div className="flex justify-end text-right">
        <Button
          onClick={() => {
            dispatch(modalToggle(-1));
          }}
          btnText={
            <div className="flex items-center">
              <MessegeSVG />
              <Space x={4} />
              SMS
            </div>
          }
          size="sm"
        />

        <Space x={12} />
        <Button
          onClick={() => dispatch(modalToggle(-6))}
          btnText={
            <div className="flex items-center">
              <EditSVG stroke="white" />
              <Space x={4} />
              등록하기
            </div>
          }
          size="sm"
        />
        <Space x={12} />
        <Button
          onClick={() => dispatch(modalToggle(-7))}
          btnText={
            <div className="flex items-center">
              <PaperPlaneSVG />
              <Space x={4} />
              새메일쓰기
            </div>
          }
          size="sm"
        />
        <Space x={12} />
        <Button
          onClick={() => setmode(mode === 0 ? 1 : 0)}
          btnText={LANG[getLang()].fold}
          size="sm"
        />
      </div>
      <Space y={20} />
      <div id="qna" className="flex">
        <div className={`${mode === 0 ? "w-full" : "w-0"} transition-all`}>
          <QuestionList />
        </div>
        <div className="w-full transition-all">{qIdx !== -1 && <Answer />}</div>
      </div>
      <TextModal />
    </div>
  );
};

export default QnA;
