import React, { useState, useEffect } from "react";
import QnaInputLabel from "Components/Molecules/QnaInputLabel/qnaInputLabel";
import Space from "Components/Atoms/Space/space";
import {
  getConsultantListApi,
  putAssignmentApi,
  useGetQDetailApi,
} from "Apis/apis";
import Button from "Components/Atoms/Button/button";
import { ReducerType, useAppDispatch } from "Store/store";
import { toCheckboxForm } from "Util/function";

import { modalToggle } from "Store/Slices/modal";
import { maskingName } from "Util/function";
import MaskingModal from "Components/Molecules/MaskingModal/maskingModal";
import Select from "Components/Atoms/Select/select";
import {
  ClickInputForm,
  GetQnAListApiBody,
  QDetail,
  ReduxQnAOption,
} from "types/interface";
import CGModal from "Components/Molecules/CGModal/cgModal";
import QnaInputTextBtn from "Components/Molecules/QnaInputTextBtn/qnaInputTextBtn";
import QnaLabel from "Components/Atoms/QnaLabel/qnaLabel";
import AnswerHistoryList from "Components/Molecules/AnswerHistory/answerHistory";
import PreviewModal from "Components/Molecules/PreviewModal/previewModal";

import LANG, { getLang } from "Util/lang";
import { useSelector } from "react-redux";
import TranceBtnList from "Components/Molecules/TranceBtn/tranceBtn";
import ImgModal from "Components/Molecules/ImgModal/imgModal";
import { useQueryClient } from "@tanstack/react-query";
import { Tooltip } from "antd";
import { setTrans } from "Store/Slices/translate";
import UserInfoModal from "Components/Molecules/UserInfoModal/userInfoModal";
import { setSmsNumber } from "Store/Slices/smsNumber";
import { levelCheck } from "Store/Slices/user";
import MemoListModal from "Components/Molecules/MemoListModal/memoListModal";

const QnaLecordForm = ({
  serviceNo,
  reply,
  setserviceNo,
  setReply,
}: {
  reply: string;
  serviceNo: number;
  setserviceNo: React.Dispatch<React.SetStateAction<number>>;
  setReply: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const dispatch = useAppDispatch();
  const user = useSelector((state: ReducerType) => state.user);
  const qIdx = useSelector((state: ReducerType) => state.qIdx);
  const imgModalUrl = useSelector((state: ReducerType) => state.imgModalUrl);
  const serviceList = useSelector((state: ReducerType) => state.serviceList);
  const queryClient = useQueryClient();
  const { date, ...searchoption }: ReduxQnAOption = useSelector(
    (state: ReducerType) => state.qnaSearchOption,
  );

  const [qna, setQna] = useState<QDetail>({} as QDetail);
  const [masking, setMasking] = useState(true);
  const [model, setModel] = useState("");
  const [path, setPath] = useState("");
  const [ip, setIp] = useState("");
  const [more1, setMore1] = useState("");
  const [more2, setMore2] = useState("");
  const [more3, setMore3] = useState("");
  const [CTList, setCTList] = useState<ClickInputForm[]>([]);
  const [selectedCT, setSelectedCT] = useState(-1);
  const [itemObj, setItemObj] = useState<{ [index: string]: string }>({});

  const { data } = useGetQDetailApi(qIdx);
  const userId = data?.qna.user_id || "";
  const queryQna = data?.qna;

  // 질문 상세 setState
  const getQDetail = async () => {
    if (qIdx === -1 || !queryQna) return;
    // 문의상세
    setQna(queryQna);
    setserviceNo(queryQna.service_no);
    setModel(queryQna.device_info);
    setPath(queryQna.user_agent);
    setMore1(queryQna.opt1);
    setMore2(queryQna.opt2);
    setMore3(queryQna.opt3);
    const items = JSON.parse(queryQna.item);
    setItemObj(items);
    setIp(queryQna.ip_address);

    // 최종답변
    const finalAnswer = queryQna.reply.find(
      (answer) => answer.state === 0,
    )?.contents;
    setReply(finalAnswer || "");

    // 상담사리스트 (할당)
    const {
      data: { cs },
    } = await getConsultantListApi(0, "", queryQna.service_no);

    const ctListApi: ClickInputForm[] = [
      { idx: -1, label: LANG[getLang()].select },
      ...toCheckboxForm(cs, "cs_no", "cs_name"),
    ];

    //본인선택
    setSelectedCT(user.clone_idx || user.idx);
    setCTList(ctListApi);
  };

  // 폰번호 확인 사유 모달
  const openReasonModal = () => {
    if (!masking) return;
    dispatch(modalToggle(6));
  };

  // 유저에게 sms보내기
  const onClickSms = () => {
    if (masking) {
      alert("핸드폰번호 확인 후 진행 해주세요");
      return;
    }
    dispatch(
      setSmsNumber({
        phoneNumber: qna.phone_number,
        serviceNo: qna.service_no,
      }),
    );
    dispatch(modalToggle(-1));
  };

  // 할당
  const assignment = async () => {
    if (selectedCT === -1) {
      alert("상담원을 선택 해주세요");
      return;
    }
    const confirm = window.confirm("할당 하시겠습니까?");
    if (!confirm) return;

    const body = {
      csNo: selectedCT,
    };
    if (queryQna?.cs_no === selectedCT) {
      // 선택된 상담원
      const CTName = CTList.find((el) => el.idx === selectedCT)?.label;
      alert(`이미 ${CTName}에게 할당된 문의입니다.`);
      return;
    }

    await putAssignmentApi(qIdx, body);
    const reduxBody: GetQnAListApiBody = {
      startDate: date[0],
      endDate: date[1],
      ...searchoption,
    };
    queryClient.invalidateQueries(["QnaListApi", reduxBody]);
    queryClient.invalidateQueries(["useGetQDetailApi", qIdx]);
  };

  // 번역모달
  const onTranslation = (transed: string) => {
    dispatch(modalToggle(13));
    dispatch(
      setTrans({
        // p태그는 줄바꿈 및 복사할때 줄바꿈을 유지하기 위해 적용 하였습니다.
        original: `<p>${qna.title}</p>${qna.contents}`,
        transed,
      }),
    );
  };

  useEffect(() => {
    setMasking(true);
  }, [qIdx]);
  useEffect(() => {
    getQDetail();
  }, [queryQna]);

  return (
    <div className="p-16 rounded bg-ffffff">
      {/* 상담원 */}
      <div className="flex items-center">
        <div className="flex-[1]">
          <QnaInputLabel
            name={LANG[getLang()].assistant}
            readonly
            id="account"
            value={qna.cs_name || ""}
          />
        </div>
        <div className="flex items-center ">
          <Select dataList={CTList} val={selectedCT} setVal={setSelectedCT} />
          <Button
            btnText={LANG[getLang()].assign}
            size="sm"
            onClick={() =>
              dispatch(
                levelCheck({
                  sl: "t03",
                  fuc: assignment,
                }),
              )
            }
            standardItem="t03"
          />
        </div>
      </div>

      <Space y={24} />
      {/* 카테고리 */}
      <div className="flex items-center justify-between">
        <div className="flex-[1]">
          <QnaInputLabel
            name={LANG[getLang()].category}
            readonly
            id="accountCategory"
            value={`${qna.big_category_name || ""} > ${
              qna.middle_category_name || ""
            }`}
          />
        </div>
        <Space x={12} />
        <Button
          btnText={LANG[getLang()].change}
          size="sm"
          onClick={() => dispatch(modalToggle(7))}
        />
      </div>

      <Space y={24} />
      {/* 문의 제목 */}
      <div className="flex">
        <Tooltip placement="top" title={qna.title}>
          <div className="flex-[1]">
            <QnaInputLabel
              name={LANG[getLang()].title}
              readonly
              id="accountmenu"
              value={qna.title || ""}
            />
          </div>
        </Tooltip>
      </div>
      <Space y={24} />

      {/* 폰번호 */}
      <div className="flex ">
        <div onClick={openReasonModal} className="flex-[1]">
          <QnaInputLabel
            name={LANG[getLang()].phoneNumber}
            id="accountPhone"
            value={masking ? maskingName(qna.phone_number) : qna.phone_number}
            readonly
          />
        </div>
        <Button
          btnText="sms"
          size="sm"
          onClick={() =>
            dispatch(
              levelCheck({
                sl: "q10",
                fuc: onClickSms,
              }),
            )
          }
          standardItem="q10"
        />
      </div>
      <Space y={24} />
      {/* 기종 */}
      <div className="flex-[2]">
        <QnaInputLabel
          name={LANG[getLang()].mobileDevice}
          id="accountDevice"
          value={model}
          readonly
        />
      </div>
      <Space y={24} />
      {/* 닉네임 */}
      <div className="flex justify-between">
        <div className="flex-[1]">
          <QnaInputLabel
            name={LANG[getLang()].nickName}
            readonly
            id="accountId"
            value={String(qna.user_name || "")}
          />
        </div>
        <Space x={8} />
        <Button
          onClick={() => {
            if (user.check_code.findIndex((el) => el === "c03") === -1) {
              return alert("상품을 업그레이드 해주세요.");
            }
            dispatch(modalToggle(15));
          }}
          btnText="고객 메모"
          size="sm"
          standardItem="c03"
        />
      </div>
      <Space y={20} />
      {/* UID */}
      <div className="">
        <QnaInputLabel name="UID" readonly id="uid" value={userId} />
      </div>
      <Space y={24} />
      <div className="flex-[3]">
        <QnaInputTextBtn
          serviceNo={serviceNo}
          fileList={qna.attached_file || []}
          name={LANG[getLang()].attachedFile}
          fileNameList={qna.original_filename}
        />
      </div>
      <Space y={24} />
      <div className="flex-[1]">
        <QnaInputLabel
          name={LANG[getLang()].pathCondition}
          readonly
          id="accountRoute"
          value={path}
        />
      </div>
      <Space y={24} />
      <div className="flex-[1]">
        <QnaInputLabel name="ip" readonly id="accountRoute" value={ip} />
      </div>
      <Space y={24} />

      {Object.keys(itemObj).map((item: string) => {
        return (
          <div key={item} className="flex mb-24">
            <div className="flex-[1]">
              <QnaInputLabel
                name={item}
                readonly
                id="accountRoute"
                value={itemObj[item]}
              />
            </div>
          </div>
        );
      })}

      <div className="flex-[1]">
        <QnaInputLabel
          name="세부 기재 항목1"
          readonly
          id="accountRoute"
          value={more1}
        />
      </div>
      <Space y={24} />
      <div className="flex-[1]">
        <QnaInputLabel
          name="세부 기재 항목2"
          readonly
          id="accountRoute"
          value={more2}
        />
      </div>
      <Space y={24} />
      <div className="flex-[1]">
        <QnaInputLabel
          name="세부 기재 항목3"
          readonly
          id="accountRoute"
          value={more3}
        />
      </div>
      <Space y={24} />

      <QnaLabel name={LANG[getLang()].assistanceDetail} />
      <TranceBtnList
        text={`<p>${qna.title}</p>${qna.contents}`}
        onClick={onTranslation}
      />
      <AnswerHistoryList qna={qna} />

      <MaskingModal qnaNo={qna.qna_no} setMasking={setMasking} />
      <CGModal serviceNo={serviceNo} qIdx={qIdx} />
      <PreviewModal
        qna={{ title: qna.title, contents: qna.contents, a: reply }}
      />
      <ImgModal url={imgModalUrl} />
      <UserInfoModal
        userId={userId}
        serviceName={
          serviceList.find((el) => el.idx === qna.service_no)?.label || ""
        }
        serviceNo={qna.service_no}
      />
    </div>
  );
};

export default QnaLecordForm;
