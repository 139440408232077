import { useEffect, useState } from "react";

import Background from "Components/Atoms/Background/background";
import H2 from "Components/Atoms/H2/h2";
import Space from "Components/Atoms/Space/space";
import { modalToggle } from "Store/Slices/modal";
import { ReducerType, useAppDispatch } from "Store/store";
import { ReactComponent as ModalX } from "Util/svg/modalX.svg";
import { GetMemoApi } from "Apis/apis";
import { useSelector } from "react-redux";
import { User } from "types/interface";

const MemoListModal = () => {
  const user: User = useSelector((state: ReducerType) => state.user);

  const dispatch = useAppDispatch();
  const { serviceNo, userId } = useSelector(
    (state: ReducerType) => state.memoListInfo,
  );

  const { data } = GetMemoApi(serviceNo, userId, user.product_level);

  return (
    <Background modalNum={-5}>
      <div
        style={{ width: "640px" }}
        className="max-h-[640px] overflow-y-scroll p-32 bg-f2f3f8"
      >
        <div className="flex justify-between items-center">
          <H2 isPrimary={true} name="메모내역" />
          <div
            className="inline-block cursor-pointer"
            onClick={() => dispatch(modalToggle(-5))}
          >
            <ModalX />
          </div>
        </div>
        <Space y={20} />
        <div className="grid grid-cols-1 gap-16">
          {data?.map((el) => (
            <section
              key={el.user_memo_no}
              className="px-20 py-16 text-right bg-white rounded"
            >
              <p className="text-left text-16">{el.memo}</p>
              <span className="text-787878 text-12">{el.date}</span>
            </section>
          ))}
        </div>
      </div>
    </Background>
  );
};

export default MemoListModal;
