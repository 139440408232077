import React, { useEffect, useState } from "react";
import type { ColumnsType } from "antd/es/table";
import SearchResult from "Components/Atoms/SearchResult/searchResult";
import Space from "Components/Atoms/Space/space";
import Select from "Components/Atoms/Select/select";
import SearchInput from "Components/Molecules/SearchInput/searchInput";
import { useLocation, useNavigate } from "react-router-dom";
import { GetLang } from "Util/lang";
import { getCsLogApi } from "Apis/apis";
import { CsLog, getCsLog } from "types/interface";
import DatePick from "Components/Atoms/DatePick/datePick";
import useDatePicker from "Util/Hooks/useDatePicker";
import useServiceNo from "Util/Hooks/useServiceIdx";
import { ReducerType } from "Store/store";
import { useSelector } from "react-redux";
import Table from "antd/es/table";
import { order } from "Util/function";

import ExcelButton from "Components/Molecules/ExcelButton/excelButton";
const EXCEL_OBJ = {
  idx: "번호",
  service_name: "서비스명",
  action: "작업",
  contents: "콘텐츠",
  cs_name: "상담원 이름",
  cs_id: "상담원 ID",
  ip_address: "IP",
  date: "날짜",
};

const Log = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const translate = GetLang();

  const [dateRange, setDateRange] = useDatePicker();
  const [logList, setLogList] = useState<CsLog[]>([]);
  const [serviceNo, setServiceNo] = useServiceNo();
  const serviceList = useSelector((state: ReducerType) => state.serviceList);
  const [search, setSearch] = useState("");

  const onSubmit = () => {
    navigate("", {
      state: {
        first: dateRange[0],
        second: dateRange[1],
        search: search,
      },
    });
  };

  const getCsList = async () => {
    const {
      first = dateRange[0],
      second = dateRange[1],
      search = "",
    } = location.state || {};

    const firstDay = first;
    const secondDay = second;
    setSearch(search);
    setDateRange([first, second]);
    const option: getCsLog = {
      startDate: firstDay,
      endDate: secondDay,
      csId: search,
      serviceNo,
    };
    const {
      data: { log },
    } = await getCsLogApi(option);

    setLogList(
      log
        .filter((el) => el.service_name !== null)
        .map((log: CsLog, idx: number) => {
          const _noteice = { ...log };
          _noteice.idx = idx + 1;
          return _noteice;
        })
    );
  };

  useEffect(() => {
    getCsList();
  }, [location.state]);

  const columns: ColumnsType<CsLog> = [
    {
      title: translate("index"),
      dataIndex: "cs_no",
      align: "center",
      render: (now, row) => {
        return order(logList, row.idx, "idx");
      },
    },
    {
      title: translate("serviceName"),
      dataIndex: "service_name",
      align: "center",
      render: (now) => now,
    },

    {
      title: translate("work"),
      dataIndex: "action",
      align: "center",
    },
    {
      title: "콘텐츠",
      dataIndex: "contents",
      align: "center",
    },
    {
      title: "상담원 이름(상담원ID)",
      dataIndex: "cs_id",
      align: "center",
      render: (now, row) => {
        return now === null ? "" : `${row.cs_name} / ${now}`;
      },
    },
    {
      title: "IP",
      dataIndex: "ip_address",
      align: "center",
    },
    {
      title: translate("date"),
      dataIndex: "date",
      align: "center",
    },
  ];

  return (
    <div id="log">
      <div className="flex ">
        <DatePick dateRange={dateRange} setDateRange={setDateRange} />
        <Space x={9} />
        <Select dataList={serviceList} val={serviceNo} setVal={setServiceNo} />
        <Space x={9} />
        <SearchInput
          size="md"
          value={search}
          setValue={setSearch}
          placeholder={"상담원 이름 검색"}
          onSubmit={onSubmit}
        />
      </div>
      <Space y={32} />
      <div className="flex justify-between">
        <SearchResult max={logList.length} />
        <ExcelButton
          data={logList}
          obj={EXCEL_OBJ}
          fileName={`${dateRange} 상담원Log`}
        />
      </div>
      <Space y={16} />
      <Table
        columns={columns}
        dataSource={logList}
        rowKey="idx"
        pagination={{ defaultPageSize: 10 }}
      />
    </div>
  );
};

export default Log;
