import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: number = -1;

export const qIdx = createSlice({
  name: "qIdx",
  initialState,
  reducers: {
    setQIdx: (state, action: PayloadAction<number>) => {
      return action.payload;
    },
  },
});

export const { setQIdx } = qIdx.actions;
export default qIdx.reducer;
