import { DatePicker } from "antd";
import { DatePickT } from "types/interface.d";
import dayjs from "dayjs";
import locale from "antd/es/date-picker/locale/ko_KR";
import "dayjs/locale/ko";
const DatePick = ({ dateRange, setDateRange }: DatePickT) => {
  const { RangePicker } = DatePicker;

  const choiceDate = (
    _: null | (dayjs.Dayjs | null)[],
    dateString: string[],
  ) => {
    setDateRange(dateString);
  };

  return (
    <RangePicker
      value={[dayjs(dateRange[0]), dayjs(dateRange[1])]}
      allowEmpty={[false, false]}
      allowClear={false}
      onChange={choiceDate}
      locale={locale}
    />
  );
};

export default DatePick;
