import { useState } from "react";

import Background from "Components/Atoms/Background/background";
import H2 from "Components/Atoms/H2/h2";
import Space from "Components/Atoms/Space/space";
import Button from "Components/Atoms/Button/button";
import { modalToggle } from "Store/Slices/modal";
import { useAppDispatch } from "Store/store";
import { ReactComponent as ModalX } from "Util/svg/modalX.svg";
import { payCancleApi } from "Apis/apis";
import { logout } from "Store/Slices/user";
import { deleteServiceList } from "Store/Slices/serviceList";

const CancleModal = ({ date }: { date: string }) => {
  const year = date.split("-")[0];
  const month = date.split("-")[1];
  const day = date.split("-")[2];
  const dispatch = useAppDispatch();
  const payCancle = async () => {
    const confirm = window.confirm("상품을 해지하시겠습니까?");
    if (!confirm) return;
    try {
      await payCancleApi();
      alert("상품 구독 취소가 완료되었습니다.");
      dispatch(modalToggle(1));
      dispatch(logout());
      dispatch(deleteServiceList());
      window.location.href = "https://csbye.com/";
    } catch {
      alert("상품 구독 취소를 실패하셨습니다.");
    }
  };
  return (
    <Background modalNum={1}>
      <div style={{ width: "640px" }} className="p-32 bg-f2f3f8">
        <div className="flex justify-between items-center">
          <H2 isPrimary={true} name="상품 자동 결제 해지" />
          <div
            className="inline-block cursor-pointer"
            onClick={() => dispatch(modalToggle(1))}
          >
            <ModalX />
          </div>
        </div>
        <Space y={20} />
        <span className="font-bold text-14 text-primary">{`서비스는 최근 결제 샹품 기간인 ${year}년 ${month}월 ${day}일 까지 사용할 수 있습니다.`}</span>
        <Space y={10} />
        {/* <a
          className="font-bold text-14 text-black border-b-2 border-black hover:text-blue-600 hover:border-blue-500 "
          href="https://www.naver.com/"
          target="_blank"
        >{`정기 결제 중도 해지 부분 환불 OR 전체 환불 관련 안내 사항`}</a> */}
        <Space y={30} />
        <div className="flex justify-center">
          <Button btnText="해지하기" onClick={payCancle} />
          <Space x={8} />
          <Button btnText="취소" onClick={() => dispatch(modalToggle(1))} />
        </div>
      </div>
    </Background>
  );
};

export default CancleModal;
