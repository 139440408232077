import {
  ReactNode,
  useRef,
  ChangeEvent,
  Dispatch,
  SetStateAction,
} from "react";

/**
 * 버튼 atomic
 * @param {string} name 텍스트
 * @param {'xl'|'sm'} size 사이즈 (xl)
 * @param {boolean} full Width 100% (false)
 * @param {boolean} type 백그라운드 배경색상(primary)
 * @param {boolean} grow flex-grow 여부(false)
 */
const FileButton = ({
  size = "xl",
  value,
  setValue,
  max = 1,
}: {
  value: File[];
  setValue: Dispatch<SetStateAction<File[]>>;
  size?: "xl" | "md" | "sm";
  max?: number;
}) => {
  const imgUpload = useRef<HTMLInputElement>(null); // 파일업로드 input
  const sizeStyle =
    size === "xl"
      ? "px-24 leading-46 text-16 font-bold"
      : size === "md"
      ? "px-18 leading-38 text-14"
      : "px-20 leading-24 text-14";

  const getfile = (e: ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files!;
    const fileSize = fileList[0].size;
    const maxSize = 10 * 1024 * 1024;
    if (fileSize > maxSize)
      return alert("10MB를 초과하는 파일은 지원하지않습니다.");

    const fileLength = Object.keys(fileList).length; // 선택한 파일 갯수

    if (fileLength + value.length > max) {
      alert(`파일을 ${max}개 이하로 선택 해주세요`);
      return;
    }
    for (const key in fileList) {
      const file = fileList[key];

      if (typeof file !== "object") break;

      setValue((value) => {
        return value.concat(file);
      });
    }
  };

  return (
    <>
      <button
        type="button"
        onClick={() => imgUpload.current?.click()}
        className={`${sizeStyle} bold rounded bg-primary text-white`}
      >
        파일 선택
      </button>
      <input
        multiple
        max={3}
        className="hidden opacity-0"
        onChange={(e) => getfile(e)}
        ref={imgUpload}
        type="file"
        accept=".png, .jpg, .jpeg, .hwp, .pdf, .xlsx, .xlx, .txt"
      />
    </>
  );
};

export default FileButton;
