import Background from "Components/Atoms/Background/background";
import H2 from "Components/Atoms/H2/h2";
import Space from "Components/Atoms/Space/space";
import { modalToggle } from "Store/Slices/modal";
import { useAppDispatch } from "Store/store";
import { ReactComponent as ModalX } from "Util/svg/modalX.svg";

const StarModal = ({ review }: { review: string }) => {
  const dispatch = useAppDispatch();

  return (
    <Background modalNum={14}>
      <div style={{ width: "640px" }} className="p-32 bg-f2f3f8">
        <div className="flex justify-between items-center">
          <H2 isPrimary={true} name="별점 리뷰" />

          <div
            className="inline-block cursor-pointer"
            onClick={() => dispatch(modalToggle(14))}
          >
            <ModalX />
          </div>
        </div>
        <Space y={20} />
        <p>{review}</p>
      </div>
    </Background>
  );
};

export default StarModal;
