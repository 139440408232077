import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Translation, User } from "types/interface";

const initialState = {
  original: "",
  transed: "",
} as Translation;

export const translate = createSlice({
  name: "translate",
  initialState,
  reducers: {
    setTrans: (state, action: PayloadAction<Translation>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setTrans } = translate.actions;
export default translate.reducer;
