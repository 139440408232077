import { useState, useEffect } from "react";
import type { ColumnsType } from "antd/es/table";

import { Table } from "antd";

import { getCompanyProductApi, getConsultantListApi } from "Apis/apis";
import Button from "Components/Atoms/Button/button";
import { Link } from "react-router-dom";
import SearchResult from "Components/Atoms/SearchResult/searchResult";
import SearchRowRadio from "Components/Molecules/SearchRowRadio/searchRowRadio";
import Space from "Components/Atoms/Space/space";
import Tag from "Components/Atoms/Tag/tag";
import Select from "Components/Atoms/Select/select";

import { useLocation, useNavigate } from "react-router-dom";
import { CT } from "types/interface";
import LANG, { getLang } from "Util/lang";
import { order } from "Util/function";
import { useSelector } from "react-redux";
import { ReducerType } from "Store/store";
import useServiceNo from "Util/Hooks/useServiceIdx";
import SearchInput from "Components/Molecules/SearchInput/searchInput";

interface DataType {
  cs_no: number;
  cs_name: string;
  cs_id: string;
  service_name: string;
  state: number;
  date: string;
}

const Account = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const serviceList = useSelector((state: ReducerType) =>
    state.serviceList.filter((el) => el.state !== 2 && el.state !== 1)
  );

  const [serviceNo, setServiceNo] = useServiceNo();
  const [consultantList, setConsultantList] = useState<CT[]>([]);
  const [sheet, setSheet] = useState(0);
  const [csCount, setCsCount] = useState(0);
  const [search, setSearch] = useState("");

  const getConsultantList = async () => {
    const { serviceNo = -1, searching = "" } = location.state || {};

    setSearch(searching);
    const {
      data: { cs },
    } = await getConsultantListApi(-1, searching, serviceNo, 2);
    setConsultantList(cs);
  };
  const getProduct = async () => {
    const {
      data: { product },
    } = await getCompanyProductApi();
    setSheet(product.sheet_count);
    setCsCount(product.cs_count);
  };

  const onSubmit = () => {
    navigate("", {
      state: {
        pageNo: 1,
        serviceNo: serviceNo,
        searching: search,
      },
    });
  };

  const goFixAccount = (cs_No: number) => {
    navigate(`/account/accountfix/${cs_No}`, {
      state: cs_No,
    });
  };
  const columns: ColumnsType<DataType> = [
    {
      title: LANG[getLang()].index,
      dataIndex: "cs_no",
      align: "center",
      render: (now, row) => order(consultantList, row.cs_no, "cs_no"),
    },
    {
      title: LANG[getLang()].assistantID,
      dataIndex: "cs_id",
      align: "center",
      render: (now, row) => {
        return (
          <span
            className="cursor-pointer"
            onClick={() => goFixAccount(row.cs_no)}
          >
            {now}
          </span>
        );
      },
    },
    {
      title: LANG[getLang()].name,
      dataIndex: "cs_name",
      align: "center",
    },
    {
      title: "LEVEL",
      dataIndex: "level",
      align: "center",
      render: (now) => (now ? "Lv 1(일반)" : "Lv 2(프로)"),
    },
    {
      title: "서비스명",
      dataIndex: "service_name",
      align: "center",
      render: (now) => {
        const accountService = now.split(",");

        const lastService = serviceList
          .filter((el) => accountService.includes(el.label))
          .filter((el2) => el2.state === 0)
          .map((el3) => el3.label)
          .join(",");

        return lastService;
      },
    },
    {
      title: "상태",
      dataIndex: "state",
      align: "center",
      render: (now: number) => {
        if (now === 1)
          return <Tag type="ready" name={LANG[getLang()].waiting} />;
        else return <Tag name="승인" />;
      },
    },
    {
      title: "등록 날짜",
      dataIndex: "date",
      align: "center",
    },
  ];

  useEffect(() => {
    getConsultantList();
    setSearch(location.state?.searching);
    getProduct();
  }, [location.state]);

  return (
    <div id="account">
      <div className="flex">
        <Select dataList={serviceList} val={serviceNo} setVal={setServiceNo} />
        <Space x={20} />
        <SearchInput
          onSubmit={onSubmit}
          value={search || ""}
          setValue={setSearch}
          size="xl"
          require={false}
        />
      </div>
      <Space y={16} />
      <div className="flex items-center justify-between mb-15">
        <span className="text-16 font-bold self-end text-[#1c274c]">
          {`관리자 1명 + 현재 승인된 팀원 ( ${csCount} ) 명 / 현재 시트 ( ${sheet} ) 석  `}
        </span>
        <Link to="/account/accountadd">
          <Button
            btnText={LANG[getLang()].assistantaccountRegisteration}
          ></Button>
        </Link>
      </div>
      <Table
        rowKey="cs_no"
        columns={columns}
        dataSource={consultantList}
        pagination={{ showSizeChanger: false, defaultPageSize: 10 }}
      />
    </div>
  );
};

export default Account;
