import { useState } from "react";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";

import Background from "Components/Atoms/Background/background";
import H2 from "Components/Atoms/H2/h2";
import Space from "Components/Atoms/Space/space";
import Button from "Components/Atoms/Button/button";
import { modalToggle } from "Store/Slices/modal";
import { ReducerType, useAppDispatch } from "Store/store";
import { ReactComponent as ModalX } from "Util/svg/modalX.svg";
import Input from "Components/Atoms/Input/input";
import { Telegram, TelegramKeyword, User } from "types/interface";
import {
  delTelegramKeywordApi,
  postTelegramKeywordApi,
  useGetAlarmListApi,
} from "Apis/apis";
import { useQueryClient } from "@tanstack/react-query";
import LANG, { getLang } from "Util/lang";
import { useSelector } from "react-redux";

const KeywordModal = ({ serviceNo }: { serviceNo: number }) => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const { data } = useGetAlarmListApi();
  const { limit_keyword }: User = useSelector(
    (state: ReducerType) => state.user,
  );
  const keywordList = data?.find(
    (el) => el.service_no === serviceNo,
  )?.alarm_keyword;
  console.log(keywordList);
  const [keyword, setKeyword] = useState("");

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!keywordList) return;
    if (keyword === "") {
      alert("키워드를 입력 해주세요");
      return;
    }

    if (keywordList?.length >= limit_keyword && limit_keyword !== -999) {
      alert("상품을 업그레이드 해주세요");
      return;
    }
    const body = {
      serviceNo: serviceNo,
      alarmKeywordList: [keyword],
    };
    await postTelegramKeywordApi(body);
    setKeyword("");
    queryClient.invalidateQueries(["useGetAlarmListApi"]);
  };

  const onClickDel = async (idx: number) => {
    const confirm = window.confirm("삭제 하시겠습니까?");
    if (!confirm) return;
    await delTelegramKeywordApi(idx);
    queryClient.invalidateQueries(["useGetAlarmListApi"]);
  };

  const columns: ColumnsType<TelegramKeyword> = [
    {
      title: "키워드명",
      dataIndex: "keyword",
      align: "center",
    },
    {
      title: "키워드 관리",
      dataIndex: "tel_key_no",
      align: "center",
      width: "130px",
      render: (now) => (
        <Button
          btnText={LANG[getLang()].delete}
          size="sm"
          color="delete"
          onClick={() => onClickDel(now)}
        />
      ),
    },
  ];

  return (
    <Background modalNum={1}>
      <div style={{ width: "640px" }} className="p-32 bg-f2f3f8">
        <div className="flex items-center justify-between">
          <H2 isPrimary={true} name="텔레그램 키워드 관리" />
          <div
            className="inline-block cursor-pointer"
            onClick={() => dispatch(modalToggle(1))}
          >
            <ModalX />
          </div>
        </div>

        <Space y={20} />
        <form className="flex" onSubmit={onSubmit}>
          <Input
            id="telegramKeyword"
            value={keyword}
            onChange={(e) => {
              setKeyword(e.target.value);
            }}
            width={70}
            placeholder="키워드 입력"
          />
          <Space x={20} />
          <Button btnText={LANG[getLang()].register} type="submit" />
        </form>
        <Space y={32} />
        <Table
          rowKey="tel_key_no"
          columns={columns}
          dataSource={keywordList}
          pagination={{ defaultPageSize: 5 }}
        />
      </div>
    </Background>
  );
};

export default KeywordModal;
