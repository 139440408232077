/**
 * 검색창 인풋 atomic
 * @param {string} placeholder 정의어 ("")
 * @param {number} width 가로너비 (100)
 * @param {xl | md } size 검색창크기 (xl)
 */

const QuerySearchInput = ({
  placeholder = "",
  size = "xl",
  refInput,
}: {
  placeholder?: string;
  width?: number;
  size?: "xl" | "md";
  refInput: React.RefObject<HTMLInputElement>;
}) => {
  return (
    <input
      ref={refInput}
      type="text"
      placeholder={placeholder}
      className={`bg-#ffffff border-solid rounded text-14 w-444 pl-16 ${
        size === "xl" ? "leading-46" : "leading-38"
      }`}
      size={1}
    />
  );
};

export default QuerySearchInput;
