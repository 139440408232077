import Background from "Components/Atoms/Background/background";
import H2 from "Components/Atoms/H2/h2";
import Quill from "Components/Atoms/Quill/quill";
import Space from "Components/Atoms/Space/space";
import { modalToggle } from "Store/Slices/modal";
import { useAppDispatch } from "Store/store";
import { ReactComponent as ModalX } from "Util/svg/modalX.svg";
import parse from "html-react-parser";

const InqueryPolicyModal = ({ policy }: { policy: string }) => {
  const dispatch = useAppDispatch();

  return (
    <Background modalNum={1}>
      <div className="p-32 bg-f2f3f8 w-320 md:w-500">
        <div className="flex items-center justify-between">
          <H2 isPrimary={true} name="개인정보 수집 정책" />
          <div
            className="inline-block cursor-pointer"
            onClick={() => dispatch(modalToggle(1))}
          >
            <ModalX />
          </div>
        </div>
        <Space y={20} />
        <p>
          <Quill readOnly value={policy} toolbar={false} />
        </p>
      </div>
    </Background>
  );
};

export default InqueryPolicyModal;
