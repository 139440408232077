import { useDispatch, useSelector } from "react-redux";
import { ReducerType } from "Store/store";
import { modalToggle } from "Store/Slices/modal";

/**
 * 전체화면 백그라운드
 * @param  child 안에 보일 엘리먼트
 * @returns
 */
const Background = ({
  children,
  modalNum = 1,
}: {
  children: React.ReactNode;
  modalNum?: number;
}) => {
  const open = useSelector((state: ReducerType) => state.modal);

  if (modalNum !== open) return null;
  return (
    <div className="fixed w-full h-[100vh] bg-black1/2 top-0 left-0 z-50">
      {/* 버블링 막음 */}
      <div onClick={(e) => e.stopPropagation()} className="fixed p-center">
        {children}
      </div>
    </div>
  );
};

export default Background;
