import React, { useState, useEffect } from "react";

import LoginForm from "Components/Organisms/LoginForm/loginForm";

import "./login.scss";
import { postDomainLoginApi } from "Apis/apis";
import { useAppDispatch } from "Store/store";
import { logoutMode } from "Store/Slices/layouts";

const LANGUAGE = [
  {
    idx: 1,
    label: "한국어",
  },
  {
    idx: 2,
    label: "English",
  },
  {
    idx: 3,
    label: "日本語",
  },
  {
    idx: 4,
    label: "简体中文",
  },
  {
    idx: 5,
    label: "繁體中文",
  },
];
// const EVERYTOWN = "kyo";
const EVERYTOWN = "everytown";
const Login = () => {
  const dispatch = useAppDispatch();
  const game = window.location.host.split(".")[0];
  const subDomain = game.includes("localhost") ? EVERYTOWN : game;

  const [idValue, setIdValue] = useState("");
  const [pwValue, setPwValue] = useState("");
  const [checkValue, setCheckValue] = useState<number[]>([]);
  const [languageValue, setLanguageValue] = useState(1);
  const [logoImg, setLogoImg] = useState("");
  const [company, setCompany] = useState("");

  const getCompany = async () => {
    const { data } = await postDomainLoginApi({ subDomain });
    setLogoImg(data.logo_image);
    setCompany(data.company);
  };

  const languageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setLanguageValue(+e.target.value);
  };

  useEffect(() => {
    dispatch(logoutMode());
    getCompany();
  }, []);

  return (
    <div className="h-[100vh] login-bg relative">
      <LoginForm
        subDomain={subDomain}
        logo={logoImg}
        idValue={idValue}
        pwValue={pwValue}
        checkValue={checkValue}
        setIdValue={setIdValue}
        setPwValue={setPwValue}
        setCheckValue={setCheckValue}
        languageChange={languageChange}
        LANGUAGE={LANGUAGE}
        languageValue={languageValue}
        setLanguageValue={setLanguageValue}
        company={company}
      />
    </div>
  );
};

export default Login;
