import { useEffect, useState } from "react";

import Background from "Components/Atoms/Background/background";
import H2 from "Components/Atoms/H2/h2";
import Space from "Components/Atoms/Space/space";
import Button from "Components/Atoms/Button/button";
import { modalToggle } from "Store/Slices/modal";
import { useAppDispatch } from "Store/store";
import { ReactComponent as ModalX } from "Util/svg/modalX.svg";
import { comma } from "Util/function";
import { useLocation } from "react-router-dom";

const ReceiptModal = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { price = 0, sheet_count = 0, date = "" } = location.state || {};
  const firstMoney = Math.ceil(price / 1.1); // 부가세 없는 가격

  return (
    <Background modalNum={3}>
      <div style={{ width: "640px" }} className="p-32 bg-f2f3f8">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-10 ">
            <H2 isPrimary={true} name="결제 상세 내역" />
            <span className="font-bold text-primary text-18">{date}</span>
          </div>
          <div
            className="inline-block cursor-pointer"
            onClick={() => dispatch(modalToggle(3))}
          >
            <ModalX />
          </div>
        </div>
        <Space y={20} />
        <hr className="mb-20 border-black border-1" />
        <div className="flex items-center justify-between gap-20 font-bold text-primary">
          <span>Premium</span>
          <div className="flex flex-col text-center">
            <span>{`${comma(firstMoney / sheet_count)}원 `}</span>
            <span>(시트 당 사용료)</span>
          </div>{" "}
          *<span>{sheet_count}개</span> =<span>{`${comma(firstMoney)}원`}</span>
        </div>
        <hr className="mt-20 mb-20 border-black border-1" />
        <div className="flex items-center justify-end gap-20 font-bold text-primary">
          <span>부가세</span> =<span>{`${comma(firstMoney / 10)}원`}</span>
        </div>
        <hr className="mt-20 mb-20 border-black border-1" />
        <div className="flex items-center justify-end gap-20 font-bold text-primary">
          <span>총 결제 금액</span> =<span>{`${comma(price)}원`}</span>
        </div>
        <Space y={20} />
        <div className="flex justify-end gap-20">
          <Button
            btnText="확인"
            onClick={() => {
              dispatch(modalToggle(3));
            }}
          />
        </div>
      </div>
    </Background>
  );
};

export default ReceiptModal;
