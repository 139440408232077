import Background from "Components/Atoms/Background/background";
import H2 from "Components/Atoms/H2/h2";
import Space from "Components/Atoms/Space/space";
import { modalToggle } from "Store/Slices/modal";
import { useAppDispatch } from "Store/store";
import parse from "html-react-parser";
import { ReactComponent as ModalX } from "Util/svg/modalX.svg";
import LANG, { getLang } from "Util/lang";

const PreviewModal = ({
  qna,
}: {
  qna: { title: string; contents: string; a: string };
}) => {
  const dispatch = useAppDispatch();

  return (
    <Background modalNum={10}>
      <div
        style={{ width: "640px" }}
        className="p-32 max-h-[80vh] overflow-auto bg-f2f3f8 pretty-scrollbar"
      >
        <div className="flex justify-between items-center">
          <H2 isPrimary={true} name={LANG[getLang()].preview} />
          <div
            className="inline-block cursor-pointer"
            onClick={() => dispatch(modalToggle(10))}
          >
            <ModalX />
          </div>
        </div>
        <Space y={20} />

        <div className="p-16 bg-white">
          <img className="h-50" src="/imgs/common/logo.png" alt="" />
          <Space y={20} />
          <H2 name="안녕하세요 에드업 고객센터입니다." />
          <Space y={20} />
          <div>
            <h3 className="text-lg font-bold">고객 문의</h3>
            <Space y={16} />
            <p className="font-bold">{parse(qna.title || "")}</p>
            <p>{parse(qna.contents || "")}</p>
          </div>
          <Space y={20} />
          <hr />
          <Space y={20} />
          <div>
            <h3 className="text-lg font-bold">답변</h3>
            <Space y={16} />
            <p>{parse(qna.a || "")}</p>
          </div>
        </div>
      </div>
    </Background>
  );
};

export default PreviewModal;
