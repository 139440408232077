import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { deleteServiceList } from "Store/Slices/serviceList";
import { levelCheck, logout } from "Store/Slices/user";
import { ReducerType, useAppDispatch } from "Store/store";
import { ReactComponent as LogoutSVG } from "Util/svg/logout.svg";
import { ReactComponent as PutProfileSVG } from "Util/svg/putProfile.svg";
import { ReactComponent as SettingSVG } from "Util/svg/setting_primary.svg";

import { GetLang } from "Util/lang";
import Button from "Components/Atoms/Button/button";
import Space from "Components/Atoms/Space/space";
import { modalToggle } from "Store/Slices/modal";
import SmsModal from "Components/Molecules/SmsModal/smsModal";
import { GetShortCutListApi, usePostDomainLoginApi } from "Apis/apis";
import { useState } from "react";
import { useEffect } from "react";
import { ShortCut, User } from "types/interface";
import ProfileImg from "Components/Atoms/ProfileImg/profileImg";
import ShortcutsModal from "Components/Molecules/ShortcutsModal/shortcutsModal";
import ShortcutsPostModal from "Components/Molecules/ShortcutsPostModal/shortcutsPostModal";
import ShortcutsPutModal from "Components/Molecules/ShortcutsPutModal/shortcutsPutModal";
import MemoListModal from "Components/Molecules/MemoListModal/memoListModal";
import RegistModal from "Components/Molecules/RegistModal/registModal";
import MailModal from "Components/Molecules/MailModal/mailModal";
import Select from "Components/Atoms/Select/select";
import { setLangIdx } from "Store/Slices/langIdx";

const EVERYTOWN = "everytown";
const LANGUAGE = [
  { idx: 1, label: "kr" },
  { idx: 2, label: "en" },
  { idx: 3, label: "ja" },
  { idx: 4, label: "cn" },
  { idx: 5, label: "tw" },
];

const Header = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const translate = GetLang();

  const game = window.location.host.split(".")[0];
  const subDomain = game.includes("localhost") ? EVERYTOWN : game;
  const user: User = useSelector((state: ReducerType) => state.user);

  const { header } = useSelector((state: ReducerType) => state.layouts);
  const { level } = useSelector((state: ReducerType) => state.user);
  const { data } = usePostDomainLoginApi({ subDomain }); // 로고사진
  const { data: shortCutList } = GetShortCutListApi();

  const [logoImg, setLogoImg] = useState("");
  const [choicedStortCut, setChoicedStortCut] = useState(0);
  const [lang, setlang] = useState(1);

  const logOut = () => {
    dispatch(logout());
    dispatch(deleteServiceList());
    window.location.href = "https://csbye.com/";
  };

  const getProfile = async () => {
    if (!data) return;
    setLogoImg(data.logo_image);
  };

  const goUserInfo = () => {
    level === 1
      ? navigate("infomation/userinfo")
      : navigate("infomation/csinfo");
  };
  useEffect(() => {
    getProfile();
  }, [data]);

  useEffect(() => {
    dispatch(setLangIdx(lang));
  }, [lang]);

  const link = ` ${user.level !== 1 ? "/user/ctqna" : "/"} `;
  if (!header) return <></>;
  return (
    <div id="header" className="flex items-center justify-between h-56 p-24">
      <h1>
        <Link to={link} title="홈으로 이동">
          <img
            src={logoImg ? logoImg : "/imgs/common/logo.png"}
            alt="기업로고"
            className="max-w-[260px] h-43 "
          />
        </Link>
      </h1>
      <div className="flex items-center text-primary">
        {shortCutList?.map((shortCut: ShortCut, idx: number) => {
          return (
            <div key={shortCut.shortcut_no} className="flex">
              <Button
                btnText={`${shortCut.title}`}
                onClick={() => window.open(shortCut.url)}
                color="ready"
              />
              <Space x={8} />
            </div>
          );
        })}
        <Button
          btnText="바로가기 관리"
          onClick={() => dispatch(modalToggle(-2))}
        />
        <Space x={16} />
        <Select border dataList={LANGUAGE} val={lang} setVal={setlang} />
        <Space x={16} />
        {user.level > 1 && (
          <Link to="/infomation/labeling">
            <div className="flex">
              <SettingSVG /> 설정
            </div>
          </Link>
        )}

        <Space x={16} />
        <button className="flex items-center" onClick={goUserInfo}>
          {user.level === 1 ? (
            <>
              <PutProfileSVG /> &nbsp;
              {translate("personalInfo")}
            </>
          ) : (
            <>
              <ProfileImg />
              <Space x={8} />
              <span>{user.name}님</span>
            </>
          )}
        </button>
        <button onClick={logOut} className="flex items-center ml-17">
          <>
            <LogoutSVG /> &nbsp;
            {translate("logout")}
          </>
        </button>
      </div>
      <SmsModal />
      <ShortcutsModal setChoicedStortCut={setChoicedStortCut} />
      <ShortcutsPostModal />
      <ShortcutsPutModal idx={choicedStortCut} />
      {user.product_level >= 2 && <MemoListModal />}
      <RegistModal />
      <MailModal />
    </div>
  );
};

export default Header;
