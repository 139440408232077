import Space from "Components/Atoms/Space/space";
import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CT, ClickInputForm } from "types/interface";
import { ColumnsType } from "antd/es/table";
import Button from "Components/Atoms/Button/button";
import Select from "Components/Atoms/Select/select";
import { getConsultantListApi, putConsultantApi } from "Apis/apis";
import SearchInput from "Components/Molecules/SearchInput/searchInput";
import { order } from "Util/function";
import { clearScreenDown } from "readline";
import { useDispatch } from "react-redux";
import { levelCheck } from "Store/Slices/user";

const StarPointReview = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const Percent: ClickInputForm[] = [...Array(11)].map((el, idx) => ({
    idx: idx * 10,
    label: `${idx * 10}%`,
  }));
  const [consultantList, setConsultantList] = useState<CT[]>([]);

  const getConsultantList = async () => {
    const { searching = "" } = location.state || {};
    setSearch(searching);
    const {
      data: { cs },
    } = await getConsultantListApi(-1, searching);
    setConsultantList(
      cs.filter((el: CT) => el.work_state !== 2 && el.state === 0)
    );
  };
  const onSubmit = () => {
    navigate("", {
      state: { pageNo: 3, searching: search },
    });
  };
  const onChange = (cs: CT) => async (percent: number) => {
    dispatch(
      levelCheck({
        sl: "c041",
        fuc: async () => {
          setConsultantList(
            consultantList.map((el) => {
              if (el.cs_no !== cs.cs_no) return el;

              const _el = { ...el };
              _el.star_percent = percent;
              return _el;
            })
          );
          const body = {
            starPercent: percent,
          };
          await putConsultantApi(body, cs.cs_no);
        },
      })
    );
  };

  const columns: ColumnsType<any> = [
    {
      title: "번호",
      dataIndex: "cs_no",
      align: "center",
      width: "100px",
      render: (row, now) => order(consultantList, now.cs_no, "cs_no"),
    },
    {
      title: "상담사명",
      dataIndex: "cs_name",
      align: "center",
      width: "200px",
    },
    {
      title: "상담사 ID",
      dataIndex: "cs_id",
      align: "center",
      width: "200px",
    },
    {
      title: "Level",
      dataIndex: "level",
      align: "center",
      width: "200px",
      render: (now) => (now ? "Lv 1(일반)" : "Lv 2(프로)"),
    },
    {
      title: "별점 리뷰",
      dataIndex: "star_percent",
      align: "center",
      width: "100px",
      render: (now, row, idx) => {
        return (
          <div className="flex justify-center">
            <Select
              dataList={Percent}
              onChange={onChange(row)}
              val={now}
              zindex={1}
            />
          </div>
        );
      },
    },
  ];
  useEffect(() => {
    getConsultantList();
  }, [location.state]);
  return (
    <div>
      <SearchInput
        onSubmit={onSubmit}
        setValue={setSearch}
        size="md"
        value={search}
        placeholder="상담원 명"
      />
      <Space y={20} />
      <div className="max-w-7xl">
        <Table
          rowKey="cs_service_no"
          dataSource={consultantList}
          columns={columns}
          pagination={{
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ["10", "15", "20", "25", "30"],
          }}
        />
      </div>
    </div>
  );
};

export default StarPointReview;
