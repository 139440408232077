import Button from "Components/Atoms/Button/button";
import Select from "Components/Atoms/Select/select";
import Space from "Components/Atoms/Space/space";

import React, { useEffect, useState } from "react";
import LANG, { getLang } from "Util/lang";
import { useLocation, useNavigate } from "react-router-dom";
import FAQ from "Pages/Board/faq";
import Policy from "Pages/Board/policy";
import BoardNotice from "Pages/Board/boardNotice";
const PAGE_TYPE = [
  {
    idx: 1,
    label: "FAQ",
  },
  {
    idx: 2,
    label: "약관/정책",
  },
  {
    idx: 3,
    label: "공지사항",
  },
];
const PotalBoard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [page, setPage] = useState(1);

  const selectButton = (e: number) => {
    navigate("", {
      state: { pageNo: e, selectServiceNo: -1 },
    });
  };
  const selectPage = (page: number) => {
    switch (page) {
      case 1:
        return <FAQ />;
      case 2:
        return <Policy />;
      case 3:
        return <BoardNotice />;
    }
  };
  useEffect(() => {
    setPage(location.state?.pageNo || 1);
  }, [location.state]);

  return (
    <div>
      <div className="relative z-20 flex">
        <div className="flex gap-19">
          {PAGE_TYPE.map((item) => {
            return (
              <Button
                key={item.idx}
                btnText={item.label}
                size={"md"}
                color={item.idx === page ? "primary" : "normal"}
                onClick={() => selectButton(item.idx)}
              />
            );
          })}
        </div>
      </div>
      <Space y={20} /> {selectPage(page)}
    </div>
  );
};

export default PotalBoard;
