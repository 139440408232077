const Tag = ({
  name,
  type = "primary",
}: {
  name: string;
  type?: "primary" | "success" | "ready" | "error";
}) => {
  const textColor = () => {
    switch (type) {
      case "success":
        return "text-success";
      case "ready":
        return "text-969696";
      case "error":
        return "text-error";

      default:
        return "text-primary";
    }
  };

  return <span className={`text-16 bold  ${textColor()}`}>{name}</span>;
};

export default Tag;
