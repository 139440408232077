import React, { useState, useEffect } from "react";
import Select from "Components/Atoms/Select/select";
import Space from "Components/Atoms/Space/space";
import { ClickInputForm, MiddleData } from "types/interface";
import { toCheckboxForm } from "Util/function";

import { getCategoryListApi } from "Apis/apis";
import LANG, { getLang } from "Util/lang";

const DEFAULT_MID_CT_IDX = -1;
const TOP_MID_CT = { idx: DEFAULT_MID_CT_IDX, label: "중 카테고리" };

/**
 *
 * @param {number} serviceNo 서비스넘버 - 카테고리api에 사용
 * @param {number} bCode 선택된 빅카테고리 인덱스
 * @param {number} mCode 선택된 미드카테고리 인덱스
 * @returns
 */
const Category = ({
  serviceNo,
  bCode,
  setBCode,
  mCode,
  setMCode,
}: {
  serviceNo: number;
  bCode: number;
  setBCode: React.Dispatch<React.SetStateAction<number>>;
  mCode: number;
  setMCode: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const [bCodeList, setBCodeList] = useState<ClickInputForm[]>([]); // 서비스별 대카테고리
  const [allMCodeList, setAllMCodeList] = useState<MiddleData[]>([]); // 서비스별 중카테고리
  const [mCodeList, setMCodeList] = useState<ClickInputForm[]>([]); //  대카테고리별 중카테고리

  const getCategoryList = async () => {
    // prettier-ignore
    const {data: {category: { big_category, middle_category }}} = await getCategoryListApi(serviceNo);
    // prettier-ignore
    const bigCategory: ClickInputForm[] = toCheckboxForm(big_category, "big_category_code", "big_category_name");

    const _bigCategory = [
      { idx: -1, label: LANG[getLang()].lCategory },
      ...bigCategory,
    ];

    setBCodeList(_bigCategory);
    setMCodeList([TOP_MID_CT]);
    setAllMCodeList(middle_category);

    setBCode(_bigCategory[0].idx);
    setMCode(DEFAULT_MID_CT_IDX);
  };

  const setMidCt = () => {
    const nowMidCt = allMCodeList.filter(
      (mCode) => mCode.big_category_code === bCode
    );

    const midCategory: ClickInputForm[] = toCheckboxForm(
      nowMidCt,
      "middle_category_code",
      "middle_category_name"
    );
    setMCode(DEFAULT_MID_CT_IDX);
    setMCodeList([TOP_MID_CT, ...midCategory]);
  };
  useEffect(() => {
    getCategoryList();
  }, [serviceNo]);

  useEffect(() => {
    if (bCode === -1) return;
    setMidCt();
  }, [bCode]);

  return (
    <div className="flex">
      <Select dataList={bCodeList} val={bCode} setVal={setBCode} />
      <Space x={20} />
      <Select dataList={mCodeList} val={mCode} setVal={setMCode} />
    </div>
  );
};

export default Category;
