import React from "react";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { DailyLog } from "types/interface";
const DAY = ["", "오늘", "어제", "그제"];
const ReceiptStatus = ({ statusList }: { statusList: DailyLog[] }) => {
  const columns: ColumnsType<DailyLog> = [
    {
      title: "날짜",
      dataIndex: "day",
      align: "center",
      render: (now, row, idx) => {
        return <span>{now === "계" ? "계" : `${DAY[idx]} (${now})`}</span>;
      },
    },
    {
      title: "전체문의",
      dataIndex: "count_sum",
      align: "center",
    },
    {
      title: "답변완료",
      dataIndex: "count_reply_complete",
      align: "center",
    },
    {
      title: "미처리 (전체 미처리 개수)",
      dataIndex: "count_reply_no",
      align: "center",
      width: 300,
    },
    {
      title: "숨김",
      dataIndex: "count_hidden",
      align: "center",
    },
  ];

  return (
    <div>
      <Table columns={columns} dataSource={statusList} rowKey="day" />
    </div>
  );
};

export default ReceiptStatus;
