import Background from "Components/Atoms/Background/background";
import H2 from "Components/Atoms/H2/h2";
import { modalToggle } from "Store/Slices/modal";
import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { ReactComponent as ModalX } from "Util/svg/modalX.svg";
import Space from "Components/Atoms/Space/space";
import H4 from "Components/Atoms/H4/h4";
import QuerySearchInput from "Components/Atoms/Input2/querySearchInput";
import Button from "Components/Atoms/Button/button";
import { putTemporaryPwApi } from "Apis/apis";
import { PutTemporaryPw } from "types/interface";
const LoginModal = () => {
  const ref = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const getPasswordApi = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!ref.current) return;
    const companyId = ref.current.value;
    if (companyId === "") {
      alert("이메일을 입력해주세요.");
      return;
    }
    const body = {
      companyId,
    };
    await putTemporaryPwApi(body);
    alert("임시비밀번호가 메일로 발송되었습니다.");
  };
  return (
    <Background>
      <div style={{ width: "640px" }} className="p-32 bg-f2f3f8">
        <div className="flex justify-between items-center">
          <H2 isPrimary={true} name="비밀번호 찾기" />
          <div
            className="inline-block cursor-pointer"
            onClick={() => dispatch(modalToggle(1))}
          >
            <ModalX />
          </div>
        </div>
        <Space y={20} />
        <H4 name="가입시 등록하신 이메일 주소를 입력하시면" />
        <H4 name="비밀번호 재설정 하실 수 있습니다." />
        <Space y={20} />
        <form className="flex" onSubmit={getPasswordApi}>
          <QuerySearchInput refInput={ref} placeholder="이메일주소 입력" />
          <Space x={24} />
          <Button btnText="찾기" type="submit" />
        </form>
      </div>
    </Background>
  );
};

export default LoginModal;
