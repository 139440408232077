import { useState, useEffect } from "react";
import Button from "Components/Atoms/Button/button";
import Space from "Components/Atoms/Space/space";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReducerType, useAppDispatch } from "Store/store";
import { ClickInputForm, PutUserQuit, RadioForm, User } from "types/interface";
import Radio from "Components/Atoms/Radio/radio";
import Quill from "Components/Atoms/Quill/quill";
import { ctMode } from "Store/Slices/layouts";
import Input from "Components/Atoms/Input/input";
import Checkbox from "Components/Atoms/Checkbox/checkbox";
import OutModal from "Components/Molecules/OutModal/outModal";
import { modalToggle } from "Store/Slices/modal";
import { logout } from "Store/Slices/user";
import { deleteServiceList } from "Store/Slices/serviceList";
import { getCompanyProductApi, putUserQuitApi } from "Apis/apis";
const RADIO_DATA: RadioForm[] = [
  { idx: 0, label: "서비스 이용 불편", id: "admin" },
  { idx: 1, label: "가격 불만족", id: "consultant" },
  { idx: 2, label: "무료 이용 기간 종료", id: "freeout" },
  { idx: 3, label: "직접입력", id: "user" },
];
const ID_DATA: ClickInputForm[] = [
  { idx: 1, label: "안내사항을 모두 확인하였으며, 이에 동의합니다" },
];

const Out = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [reason, setReason] = useState(0);
  const [text, setText] = useState("");
  const [password, setPassword] = useState("");
  const [agree, setAgree] = useState<number[]>([]);
  const [nextDate, setNextDate] = useState(""); // 정식 회원탈퇴일
  const getCompanyProduct = async () => {
    const {
      data: { product },
    } = await getCompanyProductApi();
    setNextDate(product.next_date);
  };
  const putQuit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (reason === 3 && text === "") return alert("내용을 입력해주세요.");
    if (agree) if (agree.length !== 1) return alert("안내사항을 체크해주세요.");
    if (password.length < 6) return alert("비밀번호를 6자리 입력해주세요.");
    const confirm = window.confirm("회원탈퇴 하시겠습니까?");
    if (!confirm) return;
    const body: PutUserQuit = {
      reasonType: reason,
      reason: reason === 3 ? text : "",
      password: password,
    };

    await putUserQuitApi(body);
    dispatch(logout());
    dispatch(deleteServiceList());
    window.location.href = "https://csbye.com/";
  };
  useEffect(() => {
    getCompanyProduct();
  }, []);
  return (
    <div>
      <h2 className="text-lg font-bold">유의사항</h2>
      <ul>
        <li>
          계정 탈퇴는 영구적이며, 복구할 수 없습니다. 탈퇴 전에 신중하게
          고려해주세요.
        </li>
        <li>
          계정 탈퇴를 진행하시면, 해당 계정과 관련된 모든 데이터가 삭제됩니다.
        </li>
        <li className="flex">
          <p>계정 탈퇴 전 이용 중인 플랜 자동 결제를 해지하세요.</p>
          <Space x={8} />
          <Button
            btnText="바로가기"
            size="sm"
            onClick={() => dispatch(modalToggle(1))}
          />
        </li>
      </ul>

      <Space y={40} />
      <h3 className="text-xl font-bold">서비스 탈퇴 사유(필수)</h3>
      <Space y={20} />
      <Radio
        vertical
        dataList={RADIO_DATA}
        name="out"
        value={reason}
        setValue={setReason}
      />
      <Space y={20} />
      <div className={`w-1/2 ${reason === 3 ? "" : "pointer-events-none"}`}>
        <Quill value={text} setValue={setText} toolbar={false} />
      </div>
      <Space y={20} />
      <form onSubmit={putQuit}>
        <div className="flex">
          <Input
            type="password"
            id="password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            width={20}
            placeholder="비밀번호 입력"
          />
        </div>

        <Space y={20} />
        <Checkbox value={agree} setValue={setAgree} dataList={ID_DATA} />
        <Space y={40} />
        <Button btnText="탈퇴신청" type="submit" />
      </form>

      <OutModal date={nextDate} />
    </div>
  );
};

export default Out;
