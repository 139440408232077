import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Button from "Components/Atoms/Button/button";
import Space from "Components/Atoms/Space/space";

import { getPolicyListApi } from "Apis/apis";

import type { ColumnsType } from "antd/es/table";
import { Table } from "antd";
import { PolicyList } from "types/interface";
import LANG, { getLang } from "Util/lang";
import { order } from "Util/function";
import { useSelector } from "react-redux";
import { ReducerType } from "Store/store";
import Select from "Components/Atoms/Select/select";
import useServiceNo from "Util/Hooks/useServiceIdx";
import SearchInput from "Components/Molecules/SearchInput/searchInput";

const Policy = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const serviceList = useSelector((state: ReducerType) =>
    state.serviceList
      .filter((el) => el.state !== 2 && el.state !== 1)
      .map((el) => (el.idx === -1 ? { ...el, label: "서비스 선택" } : el)),
  );
  const [serviceNo, setServiceNo] = useServiceNo();
  const companyNo = useSelector((state: ReducerType) => state.user.idx);
  const [policyList, setPolicyList] = useState<PolicyList[]>([]);
  const [keyword, setKeyword] = useState("");
  const goAddPolicy = () => {
    navigate("/service/policyadd");
  };
  const GetPolicyList = async () => {
    const { selectServiceNo = -1 } = location.state || {};
    setServiceNo(selectServiceNo);
    if (selectServiceNo === -1) return;
    const {
      data: { terms },
    } = await getPolicyListApi(companyNo, selectServiceNo, keyword);
    setPolicyList(terms);
  };

  const onSubmit = () => {
    if (serviceNo === -1) return alert("서비스를 선택해주세요.");
    navigate("", {
      state: { pageNo: 2, selectServiceNo: serviceNo },
    });
  };

  const columns: ColumnsType<PolicyList> = [
    {
      title: LANG[getLang()].index,
      dataIndex: "terms_no",
      align: "center",
      render: (now, row) => order(policyList, row.terms_no, "terms_no"),
    },
    {
      title: "서비스 로고",
      dataIndex: "service_logo",
      align: "center",
      width: 140,
      render: (now) => {
        const defalut = "../imgs/common/defaultService.png";
        return (
          <div className="flex justify-center">
            {now ? (
              <img src={now} className="w-80 h-80" alt="서비스 로고" />
            ) : (
              <img src={defalut} alt="서비스 로고" />
            )}
          </div>
        );
      },
    },
    {
      title: "서비스명",
      dataIndex: "service_name",
      align: "center",
      render: (now) => {
        return now;
      },
    },
    {
      title: LANG[getLang()].title,
      dataIndex: "title",
      align: "center",
      render: (now, row) => (
        <span
          onClick={() => navigate(`/service/policyfix/${row.terms_no}`)}
          className="cursor-pointer"
        >
          {now}
        </span>
      ),
    },
    {
      title: LANG[getLang()].date,
      dataIndex: "date",
      align: "center",
    },
  ];

  useEffect(() => {
    GetPolicyList();
  }, [location.state]);
  return (
    <div id="policy">
      <div className="flex">
        <Select dataList={serviceList} val={serviceNo} setVal={setServiceNo} />
        <Space x={20} />
        <SearchInput
          onSubmit={onSubmit}
          value={keyword}
          setValue={setKeyword}
          size="xl"
          require={false}
        />
      </div>
      <Space y={20} />
      <div className="flex justify-end">
        <Button
          btnText={`+ ${LANG[getLang()].register}`}
          onClick={goAddPolicy}
        />
      </div>
      <Space y={20} />
      <Table rowKey="terms_no" columns={columns} dataSource={policyList} />
    </div>
  );
};

export default Policy;
