import React from "react";
import Input from "Components/Atoms/Input2/input2";
import Button from "Components/Atoms/Button/button";
import "./searchInput.scss";
import { ReactComponent as SearchXl } from "Util/svg/searchXl.svg";
import { ReactComponent as SearchMd } from "Util/svg/searchMd.svg";
import LANG, { getLang } from "Util/lang";

const SearchInput = ({
  placeholder = "",
  size,
  value,
  setValue,
  onSubmit,
  require = true,
  radioValue,
  standardItem,
}: {
  placeholder?: string;
  size: "xl" | "md";
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  onSubmit: () => void;
  require?: boolean;
  radioValue?: number;
  standardItem?: string;
}) => {
  const sizeStyle = size === "xl" ? "search_xl" : "search_md";

  const checkSubmit: React.ChangeEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <form onSubmit={checkSubmit}>
      <div className="relative">
        <div className={`flex justify-between ${sizeStyle}`}>
          <div
            className={`absolute top-11 ${
              size === "xl" ? "left-16" : "left-8"
            }`}
          >
            {size === "xl" ? <SearchXl /> : <SearchMd />}
          </div>
          <div className={`${size === "xl" ? "w-444" : "w-204"}`}>
            <Input
              id="search"
              placeholder={placeholder}
              size={size}
              value={value}
              setValue={setValue}
            />
          </div>
          <div className={`${size === "xl" ? "w-100" : ""} flex`}>
            <Button
              btnText={LANG[getLang()].search}
              size={size}
              type="submit"
              standardItem={standardItem ? standardItem : ""}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default SearchInput;
