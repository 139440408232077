import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import InputRow from "Components/Molecules/InputRow/inputRow";
import InputRowRadio from "Components/Molecules/InputRowRadio/inputRowRadio";
import LabelQuill from "Components/Molecules/LabelQuill/labelQuill";
import Label from "Components/Atoms/Label/label";
import Space from "Components/Atoms/Space/space";
import Button from "Components/Atoms/Button/button";
import ServiceCategory from "Components/Molecules/ServiceCategory/serviceCategory";
import InputRowMultiFile from "Components/Molecules/InputRowMultiFile/inputRowMultiFile";

import { ReactComponent as Require } from "Util/svg/require.svg";

import {} from "types/interface.d";
import { RadioForm } from "types/interface";
import FileNameList from "Components/Atoms/FileNameList/fileNameList";
import LANG, { getLang } from "Util/lang";

const FaqForm = ({
  title,
  text,
  state,
  serviceNo,
  bCode,
  mCode,

  file,
  setFile,
  setTitle,
  setText,
  setState,
  setServiceNo,
  setBCode,
  setMCode,
  onClick,
}: {
  title: string;
  text: string;
  state: number;
  serviceNo: number;
  bCode: number;
  mCode: number;

  file: File[];
  setFile: React.Dispatch<React.SetStateAction<File[]>>;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  setText: React.Dispatch<React.SetStateAction<string>>;
  setState: React.Dispatch<React.SetStateAction<number>>;
  setServiceNo: React.Dispatch<React.SetStateAction<number>>;
  setBCode: React.Dispatch<React.SetStateAction<number>>;
  setMCode: React.Dispatch<React.SetStateAction<number>>;
  onClick: () => Promise<void>;
}) => {
  const navigate = useNavigate();

  const STATE_DATA: RadioForm[] = [
    { idx: 1, label: LANG[getLang()].waiting, id: "wait" },
    { idx: 0, label: LANG[getLang()].approval, id: "ok" },
  ];

  return (
    <>
      <div className="rounded bg-ffffff">
        <InputRow
          id="faqTitle"
          name={LANG[getLang()].title}
          value={title}
          setValue={setTitle}
        />
        <div className="flex items-center">
          <div className="flex items-center px-16 py-20 bg-e5e6ed w-130">
            <Label labelfor="select" name="분류" fontSize={16} />
            <Space x={4} />
            <Require />
          </div>
          <div className="flex-1 h-full bg-white p-13">
            <div className="flex w-full">
              <ServiceCategory
                serviceNo={serviceNo}
                setServiceNo={setServiceNo}
                bCode={bCode}
                setBCode={setBCode}
                mCode={mCode}
                setMCode={setMCode}
              />
            </div>
          </div>
        </div>
        <LabelQuill
          id="faqQuill"
          name={LANG[getLang()].content}
          text={text}
          setText={setText}
        />
        <div className="flex items-center">
          <InputRowMultiFile
            id="profile_img"
            name={LANG[getLang()].attachedFile}
            setValue={setFile}
            value={file}
          />
          <FileNameList fileList={file} setFileList={setFile} />
        </div>
        <InputRowRadio
          data={STATE_DATA}
          name={LANG[getLang()].state}
          value={state}
          setValue={setState}
        />
      </div>
      <div className="flex justify-end gap-10 mt-10 ">
        <Button
          btnText={LANG[getLang()].list}
          onClick={() =>
            navigate("/service/potalboard", {
              state: { pageNo: 1 },
            })
          }
        />
        <Button onClick={onClick} btnText={LANG[getLang()].register} />
      </div>
    </>
  );
};

export default FaqForm;
