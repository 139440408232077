import { Collapse } from "antd";
import axios from "axios";
import Space from "Components/Atoms/Space/space";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { logoutMode } from "Store/Slices/layouts";
import DOMPurify from "dompurify";
import { useAppDispatch } from "Store/store";
import { ReactComponent as ArrowSVG } from "Util/svg/leftArrow.svg";
import { getQnaImage } from "Apis/apis";
import Quill from "Components/Atoms/Quill/quill";

const Index = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { Panel } = Collapse;
  const params = useParams();
  const serviceNo = +(params.idx || -1);

  const [policy, setPolicy] = useState<any>([]);
  const [logoImg, setLogoImg] = useState("");
  const [pageTitle, setPageTitle] = useState("");
  const [pageContent, setPageContent] = useState("");

  const layoutRender = () => {
    dispatch(logoutMode());
  };

  const getDetail = async (idx: number) => {
    const {
      data: { terms },
    } = await axios.get(`${process.env.REACT_APP_API_V1}boards/terms/${idx}`);

    return terms;
  };

  const getApi = async () => {
    const {
      data: { terms },
    } = await axios.get(
      `${process.env.REACT_APP_API_V1}boards/terms?serviceNo=${serviceNo}&page=1&max=1000000`,
    );

    const myTerms = terms.filter((el: any) => el.service_no === serviceNo);

    const arr = [];
    for (let i = 0; i < myTerms.length; i++) {
      const dummy = await getDetail(myTerms[i].terms_no);
      arr.push(dummy);
    }

    setPolicy(arr);
  };

  // 로고 및 서비스소개
  const getImage = async () => {
    const {
      data: { image },
    } = await getQnaImage(serviceNo);
    setLogoImg(image.icon || "/imgs/common/service_icon.png");
    setPageTitle(image.page_title);
    setPageContent(image.description);
  };

  useEffect(() => {
    layoutRender();
    getApi();
    getImage();
  }, []);
  return (
    <div className="bg-[F2F3F8] h-[100vh]">
      <div className="flex items-center h-56 px-20 bg-white">
        <button
          className="flex items-center"
          onClick={() => navigate(`/menu/${serviceNo}`)}
        >
          <ArrowSVG />
          <Space x={5} />
          <span className="bold text-24">약관/정책</span>
        </button>
      </div>
      <div className="w-4/6 mx-auto mt-20">
        <Link to={`/menu/${serviceNo}`} className="flex items-end ">
          <img
            src={logoImg || "/imgs/common/inquery_default.png"}
            alt="로고이미지"
            className="w-48 h-48 rounded-lg "
          ></img>
          <Space x={8} />
          <div className="flex flex-col ">
            <span className="font-bold text-14 text-primary">{pageTitle}</span>
            <span className="text-12 text-primary">{pageContent}</span>
          </div>
        </Link>
      </div>
      <div className="w-5/6 mx-auto mt-20 text-center lg:w-4/6">
        <Collapse className="border-none" accordion>
          {policy.map((el: any) => (
            <Panel
              style={{
                borderRadius: "0px",
                backgroundColor: "#E5E6ED",
              }}
              className="mb-10 text-left"
              header={<p className="">{el.title}</p>}
              key={el.terms_no}
            >
              <Quill
                className="plane_quill"
                value={el.contents}
                readOnly
                toolbar={false}
              />
            </Panel>
          ))}
        </Collapse>
        <p className="text-center text-696969 text-12 light">
          Copyright © ADD-UP Co., Ltd. All Rights Reserved.
        </p>
      </div>
    </div>
  );
};

export default Index;
