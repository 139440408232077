import Input from "Components/Atoms/Input/input";
import Label from "Components/Atoms/Label/label";
import Space from "Components/Atoms/Space/space";
import { ReactComponent as Require } from "Util/svg/require.svg";
import Button from "Components/Atoms/Button/button";
import { ClickInputForm } from "types/interface";
import Select from "Components/Atoms/Select/select";

/**
 * Select 창
 */
const InputRowSelect = ({
  id,
  name,
  value,
  setValue,
  dataList,
  value2,
  setValue2,
  dataList2,
  require = true,
  zindex = 10,
  width = "w-130",
}: {
  id: string;
  name: string;
  value: number;
  setValue: React.Dispatch<React.SetStateAction<number>>;
  dataList: ClickInputForm[];
  value2?: number;
  setValue2?: React.Dispatch<React.SetStateAction<number>>;
  dataList2?: ClickInputForm[];
  require?: boolean;
  zindex?: number;
  width?: string;
}) => {
  return (
    <div className="flex items-center bg-white">
      <div className={`flex items-center px-16 py-20 bg-e5e6ed ${width}`}>
        <Label labelfor={id} name={name} fontSize={16} />
        <Space x={4} />
        {require ? <Require /> : null}
      </div>
      <div className="flex-1 h-full bg-white p-14">
        <div className={`w-300 ${dataList2 && "flex"}`}>
          <Select
            dataList={dataList}
            val={value}
            setVal={setValue}
            zindex={zindex}
          />
          {dataList2 ? (
            <>
              <Space x={20} />
              <Select
                dataList={dataList2 || []}
                val={value2 || 0}
                setVal={setValue2}
                zindex={zindex}
              />
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default InputRowSelect;
