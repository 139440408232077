import Background from "Components/Atoms/Background/background";
import H2 from "Components/Atoms/H2/h2";
import Space from "Components/Atoms/Space/space";
import { modalToggle } from "Store/Slices/modal";
import { useAppDispatch } from "Store/store";
import { ReactComponent as ModalX } from "Util/svg/modalX.svg";
import Button from "Components/Atoms/Button/button";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import LANG, { getLang } from "Util/lang";
import { levelCheck } from "Store/Slices/user";

/**
 * 모달 molecule
 *
 */
const TimeModal = ({
  setTime,
  onSubmit,
}: {
  setTime: React.Dispatch<React.SetStateAction<string>>;
  onSubmit: any;
}) => {
  const dispatch = useAppDispatch();

  const now = dayjs().format();
  const nowHour = dayjs().format("HH");
  const nowMin = dayjs().format("mm");
  const nowDay = dayjs().format("DD");

  const range = (start: number, end: number) => {
    const result = [];
    for (let i = start; i <= end; i++) {
      result.push(i);
    }
    return result;
  };

  // 오늘의 이전시간 disable
  const disabledDateTime = (a: any) => {
    if (!a) {
      return {
        disabledHours: () => range(0, 23),
        disabledMinutes: () => range(0, 60),
      };
    }

    const pickedDay = a.format("DD"); // 선택된 날짜
    const pickedHour = a.format("HH"); // 선택된 시간
    const isOver50Min = +nowMin >= 50; // 50분넘는지 체크, 넘으면 현재시간 disalble
    const min = Math.floor(+nowMin / 10) * 10; // 현재 분 10의자리
    const disableMin = pickedHour === nowHour ? min : 0; // 분 disable

    if (pickedDay > nowDay) return {}; // 오늘 이후날짜 선택하면 disable없음
    if (pickedHour === nowHour) {
      return {
        disabledHours: () => range(0, +nowHour - (isOver50Min ? 0 : 1)),
        disabledMinutes: () => range(0, disableMin),
      };
    }

    return {
      disabledHours: () => range(0, +nowHour - (isOver50Min ? 0 : 1)),
    };
  };

  const onChangeTime = (value: dayjs.Dayjs | null, dateString: string) => {
    if (!dateString) return;
    const seletedTime = +dayjs(dateString).format("YYMMDDhhmm");
    const nowTime = +dayjs(now).format("YYMMDDhhmm");

    if (seletedTime < nowTime) {
      alert("현재시간 이후로 선택 해주세요");
      return;
    }

    setTime(dateString);
  };

  // 지난날짜 disable
  const disabledDate = (current: dayjs.Dayjs) => {
    return current && current < dayjs().subtract(1, "d").endOf("day");
  };

  return (
    <Background modalNum={8}>
      <div style={{ width: "640px" }} className="p-32 bg-f2f3f8">
        <div className="flex justify-between items-center">
          <H2 isPrimary={true} name={LANG[getLang()].reserveSend} />
          <div
            className="inline-block cursor-pointer"
            onClick={() => dispatch(modalToggle(8))}
          >
            <ModalX />
          </div>
        </div>
        <Space y={20} />
        <div className="flex ">
          <DatePicker
            format="YYYY-MM-DD HH:mm"
            minuteStep={10}
            disabledDate={disabledDate}
            disabledTime={disabledDateTime}
            showTime={{
              hideDisabledOptions: true,
              defaultValue: dayjs("00:00:00", "HH:mm:ss"),
            }}
            onChange={onChangeTime}
          />
          <Space x={20} />

          <Button
            btnText={LANG[getLang()].confirm}
            onClick={() =>
              dispatch(
                levelCheck({
                  sl: "q08",
                  fuc: onSubmit,
                }),
              )
            }
          />
        </div>
      </div>
    </Background>
  );
};

export default TimeModal;
