import { useRef, useMemo, useEffect } from "react";
import { DeltaStatic, Sources } from "quill";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const Quill = ({
  value,
  setValue,
  toolbar = true,
  placeHolder = "내용을 입력해주세요",
  readOnly = false,
  className = "basic",
}: {
  value: string;
  setValue?: React.Dispatch<React.SetStateAction<string>>;
  toolbar?: boolean;
  placeHolder?: string;
  readOnly?: boolean;
  className?: string;
}) => {
  const quillRef = useRef<ReactQuill>(null);
  const TOOL_BAR = {
    container: [
      ["bold", "italic", "underline", "strike", "blockquote", "link"],
      [{ header: [1, 2, false] }],
      [{ color: [] }],
      // [{ size: ["small", false, "large", "huge"] }, { color: [] }],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
        { align: [] },
      ],
    ],
  };

  const modules = useMemo(
    () => ({
      toolbar: toolbar ? TOOL_BAR : false,
    }),
    [],
  );

  const onChange = (
    value: string,
    delta: DeltaStatic,
    source: Sources,
    editor: ReactQuill.UnprivilegedEditor,
  ) => {
    setValue && setValue(value);
  };

  const addHttps = (value: string) => {
    if (!value || !value.includes('<a href="www.')) return value;

    const correctedContent = value.replace(
      '<a href="www.',
      '<a href="https://www.',
    );

    return correctedContent;
  };

  return (
    <ReactQuill
      className={className}
      readOnly={readOnly}
      ref={quillRef}
      value={addHttps(value)}
      onChange={onChange}
      modules={modules}
      theme="snow"
      placeholder={placeHolder}
    />
  );
};

export default Quill;
