import { useState } from "react";

import Background from "Components/Atoms/Background/background";
import H2 from "Components/Atoms/H2/h2";
import Space from "Components/Atoms/Space/space";
import Button from "Components/Atoms/Button/button";
import { modalToggle } from "Store/Slices/modal";
import { useAppDispatch } from "Store/store";
import { ReactComponent as ModalX } from "Util/svg/modalX.svg";
import Quill from "Components/Atoms/Quill/quill";
import { putAnswerApi } from "Apis/apis";
import LANG, { getLang } from "Util/lang";
import { useQueryClient } from "@tanstack/react-query";

const MemoModal = ({ qIdx }: { qIdx: number }) => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const [contents, setContents] = useState("");

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (contents === "") {
      alert("내용를 입력해주세요.");
      return;
    }

    const body = {
      contents,
      state: 2,
    };

    await putAnswerApi(qIdx, body);
    dispatch(modalToggle(12));
    queryClient.invalidateQueries(["useGetQDetailApi", qIdx]);
  };

  return (
    <Background modalNum={12}>
      <div style={{ width: "640px" }} className="p-32 bg-f2f3f8">
        <div className="flex justify-between items-center">
          <H2 isPrimary={true} name="메모" />
          <div
            className="inline-block cursor-pointer"
            onClick={() => dispatch(modalToggle(12))}
          >
            <ModalX />
          </div>
        </div>
        <Space y={20} />
        <form className="" onSubmit={onSubmit}>
          <Quill value={contents} setValue={setContents} toolbar={false} />
          <Space y={20} />
          <Button btnText={LANG[getLang()].register} type="submit" />
        </form>
      </div>
    </Background>
  );
};

export default MemoModal;
