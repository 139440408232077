import { modalToggle } from "Store/Slices/modal";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { StarPointData } from "types/interface";

const StarTable = ({
  data,
  setCsNo,
}: {
  data: StarPointData;
  setCsNo: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const modalOpen = (modalNum: number, csNo: number) => {
    setCsNo(csNo);
    navigate("", { state: { main: 2, side: 2, modalNum: modalNum } });
    dispatch(modalToggle(modalNum));
  };

  if (!data.data) return null;

  return (
    <table id="starTable">
      <thead>
        <tr>
          <td className="p-16 w-200">날짜</td>
          <td className="p-16 w-180">상담원</td>
          <td className="p-16 w-130">⭐</td>
          <td className="p-16 w-130">⭐⭐</td>
          <td className="p-16 w-130">⭐⭐⭐</td>
          <td className="p-16 w-130">⭐⭐⭐⭐</td>
          <td className="p-16 w-130">⭐⭐⭐⭐⭐</td>
          <td className="p-16 w-130">총계</td>
          <td className="p-16 w-130">별점 평균</td>
        </tr>
      </thead>
      <tbody>
        {data.data.map((CTData, CTIdx) => {
          const CTLength = data.data.length;
          const isDate = CTIdx === 0;
          return (
            <tr key={CTIdx}>
              {isDate && <td rowSpan={CTLength + 1}>{data.date}</td>}
              <td>{CTData.cs_name}</td>
              <td>
                <span
                  className="cursor-pointer"
                  onClick={() => {
                    modalOpen(1, CTData.cs_no);
                  }}
                >
                  {CTData.one}
                </span>
              </td>
              <td>
                <span
                  className="cursor-pointer"
                  onClick={() => modalOpen(2, CTData.cs_no)}
                >
                  {CTData.two}
                </span>
              </td>
              <td>
                <span
                  className="cursor-pointer"
                  onClick={() => modalOpen(3, CTData.cs_no)}
                >
                  {CTData.three}
                </span>
              </td>
              <td>
                <span
                  className="cursor-pointer"
                  onClick={() => modalOpen(4, CTData.cs_no)}
                >
                  {CTData.four}
                </span>
              </td>
              <td>
                <span
                  className="cursor-pointer"
                  onClick={() => modalOpen(5, CTData.cs_no)}
                >
                  {CTData.five}
                </span>
              </td>
              <td>{CTData.sum}</td>
              <td>{CTData.avg}</td>
            </tr>
          );
        })}

        <tr>
          <td>총계</td>
          <td>{data.total.one}</td>
          <td>{data.total.two}</td>
          <td>{data.total.three}</td>
          <td>{data.total.four}</td>
          <td>{data.total.five}</td>
          <td>{data.total.sum}</td>
          <td>{data.total.avg}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default StarTable;
