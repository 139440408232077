import { useState, useEffect } from "react";

import Background from "Components/Atoms/Background/background";
import H2 from "Components/Atoms/H2/h2";
import Space from "Components/Atoms/Space/space";
import Button from "Components/Atoms/Button/button";
import { modalToggle } from "Store/Slices/modal";
import { ReducerType, useAppDispatch } from "Store/store";
import { ReactComponent as ModalX } from "Util/svg/modalX.svg";
import Input from "Components/Atoms/Input/input";
import { putVipApi } from "Apis/apis";
import LANG, { getLang } from "Util/lang";
import { useQueryClient } from "@tanstack/react-query";
import { GetQnAListApiBody, ReduxQnAOption } from "types/interface";
import { useSelector } from "react-redux";

const BlackListModal = ({
  serviceNo,
  userId,
}: {
  serviceNo: number;
  userId: string;
}) => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();

  const { date, ...searchoption }: ReduxQnAOption = useSelector(
    (state: ReducerType) => state.qnaSearchOption,
  );

  const reduxBody: GetQnAListApiBody = {
    startDate: date[0],
    endDate: date[1],
    ...searchoption,
  };

  const [memo, setMemo] = useState("");

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (memo === "") {
      alert("사유를 입력해주세요.");
      return;
    }

    const body = {
      serviceNo,
      userId,
      memo: memo,
      state: 1,
    };
    await putVipApi(body);
    queryClient.invalidateQueries(["QnaListApi", reduxBody]);
    dispatch(modalToggle(11));
  };

  return (
    <Background modalNum={11}>
      <div style={{ width: "640px" }} className="p-32 bg-f2f3f8">
        <div className="flex items-center justify-between">
          <H2 isPrimary={true} name="블랙리스트 사유 입력" />
          <div
            className="inline-block cursor-pointer"
            onClick={() => dispatch(modalToggle(11))}
          >
            <ModalX />
          </div>
        </div>
        <Space y={20} />
        <form className="flex" onSubmit={onSubmit}>
          <Input
            id="blackList"
            value={memo}
            onChange={(e) => {
              setMemo(e.target.value);
            }}
            width={70}
            placeholder="사유를 입력 해주세요."
          />
          <Space x={20} />
          <Button btnText={LANG[getLang()].register} type="submit" />
        </form>
      </div>
    </Background>
  );
};

export default BlackListModal;
