import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { ReducerType } from "Store/store";
import BreadCrumb from "./breadCrumb";
import Header from "./header";
import Sidebar from "./sidebar";

const Layout = ({ children }: { children: React.ReactNode }) => {
  const { pathname } = useLocation();
  const { mode } = useSelector((state: ReducerType) => state.layouts);

  const [nowPath, setNowPath] = useState(["account", "service"]); // 현재경로 url

  useEffect(() => {
    // 현재경로 [대제목,소제목]
    // url에 idx등이 파라미터로 있는 경우를 감안해서 slice(0, 2) 적용
    const newPath: string[] =
      pathname !== "/" ? pathname.substr(1).split("/").slice(0, 2) : ["", ""];

    setNowPath(newPath);
  }, [pathname]);

  const login = mode !== "logout" && "p-24 h-[calc(100vh-56px)]";
  return (
    <div id="layout" className="overflow-hidden">
      <Header />
      <div className="flex">
        <Sidebar nowPath={nowPath} />
        <div className={`flex-1 overflow-auto main bg-f2f3f8 ${login}`}>
          <BreadCrumb nowPath={nowPath} />
          {children}
        </div>
      </div>
    </div>
  );
};

export default Layout;
