import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Button from "Components/Atoms/Button/button";
import Select from "Components/Atoms/Select/select";
import Space from "Components/Atoms/Space/space";
import SearchInput from "Components/Molecules/SearchInput/searchInput";
import SearchResult from "Components/Atoms/SearchResult/searchResult";

import { getFaQListApi } from "Apis/apis";

import { ReducerType } from "Store/store";
import type { ColumnsType } from "antd/es/table";
import { FAQList, GetFaqListApi } from "types/interface.d";
import { Table } from "antd";

import useServiceNo from "Util/Hooks/useServiceIdx";
import LANG, { GetLang, getLang } from "Util/lang";
import { order } from "Util/function";

const FAQ = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const translate = GetLang();
  const serviceList = useSelector((state: ReducerType) =>
    state.serviceList
      .filter((el) => el.state !== 2 && el.state !== 1)
      .map((el) => (el.idx === -1 ? { ...el, label: "서비스 선택" } : el))
  );

  const companyNo = useSelector((state: ReducerType) => state.user.idx);
  const [keyword, setKeyword] = useState("");
  const [serviceNo, setServiceNo] = useServiceNo();
  const [faqList, setFaqList] = useState<FAQList[]>([]);

  const getFaQList = async () => {
    const { selectServiceNo = -1 } = location.state || {};
    setServiceNo(selectServiceNo);
    if (selectServiceNo === -1) return;
    const option: GetFaqListApi = {
      serviceNo: selectServiceNo,
      keyword,
      companyNo,
    };
    const {
      data: { faq },
    } = await getFaQListApi(option);
    setFaqList(faq);
  };

  const onSubmit = () => {
    if (serviceNo === -1) return alert("서비스를 선택해주세요.");
    navigate("", {
      state: { pageNo: 1, selectServiceNo: serviceNo },
    });
  };

  const columns: ColumnsType<FAQList> = [
    {
      title: translate("index"),
      dataIndex: "faq_no",
      align: "center",
      render: (now, row) => {
        return order(faqList, row.faq_no, "faq_no");
      },
    },
    {
      title: "서비스 로고",
      dataIndex: "service_logo",
      align: "center",
      width: 140,
      render: (now) => {
        const defalut = "../imgs/common/defaultService.png";
        return (
          <div className="flex justify-center">
            {now ? (
              <img src={now} className="w-80 h-80" alt="서비스 로고" />
            ) : (
              <img src={defalut} alt="서비스 로고" />
            )}
          </div>
        );
      },
    },
    {
      title: translate("serviceName"),
      dataIndex: "service_name",
      align: "center",
      render: (now) => now,
    },
    {
      title: translate("category"),
      dataIndex: "big_category_name",
      align: "center",
      render: (now: string, row: FAQList) => (
        <span>{`${now} > ${row.middle_category_name}`}</span>
      ),
    },
    {
      title: translate("title"),
      dataIndex: "title",
      align: "center",
      render: (now, row: FAQList) => {
        return (
          <span
            onClick={() => navigate(`/service/faqfix/${row.faq_no}`)}
            className="cursor-pointer"
          >
            {now}
          </span>
        );
      },
    },
    {
      title: translate("file"),
      dataIndex: "attached_file",
      align: "center",
      render: (now, row) => {
        return (
          <div className="flex flex-col">
            {now.map((el: any, idx: number) => {
              let fileName = "";
              if (row.original_filename) {
                fileName = row.original_filename[idx];
              }

              return (
                <a
                  href={el + "."}
                  download={row.original_filename}
                  key={idx}
                  target="_self"
                >
                  {fileName}
                </a>
              );
            })}
          </div>
        );
      },
    },
    {
      title: translate("date"),
      dataIndex: "date",
      align: "center",
    },
  ];

  useEffect(() => {
    getFaQList();
  }, [location.state]);

  return (
    <div id="faq">
      <div className="flex">
        <Select dataList={serviceList} val={serviceNo} setVal={setServiceNo} />
        <Space x={20} />
        <SearchInput
          onSubmit={onSubmit}
          value={keyword}
          setValue={setKeyword}
          size="xl"
          require={false}
        />
      </div>
      <Space y={20} />
      <div className="flex items-center justify-between">
        <SearchResult max={faqList.length} />
        <Link to="/service/faqadd">
          <Button btnText={`+ ${LANG[getLang()].register}`} />
        </Link>
      </div>
      <Space y={20} />
      <Table rowKey="faq_no" columns={columns} dataSource={faqList} />
    </div>
  );
};

export default FAQ;
