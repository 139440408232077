import React from "react";
import { ReactComponent as ModalX } from "Util/svg/modalX.svg";
import Background from "Components/Atoms/Background/background";
import { useDispatch } from "react-redux";
import { modalToggle } from "Store/Slices/modal";
import H2 from "Components/Atoms/H2/h2";
import Space from "Components/Atoms/Space/space";
import Button from "Components/Atoms/Button/button";
import parse from "html-react-parser";
import Label from "Components/Atoms/Label/label";

const PreviewBannerModal = ({
  imgUrl,
  screen,
  title,
  footer,
  modalNum,
}: {
  imgUrl: string;
  screen: number;
  title: string;
  footer: string;
  modalNum: number;
}) => {
  const dispatch = useDispatch();
  const nextPage = () => {
    dispatch(modalToggle(1));
    dispatch(modalToggle(2));
  };
  return (
    <Background modalNum={modalNum}>
      <div
        style={{ width: "600px", height: "850px" }}
        className="relative p-32 overflow-auto login-bg pretty-scrollbar"
      >
        <div className="flex justify-end h-26">
          <div
            onClick={() => dispatch(modalToggle(1))}
            className="inline-block cursor-pointer"
          >
            <div className="brightness-[10]">
              <ModalX />
            </div>
          </div>
        </div>
        <div className="absolute bg-white w-460 p-center ">
          {imgUrl ? (
            screen ? (
              <img src={imgUrl} alt="서비스배너" />
            ) : (
              <img
                className="object-cover w-full h-260"
                src={imgUrl}
                alt="서비스배너"
              />
            )
          ) : null}

          <div className="px-40 py-48">
            <H2 name={title} />
            <Space y={32} />
            <div className="flex flex-col gap-16">
              <div>
                <div className="flex justify-between">
                  <Label
                    name="FAQ 자주 묻는 질문"
                    labelfor="d"
                    fontSize={16}
                    fontBold="bold"
                  />
                  <span>전체보기</span>
                </div>
                <Space y={8} />
                <ul className="pt-8 border-gray-500 border-y-5">
                  <li className="mb-10 bold text-14">FAQ 1</li>
                  <li className="mb-10 bold text-14">FAQ 2</li>
                  <li className="mb-10 bold text-14">FAQ 3</li>
                </ul>
              </div>
              <Button btnText="1:1문의" full onClick={nextPage} />
              <Button btnText="약관/정책" full />
            </div>
            <Space y={32} />
            <p className="text-center text-black text-12 ">
              {parse(footer || "")}
            </p>
          </div>
        </div>
      </div>
    </Background>
  );
};

export default PreviewBannerModal;
