import { createContext, useState } from "react";
import { Template } from "types/interface";

export const tpContext = createContext({
  TPList: [{} as Template],
  TP: {} as Template,
  setTPListHandler: (tp: Template[]) => {},
  setTPHandler: (tp: Template) => {},
});

const TPContext = ({ children }: any) => {
  const [TPList, setTPList] = useState<Template[]>([]);
  const [TP, setTP] = useState<Template>({} as Template);
  const setTPListHandler = (tp: Template[]) => {
    setTPList(tp);
  };
  const setTPHandler = (tp: Template) => {
    setTP(tp);
  };

  return (
    <tpContext.Provider value={{ TPList, TP, setTPListHandler, setTPHandler }}>
      {children}
    </tpContext.Provider>
  );
};

export default TPContext;
