import Button from "Components/Atoms/Button/button";
import RotateElement from "Components/Atoms/RotateElement/rotateElement";
import Select from "Components/Atoms/Select/select";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { MENU } from "routes";
import { ReducerType } from "Store/store";
import { Menu, MenuChild, ServiceListForm } from "types/interface";
import { ReactComponent as ArrowSVG } from "Util/svg/arrow.svg";
import { ClickInputForm } from "types/interface";
import useServiceNo from "Util/Hooks/useServiceIdx";
import Space from "Components/Atoms/Space/space";
/**
 * 사이드바 섹션
 * @param menu 섹션 데이터
 * @param nowPath 현재 url정보
 */
const MenuComp = ({ menu, nowPath }: { menu: Menu; nowPath: string[] }) => {
  const [isOpen, setIsOpen] = useState(false); // 오픈 여부 boolean
  const notice = useSelector((state: ReducerType) => state.addupNotice);
  const checkPath = () => {
    const bool = nowPath[0] === menu.path ? true : false;
    setIsOpen(bool);
  };
  useEffect(() => {
    checkPath();
  }, [nowPath]);
  return (
    <div className="mb-24">
      <button
        onClick={() => setIsOpen((spread) => !spread)}
        className="flex items-center justify-between w-full"
      >
        <div className="flex items-center">
          <menu.svg />
          {menu.name === "정보" ? (
            notice ? (
              <div className="flex gap-10">
                <h1 className="ml-12 font-bold text-fbf9f9">{menu.name}</h1>
                <div className=" min-w-24 h-24 rounded-[50%] bg-[#ff4947]">
                  {notice}
                </div>
              </div>
            ) : (
              <h1 className="ml-12 font-bold text-fbf9f9">{menu.name}</h1>
            )
          ) : (
            <h1 className="ml-12 font-bold text-fbf9f9">{menu.name}</h1>
          )}
        </div>
        <RotateElement svg={<ArrowSVG />} isRotate={isOpen} />
      </button>
      {isOpen && (
        <ul className="mt-20 ml-36 mb-30 text-969696">
          {menu.child
            .filter((child: MenuChild) => child.sidebar)
            .map((child: MenuChild) => {
              return (
                <li
                  key={`${menu.path}-${child.path}`}
                  className={`mb-10 transition03s hover:text-fbf9f9 ${
                    nowPath[0] === menu.path &&
                    nowPath[1] === child.path &&
                    "text-fbf9f9"
                  }`}
                >
                  {child.name === "CSBYE 공지사항" ? (
                    notice ? (
                      <div className="flex">
                        <Link
                          className="block w-full"
                          to={`${menu.path}/${child.path}`}
                        >
                          {child.name}
                        </Link>
                        <div className=" min-w-24 h-24 rounded-[50%] bg-[#ff4947] text-center">
                          {notice}
                        </div>
                      </div>
                    ) : (
                      <Link
                        className="block w-full"
                        to={`${menu.path}/${child.path}`}
                      >
                        {child.name}
                      </Link>
                    )
                  ) : (
                    <Link
                      className="block w-full"
                      to={`${menu.path}/${child.path}`}
                    >
                      {child.name}
                    </Link>
                  )}
                </li>
              );
            })}
        </ul>
      )}
    </div>
  );
};
const Sidebar = ({ nowPath }: { nowPath: string[] }) => {
  const { sidebar } = useSelector((state: ReducerType) => state.layouts);
  const serviceList = useSelector((state: ReducerType) => state.serviceList);
  const serviceFilter = serviceList.filter(
    (el: ServiceListForm) => el.state === 0,
  );
  const selectList = [
    { idx: -1, label: "고객 포털 바로가기" },
    ...serviceFilter,
  ];

  const goToMunu = (key: number) => {
    if (key === -1) return;
    const url = window.location.origin;
    window.open(`${url}/menu/${key}`, "_blank");
  };

  if (!sidebar) return <></>;
  return (
    <nav
      id="sidebar"
      className="pretty-scrollbar p-24  bg-primary w-230 h-[calc(100vh-56px)] flex flex-col overflow-y-auto"
    >
      <div className="text-white">
        {MENU.map((menu: Menu) => {
          return <MenuComp key={menu.path} menu={menu} nowPath={nowPath} />;
        })}
      </div>
      <div className="flex flex-col items-center gap-10">
        <Select
          dataList={selectList}
          val={-1}
          onChange={goToMunu}
          maxH="max-h-100"
          full
        />
        <a
          className="w-full"
          href="http://csbye.com/"
          target="_blank"
          rel="noreferrer"
        >
          <Button btnText={"CSBYE.COM"} color="normal" full size="md" />
        </a>
        <Space y={8} />
        <p className="text-center text-white">
          CSBYE 플랫폼 이용에 어려움이 있으신가요?
        </p>
        <a
          href="https://pf.kakao.com/_KxjmxhG"
          target="_blank"
          rel="noreferrer"
          className="block w-full"
        >
          <Button btnText={"1:1문의"} color="normal" full size="md" />
        </a>
      </div>
    </nav>
  );
};

export default Sidebar;
