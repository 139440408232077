import { useState, useEffect } from "react";

import { useSelector } from "react-redux";
import { ReducerType } from "Store/store";
import { PolicyApiBody, RadioForm } from "types/interface";
import { ReactComponent as Require } from "Util/svg/require.svg";
import Label from "Components/Atoms/Label/label";
import Space from "Components/Atoms/Space/space";
import LabelQuill from "Components/Molecules/LabelQuill/labelQuill";
import InputRowRadio from "Components/Molecules/InputRowRadio/inputRowRadio";
import Button from "Components/Atoms/Button/button";
import { useNavigate, useParams } from "react-router-dom";
import { delPolicyApi, getPolicyDetailApi, putPolicyApi } from "Apis/apis";
import InputRow from "Components/Molecules/InputRow/inputRow";
import LANG, { getLang } from "Util/lang";

const STATE_DATA: RadioForm[] = [
  { idx: 1, label: LANG[getLang()].waiting, id: "wait" },
  { idx: 0, label: LANG[getLang()].approval, id: "ok" },
];
const PolicyFix = () => {
  const navigate = useNavigate();
  const { idx = 0 } = useParams();
  const serviceList = useSelector((state: ReducerType) => state.serviceList);

  const [text, setText] = useState("");
  const [state, setState] = useState(-1);
  const [serviceName, setServiceName] = useState("");
  const [title, setTitle] = useState("");
  const [serviceNo, setServiceNo] = useState(0);

  const getPolicyDetail = async () => {
    if (idx === 0) return;

    const {
      data: { terms },
    } = await getPolicyDetailApi(+idx);

    setText(terms.contents);
    setState(terms.state);
    setTitle(terms.title);
    setServiceNo(terms.service_no);
    const service = serviceList.find((el) => el.idx === terms.service_no)!;

    setServiceName(service.label);
  };

  const putPolicy = async () => {
    const confirm = window.confirm("수정 하시겠습니까?");
    if (!confirm) return;

    const body: PolicyApiBody = {
      serviceNo,
      contents: text,
      state: +state,
      title,
    };
    await putPolicyApi(+idx, body);
    navigate("/service/potalboard", {
      state: { pageNo: 2, serviceNo: serviceNo },
    });
  };

  const delPolicy = async () => {
    const confirm = window.confirm("삭제 하시겠습니까?");
    if (!confirm) return;

    await delPolicyApi(+idx);
    navigate("/service/potalboard", {
      state: { pageNo: 2, selectServiceNo: serviceNo },
    });
  };

  useEffect(() => {
    getPolicyDetail();
  }, [serviceList]);

  return (
    <>
      <div className="rounded bg-ffffff">
        <div className="flex items-center">
          <div className="flex items-center px-16 py-20 bg-e5e6ed w-130">
            <Label labelfor="select" name="분류" fontSize={16} />
            <Space x={4} />
            <Require />
          </div>
          <div className="flex-1 h-full bg-white p-13">{serviceName}</div>
        </div>
        <InputRow
          id="policyAdd"
          value={title}
          setValue={setTitle}
          name={LANG[getLang()].title}
        />
        <LabelQuill
          id="faqQuill"
          name={LANG[getLang()].content}
          text={text}
          setText={setText}
        />
        <InputRowRadio
          data={STATE_DATA}
          name={LANG[getLang()].state}
          value={state}
          setValue={setState}
        />
      </div>
      <div className="flex justify-end gap-10 mt-10 ">
        <Button btnText={LANG[getLang()].change} onClick={putPolicy} />
        <Button
          btnText={LANG[getLang()].list}
          onClick={() =>
            navigate(`/service/potalboard`, {
              state: { pageNo: 2, selectServiceNo: serviceNo },
            })
          }
        />
        <Button
          btnText={LANG[getLang()].delete}
          color="delete"
          onClick={delPolicy}
        />
      </div>
    </>
  );
};

export default PolicyFix;
