import Background from "Components/Atoms/Background/background";

import { modalToggle } from "Store/Slices/modal";
import { ReducerType, useAppDispatch } from "Store/store";
import { ReactComponent as ModalX } from "Util/svg/modalX.svg";
import { ReactComponent as TrnaseSVG } from "Util/svg/trnase.svg";
import { Translation } from "types/interface";
import { useSelector } from "react-redux";
import H2 from "Components/Atoms/H2/h2";
import Space from "Components/Atoms/Space/space";
import Transe from "Components/Atoms/Transe/transe";

const TextModal = () => {
  const dispatch = useAppDispatch();

  const transe: Translation = useSelector(
    (state: ReducerType) => state.translate
  );

  return (
    <Background modalNum={13}>
      <div style={{ width: "640px" }} className="p-32 bg-f2f3f8">
        <div className="flex items-center justify-between">
          <H2 isPrimary={true} name="번역" />
          <div
            className="inline-block cursor-pointer"
            onClick={() => dispatch(modalToggle(13))}
          >
            <ModalX />
          </div>
        </div>
        <Space y={20} />

        <Transe type={0} text={transe.original} />
        <div className="flex justify-center my-8">
          <TrnaseSVG />
        </div>
        <Transe type={1} text={transe.transed} />
      </div>
    </Background>
  );
};

export default TextModal;
