import { ReducerType } from "Store/store";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const LANG: any = {
  kr: {
    serviceCenter: "고객상담 센터",
    inquiry: "문의/답변",
    personalInfo: "개인정보 수정",
    logout: "로그아웃",
    lCategory: "대 카테고리",
    mCategory: "중 카테고리",
    all: "전체",
    replyComplete: "답변완료",
    outStanding: "미처리",
    hide: "숨김",
    temporarySave: "임시답변",
    userID: "사용자ID",
    nickName: "닉네임",
    eMail: "이메일",
    title: "제목",
    content: "내용",
    search: "검색",
    fold: "접기",
    index: "번호",
    category: "카테고리",
    assistantID: "상담원ID",
    vip: "VIP",
    date: "날짜",
    state: "상태",
    userUID: "고객UID(닉네임)",
    assistant: "상담원",
    change: "수정",
    select: "선택",
    assign: "할당",
    attachedFile: "첨부파일",
    pathCondition: "경로환경",
    phoneNumber: "핸드폰 번호",
    mobileDevice: "휴대기기",
    assistanceDetail: "상담내역",
    recipient: "받는사람",
    tamplate: "템플릿",
    delete: "삭제",
    communal: "공용",
    individual: "개인",
    latest: "최신순",
    aTOz: "ㄱ-ㅎ",
    registerTemplate: "템플릿 등록",
    replyTitle: "답변 제목",
    service: "서비스",
    authority: "권한",
    selectFile: "파일 선택",
    deleteFile: "파일 삭제",
    preview: "미리보기",
    sendReply: "답변전송",
    reserveSend: "예약 전송",
    confirm: "확인",
    passward: "비밀번호",
    name: "이름",
    phone: "핸드폰",
    company: "회사",
    department: "부서",
    corporateLogo: "기업 로고",
    changePW: "비밀번호 수정",
    changeInfo: "정보수정",
    information: "정보",
    account: "계정",
    serviceId: "서비스ID",
    serviceName: "서비스명",
    serviceNameRegisteration: "서비스명 등록",
    autoResponseMail: "자동응답메일",
    register: "등록",
    cancel: "취소",
    waiting: "대기",
    approval: "승인",
    stop: "정지",
    assistantaccountRegisteration: "상담원 계정 등록",
    passwordConfirmation: "비밀번호 확인",
    profileImage: "프로필 이미지",
    serviceInCharge: "담당 서비스",
    list: "목록",
    read: "읽기",
    write: "쓰기",
    duplicateCheck: "중복체크",
    registrationDate: "등록일",
    allService: "전체 서비스",
    work: "작업",
    responder: "템플릿",
    enterBlacklistReason: "블랙리스트 사유 입력",
    serviceCenterSettings: "상담센터 설정",
    alarmServiceSettings: "알림 서비스 설정",
    telegram: "텔레그램",
    customerListManagement: "고객 리스트 관리",
    ticketManagement: "티켓 관리",
    ticketAssignment: "티켓 할당",
    autoAnswerSettings: "자동 답변 설정",
    emailDesign: "이메일 디자인",
    statistics: "통계",
    statisticsPage: "통계 페이지",
    inquiryByDate: "문의 일별",
    inquiryAccount: "문의 계정",
    byCategory: "카테고리별",
    starRatings: "별점",
    allInquiries: "전체문의",
    beforeProcess: "처리전",
    sum: "합계",
    downloadExel: "엑셀 다운로드",
    dashBoard: "대시보드",
    userInquiry: "고객문의",
    noticeBoard: "게시판",
    fAQ: "FAQ",
    termofUse: "약관/정책",
    file: "파일",
    noticeTitle: "공지사항 제목",
    editNotice: "공지사항 수정",
    link: "링크",
    seeMore: "자세히 보기",
    pageSettings: "페이지 설정",
    bannerManagement: "배너관리",
    location: "위치",
    inquiries: "문의",
    mail: "메일",
    bannerImage: "배너 이미지",
    locationClassification: "위치구분",
    paymentDetails: "결제내역",
    notice: "공지사항",
    categoryName: "카테고리 명",
    etc: "기타",
    korean: "한국어",
    english: "영어",
    traditionalChinese: "중국어 간체",
    simplifiedChinese: "중국어 번체",
    japanese: "일본어",
  },
  en: {
    serviceCenter: "Costomer Service Center",
    inquiry: "Inquiry/Reply",
    personalInfo: "Change Personal Info",
    logout: "Log out",
    lCategory: "L Category",
    mCategory: "M Category",
    all: "All",
    register: "Register",
    replyComplete: "Reply Complete",
    outStanding: "Outstanding",
    hide: "Hide",
    temporarySave: "Temporary Save",
    userID: "User ID",
    nickName: "Nickname",
    eMail: "E-Mail",
    title: "Title",
    content: "Content",
    search: "Search",
    fold: "Fold",
    index: "NO.",
    category: "Category",
    assistantID: "Assistant ID",
    vip: "VIP",
    date: "Date",
    state: "State",
    userUID: "User UID(Nickname)",
    assistant: "Assistant",
    change: "Change",
    select: "Select",
    assign: "Assign",
    attachedFile: "Attached File",
    pathCondition: "Path Condition",
    phoneNumber: "Phone Number",
    mobileDevice: "Mobile Device",
    assistanceDetail: "Assistance Detail",
    recipient: "Recipient",
    tamplate: "Tamplate",
    delete: "Delete",
    communal: "Communal",
    individual: "Individual",
    latest: "Latest ",
    aTOz: "A-Z",
    registerTemplate: "Register Template",
    replyTitle: "Reply Title",
    service: "Service",
    authority: "Authority",
    selectFile: "Select File",
    deleteFile: "Delete File",
    preview: "Preview",
    sendReply: "Send Reply",
    reserveSend: "Reserve Send",
    confirm: "Confirm",
    passward: "Passward",
    name: "Name",
    phone: "Phone",
    company: "Company",
    department: "Department",
    corporateLogo: "Corporate Logo",
    changePW: "Change PW",
    changeInfo: "Change Info",
    information: "Information",
    account: "Account",
    serviceId: "Service ID",
    serviceName: "Service Name",
    serviceNameRegisteration: "Service Name Registeration",
    autoResponseMail: "Auto Response Mail",
    cancel: "Cancel",
    waiting: "Waiting",
    approval: "Approval",
    stop: "Stop",
    assistantaccountRegisteration: "Assistant Account Registeration",
    passwordConfirmation: "Password Confirmation",
    profileImage: "Profile Image",
    serviceInCharge: "Service In Charge",
    list: "List",
    read: "Read",
    write: "Write",
    duplicateCheck: "Duplicate Check",
    registrationDate: "Registration Date",
    allService: "All Service",
    work: "Work",
    responder: "Responder",
    enterBlacklistReason: "Enter Blacklist Reason",
    serviceCenterSettings: "Service Center Settings",
    alarmServiceSettings: "Alarm Service Settings",
    telegram: "Telegram",
    customerListManagement: "Customer List Management",
    ticketManagement: "Ticket Management",
    ticketAssignment: "Ticket Assignment",
    autoAnswerSettings: "Auto Answer Settings",
    emailDesign: "E-Mail Design",
    statistics: "Statistics",
    statisticsPage: "Statistics Page",
    inquiryByDate: "Inquiry By Date",
    inquiryAccount: "Inquiry Account",
    byCategory: "By Category",
    starRatings: "Star Ratings",
    allInquiries: "All Inquiries",
    beforeProcess: "Before Process",
    sum: "Sum",
    downloadExel: "Download Exel",
    dashBoard: "Dash Board",
    userInquiry: "UserInquiry",
    noticeBoard: "Notice Board",
    fAQ: "FAQ",
    termofUse: "Term of Use",
    file: "File",
    noticeTitle: "Notice Title",
    editNotice: "Edit Notice",
    link: "Link",
    seeMore: "See More",
    pageSettings: "Page Settings",
    bannerManagement: "Banner Management",
    location: "Location",
    inquiries: "Inquiries",
    mail: "Mail",
    bannerImage: "Banner Image",
    locationClassification: "Location Classification",
    paymentDetails: "Payment Details",
    notice: "Notice",
    categoryName: "Category Name",
    etc: "etc",
    korean: "Korean",
    english: "English",
    traditionalChinese: "Traditional Chinese",
    simplifiedChinese: "Simplified Chinese",
    japanese: "Japanese",
  },
  ja: {
    serviceCenter: "お客様サポート",
    inquiry: "お問い合わせ/ご返答",
    personalInfo: "個人情報修正",
    logout: "ログアウト",
    lCategory: "大カテゴリ",
    mCategory: "中カテゴリ",
    all: "全体",
    register: "受付",
    replyComplete: "返答完了",
    outStanding: "未処理",
    hide: "隠す",
    temporarySave: "臨時保存",
    userID: "ユーザーID",
    nickName: "ニックネーム",
    eMail: "メール",
    title: "タイトル",
    content: "内容",
    search: "検索",
    fold: "要約",
    index: "番号",
    category: "カテゴリ",
    assistantID: "担当者ID",
    vip: "VIP",
    date: "日時",
    state: "状態",
    userUID: "お客様UID(ニックネーム)",
    assistant: "担当者",
    change: "修正",
    select: "選択",
    assign: "割当て",
    attachedFile: "添付ファイル",
    pathCondition: "経路環境",
    phoneNumber: "スマホ番号",
    mobileDevice: "携帯機器",
    assistanceDetail: "相談内訳",
    recipient: "宛先",
    tamplate: "テンプレート",
    delete: "削除",
    communal: "共用",
    individual: "個人",
    latest: "最新順",
    aTOz: "ㄱ-ㅎ",
    registerTemplate: "テンプレート登録",
    replyTitle: "ご返答タイトル",
    service: "サービス",
    authority: "権限",
    selectFile: "ファイル選択",
    deleteFile: "ファイル削除",
    preview: "プレビュー",
    sendReply: "ご返答送信",
    reserveSend: "予約送信",
    confirm: "確認",
    passward: "パスワード",
    name: "名前",
    phone: "スマホ",
    company: "会社",
    department: "部署",
    corporateLogo: "企業ロゴ",
    changePW: "パスワード修正",
    changeInfo: "情報修正",
    information: "情報",
    account: "アカウント",
    serviceId: "サービスID",
    serviceName: "サービス名",
    serviceNameRegisteration: "サービス名登録",
    autoResponseMail: "留守番メール",
    cancel: "取消",
    waiting: "待機.",
    approval: "承認",
    stop: "停止",
    assistantaccountRegisteration: "担当者アカウント登録",
    passwordConfirmation: "パスワード確認",
    profileImage: "プロフィールイメージ",
    serviceInCharge: "担当サービス",
    list: "目録",
    read: "読む",
    write: "書く",
    duplicateCheck: "重複チェック",
    registrationDate: "登録日",
    allService: "全体サービス",
    work: "作業",
    responder: "返答機",
    enterBlacklistReason: "ブラックリスト理由入力",
    serviceCenterSettings: "相談センター設定",
    alarmServiceSettings: "お知らせサービス設定",
    telegram: "テレグラム",
    customerListManagement: "お客様リスト管理",
    ticketManagement: "チケット管理",
    ticketAssignment: "チケット割当て",
    autoAnswerSettings: "自動返答設定",
    emailDesign: "メールデザイン",
    statistics: "通計",
    statisticsPage: "通計ページ",
    inquiryByDate: "お問い合わせ日別",
    inquiryAccount: "お問い合わせアカウント",
    byCategory: "カテゴリー別",
    starRatings: "星点",
    allInquiries: "全体お問い合わせ",
    beforeProcess: "処理前",
    sum: "合計",
    downloadExel: "エクセルダウンロード",
    dashBoard: "ダッシュボード",
    userInquiry: "お客様へのお問い合わせ",
    noticeBoard: "掲示板",
    fAQ: "FAQ",
    termofUse: "利用規約",

    file: "ファイル選択",
    noticeTitle: "お知らせ事項タイトル",
    editNotice: "お知らせ事項修正",
    link: "リンク",
    seeMore: "詳しく見る",
    pageSettings: "ページ設定",
    bannerManagement: "バナー管理",
    location: "位置",
    inquiries: "お問い合わせ",
    mail: "メール",
    bannerImage: "バナーイメージ",
    locationClassification: "位置区分",
    paymentDetails: "決済内訳",
    notice: "お知らせ事項",
    categoryName: "カテゴリー名",
    etc: "その他",
    korean: "韓国語",
    english: "英語",
    traditionalChinese: "中国語簡体字",
    simplifiedChinese: "中国語繫体字",
    japanese: "日本語",
  },
  cn: {
    serviceCenter: "客戶諮詢中心",
    inquiry: "查詢/回答",
    personalInfo: "編輯個人信息",
    logout: "登出",
    lCategory: "大 範疇",
    mCategory: "中 範疇",
    all: "全部",
    register: "受理",
    replyComplete: "回答完畢",
    outStanding: "未處理",
    hide: "隱藏",
    temporarySave: "臨時存儲",
    userID: "用戶ID",
    nickName: "暱稱",
    eMail: "郵件",
    title: "題目",
    content: "內容",
    search: "搜索",
    fold: "折疊",
    index: "號碼",
    category: "範疇",
    assistantID: "接線員ID",
    vip: "VIP",
    date: "日期",
    state: "狀態",
    userUID: "顧客UID(暱稱)",
    assistant: "接線員",
    change: "修改",
    select: "選擇",
    assign: "分配",
    attachedFile: "文件附加",
    pathCondition: "路徑環境",
    phoneNumber: "電話號碼",
    mobileDevice: "移動設備",
    assistanceDetail: "諮詢詳情",
    recipient: "接收人",
    tamplate: "模板",
    delete: "刪除",
    communal: "通用",
    individual: "個人",
    latest: "最新順序",
    aTOz: "A-Z",
    registerTemplate: "註冊模板",
    replyTitle: "回复題目",
    service: "服務",
    authority: "權限",
    selectFile: "文件選擇",
    deleteFile: "文件刪除",
    preview: "預覽",
    sendReply: "發送回复",
    reserveSend: "預約傳輸",
    confirm: "確認",
    passward: "密碼",
    name: "姓名",
    phone: "手機",
    company: "公司",
    department: "部門",
    corporateLogo: "企業標誌",
    changePW: "修改密碼",
    changeInfo: "信息修改",
    information: "信息",
    account: "賬號",
    serviceId: "服務ID",
    serviceName: "服務名",
    serviceNameRegisteration: "註冊服務名",
    autoResponseMail: "自動回復郵件",
    cancel: "取消",
    waiting: "待機",
    approval: "同意",
    stop: "停止",
    assistantaccountRegisteration: "註冊咨詢員賬號",
    passwordConfirmation: "確認密碼",
    profileImage: "個人簡介圖片",
    serviceInCharge: "負責服務",
    list: "目錄",
    read: "讀",
    write: "寫",
    duplicateCheck: "重複核對",
    registrationDate: "註冊日",
    allService: "全部服務",
    work: "工作",
    responder: "答復日",
    enterBlacklistReason: "加入黑名單原因",
    serviceCenterSettings: "設置咨詢中心",
    alarmServiceSettings: "設置通知服務",
    telegram: "電報",
    customerListManagement: "顧客列表管理",
    ticketManagement: "票券管理",
    ticketAssignment: "票券分配",
    autoAnswerSettings: "設置自動回復",
    emailDesign: "郵件設計",
    statistics: "統計",
    statisticsPage: "統計頁面",
    inquiryByDate: "按日期查詢",
    inquiryAccount: "查詢帳號",
    byCategory: "按分類",
    starRatings: "星級評定",
    allInquiries: "全部查詢",
    beforeProcess: "處理前",
    sum: "總計",
    downloadExel: "表格下載",
    dashBoard: "儀表板",
    userInquiry: "客戶諮詢",
    noticeBoard: "公告欄",
    fAQ: "FAQ",
    termofUse: "使用條款",
    file: "文件",
    noticeTitle: "公告事項標題",
    editNotice: "公告事項編輯",
    link: "鏈接",
    seeMore: "查看更多",
    pageSettings: "頁面設置",
    bannerManagement: "橫幅管理",
    location: "位置",
    inquiries: "咨詢",
    mail: "郵件",
    bannerImage: "橫幅圖片",
    locationClassification: "位置劃分",
    paymentDetails: "付款詳情",
    notice: "公告事項",
    categoryName: "分類名稱",
    etc: "其他",
    korean: "韓國語",
    english: "英語",
    traditionalChinese: "中國語簡體",
    simplifiedChinese: "中國語繁體",
    japanese: "日本語",
  },
  tw: {
    serviceCenter: "客户咨询中心",
    inquiry: "查询/回答",
    personalInfo: "编辑个人信息",
    logout: "登出",
    lCategory: "大 范畴",
    mCategory: "中 范畴",
    all: "全部",
    register: "受理",
    replyComplete: "回答完毕",
    outStanding: "未处理",
    hide: "隐藏",
    temporarySave: "临时存储",
    userID: "用户ID",
    nickName: "昵称",
    eMail: "邮件",
    title: "题目",
    content: "内容",
    search: "搜索",
    fold: "折叠",
    index: "号码",
    category: "范畴",
    assistantID: "接线员ID",
    vip: "VIP",
    date: "日期",
    state: "状态",
    userUID: "顾客UID(昵称)",
    assistant: "接线员",
    change: "修改",
    select: "选择",
    assign: "分配",
    attachedFile: "文件附加",
    pathCondition: "路径环境",
    phoneNumber: "电话号码",
    mobileDevice: "移动设备",
    assistanceDetail: "咨询详情",
    recipient: "接收人",
    tamplate: "模板",
    delete: "删除",
    communal: "通用",
    individual: "个人",
    latest: "最新顺序",
    aTOz: "A-Z",
    registerTemplate: "注册模板",
    replyTitle: "回复题目",
    service: "服务",
    authority: "权限",
    selectFile: "文件选择",
    deleteFile: "文件删除",
    preview: "预览",
    sendReply: "发送回复",
    reserveSend: "预约传输",
    confirm: "确认",
    passward: "密码",
    name: "姓名",
    phone: "手机",
    company: "公司",
    department: "部门",
    corporateLogo: "企业标志",
    changePW: "修改密码",
    changeInfo: "信息修改",
    information: "信息",
    account: "账号",
    serviceId: "服务ID",
    serviceName: "服务名",
    serviceNameRegisteration: "注册服务名",
    autoResponseMail: "自动回复邮件",
    cancel: "取消",
    waiting: "待机",
    approval: "同意",
    stop: "停止",
    assistantaccountRegisteration: "注册咨询员账号",
    passwordConfirmation: "确认密码",
    profileImage: "个人简介图片",
    serviceInCharge: "负责服务",
    list: "目录",
    read: "读",
    write: "写",
    duplicateCheck: "重复核对",
    registrationDate: "注册日",
    allService: "全部服务",
    work: "工作",
    responder: "答复日",
    enterBlacklistReason: "加入黑名单原因",
    serviceCenterSettings: "设置咨询中心",
    alarmServiceSettings: "设置通知服务",
    telegram: "电报",
    customerListManagement: "顾客列表管理",
    ticketManagement: "票券管理",
    ticketAssignment: "票券分配",
    autoAnswerSettings: "设置自动回复",
    emailDesign: "邮件设计",
    statistics: "统计",
    statisticsPage: "统计页面",
    inquiryByDate: "按日期查询",
    inquiryAccount: "查询帐号",
    byCategory: "按分类",
    starRatings: "星级评定",
    allInquiries: "全部查询",
    beforeProcess: "处理前",
    sum: "总计",
    downloadExel: "表格下载",
    dashBoard: "仪表板",
    userInquiry: "客户咨询",
    noticeBoard: "公告栏",
    fAQ: "FAQ",
    termofUse: "使用条款",
    file: "文件",
    noticeTitle: "公告事项标题",
    editNotice: "公告事项编辑",
    link: "链接",
    seeMore: "查看更多",
    pageSettings: "页面设置",
    bannerManagement: "横幅管理",
    location: "位置",
    inquiries: "咨询",
    mail: "邮件",
    bannerImage: "横幅图片",
    locationClassification: "位置划分",
    paymentDetails: "付款详情",
    notice: "公告事项",
    categoryName: "分类名称",
    etc: "其他",
    korean: "韩国语",
    english: "英语",
    traditionalChinese: "中国语简体",
    simplifiedChinese: "中国语繁体",
    japanese: "日本语",
  },
};
export const getLang = () => {
  const langKey = process.env.REACT_APP_LANG || "";
  const lang = () => +localStorage.getItem(langKey)! || 1;

  switch (lang()) {
    case 1:
      return "kr";
    case 2:
      return "en";
    case 3:
      return "ja";
    case 4:
      return "cn";
    case 5:
      return "tw";

    default:
      return "kr";
  }
};

export const GetLang = ()  => {
  const lang = useSelector((state: ReducerType) => state.langIdx);
  const LANGOBJ: { [idx: number]: string } = {
    1: "kr",
    2: "en",
    3: "ja",
    4: "cn",
    5: "tw",
  };

  const translate  = (keywords:string) => {
    return LANG[LANGOBJ[lang]][keywords]
  }

  return translate ;
};
export default LANG;
