import { useState, useEffect, useContext } from "react";
import { Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";

import Background from "Components/Atoms/Background/background";
import H2 from "Components/Atoms/H2/h2";
import Space from "Components/Atoms/Space/space";
import Button from "Components/Atoms/Button/button";
import Radio from "Components/Atoms/Radio/radio";
import { modalToggle } from "Store/Slices/modal";
import { useAppDispatch } from "Store/store";
import { RadioForm, Template } from "types/interface";
import { htmlToText } from "Util/function";
import { ReactComponent as ModalX } from "Util/svg/modalX.svg";
import { ReactComponent as StarSVG } from "Util/svg/star.svg";
import { ReactComponent as EditSVG } from "Util/svg/edit.svg";
import { tpContext } from "Util/Hooks/useTPContext";
import { delTPBookmarkApi, postTPBookmarkApi } from "Apis/apis";
import LANG, { getLang } from "Util/lang";

const stateRadio: RadioForm[] = [
  { idx: 0, label: LANG[getLang()].communal, id: "public" },
  { idx: 1, label: LANG[getLang()].individual, id: "private" },
];

/**
 * 모달 molecule
 *
 */
const TemplateModal = ({
  onClickTemplate,
  serviceNo,
}: {
  onClickTemplate: (contents: string) => void;
  serviceNo: number;
}) => {
  const dispatch = useAppDispatch();
  const { TPList, setTPListHandler, setTPHandler } = useContext(tpContext);

  const [normalTPList, setNormalTPList] = useState<any>([]);
  const [bookmarkTPList, setBookmarkTPList] = useState<any>([]);
  const [state, setState] = useState(0);
  const [sort, setSort] = useState(1); // 1:시간순, 2:시간 역순, 3:ㄱㄴㄷ순, 4:ㄱㄴㄷ역순

  // 템플릿리스트 분기
  const getTemplateList = async () => {
    const normalTp = TPList.filter((el) => !el.bookmark);
    const starTp = TPList.filter((el) => el.bookmark);

    setNormalTPList(normalTp);
    setBookmarkTPList(starTp);
  };
  // 날짜정렬
  const dateSorting = () => {
    const _TP = [...normalTPList];
    const dateSortedTP = _TP.sort((a: any, b: any) => {
      if (sort === 1) {
        setSort(2);
        return b.template_no - a.template_no;
      } else {
        setSort(1);
        return a.template_no - b.template_no;
      }
    });
    setNormalTPList(dateSortedTP);
  };
  // ㄱㄴㄷ정렬
  const koreanSorting = () => {
    const _TP = [...normalTPList];
    const koreanSortedTP = _TP.sort((a: any, b: any) => {
      if (sort === 3) {
        setSort(4);
        return htmlToText(b.contents).localeCompare(htmlToText(a.contents));
      } else {
        setSort(3);
        return htmlToText(a.contents).localeCompare(htmlToText(b.contents));
      }
    });
    setNormalTPList(koreanSortedTP);
  };
  // 수정버튼 클릭(모달열림)
  const onClickEdit = (TP: Template) => {
    setTPHandler(TP);
    dispatch(modalToggle(4));
  };
  // 즐겨찾기 토글
  const bookmarkToggle = async (bookmark: boolean, idx: number) => {
    if (bookmark) {
      await delTPBookmarkApi(idx);
    } else {
      await postTPBookmarkApi(idx);
    }

    const _TPList = TPList.map((tp) => {
      if (tp.template_no !== idx) return tp;
      const _tp = { ...tp };
      _tp.bookmark = !_tp.bookmark;
      return _tp;
    });

    setTPListHandler(_TPList);
  };

  const columns: ColumnsType<Template> = [
    {
      title: <StarSVG fill="#1C274C" />,
      align: "center",
      dataIndex: "bookmark",
      width: 50,
      render: (bookmark, row) => (
        <button onClick={() => bookmarkToggle(bookmark, row.template_no)}>
          <StarSVG fill={bookmark ? "#1C274C" : "#fff"} />
        </button>
      ),
    },
    {
      title: LANG[getLang()].replyTitle,
      dataIndex: "title",
      align: "center",
      ellipsis: true,
      render: (contents, row) => (
        <div className="flex justify-between">
          <Tooltip placement="top" title={htmlToText(row.contents)}>
            {htmlToText(contents).substr(0, 30)}
          </Tooltip>
          <div className="flex">
            <Button
              onClick={() => onClickTemplate(row.contents)}
              btnText={LANG[getLang()].select}
              size="sm"
            />
            <Space x={16} />
            <button onClick={() => onClickEdit(row)}>
              <EditSVG stroke="#1C274C" />
            </button>
          </div>
        </div>
      ),
    },
  ];

  useEffect(() => {
    getTemplateList();
  }, [TPList]);

  return (
    <Background>
      <div
        style={{ width: "640px" }}
        className="p-32 bg-f2f3f8 max-h-[640px] overflow-auto pretty-scrollbar">
        <div className="flex justify-between items-center">
          <H2 isPrimary={true} name="템플릿" />
          <div
            className="inline-block cursor-pointer"
            onClick={() => dispatch(modalToggle(1))}>
            <ModalX />
          </div>
        </div>
        <Space y={20} />
        <div className="flex justify-between">
          <div className="flex">
            <Radio
              value={state}
              setValue={setState}
              name="state"
              dataList={stateRadio}
            />
            <Button
              onClick={dateSorting}
              color="normal"
              btnText={LANG[getLang()].latest}
            />
            <Space x={20} />
            <Button
              onClick={koreanSorting}
              color="normal"
              btnText={LANG[getLang()].aTOz}
            />
          </div>
          <Button
            onClick={() => dispatch(modalToggle(3))}
            btnText={LANG[getLang()].registerTemplate}
          />
        </div>
        <Space y={20} />
        <Table
          rowKey="template_no"
          columns={columns}
          dataSource={[...bookmarkTPList, ...normalTPList].filter(
            (template) => template.state === state
          )}
          pagination={false}
        />
      </div>
    </Background>
  );
};

export default TemplateModal;
