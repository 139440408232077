import { useState, useEffect } from "react";

import Background from "Components/Atoms/Background/background";
import InputRow from "../InputRow/inputRow";
import H2 from "Components/Atoms/H2/h2";
import { ReactComponent as ModalX } from "Util/svg/modalX.svg";
import InputRowRadio from "../InputRowRadio/inputRowRadio";
import Space from "Components/Atoms/Space/space";
import Button from "Components/Atoms/Button/button";
import { modalToggle } from "Store/Slices/modal";
import { useDispatch } from "react-redux";
import {
  ClickInputForm,
  MServiceCategory,
  putServiceId,
  RadioForm,
  ServiceIdList,
  ServiceListForm,
} from "types/interface";
import {
  getServiceIdDetailApi,
  putServiceApi,
  getServiceIdListApi,
} from "Apis/apis";
import LANG, { getLang } from "Util/lang";
import InputText from "../InputText/inputText";
import InputRowFile from "../InputRowFile/inputRowFile";
import { setServiceList } from "Store/Slices/serviceList";
import { toCheckboxForm } from "Util/function";
import InputRowSelect from "../InputRowSelect/inputRowSelect";
import { getServiceCategoryApi } from "Apis/apis";
import useDidMountEffect from "Util/Hooks/useDidMountEffect";
const EVERYTOWN = "everytown";

const STATE_DATA: RadioForm[] = [
  { idx: 1, label: LANG[getLang()].waiting, id: "wait" },
  { idx: 0, label: LANG[getLang()].approval, id: "ok" },
];
const INIT_FILE = {} as File;
const colorList = [
  "bg-[#FFF]",
  "bg-[#D3D5EA]",
  "bg-[#E7FDFF]",
  "bg-[#DCF9F0]",
  "bg-[#FFE5F2]",
];

const initBigCategory: ClickInputForm[] = [{ idx: -1, label: "대분류" }];
const initMidCategory: ClickInputForm[] = [
  {
    idx: -1,
    label: "소분류",
  },
];
const ServiceIdFixModal = ({
  bCode,
  setBCode,
  mCode,
  setMCode,
  setServiceAllList,
  selectServiceIdx,
  setselectServiceIdx,
}: {
  bCode: number;
  setBCode: React.Dispatch<React.SetStateAction<number>>;
  mCode: number;
  setMCode: React.Dispatch<React.SetStateAction<number>>;
  setServiceAllList: React.Dispatch<React.SetStateAction<ServiceIdList[]>>;
  selectServiceIdx: number;
  setselectServiceIdx: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const dispatch = useDispatch();

  const [serviceName, setServiceName] = useState("");
  const [file, setFile] = useState<File>({} as File);
  const [originName, setOriginName] = useState("");
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState(1);
  const [supportMail, setSupportMail] = useState("");
  const [bCodeList, setBCodeList] = useState<ClickInputForm[]>(initBigCategory); // 서비스별 대카테고리
  const [mCodeList, setMCodeList] = useState<ClickInputForm[]>(initMidCategory); //  대카테고리별 중카테고리
  const [allMCodeList, setAllMCodeList] = useState<MServiceCategory[]>([]); // 서비스별 중카테고리
  const [autoEmail, setAutoEmail] = useState("");
  const game = window.location.host.split(".")[0];
  const hostUrl = game.includes("localhost") ? EVERYTOWN : game;
  const getServiceIdDetail = async () => {
    if (selectServiceIdx !== 0) {
      const {
        data: { service },
      } = await getServiceIdDetailApi(selectServiceIdx);
      const _email = service.support_mail.split("@")[0].split(".")[0];
      const _supportEmail = service.support_mail.split("@")[1];

      setServiceName(service.service_name);
      setStatus(service.state);
      setOriginName(service.original_filename || "");
      setEmail(_email);
      setSupportMail(_supportEmail);
      setFile(INIT_FILE);
      setBCode(service.bigCategoryCode || -1);
      setMCode(service.middleCategoryCode || -1);
      setAutoEmail(service.auto_send_mail);
    }
  };

  const fixService = async () => {
    if (serviceName === "") {
      alert("서비스명을 입력 해주세요");
      return;
    }
    const confirm = window.confirm("수정 하시겠습니까?");
    if (!confirm) return;
    const formData: any = new FormData();
    formData.append("serviceName", serviceName);
    formData.append("state", status);
    formData.append("supportMail", `${email}.${hostUrl}@${supportMail}`);
    if (file.name) {
      formData.append("slogo", file);
    }
    formData.append("bigCategoryCode", bCode);
    formData.append("middleCategoryCode", mCode);
    formData.append("autoSendMail", autoEmail);

    await putServiceApi(selectServiceIdx, formData);

    const {
      data: { serviceList },
    } = await getServiceIdListApi("");

    const checkboxForm: ClickInputForm[] = toCheckboxForm(
      serviceList,
      "service_no",
      "service_name"
    );

    const colorForm: ServiceListForm[] = checkboxForm.map((el, idx) => {
      const _colorService = { ...el, color: colorList[idx] } as ServiceListForm;
      const _stateService = {
        ..._colorService,
        state: serviceList[idx].state,
      } as ServiceListForm;

      return _stateService;
    });

    const list: ServiceListForm[] = [
      { idx: -1, label: LANG[getLang()].allService },
      ...colorForm,
    ];

    setServiceAllList(serviceList.filter((el) => el.state !== 2));
    setselectServiceIdx(0);
    dispatch(setServiceList(list));
    dispatch(modalToggle(2));
  };

  const deleteService = async () => {
    const confirm = window.confirm(
      `서비스명과 이메일만 삭제가 됩니다. 
문의내역은 보존됩니다.`
    );
    if (!confirm) return;
    const body = {
      state: 2,
    };
    await putServiceApi(selectServiceIdx, body);
    const {
      data: { serviceList },
    } = await getServiceIdListApi("");
    setServiceAllList(serviceList.filter((el) => el.state !== 2));
    dispatch(modalToggle(2));
  };

  const getAllCategory = async () => {
    setBCodeList(initBigCategory);

    const {
      data: {
        service_category: { big_category, middle_category },
      },
    } = await getServiceCategoryApi();

    const bigCategory: ClickInputForm[] = toCheckboxForm(
      big_category.filter((el) => el.state === 0),
      "big_category_code",
      "big_category_name"
    );

    setBCodeList((bCodeList) => bCodeList.concat(bigCategory));
    setAllMCodeList(middle_category);
    setMidCt(middle_category, bCode);
  };

  // 중카테고리
  const setMidCt = (allMCodeList: MServiceCategory[], bcode: number) => {
    setMCodeList(initMidCategory);
    if (bcode !== -1) {
      const nowMidCt = allMCodeList.filter(
        (mCode) => mCode.big_category_code === bcode && mCode.state === 0
      );

      const midCategory: ClickInputForm[] = toCheckboxForm(
        nowMidCt,
        "middle_category_code",
        "middle_category_name"
      );
      setMCodeList((mCodeList) => mCodeList.concat(midCategory));
    }
  };
  useEffect(() => {
    getServiceIdDetail();
    getAllCategory();
  }, [selectServiceIdx]);

  useDidMountEffect(() => {
    setMidCt(allMCodeList, bCode);
  }, [bCode]);

  return (
    <Background modalNum={2}>
      <div className="p-32 bg-f2f3f8">
        <div className="flex items-center justify-between">
          <H2 isPrimary={true} name="서비스 명 수정" />
          <div
            className="inline-block cursor-pointer"
            onClick={() => dispatch(modalToggle(2))}
          >
            <ModalX />
          </div>
        </div>
        <Space y={20} />
        <InputRow
          id="service"
          name="서비스 명"
          value={serviceName}
          setValue={setServiceName}
          width="w-145"
        />
        <InputRowSelect
          dataList={bCodeList}
          dataList2={mCodeList}
          id="category"
          name="분류"
          setValue={(el) => {
            setMCode(-1);
            setBCode(el);
          }}
          value={bCode}
          setValue2={setMCode}
          value2={mCode}
          width="w-145"
        />
        <InputText
          name="계정명"
          text={`${hostUrl}.${supportMail}`}
          width="w-145"
        />
        <InputRowFile
          id="file"
          name="서비스 로고"
          setValue={setFile}
          width="w-145"
          value={originName}
        />

        <InputRowRadio
          data={STATE_DATA}
          name={LANG[getLang()].state}
          value={status}
          setValue={setStatus}
          width="w-145"
        />
        <InputRow
          id="email"
          name={`자동전달 메일`}
          value={autoEmail}
          setValue={setAutoEmail}
          placeholder="이메일을 입력해주세요."
          width="w-145"
          type="email"
          require={false}
        />
        <div className="relative flex items-center bg-white">
          <InputRow
            id="costomerEmail"
            name="고객지원 이메일"
            value={email}
            setValue={setEmail}
            type="text"
            require
            width="w-145"
            inputWidth="w-200"
            placeholder="영어 및 숫자만 입력 가능합니다"
          />
          <span className="-ml-20">{`.${hostUrl} @ ${supportMail}`}</span>
        </div>

        <span className="text-">
          고객지원 이메일은 브랜드 문의 관련 발신 전용 이메일입니다.
        </span>
        <div className="flex justify-end mt-10">
          <Button color="delete" onClick={deleteService} btnText="삭제" />
          <Space x={10} />
          <Button onClick={fixService} btnText={LANG[getLang()].change} />
          <Space x={10} />
          <Button onClick={() => dispatch(modalToggle(2))} btnText="닫기" />
        </div>
      </div>
    </Background>
  );
};

export default ServiceIdFixModal;
