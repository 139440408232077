import Input from "Components/Atoms/Input/input";
import Label from "Components/Atoms/Label/label";
import Space from "Components/Atoms/Space/space";
import { ReactComponent as Require } from "Util/svg/require.svg";
import Button from "Components/Atoms/Button/button";

import { useDispatch } from "react-redux";
import { modalToggle } from "Store/Slices/modal";
import LANG, { getLang } from "Util/lang";

/**
 * 로그인페이지 : ID PW 인풋창
 * @param {string} placeholder 정의어
 */
const InputRow = ({
  id,
  placeholder,
  name,
  value,
  setValue,
  require = true,
  type = "string",
  button = false,
  disabled = false,
  width = "w-130",
  inputWidth = "w-300",
  maxLength,
  emailReg = false,
}: {
  id: string;
  name: string;
  placeholder?: string;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  require?: boolean;
  type?: string;
  button?: boolean;
  disabled?: boolean;
  width?: string;
  inputWidth?: string;
  maxLength?: number;
  emailReg?: boolean;
}) => {
  const inputBox = (e: React.ChangeEvent<HTMLInputElement>) => {
    emailReg
      ? setValue(e.target.value.replace(/[^A-Za-z0-9]/gi, ""))
      : setValue(e.target.value);
  };
  const dispatch = useDispatch();

  return (
    <div className="flex items-center">
      <div className={`flex items-center px-16 py-20 bg-e5e6ed ${width} `}>
        <Label labelfor={id} name={name} fontSize={16} />
        <Space x={4} />
        {require && <Require />}
      </div>
      <div className="flex-1 h-full bg-white p-13">
        <div className="flex items-center ">
          <div className={inputWidth}>
            <Input
              id={id}
              placeholder={placeholder}
              value={value}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => inputBox(e)}
              type={type}
              disabled={disabled}
              required={require}
              maxLength={maxLength}
            />
          </div>
          <Space x={15} />
          {button ? (
            <Button
              size="md"
              btnText={LANG[getLang()].changePW}
              onClick={() => dispatch(modalToggle(1))}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default InputRow;
