import React from "react";
import ReactExport from "@ibrahimrahmani/react-export-excel";
import Button from "Components/Atoms/Button/button";
import LANG, { getLang } from "Util/lang";
import { useDispatch, useSelector } from "react-redux";
import { ReducerType } from "Store/store";

import { levelCheck } from "Store/Slices/user";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ExcelButton = ({
  data,
  obj,
  fileName,
  btnText = "엑셀 다운로드",
}: {
  data: any[];
  obj: any;
  fileName: string;
  btnText?: string;
}) => {
  const { product_level } = useSelector((state: ReducerType) => state.user);
  const dispatch = useDispatch();
  const excelColumn = () => {
    const excelColumnData = [];
    for (let i in obj) {
      excelColumnData.push(<ExcelColumn key={i} label={obj[i]} value={i} />);
    }
    return excelColumnData;
  };

  if (product_level < 3) {
    return (
      <Button
        onClick={() =>
          dispatch(
            levelCheck({
              sl: "re04",
              fuc: () => {},
            }),
          )
        }
        btnText={btnText}
        size="md"
        color="ready"
        standardItem="re04"
      />
    );
  }
  return (
    <ExcelFile
      element={<Button btnText={btnText} size="md" color="primary" />}
      filename={fileName}
    >
      <ExcelSheet data={data} name="Sheet1">
        {excelColumn()}
      </ExcelSheet>
    </ExcelFile>
  );
};

export default ExcelButton;
