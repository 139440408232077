import { Collapse } from "antd";
import axios from "axios";
import Space from "Components/Atoms/Space/space";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { logoutMode } from "Store/Slices/layouts";
import DOMPurify from "dompurify";
import { ReducerType, useAppDispatch } from "Store/store";
import { ReactComponent as ArrowSVG } from "Util/svg/leftArrow.svg";
import { useGetFaQListApi } from "Apis/apis";
import Quill from "Components/Atoms/Quill/quill";
import ImgModal from "Components/Molecules/ImgModal/imgModal";
import { useSelector } from "react-redux";
import { modalToggle } from "Store/Slices/modal";
import { setUrl } from "Store/Slices/imgModalUrl";

const Index = () => {
  const dispatch = useAppDispatch();
  const imgModalUrl = useSelector((state: ReducerType) => state.imgModalUrl);
  const params = useParams();
  const serviceNo = +(params.idx || -1);

  const navigate = useNavigate();
  const { Panel } = Collapse;

  const { data: faqList } = useGetFaQListApi(serviceNo);

  const layoutRender = () => {
    dispatch(logoutMode());
  };

  useEffect(() => {
    layoutRender();
  }, []);

  if (!faqList) return null;
  return (
    <div className="bg-[F2F3F8] h-[100vh]">
      <div className="flex items-center h-56 px-20 bg-white">
        <button
          className="flex items-center"
          onClick={() => navigate(`/menu/${serviceNo}`)}
        >
          <ArrowSVG />
          <Space x={5} />
          <span className="bold text-24">FAQ</span>
        </button>
      </div>
      <div className="w-5/6 mx-auto mt-20 text-center lg:w-4/6">
        <Collapse className="border-none" accordion>
          {faqList.map((el: any) => (
            <Panel
              style={{
                borderRadius: "0px",
                backgroundColor: "#E5E6ED",
              }}
              className="mb-10 text-left"
              header={
                <div className="flex flex-col">
                  <div className="text-gray-500 w-300">
                    {`[${el.big_category_name} - ${el.middle_category_name}]`}
                  </div>
                  <h4>{el.title}</h4>
                </div>
              }
              key={el.faq_no}
            >
              <Quill
                className="plane_quill"
                value={el.contents}
                readOnly
                toolbar={false}
              />
              <Space y={16} />
              <hr />

              <div className="flex ">
                {el.attached_file.map((el: string) => (
                  <img
                    onClick={() => {
                      dispatch(setUrl(el));
                      dispatch(modalToggle(9));
                    }}
                    className="h-100 cursor-pointer"
                    src={el}
                    alt="이미지"
                  />
                ))}
              </div>
            </Panel>
          ))}
        </Collapse>
        <p className="text-center text-696969 text-12 light">
          Copyright © ADD-UP Co., Ltd. All Rights Reserved.
        </p>
      </div>
      <ImgModal url={imgModalUrl} />
    </div>
  );
};

export default Index;
