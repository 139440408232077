import { useState, useEffect } from "react";

import { ReactComponent as SelectArrowSVG } from "Util/svg/selectArrow.svg";
import Space from "Components/Atoms/Space/space";
import { ClickInputForm } from "types/interface";
import { NumberLiteralType } from "typescript";

/**
 * 셀렉트 atomic
 * @param {Array} dataList 전체 데이터
 * @param {number} val 선택된 state
 * @param {'xl | md'} size 버튼사이즈
 * @param {SetStateAction} setVal setState
 */

const Select = ({
  dataList,
  val,
  size = "xl",
  setVal,
  onChange,
  zindex = 10,
  border = false,
  maxH = "max-h-200",
  full = false,
}: {
  dataList: ClickInputForm[];
  val: number;
  size?: "xl" | "md";
  onChange?: (key: number) => void;
  setVal?: React.Dispatch<React.SetStateAction<number>>;
  zindex?: number;
  border?: boolean;
  maxH?: string;
  full?: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const onClickBtn = (key: number) => {
    setVal && setVal(key);
    setIsOpen(false);
    onChange && onChange(key);
  };

  const toggleSelect = () => {
    setIsOpen((old: boolean) => !old);
  };

  const boxSize =
    size === "xl"
      ? "leading-[36px] px-18 text-sm"
      : "leading-[26px] px-12 text-xs";
  return (
    <div
      id="select"
      className={`relative z-${zindex} inline-block bg-white rounded-t-md ${
        !isOpen && "rounded-b-md"
      } ${full && "w-full justify-center"}`}
      onBlur={() => setIsOpen(false)}
    >
      <button
        onClick={toggleSelect}
        className={`flex items-center bold  ${
          full && "w-full justify-center"
        } ${boxSize} ${border && "border-black border-1"} rounded-md h-full`}
        type="button"
      >
        {dataList.find((el: ClickInputForm) => el.idx === val)?.label}
        <Space x={8} />
        <SelectArrowSVG />
      </button>
      {isOpen && (
        <div
          className={`absolute z-10 flex flex-col min-w-full overflow-y-auto bg-white rounded-b-md bold flex-nowrap ${maxH}`}
        >
          {dataList.map((el: ClickInputForm) => (
            <button
              key={el.idx}
              onMouseDown={(e) => e.preventDefault()}
              onClick={() => onClickBtn(el.idx)}
              className="flex-shrink-0 py-5 pl-16 pr-12 text-left ell"
              type="button"
            >
              {el.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

// 폰트사이즈 ,라인 헤이트, 보더 및 색상 , 크기 height 28 / 38 ,
export default Select;
