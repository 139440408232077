import { useState, useEffect } from "react";

import Background from "Components/Atoms/Background/background";
import H2 from "Components/Atoms/H2/h2";
import Space from "Components/Atoms/Space/space";
import Button from "Components/Atoms/Button/button";
import { modalToggle } from "Store/Slices/modal";
import { useAppDispatch } from "Store/store";
import { ReactComponent as ModalX } from "Util/svg/modalX.svg";
import { Divider, Radio, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { CT } from "types/interface";
import { getConsultantListApi } from "Apis/apis";
import { putAuthorityApi } from "Apis/apis";
import dayjs from "dayjs";
import { logout } from "Store/Slices/user";
import { useNavigate } from "react-router-dom";

const AuthorityModal = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [consultantList, setConsultantList] = useState<CT[]>([]);
  const [csNo, setCsNo] = useState(0);
  const [csName, setCsName] = useState("");
  const date = dayjs().format("YYYY년 MM월 DD일");

  const getConsultantList = async () => {
    const {
      data: { cs },
    } = await getConsultantListApi(-1, "");

    setConsultantList(
      cs
        .filter((el) => el.state === 0)
        .map((item: any, idx: number) => {
          const addItem = { ...item };
          addItem.key = idx + 1;
          // Key로 추가해야만 들어감
          return addItem;
        }),
    );
  };
  const putAuthority = async () => {
    const confirm = window.confirm(`${csName}님에게 정말 기업관리자 권한을
위임하시겠습니까?`);
    if (!confirm) return alert("기업관리자 권한 위임이 취소되었습니다.");
    await putAuthorityApi(csNo);
    alert(`${date} ${csName}님에게 기업관리자
권한이 위임되었습니다.`);
    dispatch(logout());
    dispatch(modalToggle(2));
    navigate("/");
  };
  useEffect(() => {
    getConsultantList();
  }, []);
  const columns: ColumnsType<any> = [
    {
      title: "상담원 ID",
      dataIndex: "cs_id",
      align: "center",
    },
    {
      title: "이름",
      dataIndex: "cs_name",
      align: "center",
    },
    {
      title: "LEVEL",
      dataIndex: "level",
      align: "center",
      render: (now) => (now ? "Lv 1(일반)" : "Lv 2(프로)"),
    },
  ];
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: CT[]) => {
      setCsNo(selectedRows[0].cs_no);
      setCsName(selectedRows[0].cs_name);
    },
  };

  return (
    <Background modalNum={2}>
      <div style={{ width: "860px" }} className="p-32 bg-f2f3f8">
        <div className="flex justify-between items-center">
          <H2 isPrimary={true} name="관리자 권한 위임" />
          <div
            className="inline-block cursor-pointer"
            onClick={() => dispatch(modalToggle(2))}
          >
            <ModalX />
          </div>
        </div>
        <Space y={20} />

        <Table
          dataSource={consultantList}
          columns={columns}
          rowSelection={{
            type: "radio",
            ...rowSelection,
          }}
        />
        <Space y={20} />
        <div className="flex justify-end">
          <Button btnText="위임" onClick={putAuthority} />
          <Space x={8} />
          <Button btnText="닫기" onClick={() => dispatch(modalToggle(2))} />
        </div>
      </div>
    </Background>
  );
};

export default AuthorityModal;
