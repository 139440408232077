import { ReducerType } from "Store/store";
import { ReactNode } from "react";
import { useSelector } from "react-redux";
import { User } from "types/interface";

const typeStyle = {
  primary: "bg-primary text-white",
  ready: "bg-e2e2e2 text-969696",
  normal: "bg-white text-black",
  delete: "bg-error text-white",
};

/**
 * 아이콘 버튼 atomic
 * @param {string} name 텍스트
 * @param {boolean} onClick 클릭시 실행할 함수
 */
const IconBtn = ({
  name,
  onClick,
  color = "primary",
  standardItem,
}: {
  name: ReactNode;
  onClick?: () => void;
  standardItem?: string;
  color?: "primary" | "ready" | "normal" | "delete";
}) => {
  const { check_code }: User = useSelector((state: ReducerType) => state.user);
  const itemNo = check_code.findIndex((el) => el === standardItem) !== -1;
  const btnColor = () => {
    if (!standardItem || itemNo) {
      return typeStyle[color];
    }
    return typeStyle["ready"];
  };
  return (
    <button
      type="button"
      onClick={onClick}
      className={`px-5 leading-46 text-16 font-bold bold rounded ${btnColor()}`}
    >
      {name}
    </button>
  );
};

export default IconBtn;
