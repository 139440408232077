import { useState } from "react";

const useServiceNo = (): [
  number,
  React.Dispatch<React.SetStateAction<number>>
] => {
  const [serviceNo, setServiceNo] = useState(-1);

  return [serviceNo, setServiceNo];
};

export default useServiceNo;
