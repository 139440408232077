import React from "react";

const QnaInput = ({
  id,
  value,
  setValue,
  readonly = false,
}: {
  id: string;
  setValue?: React.Dispatch<React.SetStateAction<string>>;
  value: string;
  readonly?: boolean;
}) => {
  const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setValue && setValue(e.target.value);
  };
  return (
    <input
      type="text"
      id={id}
      className="border-e7e7e7 bg-transparent text-#181818 flex-1 text-14 pl-16 "
      onChange={onChange}
      value={value}
      readOnly={readonly}
      size={1}
    />
  );
};

export default QnaInput;
