import { ReactComponent as Arrow } from "Util/svg/arrow.svg";

const thirdCrumb = (text: string, text2: string) => {
  return (
    <>
      {text}
      <Arrow stroke="#969696" />
      {text2}
    </>
  );
};

export default thirdCrumb;
