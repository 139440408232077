/**
 * 검색창 인풋 atomic
 * @param {string} id label에 사용할 ID
 * @param {string} type input 타입
 * @param {string} placeholder 정의어 ("")
 * @param {string | number} value 인풋에 들어가는값
 * @param {number} width 가로너비 (100)
 * @param {React.ChangeEventHandler<HTMLInputElement>} onChange 인풋 값 변경 함수
 * @param {xl | md } size 검색창크기 (xl)
 */

const Input2 = ({
  id,
  value,
  placeholder = "",
  type = "text",
  width = 100,
  setValue,
  size = "xl",
}: {
  id: string;
  value?: string | number;
  placeholder?: string;
  type?: string;
  width?: number;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  size?: "xl" | "md";
}) => {
  return (
    <input
      id={id}
      type={type}
      placeholder={placeholder}
      className={`bg-#ffffff border-solid rounded text-14 ${
        size === "xl" ? "pl-56 leading-46" : "pl-36 leading-38"
      }`}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      style={{ width: `${width}%` }}
      size={1}
    />
  );
};

export default Input2;
