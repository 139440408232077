import Label from "Components/Atoms/Label/label";

const InputText = ({
  name,
  text,
  width = "w-130",
}: {
  name: string;
  text: string;
  width?: string;
}) => {
  return (
    <div className="flex items-center bg-white">
      <div className={`flex items-center px-16 py-20 bg-e5e6ed ${width}`}>
        <Label labelfor="label" name={name} fontSize={16} />
      </div>
      <div className="flex-1 h-full bg-white p-13">{text}</div>
    </div>
  );
};

export default InputText;
