import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "Components/Atoms/Button/button";
import { postPay } from "Apis/baseApis";
import { sha256 } from "js-sha256";
import { setFormData } from "Util/function";
import { useEffect, useRef, useState } from "react";

declare global {
  interface Window {
    INIStdPay: any;
    onSendMsg: (success: boolean) => void;
  }
}

const Pay = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const url = window.location.href;
  // const apiUrl = "https://api.csbye.com/v1/enterprises/results";
  const apiUrl = "http://localhost:3000/paychild";
  // const apiUrl = "https://everytown.csbye.com/paychild";

  // const [popup, setPopup] = useState<any>("");
  const popup = useRef<any>(null);
  let monitor: any;

  const goodsInfo = (): { [index: string]: any } => {
    const timestamp = Date.now();
    const oid = `addup_${timestamp}`;
    const price = 2000;
    const signatureString = `oid=${oid}&price=${price}&timestamp=${timestamp}`;
    const signature = sha256(signatureString);
    return {
      oid,
      price,
      timestamp,
      signature,
      version: "1.0",
      gopaymethod: "",
      mid: "INIBillTst",
      currency: "WON",
      goodname: "테스트상품명",
      buyername: "젠틀리",
      buyertel: "01079280599",
      buyeremail: "gentlee@indj.net",
      mKey: "3a9503069192f207491d4b19bd743fc249a761ed94246c8c42fed06c3cd15a33",
      acceptmethod: "BILLAUTH(Card)",
      returnUrl: "http://localhost:3000/user/ctqna",
      closeUrl: "http://localhost:3000/user/ctqna",
      // returnUrl: "http://localhost:3000/account/service",
      // closeUrl: url,
    };
  };

  const onClickPay = async () => {
    window.name = "kgpay";
    popup.current = window.open(
      apiUrl,
      "pop01",
      "top=10, left=10, width=500, height=600, status=no, menubar=no, toolbar=no, resizable=no",
    );
  };

  const onSendMsg = () => {
    if (popup.current) {
      popup.current.postMessage("Hello", apiUrl);
    }
  };
  useEffect(() => {
    window.addEventListener("message", (event) => {}, false);
  }, []);

  return (
    <div>
      <Button onClick={onClickPay} btnText="결제" />
      <Button onClick={onSendMsg} btnText="메세지" />
      <form id="pay" action="">
        {Object.keys(goodsInfo()).map((el: any) => {
          return (
            <div key={el}>
              <input
                readOnly
                className="w-full"
                name={el}
                value={goodsInfo()[el]}
                type="text"
              />
              <br />
            </div>
          );
        })}
      </form>
    </div>
  );
};

export default Pay;
