import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReduxQnAOption } from "types/interface";
import dayjs from "dayjs";

type OptionProp = Partial<ReduxQnAOption>;

const DATE_FORMAT = "YYYY-MM-DD";
const isDev = process.env.NODE_ENV === "development";
const today = dayjs(new Date()).format(DATE_FORMAT);
const yesterday = isDev
  ? dayjs(new Date("2023-01-01")).format(DATE_FORMAT)
  : dayjs(new Date()).subtract(1, "d").format(DATE_FORMAT);

const initialState: ReduxQnAOption = {
  // date: ["2023-02-01", today],
  date: [yesterday, today],
  serviceNo: -1,
  bCode: -1,
  mCode: -1,
  stateType: 0,
  searchType: -1,
  vip: -1,
  searchKeyword: "",
  sort: "DESC",
};
export const serviceList = createSlice({
  name: "qnaSearchOption",
  initialState,
  reducers: {
    setOption: (state: ReduxQnAOption, action: PayloadAction<OptionProp>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setOption } = serviceList.actions;
export default serviceList.reducer;
