import React, { useContext, useEffect } from "react";
import QnaLabel from "Components/Atoms/QnaLabel/qnaLabel";
import Space from "Components/Atoms/Space/space";
import Button from "Components/Atoms/Button/button";
import { ReducerType, useAppDispatch } from "Store/store";
import { modalToggle } from "Store/Slices/modal";
import TemplateModal from "../TemplateModal/templateModal";
import { getTemplateListApi } from "Apis/apis";
import AddTemplateModal from "../TemplateModal/addTemplateModal";
import { tpContext } from "Util/Hooks/useTPContext";
import { useSelector } from "react-redux";
import PutTemplateModal from "../TemplateModal/putTemplateModal";
import LANG, { getLang } from "Util/lang";

const QnaTemplate = ({
  name,
  setContents,
  serviceNo,
}: {
  name: string;
  serviceNo: number;
  setContents: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const dispatch = useAppDispatch();
  const { setTPListHandler } = useContext(tpContext);
  const open = useSelector((state: ReducerType) => state.modal);

  const onClickTemplate = (template: string) => {
    setContents(template);
    dispatch(modalToggle(0));
  };

  const getTemplateList = async () => {
    if (serviceNo === -1) return;
    const option = { serviceNo };
    const {
      data: { template },
    } = await getTemplateListApi(option);
    setTPListHandler(template);
  };

  useEffect(() => {
    getTemplateList();
  }, [serviceNo, open]);

  return (
    <div className="flex-center">
      <QnaLabel name={name} />
      <Space x={8} />
      <Button
        btnText={LANG[getLang()].responder}
        onClick={() => dispatch(modalToggle(1))}
        size="sm"
      />
      <TemplateModal serviceNo={serviceNo} onClickTemplate={onClickTemplate} />
      <AddTemplateModal />
      <PutTemplateModal onClickTemplate={onClickTemplate} />
    </div>
  );
};

export default QnaTemplate;
