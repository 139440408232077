import { useState, useEffect } from "react";

import InputRow from "Components/Molecules/InputRow/inputRow";
import InputRowID from "Components/Molecules/InputRowId/inputRowId";
import InputRowPw from "Components/Molecules/InputRowPw/inputRowPw";

import InputRowRadio from "Components/Molecules/InputRowRadio/inputRowRadio";
import InputRowCheckBox from "Components/Molecules/InputRowCheckBox/inputRowCheckBox";
import { getServiceIdListApi } from "Apis/apis";
import { ClickInputForm, RadioForm, ServiceIdList } from "types/interface";
import { toCheckboxForm } from "Util/function";
import { useLocation } from "react-router-dom";
import LANG, { GetLang } from "Util/lang";
import InputRowImg from "Components/Molecules/InputRowImg/inputRowImg";

const ConsultantForm = ({
  id,
  pw,
  pwCheck,
  name,
  phone,
  service,
  level,
  auth,
  disable = false,
  setId,
  setPw,
  setPwCheck,
  setName,
  setPhone,
  setImg,
  setService,
  setLevel,
  setAuth,
  imgUrl,
  setImgUrl,
  isidCheck,
  setIsIdCheck,
}: {
  id: string;
  pw: string;
  pwCheck: string;
  name: string;
  phone: string;
  service: number[];
  level: number;
  auth: number[];
  disable?: boolean;
  imgUrl: string;

  setImgUrl: React.Dispatch<React.SetStateAction<string>>;
  setId: React.Dispatch<React.SetStateAction<string>>;
  setPw: React.Dispatch<React.SetStateAction<string>>;
  setPwCheck: React.Dispatch<React.SetStateAction<string>>;
  setName: React.Dispatch<React.SetStateAction<string>>;
  setPhone: React.Dispatch<React.SetStateAction<string>>;
  setImg: React.Dispatch<React.SetStateAction<File>>;
  setService: React.Dispatch<React.SetStateAction<number[]>>;
  setLevel: React.Dispatch<React.SetStateAction<number>>;
  setAuth: React.Dispatch<React.SetStateAction<number[]>>;

  isidCheck: boolean;
  setIsIdCheck: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const translate = GetLang();

  const [service_data, setservice_data] = useState<ClickInputForm[]>([]);

  const RADIO_DATA: RadioForm[] = [
    { idx: 0, label: "Lv.2(프로)", id: "admin" },
    { idx: 1, label: "Lv.1(일반)", id: "consultant" },
  ];
  const pwReg =
    /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=\-<>?./:;'"[\]{}])(?=.*[0-9]).{8,32}$/;

  const getServiceIdList = async () => {
    const {
      data: { serviceList },
    } = await getServiceIdListApi("");
    const checkboxForm: ClickInputForm[] = toCheckboxForm(
      serviceList.filter((el) => el.state === 0),
      "service_no",
      "service_name"
    );
    setservice_data(checkboxForm);
  };

  useEffect(() => {
    getServiceIdList();
  }, []);

  return (
    <>
      <InputRowID
        id="id"
        name={translate("eMail")}
        value={id}
        setValue={setId}
        check={isidCheck}
        setCheck={setIsIdCheck}
        disable={disable}
      />
      <div className="relative">
        <InputRowPw
          id="pw"
          name={translate("passward")}
          value={pw}
          setValue={setPw}
          placeholder="문자, 숫자, 특수문자 조합 최소 8자, 최대 32자"
          condition={pw.match(pwReg) === null ? false : true}
        />
        {pw.match(pwReg) === null ? (
          <span className="top-20 left-[450px] absolute text-red-500">
            특수문자는 ‘!, @, #, $, %, ^, &, *’만 사용 가능합니다.
          </span>
        ) : (
          <></>
        )}
      </div>
      <InputRowPw
        id="pwCheck"
        name={translate("passwordConfirmation")}
        value={pwCheck}
        setValue={setPwCheck}
        placeholder="다시 한번 입력하세요."
        condition={pwCheck !== "" && pw === pwCheck}
      />
      <InputRow
        id="name"
        name={translate("name")}
        value={name}
        setValue={setName}
      />
      <InputRow
        id="phone"
        name={translate("phone")}
        value={phone}
        setValue={setPhone}
        placeholder="“-” 없이 입력, ex) 01071545574"
      />
      <InputRowImg
        id="logo"
        name={translate("profileImage")}
        setValue={setImg}
        imgUrl={imgUrl}
        setImgUrl={setImgUrl}
      />
      <InputRowCheckBox
        name={translate("serviceInCharge")}
        data={service_data}
        value={service}
        setValue={setService}
      />
      <InputRowRadio
        data={RADIO_DATA}
        name="Level"
        value={level}
        setValue={setLevel}
      />
      {/* <InputRowCheckBox
        name={LANG[getLang()].authority}
        data={AUTH_DATA}
        value={auth}
        setValue={setAuth}
      /> */}
    </>
  );
};

export default ConsultantForm;
