import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "types/interface";
import { delToken } from "Util/function";

const LEVEL_LIST: { [idx: string]: number } = {
  BASIC: 2,
  "BASIC+": 3,
  PREMIUM: 4,
  SPECIAL: 5,
};
const initialState = {
  idx: -1,
  level: -1,
  serviceNo: -1,
  verify: false,
} as User;
const logoutState = { idx: 0, level: -1, serviceNo: -1, verify: false } as User;

export const users = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      return {
        ...state,
        ...action.payload,
        product_level: LEVEL_LIST[action.payload.product_name],
      };
    },
    logout: () => {
      delToken();
      return logoutState;
    },
    levelCheck: (state, action) => {
      const { check_code } = { ...state };
      const levelList = [...check_code];

      if (levelList.findIndex((el) => el === action.payload.sl) === -1) {
        alert("상품 업그레이드가 필요합니다.");
      } else {
        action.payload.fuc && action.payload.fuc();
      }
    },
  },
});

export const { setUser, logout, levelCheck } = users.actions;
export default users.reducer;
