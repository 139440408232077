import { ReactNode } from "react";

const sizeStyle = {
  xl: "px-24 leading-46 text-16 font-bold",
  md: "px-18 leading-38 text-14",
  sm: "px-20 leading-24 text-14",
};

const typeStyle = {
  primary: " text-white",
  ready: " text-969696",
};
/**
 * 배너버튼 atomic
 * @param {string} name 텍스트
 * @param {boolean} onClick 클릭시 실행할 함수
 */

const BannerButton = ({
  name,
  size = "xl",
  full = false,
  color = "ready",
  type = "button",
  onClick,
}: {
  name: string | ReactNode;
  size?: "xl" | "md" | "sm";
  full?: boolean;
  color?: "primary" | "ready";
  type?: "button" | "submit";
  onClick?: () => void;
}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className={`${sizeStyle[size]} ${full && "w-full"} bold rounded ${
        typeStyle[color]
      } bg-primary`}
    >
      {name}
    </button>
  );
};

export default BannerButton;
