import { useEffect, useState } from "react";
import QnaLabel from "Components/Atoms/QnaLabel/qnaLabel";
import Space from "Components/Atoms/Space/space";
import parse from "html-react-parser";
import {
  AnswerHistory,
  GetQnAListApiBody,
  QDetail,
  ReduxQnAOption,
} from "types/interface";
import StarPoin from "Components/Atoms/StarPoin/starPoint";
import FileLIst from "Components/Atoms/FileList/fileList";
import { ReducerType, useAppDispatch } from "Store/store";
import { modalToggle } from "Store/Slices/modal";
import Button from "Components/Atoms/Button/button";
import { delReservationApi } from "Apis/apis";
import { useQueryClient } from "@tanstack/react-query";
import StarModal from "../StarModal/starModal";
import Quill from "Components/Atoms/Quill/quill";
import { setUrl } from "Store/Slices/imgModalUrl";
import { useSelector } from "react-redux";

// state
const STATE_TEXT: { [index: number]: string } = {
  0: "최종답변",
  1: "임시답변",
  2: "메모",
  5: "예약중",
};

const BG_COLOR: { [index: number]: string } = {
  0: "bg-[#D3D9EE]",
  1: "bg-[#DDD3c1]",
  2: "bg-[#F0AB25]",
  3: "bg-[#E5E6ED]",
  4: "bg-[#E5E6AD]",
};

const HEAD: { [index: number]: string } = {
  0: "A. ",
  1: "A. ",
  2: "M. ",
  3: "Q. ",
  4: "전화번호 확인 사유 :  ",
};

const AnswerHistoryList = ({ qna }: { qna: QDetail }) => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const { date, ...searchoption }: ReduxQnAOption = useSelector(
    (state: ReducerType) => state.qnaSearchOption
  );

  const [answerList, setAnswerList] = useState<AnswerHistory[]>([]);
  const [memo, setMemo] = useState("");

  const reduxBody: GetQnAListApiBody = {
    startDate: date[0],
    endDate: date[1],
    ...searchoption,
  };

  const onClickStar = (text: string) => {
    setMemo(text);
    dispatch(modalToggle(14));
  };

  const onClickImg = (url: string) => {
    dispatch(setUrl(url));

    dispatch(modalToggle(9));
  };

  const onDelReservation = async (rePlyIdx: number) => {
    const confirm = window.confirm("예약을 취소 하시겠습니까?");
    if (!confirm) return;
    await delReservationApi(rePlyIdx);
    queryClient.invalidateQueries(["QnaListApi", reduxBody]);
    queryClient.invalidateQueries(["useGetQDetailApi", qna.qna_no]);
  };

  useEffect(() => {
    const q: AnswerHistory = {
      contents: qna.contents,
      date: qna.date,
      state: 3,
      cs_name: "",
    } as AnswerHistory;

    if (!qna.reply) return;
    const aList = [q, ...qna.reply];
    setAnswerList(aList);
  }, [qna]);

  // state
  // 0 : 최종답변
  // 1 : 임시답변
  // 2 : 메모
  // 3 : 질문
  // 4 : 전화번호 확인 사유
  // 5 : 예약

  return (
    <>
      {answerList.map((answer, idx) => {
        const state =
          answer.state === 0 && answer.send === 0 ? 5 : answer.state;
        return (
          <section key={idx}>
            <div className="flex justify-between px-4">
              <div className="flex items-center">
                <QnaLabel name={answer.state !== 3 ? answer.cs_name : ""} />
                <Space x={8} />

                {STATE_TEXT[state]}
                {state === 5 && (
                  <>
                    <Space x={12} />
                    <Button
                      onClick={() => onDelReservation(answer.reply_no)}
                      btnText="예약취소"
                      size="sm"
                    />
                  </>
                )}
                <Space x={32} />
                <FileLIst
                  fileList={answer.attached_file}
                  fileNameList={answer.original_filename}
                  onClick={onClickImg}
                  serviceNo={qna.qna_no}
                  type={1}
                />
              </div>
              <QnaLabel name={answer.date} />
            </div>
            <Space y={8} />

            <div
              className={`flex justify-between text-14 py-8 px-16 rounded-lg ${
                BG_COLOR[answer.state]
              }`}
            >
              <div className="w-full ">
                <span>{HEAD[answer.state]}</span>
                <Space x={4} />
                {/* <div>{parse(answer.contents)}</div> */}
                <Quill
                  toolbar={false}
                  className="plane_quill"
                  value={answer.contents}
                  readOnly
                />
              </div>

              {answer.state < 2 && answer.star > 0 && (
                <button
                  onClick={() => onClickStar(answer.star_memo)}
                  className="flex"
                >
                  <StarPoin starPoint={answer.star} />
                </button>
              )}
            </div>
            <Space y={20} />
          </section>
        );
      })}
      <StarModal review={memo} />
    </>
  );
};

export default AnswerHistoryList;
