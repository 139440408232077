import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: number = 1;

export const langIdx = createSlice({
  name: "langIdx",
  initialState,
  reducers: {
    setLangIdx: (state, action: PayloadAction<number>) => {
      return action.payload;
    },
  },
});

export const { setLangIdx } = langIdx.actions;
export default langIdx.reducer;
