const CTTable = ({ data }: { data: any }) => {
  if (!data.data) return null;
  return (
    <table id="starTable">
      <thead>
        <tr>
          <td className="w-200">날짜</td>
          <td className="w-180">상담원</td>
          <td className="w-150">전체문의</td>
          <td className="w-150">답변완료</td>
          <td className="w-150">미처리</td>
          <td className="w-170">평균시간</td>
        </tr>
      </thead>
      <tbody>
        {data?.data.map((CTData: any, CTIdx: any) => {
          const CTLength = data.data.length;
          const isDate = CTIdx === 0;
          return (
            <tr key={CTIdx}>
              {isDate && <td rowSpan={CTLength + 1}>{data.date}</td>}
              <td>{CTData.cs_name}</td>
              <td>{CTData.sum}</td>
              <td>{CTData.reply_complete}</td>
              <td>{CTData.reply_no}</td>
              <td>{CTData.delay_time}</td>
            </tr>
          );
        })}

        <tr>
          <td>총계</td>
          <td>{data.total.sum}</td>
          <td>{data.total.reply_complete}</td>
          <td>{data.total.reply_no}</td>
          <td>{data.total.delay_time}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default CTTable;
