import { useState, useEffect, useContext } from "react";

import { postTempleteApi } from "Apis/apis";
import Background from "Components/Atoms/Background/background";
import H2 from "Components/Atoms/H2/h2";
import Space from "Components/Atoms/Space/space";
import Button from "Components/Atoms/Button/button";
import { modalToggle } from "Store/Slices/modal";
import { ReducerType, useAppDispatch } from "Store/store";
import { RadioForm, TemplateApiBody } from "types/interface";
import { ReactComponent as ModalX } from "Util/svg/modalX.svg";
import InputRow from "../InputRow/inputRow";
import InputRowRadio from "../InputRowRadio/inputRowRadio";
import LabelQuill from "../LabelQuill/labelQuill";
import useServiceNo from "Util/Hooks/useServiceIdx";
import { useSelector } from "react-redux";
import LANG, { getLang } from "Util/lang";
import InputRowSelect from "../InputRowSelect/inputRowSelect";

const stateRadio: RadioForm[] = [
  { idx: 0, label: LANG[getLang()].communal, id: "public" },
  { idx: 1, label: LANG[getLang()].individual, id: "private" },
];

/**
 * 모달 molecule
 *
 */
const AddTemplateModal = () => {
  const dispatch = useAppDispatch();
  const serviceList = useSelector((state: ReducerType) =>
    state.serviceList
      .filter((el) => el.state !== 2 && el.state !== 1)
      .map((el) => (el.idx === -1 ? { ...el, label: "서비스 선택" } : el)),
  );

  const [title, setTitle] = useState("");
  const [state, setState] = useState(0);
  const [contents, setContents] = useState("");
  const [serviceNo, setServiceNo] = useServiceNo();
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (title === "") {
      alert("제목을 입력 해주세요");
      return;
    }
    if (contents === "<p><br></p>" || contents === "") {
      alert("내용을 입력 해주세요");
      return;
    }

    const confirm = window.confirm("등록 하시겠습니까?");
    if (!confirm) return;

    const body: TemplateApiBody = {
      title,
      serviceNo,
      contents,
      state,
    };
    await postTempleteApi(body);
    dispatch(modalToggle(1));
  };

  return (
    <Background modalNum={3}>
      <div style={{ width: "1000px" }} className="p-32 bg-f2f3f8">
        <div className="flex justify-between items-center">
          <H2 isPrimary={true} name="템플릿 추가" />
          <div
            className="inline-block cursor-pointer"
            onClick={() => dispatch(modalToggle(3))}
          >
            <ModalX />
          </div>
        </div>
        <Space y={20} />
        <form onSubmit={onSubmit}>
          <InputRowSelect
            id="service"
            name="서비스 목록"
            value={serviceNo}
            setValue={setServiceNo}
            dataList={serviceList}
          />
          <InputRow
            id="title"
            name={LANG[getLang()].title}
            value={title}
            setValue={setTitle}
          />
          <InputRowRadio
            data={stateRadio}
            name={LANG[getLang()].authority}
            value={state}
            setValue={setState}
          />
          <LabelQuill
            id="contents"
            name={LANG[getLang()].content}
            text={contents}
            setText={setContents}
          />

          <Space y={20} />
          <div className="flex justify-end">
            <Button type="submit" btnText={LANG[getLang()].register} />
          </div>
        </form>
      </div>
    </Background>
  );
};

export default AddTemplateModal;
