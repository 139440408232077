import { useState, useEffect } from "react";

import InputRow from "Components/Molecules/InputRow/inputRow";
import InputRowID from "Components/Molecules/InputRowId/inputRowId";
import InputRowFile from "Components/Molecules/InputRowFile/inputRowFile";
import InputRowRadio from "Components/Molecules/InputRowRadio/inputRowRadio";
import InputRowCheckBox from "Components/Molecules/InputRowCheckBox/inputRowCheckBox";
import { getServiceIdListApi } from "Apis/apis";
import { ClickInputForm, RadioForm } from "types/interface";
import { toCheckboxForm } from "Util/function";
import InputRowSelect from "Components/Molecules/InputRowSelect/inputRowSelect";
import ChangePw from "Components/Molecules/ChangePw/changePw";
import LANG, { getLang } from "Util/lang";
import InputRowImg from "Components/Molecules/InputRowImg/inputRowImg";

const AccountFixForm = ({
  id,
  name,
  phone,
  service,
  level,
  auth,
  disable = false,
  profileUrl,
  setId,
  setName,
  setPhone,
  setProfile,
  setService,
  setLevel,
  setAuth,
  setProfileUrl,
  isidCheck,
  setIsIdCheck,
  originName,
}: {
  id: string;
  name: string;
  phone: string;
  service: number[];
  level: number;
  auth: number[];
  disable?: boolean;
  profileUrl: string;
  originName: string;
  setProfileUrl: React.Dispatch<React.SetStateAction<string>>;
  setId: React.Dispatch<React.SetStateAction<string>>;
  setName: React.Dispatch<React.SetStateAction<string>>;
  setPhone: React.Dispatch<React.SetStateAction<string>>;
  setProfile: React.Dispatch<React.SetStateAction<File>>;
  setService: React.Dispatch<React.SetStateAction<number[]>>;
  setLevel: React.Dispatch<React.SetStateAction<number>>;
  setAuth: React.Dispatch<React.SetStateAction<number[]>>;
  isidCheck: boolean;
  setIsIdCheck: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [service_data, setservice_data] = useState<ClickInputForm[]>([]);
  const RADIO_DATA: RadioForm[] = [
    { idx: 0, label: "Lv.2(프로)", id: "admin" },
    { idx: 1, label: "Lv.1(일반)", id: "consultant" },
  ];

  const getServiceIdList = async () => {
    const {
      data: { serviceList },
    } = await getServiceIdListApi("");
    const checkboxForm: ClickInputForm[] = toCheckboxForm(
      serviceList.filter((el) => el.state === 0),
      "service_no",
      "service_name",
    );

    setservice_data(checkboxForm);
  };

  useEffect(() => {
    getServiceIdList();
  }, []);

  return (
    <>
      <InputRowID
        id="cs_id"
        name={LANG[getLang()].eMail}
        value={id}
        setValue={setId}
        check={isidCheck}
        setCheck={setIsIdCheck}
        disable={disable}
      />
      <ChangePw id="pw" name={LANG[getLang()].passward} />
      <InputRow
        id="name"
        name={LANG[getLang()].name}
        value={name}
        setValue={setName}
      />
      <InputRow
        id="phone"
        name={LANG[getLang()].phone}
        value={phone}
        setValue={setPhone}
        placeholder="“-” 없이 입력, ex) 01071545574"
      />
      <InputRowImg
        id="logo"
        imgUrl={profileUrl || ""}
        name={LANG[getLang()].profileImage}
        setImgUrl={setProfileUrl}
        setValue={setProfile}
        originName={originName || ""}
      />
      <InputRowCheckBox
        name={LANG[getLang()].serviceInCharge}
        data={service_data}
        value={service}
        setValue={setService}
      />
      <InputRowRadio
        data={RADIO_DATA}
        name="Level"
        value={level}
        setValue={setLevel}
      />
    </>
  );
};

export default AccountFixForm;
