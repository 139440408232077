import React from "react";

const H2 = ({
  name,
  isPrimary = false,
}: {
  name: string;
  isPrimary?: boolean;
}) => {
  const textColor = isPrimary ? "text-primary" : "text-181818";

  return <h2 className={`text-24 bold ${textColor}`}>{name}</h2>;
};

export default H2;
