import React from "react";
import Label from "Components/Atoms/Label/label";
import Space from "Components/Atoms/Space/space";
import { ReactComponent as Require } from "Util/svg/require.svg";
import Button from "Components/Atoms/Button/button";
import BannerButton from "Components/Atoms/Button/bannerButton";
import { useDispatch, useSelector } from "react-redux";
import { modalToggle } from "Store/Slices/modal";
import { ADDBanner } from "types/interface";
import { ReducerType } from "Store/store";

const InputRowBannerButton = ({
  id,
  name,
  width = "w-130",
  require = true,
  data,
  Buttonidx = 1,
}: {
  id: string;
  name: string;
  width?: string;
  require?: boolean;
  data: ADDBanner[];
  Buttonidx?: number;
}) => {
  const dispatch = useDispatch();
  const addModal = (modalNum: number) => {
    dispatch(modalToggle(modalNum));
  };
  const { check_code } = useSelector((state: ReducerType) => state.user);

  return (
    <div className="flex items-center">
      <div
        className={`flex self-stretch items-center px-16 py-20 bg-e5e6ed ${width}`}
      >
        <Label labelfor={id} name={name} fontSize={16} />
        <Space x={4} />
        {require && <Require />}
      </div>
      <div className="flex-1 h-full bg-white p-13">
        <div className="flex items-center">
          {[...Array(Buttonidx)].map((el, idx) => {
            return (
              <div key={idx} className="flex">
                <BannerButton
                  name={`광고${idx + 1}`}
                  onClick={() => addModal(idx + 1)}
                  size="md"
                  color={data[idx]?.ad_name !== "" ? "primary" : "ready"}
                  key={idx}
                />
                <Space x={20} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default InputRowBannerButton;
