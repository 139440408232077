import react, { useState, useEffect, useRef } from "react";
import SearchSelect from "Components/Molecules/SearchSelect/searchSelect";
import SearchInput from "Components/Molecules/SearchInput/searchInput";
import Radio from "Components/Atoms/Radio/radio";
import { RadioForm } from "types/interface";
import { useDispatch, useSelector } from "react-redux";
import { ReducerType } from "Store/store";
import { useLocation, useNavigate } from "react-router-dom";
import useServiceNo from "Util/Hooks/useServiceIdx";
import Space from "Components/Atoms/Space/space";
import SearchResult from "Components/Atoms/SearchResult/searchResult";

import Table, { ColumnsType } from "antd/es/table";
import Tag from "Components/Atoms/Tag/tag";
import { modalToggle } from "Store/Slices/modal";
import VipModal from "Components/Molecules/VipModal/vipModal";
import { getUserListApi, putVipExcelUploadApi } from "Apis/apis";
import { UserList } from "types/interface";
import VipInquiryModal from "Components/Molecules/VipModal/vipInquiryModal";
import LANG, { getLang } from "Util/lang";
import Button from "Components/Atoms/Button/button";
import { getExcelData } from "Util/function";
import Select from "Components/Atoms/Select/select";
import ExcelButton from "Components/Molecules/ExcelButton/excelButton";
const USER_RADIO: RadioForm[] = [
  { idx: -1, label: LANG[getLang()].all, id: "all" },
  { idx: 2, label: "일반", id: "normal" },
  { idx: 0, label: "VIP", id: "vip" },
  { idx: 1, label: "블랙리스트", id: "black" },
];

const EXCEL_OBJ = {
  idx: "index",
  service_no: "service-id",
  user_id: "user_num",
  user_name: "user_nick",
  email: "user_email",
  date: "register-time",
};
const VipPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const serviceList = useSelector((state: ReducerType) =>
    state.serviceList
      .filter((el) => el.state !== 2 && el.state !== 1)
      .map((el) => (el.idx === -1 ? { ...el, label: "서비스 선택" } : el)),
  );
  const [serviceNo, setServiceNo] = useServiceNo();
  const [search, setSearch] = useState("");
  const [userState, setUserState] = useState(-1);
  const [list, setList] = useState<UserList[]>([]);
  const [selectUser, setSelectUser] = useState<UserList[]>([]);
  const [excelList, setExcelList] = useState<UserList[]>([]);

  const imgUpload = useRef<HTMLInputElement>(null); // 파일업로드 input
  const getVip = async () => {
    const { userSearch = search } = location.state || {};
    setSearch(userSearch);
    const { service = serviceNo } = location.state || {};
    setServiceNo(service);
    if (service === -1) return;
    const {
      data: { vip },
    } = await getUserListApi(service, userSearch, userState);
    setList(vip);
    setExcelList(
      vip
        .filter((el: UserList) => el.state === 0)
        .map((el2: UserList, idx: number) => ({ ...el2, idx: idx + 1 })),
    );
  };

  const onService = () => {
    if (serviceNo === -1) return alert("서비스를 선택해주세요.");
    navigate("", {
      state: {
        service: serviceNo,
        userSearch: search,
      },
    });
  };

  const handleFileChange = async (e: react.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;

    // const [로딩, 셋로딩]
    const dataList = await getExcelData(e.target.files[0]);
    const keys: (keyof any)[] = dataList[0];
    const transformedData: any[] = [];

    for (let i = 1; i < dataList.length; i++) {
      const obj: any = {} as any;
      const values: any[] = dataList[i];
      for (let j = 0; j < keys.length; j++) {
        const key = keys[j];
        obj[key] = values[j];
      }
      transformedData.push(obj);
    }

    const body = {
      serviceNo: serviceNo,
      vipList: transformedData,
    };
    try {
      await putVipExcelUploadApi(body);
      getVip();
      alert("VIP를 업로드하셨습니다.");
    } catch {
      alert("다시 시도해주세요.");
    }
    e.target.value = ""; // 같은파일 업로드시 실행안되는거 방지
  };

  useEffect(() => {
    getVip();
  }, [location.state]);

  const columns: ColumnsType<UserList> = [
    {
      title: "서비스",
      dataIndex: "service_no",
      align: "center",
      render: (now: number) => {
        const serviceName = serviceList.filter((el) => el.idx === now);

        return serviceName[0].label;
      },
    },
    {
      title: "고객UID(닉네임)",
      dataIndex: "user_id",
      align: "center",
      render: (now, row: any) => (
        <span
          onClick={() => {
            setSelectUser([row]);
            return dispatch(modalToggle(1));
          }}
          className="cursor-pointer"
        >
          {now}
          {row.user_name ? `(${row.user_name})` : `(없음)`}
        </span>
      ),
    },
    {
      title: "이메일",
      dataIndex: "email",
      align: "center",
      render: (now: string) => now,
    },
    {
      title: "메모",
      dataIndex: "memo_count",
      align: "center",
      render: (now: string) => now,
    },
    {
      title: "문의건수",
      dataIndex: "qna_count",
      align: "center",
      render: (now, row: any) => {
        return (
          <span
            className="cursor-pointer"
            onClick={() => {
              setSelectUser([row]);
              return dispatch(modalToggle(2));
            }}
          >
            {now}
          </span>
        );
      },
    },
    {
      title: LANG[getLang()].state,
      dataIndex: "state",
      align: "center",
      render: (now: number) => {
        if (now === 2) {
          return <Tag type="ready" name="일반" />;
        } else if (now === 0) {
          return <Tag name="VIP" />;
        } else {
          return <Tag type="error" name="블랙" />;
        }
      },
    },
  ];

  return (
    <div className="w-[1100px]">
      <div className="flex">
        <Select dataList={serviceList} val={serviceNo} setVal={setServiceNo} />
        <Space x={20} />
        <SearchInput
          placeholder="고객 UID 또는 이름"
          size="xl"
          value={search}
          setValue={setSearch}
          onSubmit={onService}
        />
      </div>
      <Space y={20} />
      <Radio
        dataList={USER_RADIO}
        value={userState}
        setValue={setUserState}
        name="user_state"
      />
      <Space y={20} />
      <div className="flex justify-between">
        <span className="text-16 font-bold self-end text-[#1c274c]">
          {`검색 결과 총 ( ${list.length} ) 명 `}
        </span>
        <div className="flex">
          <ExcelButton
            data={excelList}
            fileName="excel_vip_양식"
            obj={EXCEL_OBJ}
            btnText="VIP 양식 Excel 다운"
          />
          <Space x={8} />
          <button
            type="button"
            onClick={() => {
              if (serviceNo === -1) return alert("서비스를 선택해주세요.");
              imgUpload.current?.click();
            }}
            className={`px-18 bold leading-38 text-14 rounded bg-primary text-white`}
          >
            VIP 양식 Excel 업로드
          </button>
          <input
            max={1}
            className="hidden opacity-0"
            onChange={(e) => handleFileChange(e)}
            ref={imgUpload}
            type="file"
          />
        </div>
      </div>
      <Space y={20} />
      <Table
        rowKey={(a) => `${a.service_no}-${a.user_id}`}
        columns={columns}
        dataSource={list}
        pagination={{
          defaultPageSize: 5,
          showSizeChanger: true,
          pageSizeOptions: ["5", "10", "20", "30", "40"],
        }}
      />
      {selectUser.length ? (
        <VipModal obj={selectUser} getVip={getVip} />
      ) : (
        <></>
      )}
      {selectUser.length ? <VipInquiryModal obj={selectUser} /> : <></>}
    </div>
  );
};

export default VipPage;
