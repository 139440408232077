import React, { useState } from "react";
import { RadioForm } from "types/interface";
import "./radio.scss";

/**
 * 라디오 atomic
 * @param {string} value state
 * @param {string} setValue setState
 * @param {string} name 같은 라디오로 묶는 키워드
 * @param {string} idx 현재 radio idx
 * @param {string} id input과 label 묶어줌
 * @param {string} label label 내용
 */

const Radio = <T extends {}>({
  value,
  setValue,
  name,
  dataList,
  vertical = false,
}: {
  value: number;
  setValue: any;
  name: string;
  dataList: RadioForm[];
  vertical?: boolean;
}) => {
  return (
    <div className={`flex  ${vertical ? "flex-col gap-10 " : "items-center"} `}>
      {dataList.map((item) => {
        return (
          <div className="flex items-center" key={item.idx}>
            <input
              name={name}
              className="mr-4 cursor-pointer checked:bg-primary transition03s"
              id={item.id}
              type="radio"
              checked={item.idx === value}
              onChange={() => setValue(item.idx)}
            />
            <label className="mr-24 cursor-pointer text-16" htmlFor={item.id}>
              {item.label}
            </label>
          </div>
        );
      })}
    </div>
  );
};

export default Radio;
