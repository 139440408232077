import {
  deleteApi,
  getApi,
  postApi,
  putApi,
  useGetQuery,
  usePostQuery,
} from "./baseApis";
import * as T from "types/interface.d";
import { AxiosResponse } from "axios";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { ReducerType } from "Store/store";

// 로그인
export const postLoginApi = (body: T.PostLogin) => {
  const url = `enterprises/signin`;
  const data = postApi(url, body);
  return data;
};

// 회원가입
export const postJoinApi = (body: T.PostJoin) => {
  const url = `enterprises/signup`;
  const data = postApi(url, body);
  return data;
};
// 기업회원가입 중복체크
export const getJoinOverLapApi = (id: string) => {
  const url = `enterprises/duplication-id?companyId=${id}`;
  const data = getApi(url);
  return data;
};

// 기업계정정보
export const getUserInfoApi = () => {
  const url = `enterprises/profiles`;
  const data = getApi(url);
  return data;
};
// 상담원계정정보
export const getCTInfoApi = () => {
  const url = `cs/profiles`;
  const data = getApi(url);
  return data;
};

// 개인정보수정
export const putUserInfoApi = (body: T.PutUserInfo) => {
  const url = `enterprises/profiles`;
  const data = putApi(url, body);
  return data;
};
// 비밀번호 임시발급
export const putTemporaryPwApi = (body: T.PutTemporaryPw) => {
  const url = `enterprises/password/emails`;
  const data = putApi(url, body);
  return data;
};

// 개인비밀번호 수정
export const putUserPasswordApi = (body: T.putPassword) => {
  const url = `enterprises/profiles/password`;
  const data = putApi(url, body);
  return data;
};

// 서비스 조회
export const getServiceIdListApi = (keyword: string, state?: number) => {
  const url = `enterprises/services?keyword=${keyword}&page=1&max=10000&sort=ASC${
    state === 0 ? `&state=${state}` : ""
  }`;
  const data: Promise<AxiosResponse<{ serviceList: T.ServiceIdList[] }>> =
    getApi(url);
  return data;
};

// 서비스 상세
export const getServiceIdDetailApi = (serviceNo: number) => {
  const url = `enterprises/services/${serviceNo}`;
  const data = getApi(url);
  return data;
};

// 서비스 수정
export const putServiceApi = (serviceNo: number, body: T.putServiceId) => {
  const url = `enterprises/services/${serviceNo}`;
  const data = putApi(url, body);
  return data;
};

// 서비스id 등록
export const postServiceIdApi = (body: T.PostServiceId) => {
  const url = `enterprises/services`;
  const data = postApi(url, body);
  return data;
};

// 서비스 페이지 상세
export const getPageDetailApi = (serviceNo: number) => {
  const url = `enterprises/services/pages/${serviceNo}`;
  const data: Promise<AxiosResponse<{ servicePage: T.ServicePageDetail }>> =
    getApi(url);
  return data;
};

// 서비스 유효성 체크
export const getValidationServiceApi = (body: string[]) => {
  const url = `enterprises/validation-services?subDomain=${body[0]}&serviceNo=${body[1]}`;
  const data = getApi(url);
  return data;
};

// 서비스 페이지 상세 수정
export const putPageDetailApi = (
  serviceNo: number,
  body: T.PutServicePageDetail,
) => {
  const url = `enterprises/services/pages/${serviceNo}`;
  const data = putApi(url, body);
  return data;
};

// 상담원 로그
export const getCsLogApi = (option: T.getCsLog) => {
  const { startDate, endDate, serviceNo, csId } = option;
  const url = `enterprises/logs?startDate=${startDate}&endDate=${endDate}&serviceNo=${serviceNo}&csId=${csId}&page=1&max=10000&sort=DESC`;
  const data: Promise<AxiosResponse<{ log: T.CsLog[] }>> = getApi(url);
  return data;
};

// 상담원 티켓 리스트
export const getTicketApi = (serviceNo: number) => {
  const url = `cs/tickets/?serviceNo=${serviceNo}&page=1&max=100`;
  const data = getApi(url);
  return data;
};

// 상담원 티켓 비율 수정
export const putTicketApi = (body: T.PutTicketPercent, csNo: number) => {
  const url = `cs/tickets/${csNo}`;
  const data = putApi(url, body, "t04");
  return data;
};
// 상담원 티켓 처리 로그
export const getTicketLogApi = (serviceNo: number) => {
  const url = `cs/tickets/logs?serviceNo=${serviceNo}&page=1&max=10000`;
  const data = getApi(url, "se03");
  return data;
};

// cs직원 등록
export const postConsultantApi = (body: T.PostConsultant) => {
  const url = `cs`;
  const data = postApi(url, body);
  return data;
};
// 기업 cs직원 수정
export const putConsultantApi = (body: T.PutConsultant, csNo: number) => {
  const url = `cs/${csNo}`;
  const data = putApi(url, body);
  return data;
};
// 상담원 정보 수정
export const putConsultantInfoApi = (body: T.PutConsultant) => {
  const url = `cs/profiles`;
  const data = putApi(url, body);
  return data;
};

// cs직원리스트 조회
export const getConsultantListApi = (
  state: number = -1,
  keyword: string,
  serviceNo: number = -1,
  level: number = -1,
) => {
  const url = `cs?state=${state}&keyword=${keyword}&page=1&max=10000&serviceNo=${serviceNo}&level=${level}`;
  const data: Promise<AxiosResponse<{ cs: T.CT[] }>> = getApi(url);
  return data;
};
// cs직원 상세 조회
export const useGetConsultantDetailApi = (csNo: number) => {
  const url = `cs/${csNo}`;
  return useGetQuery(["useGetConsultantDetailApi", csNo], url);
};

// cs직원 상세 조회
export const getConsultantDetailApi = (csNo: number) => {
  const url = `cs/${csNo}`;
  const data = getApi(url);
  return data;
};

// cs직원 계정 중복 체크
export const getCsOverLapApi = (id: string) => {
  const url = `cs/duplication-id?csId=${id}`;
  const data = getApi(url);
  return data;
};

// cs직원 계정 비밀번호 수정
export const putCsPasswordApi = (body: T.putPassword, csNo: number) => {
  const url = `cs/${csNo}/password`;
  const data = putApi(url, body);
  return data;
};

// 질문리스트
export const QnaListApi = (option: T.GetQnAListApiBody) => {
  // prettier-ignore
  const { startDate, endDate, serviceNo, bCode, mCode, stateType, searchType, searchKeyword, sort, vip} = option;
  const url = `qna?startDate=${startDate}&endDate=${endDate}&serviceNo=${serviceNo}&bigCategoryCode=${bCode}&middleCategoryCode=${mCode}&state=${stateType}&searchType=${searchType}&searchKeyword=${searchKeyword}&page=1&max=10000&sort=${sort}&blacklist=${1}&vip=${vip}`;
  const data: UseQueryResult<{ qna: T.GetQnAListApi[] }, unknown> = useGetQuery(
    ["QnaListApi", option],
    url,
  );
  return data;
};

// 템플릿 리스트
export const getTemplateListApi = (option: any) => {
  // prettier-ignore
  const { serviceNo } = option;
  const url = `qna/templates?serviceNo=${serviceNo}&state=${-1}&max=${1000}`;
  const data: Promise<AxiosResponse<{ template: T.Template[] }>> = getApi(url);
  return data;
};

// 템플릿 추가
export const postTempleteApi = (body: T.TemplateApiBody) => {
  const url = `qna/templates`;
  const data = postApi(url, body);
  return data;
};

// 템플릿 수정
export const putTempleteApi = (idx: number, body: T.TemplateApiBody) => {
  const url = `qna/templates/${idx}`;
  const data = putApi(url, body);
  return data;
};

// 템플릿 삭제
export const delTempleteApi = (idx: number) => {
  const url = `qna/templates/${idx}`;
  const data = deleteApi(url);
  return data;
};
// 템플릿 즐겨찾기추가
export const postTPBookmarkApi = (idx: number) => {
  const url = `qna/templates/bookmark`;
  const data = postApi(url, { templateNo: idx });
  return data;
};
// 템플릿 즐겨찾기삭제
export const delTPBookmarkApi = (idx: number) => {
  const url = `qna/templates/bookmark/${idx}`;
  const data = deleteApi(url);
  return data;
};

// 카테고리 리스트
export const getCategoryListApi = (serviceNumber: number) => {
  const url = `categories?serviceNo=${serviceNumber}`;
  const data: Promise<
    AxiosResponse<{
      category: {
        big_category: T.BigData[];
        middle_category: T.MiddleData[];
      };
    }>
  > = getApi(url);
  return data;
};

// 카테고리 수정
export const putQusetoinCGApi = (idx: number, body: any) => {
  const url = `qna/${idx}/categories`;
  const data = putApi(url, body);
  return data;
};

// 카테고리 아이템 리스트
export const getCategoryItemApi = (middle: number) => {
  const url = `categories/items?middleCategoryCode=${middle}`;
  const data: Promise<
    AxiosResponse<{
      category_item: T.Item[];
    }>
  > = getApi(url);
  return data;
};

// 카테고리 아이템 추가
export const postCategoryItemApi = (body: T.PostCategoryItem) => {
  const url = `categories/items`;
  const data = postApi(url, body);
  return data;
};
// 카테고리 아이템 삭제
export const delCategoryItemApi = (itemNo: number) => {
  const url = `categories/items/${itemNo}`;
  const data = deleteApi(url);
  return data;
};

// 회원 탈퇴
export const putUserQuitApi = (body: T.PutUserQuit) => {
  const url = `enterprises/quit`;
  const data = putApi(url, body);
  return data;
};

// faq 리스트
export const getFaQListApi = (option: T.GetFaqListApi) => {
  const { serviceNo, keyword, companyNo } = option;
  const url = `boards/faq?serviceNo=${serviceNo}&keyword=${keyword}&page=${1}&max=${1000}&companyNo=${companyNo}`;
  const data: Promise<
    AxiosResponse<{
      faq: T.FAQList[];
    }>
  > = getApi(url);
  return data;
};

export const useGetFaQListApi = (serviceNo: number) => {
  const url = `boards/faq?serviceNo=${serviceNo}&keyword=&page=${1}&max=${1000}`;
  const data: UseQueryResult<T.FAQList[]> = useQuery(
    ["useGetFaQListApi", serviceNo],
    () =>
      getApi(url).then(({ data }: { data: { faq: T.FAQList[] } }) => {
        return data.faq.filter((el) => !el.state);
      }),
    { staleTime: 1000 * 60 * 5 },
  );
  return data;
};

// faq 추가
export const postFaQApi = (body: T.FaqListApiBody) => {
  const url = `boards/faq`;
  const data = postApi(url, body);
  return data;
};

// faq 수정
export const putFaQApi = (idx: number, body: T.FaqListApiBody) => {
  const url = `boards/faq/${idx}`;
  const data = putApi(url, body);
  return data;
};

// faq 삭제
export const delFaQApi = (idx: number) => {
  const url = `boards/faq/${idx}`;
  const data = deleteApi(url);
  return data;
};

// 공지사항 리스트
export const getNoticeListApi = (serviceNo: number = -1) => {
  const url = `boards/notices?serviceNo=${serviceNo}`;
  const data: Promise<
    AxiosResponse<{
      notice: T.Notice[];
    }>
  > = getApi(url);
  return data;
};

// 이용안내 상세
export const getNoticeDetailApi = (idx: number) => {
  const url = `boards/notices/${idx}`;
  const data: Promise<
    AxiosResponse<{
      notice: T.NoticeDetail;
    }>
  > = getApi(url);
  return data;
};

// 이용안내 추가
export const postNoticeApi = (body: T.NoticeApiBody) => {
  const url = `boards/notices`;
  const data = postApi(url, body);
  return data;
};
// 이용안내 수정
export const putNoticeApi = (idx: number, body: T.NoticeApiBody) => {
  const url = `boards/notices/${idx}`;
  const data = putApi(url, body);
  return data;
};

// 이용약관 삭제
export const delNoticeApi = (idx: number) => {
  const url = `boards/notices/${idx}`;
  const data = deleteApi(url);
  return data;
};

// 약관정책 리스트
export const getPolicyListApi = (
  companyNo: number,
  serviceNo: number,
  title: string,
) => {
  const url = `boards/terms?companyNo=${companyNo}&serviceNo=${serviceNo}&title=${title}`;
  const data: Promise<
    AxiosResponse<{
      terms: T.PolicyList[];
    }>
  > = getApi(url);
  return data;
};
// 약관정책 상세
export const getPolicyDetailApi = (idx: number) => {
  const url = `boards/terms/${idx}`;

  const data: Promise<
    AxiosResponse<{
      terms: T.PolicyDetail;
    }>
  > = getApi(url);
  return data;
};
// 약관정책 추가
export const postPolicyApi = (body: T.NoticeApiBody) => {
  const url = `boards/terms`;
  const data = postApi(url, body);
  return data;
};
// 약관정책 수정
export const putPolicyApi = (idx: number, body: T.PolicyApiBody) => {
  const url = `boards/terms/${idx}`;
  const data = putApi(url, body);
  return data;
};
// 약관정책 삭제
export const delPolicyApi = (idx: number) => {
  const url = `boards/terms/${idx}`;
  const data = deleteApi(url);
  return data;
};

// 카테고리 추가
export const postCategoryApi = (body: T.PostCategory) => {
  const url = `categories`;
  const data = postApi(url, body);
  return data;
};
// 카테고리 수정
export const putCategoryApi = (body: T.PutCategory, categoryCode: number) => {
  const url = `categories/${categoryCode}`;
  const data = putApi(url, body);
  return data;
};
// 통계 문의 일별
export const getDailyLogApi = (
  servceiNumber: number,
  start: string,
  end: string,
) => {
  const url = `statistics/qna/daily-logs?serviceNo=${servceiNumber}&startDate=${start}&endDate=${end}`;
  const data: Promise<AxiosResponse<{ statistic: T.DailyLog[] }>> = getApi(url);
  return data;
};
// 통계 상담원 기간별
export const getAccountLogApi = (
  statrtDate: string,
  endDate: string,
  serviceNo: number,
  keyword: string,
) => {
  const url = `statistics/qna/cs-logs?startDate=${statrtDate}&endDate=${endDate}&serviceNo=${serviceNo}&keyword=${keyword}`;
  const data = getApi(url, "re02");
  return data;
};

// 통계 카테고리 별
export const getCategoryLogApi = (
  serviceNo: number,
  statrtDate: string,
  endDate: string,
) => {
  const url = `statistics/qna/category-logs?serviceNo=${serviceNo}&startDate=${statrtDate}&endDate=${endDate}`;
  const data = getApi(url);
  return data;
};
// 통계 별점
export const getStarPointApi = (
  servceiNumber: number,
  startDate: string,
  endDate: string,
) => {
  const url = `statistics/qna/star-logs?serviceNo=${servceiNumber}&startDate=${startDate}&endDate=${endDate}`;
  const data = getApi(url, "re02");
  return data;
};

// 별점 내역
export const getStarHistoryApi = (body: string | number[]) => {
  const url = `qna/stars?startDate=${body[0]}&endDate=${body[1]}&seviceNo=${body[2]}&star=${body[3]}&csNo=${body[4]}&page=1&max=10000`;
  const data = getApi(url);
  return data;
};

// 문의내역 리스트
export const getQnaHistoryApi = (userIdx: string, serviceNo: number) => {
  const url = `qna/past?page=1&max=10000&serviceNo=${serviceNo}&userId=${userIdx}`;
  const data: Promise<
    AxiosResponse<{
      qnaPast: T.QHistoryList[];
    }>
  > = getApi(url);
  return data;
};

// 문의생성
export const postQna = (body: FormData) => {
  const url = "qna";
  const data = postApi(url, body);
  return data;
};

// 커스텀 문의생성
export const postCustomQna = (body: FormData) => {
  const url = "custom";
  const data = postApi(url, body);
  return data;
};

// 문의 상세
export const useGetQDetailApi = (qIdx: number) => {
  const url = `qna/${qIdx}`;
  const data: UseQueryResult<{ qna: T.QDetail }, unknown> = useGetQuery(
    ["useGetQDetailApi", qIdx],
    url,
    { enabled: qIdx > 0 },
  );
  return data;
};
// 문의 상태변경
export const putQuestionApi = (qIdx: number, body: any) => {
  const url = `qna/${qIdx}`;
  const data: Promise<
    AxiosResponse<{
      qna: T.QDetail;
    }>
  > = putApi(url, body);
  return data;
};
// 문의 숨김해제
export const putUnHideApi = (qIdx: number) => {
  const url = `qna/${qIdx}/unhide`;
  const data = putApi(url);
  return data;
};
//  qna답변
export const putAnswerApi = (qIdx: number, body: any) => {
  const url = `qna/${qIdx}/reply`;
  const data = putApi(url, body);
  return data;
};
//  qna답변예약
export const putReservationAnswerApi = (qIdx: number, body: any) => {
  const url = `qna/${qIdx}/reply/schedules`;
  const data = putApi(url, body, "q08");
  return data;
};

// FAQ 상세
export const getFAQDetailApi = (faqIdx: number) => {
  const url = `boards/faq/${faqIdx}`;
  const data: Promise<
    AxiosResponse<{
      faq: T.FAQDetail;
    }>
  > = getApi(url);
  return data;
};

// 배너 리스트
export const getAdvertApi = () => {
  const url = "advertisements";
  const data: Promise<
    AxiosResponse<{
      advertisement: T.getAdvertList[];
    }>
  > = getApi(url);
  return data;
};
// 배너 상세
export const getAdvertDetailApi = (idx: number) => {
  const url = `advertisements/${idx}`;
  const data: Promise<
    AxiosResponse<{
      advertisement: T.getAdvertDetail;
    }>
  > = getApi(url);
  return data;
};

// 배너 등록
export const postAdvertApi = (body: T.PostAdvert) => {
  const url = "advertisements";
  const data = postApi(url, body);
  return data;
};

// 배너 수정
export const putAdvertApi = (idx: number, body: T.putAdvert) => {
  const url = `advertisements/${idx}`;
  const data = putApi(url, body);
  return data;
};
// 배너 삭제
export const deleteAdvertApi = (idx: number) => {
  const url = `advertisements/${idx}`;
  const data = deleteApi(url);
  return data;
};

// 토큰검증
export const getTokenCheckApi = (sub: string) => {
  const url = `enterprises/verify-tokens?subDomain=${sub}`;
  const data: Promise<AxiosResponse<{ token: T.User }>> = getApi(url);
  return data;
};

// 상담원 할당
export const putAssignmentApi = (
  idx: number,
  body: {
    csNo: number;
  },
) => {
  const url = `qna/assignment/${idx}`;
  const data = putApi(url, body, "t03");
  return data;
};

// 도메인로그인
export const postDomainLoginApi = (body: any) => {
  const url = `enterprises/signin/domain`;
  const data = postApi(url, body);
  return data;
};

// 도메인 로그인
export const usePostDomainLoginApi = (body: any) => {
  const url = `enterprises/signin/domain`;
  const data = usePostQuery(["usePostDomainLoginApi"], url, body);
  return data;
};
// 알람리스트
export const useGetAlarmListApi = () => {
  const url = `enterprises/telegrams?state=0`;
  const data: UseQueryResult<T.Telegram[], unknown> = useGetQuery(
    ["useGetAlarmListApi"],
    url,
  );
  return data;
};

// 알람상세
export const useGetAlarmDetailApi = (serviceNo: number) => {
  const url = `enterprises/telegrams/qna?serviceNo=${serviceNo}`;
  const data: UseQueryResult<{ telegram: T.TelegramDetail }> = useGetQuery(
    ["useGetAlarmDetailApi"],
    url,
  );
  return data;
};

// 알람 수정
export const putAlarmApi = (body: T.PutAlarm) => {
  const url = `enterprises/telegrams`;
  const data = putApi(url, body);
  return data;
};

// 키워드 등록
export const postTelegramKeywordApi = (body: T.PutAlarm) => {
  const url = `enterprises/telegrams/keywords`;
  const data = postApi(url, body);
  return data;
};

// 키워드 삭제
export const delTelegramKeywordApi = (idx: number) => {
  const url = `enterprises/telegrams/keywords/${idx}`;
  const data = deleteApi(url);
  return data;
};
// 일반/VIP/블랙리스트 (유저리스트)
export const getUserListApi = (
  serviceNo: number,
  search: string,
  state: number,
) => {
  const url = `users?serviceNo=${serviceNo}&userId=${search}&state=${state}&page=1&max=1000000&sort=DESC`;
  const data = getApi(url);
  return data;
};

// VIP 리스트
export const getVipApi = (serviceNo: number, userId: string, state: number) => {
  const url = `users/vips?serviceNo=${serviceNo}&userId=${userId}&state=${state}&page=1&max=100000`;
  const data = getApi(url);
  return data;
};

// VIP 수정
export const putVipApi = (body: T.PutVip) => {
  const url = `users/vips`;
  const data = putApi(url, body);
  return data;
};

// VIP 업로드
export const putVipExcelUploadApi = (body: any) => {
  const url = `users/vips/loads`;
  const data = putApi(url, body);
  return data;
};

// 기업공지사항 리스트
export const getBoardNoticeApi = (serviceNo: number, companyNo: number) => {
  const url = `boards/notices?serviceNo=${serviceNo}&page=1&max=10000&companyNo=${companyNo}`;
  const data = getApi(url);
  return data;
};

// 기업공지사항 상세
export const getDetailNoitceApi = (noticeNo: number) => {
  const url = `boards/notices/${noticeNo}`;
  const data: Promise<AxiosResponse<{ notice: T.CompanyNotice }>> = getApi(url);
  return data;
};

// 기업공지사항 등록
export const postBoardNoticeApi = (body: T.PostBoardNotice) => {
  const url = `boards/notices`;
  const data = postApi(url, body);
  return data;
};

// 기업공지사항 수정
export const putBoardNoticeApi = (body: T.putBoardNotice, noticeNo: number) => {
  const url = `boards/notices/${noticeNo}`;
  const data = putApi(url, body);
  return data;
};

// 기업공지사항 삭제
export const delBoardNoticeApi = (noticeNo: number) => {
  const url = `boards/notices/${noticeNo}`;
  const data = deleteApi(url);
  return data;
};

// 에드업 -> 기업 공지사항 리스트
export const getAddupNoticeApi = () => {
  const url = `boards/admins-notices?&state=0&page=1&max=10000&dateCheck=1&target=-1`;
  const data: Promise<
    AxiosResponse<{
      admin_notice: { count: number; data: T.GetAddupNotice[]; new: number };
    }>
  > = getApi(url);
  return data;
};
// 에드업 -> 기업 공지사항 상세
export const getAddupNoticeDetailApi = (noticeNo: number) => {
  const url = `boards/admins-notices/${noticeNo}`;
  const data: Promise<AxiosResponse<{ admin_notice: T.GetDetailAddupNotice }>> =
    getApi(url);
  return data;
};

// 자동답변설정 리스트
export const getAutoMailApi = (serviceNo: number) => {
  const url = `qna/mails-autos?serviceNo=${serviceNo}&page=1&max=100&sort=DESC`;
  const data: Promise<AxiosResponse<{ autoMail: T.AutoMail[] }>> = getApi(url);
  return data;
};
// 자동답변설정 상세
export const getDetailAutoMailApi = (mailNo: number) => {
  const url = `qna/mails-autos/${mailNo}`;
  const data: Promise<AxiosResponse<{ autoMail: T.DetailAutoMail }>> =
    getApi(url);
  return data;
};
// 자동답변설정 추가
export const postAutoMailApi = (body: T.FixAutoMail) => {
  const url = `qna/mails-autos`;
  const data = postApi(url, body);
  return data;
};
// 자동답변설정 수정
export const putAutoMailApi = (body: T.FixAutoMail, mailNo: number) => {
  const url = `qna/mails-autos/${mailNo}`;
  const data = putApi(url, body);
  return data;
};
// 자동답변설정 삭제
export const deleteAutoMailApi = (mailNo: number) => {
  const url = `qna/mails-autos/${mailNo}`;
  const data = deleteApi(url);
  return data;
};

// 이메일 디자인 리스트
export const getEmailApi = (serviceNo: number) => {
  const url = `qna/mails-designs?serviceNo=${serviceNo}&page=1&max=10000&sort=DESC`;
  const data: Promise<AxiosResponse<{ mailDesign: T.EmailDesign[] }>> =
    getApi(url);
  return data;
};

// 이메일 디자인 상세
export const getDetailEmailApi = (mailNo: number) => {
  const url = `qna/mails-designs/${mailNo}`;
  const data: Promise<AxiosResponse<{ mailDesign: T.DetailEmail }>> =
    getApi(url);
  return data;
};

// 이메일 디자인 추가
export const postEmailApi = (body: T.FixEmail) => {
  const url = `qna/mails-designs`;
  const data = postApi(url, body);
  return data;
};

// 이메일 디자인 수정
export const putEmailApi = (body: T.FixEmail, mailNo: number) => {
  const url = `qna/mails-designs/${mailNo}`;
  const data = putApi(url, body);
  return data;
};

// 이메일 디자인 삭제
export const deleteEmailApi = (mailNo: number) => {
  const url = `qna/mails-designs/${mailNo}`;
  const data = deleteApi(url);
  return data;
};

// 메일 광고 배너 추가
export const postEmailBannerApi = (body: any) => {
  const url = `qna/mails-ad`;
  const data = postApi(url, body);
  return data;
};
// 메일 광고 배너 삭제
export const delEmailBannerApi = (adNo: number) => {
  const url = `qna/mails-ad/${adNo}`;
  const data = deleteApi(url);
  return data;
};

// 예약 취소
export const delReservationApi = (replyNo: number) => {
  const url = `qna/${replyNo}/schedules`;
  const data = deleteApi(url);
  return data;
};

// 문의페이지 이미지
export const getQnaImage = (serviceNo: number) => {
  const url = `qna/images?serviceNo=${serviceNo}`;
  const data = getApi(url);
  return data;
};

// 문의페이지 이미지
export const postDownLogApi = (body: any) => {
  const url = `qna/files-logs`;
  const data = postApi(url, body);
  return data;
};

// 바로가기 생성
export const postShortCuts = (body: any) => {
  const url = `cs/shortcuts`;
  const data = postApi(url, body, "q06");
  return data;
};

// 바로가기 리스트
export const GetShortCutListApi = () => {
  const { check_code, idx } = useSelector((state: ReducerType) => state.user);
  const isBoolean =
    check_code?.findIndex((el: string) => el === "q06") !== -1 && idx > 0;
  const url = `cs/shortcuts`;
  const data: UseQueryResult<T.ShortCut[]> = useQuery(
    ["GetShortCutListApi"],
    () =>
      getApi(url, "q06").then(
        ({ data }: { data: { shortcut: T.ShortCut[] } }) => {
          return data.shortcut;
        },
      ),
    { staleTime: Infinity, enabled: isBoolean },
  );

  return data;
};

// 휴가 기간 설정
export const putHolidayApi = (csNo: number, body: T.PutHoliday) => {
  const url = `cs/${csNo}/leave`;
  const data = putApi(url, body);
  return data;
};
// 휴가 기간 삭제
export const deleteHolidayApi = (csNo: number) => {
  const url = `cs/${csNo}/leave`;
  const data = deleteApi(url);
  return data;
};
// 바로가기 수정
export const putShortCuts = (idx: number, body: any) => {
  const url = `cs/shortcuts/${idx}`;
  const data = putApi(url, body, "q06");
  return data;
};
// 바로가기 삭제
export const delShortCuts = (idx: number) => {
  const url = `cs/shortcuts/${idx}`;
  const data = deleteApi(url, "q06");
  return data;
};

// 권한 위임
export const putAuthorityApi = (csNo: number, body?: any) => {
  const url = `enterprises/authority-delegation/${csNo}`;
  const data = putApi(url, body);
  return data;
};
// 고객 메모 입력
export const postUserMemoApi = (body: any) => {
  const url = `users/memos`;
  const data = postApi(url, body, "c03");
  return data;
};

// 고객 폰번호 확인
export const postPhoneCheckApi = (body: any) => {
  const url = `qna/reasons`;
  const data = postApi(url, body);
  return data;
};
// 라밸링 리스트
export const GetLabelApi = () => {
  const { check_code = [""] } = useSelector((state: ReducerType) => state.user);
  const isBoolean = check_code?.findIndex((el: string) => el === "q07") !== -1;

  const url = `cs/labels`;
  const data: UseQueryResult<T.Label[]> = useQuery(
    ["GetLabelApi"],
    () =>
      getApi(url, "q07").then(({ data }: { data: { label: T.Label[] } }) => {
        return data.label;
      }),
    { staleTime: Infinity, enabled: check_code && isBoolean },
  );
  return data;
};

// 라벨링 수정
export const putLabelCuts = (body: any) => {
  const url = `cs/labels`;
  const data = putApi(url, body, "q07");
  return data;
};

// sms전송
export const postSendSMSApi = (body: any) => {
  const url = `cs/sms`;
  const data = postApi(url, body, "q10");
  return data;
};

// 메일쓰기
export const postSendMailApi = (body: any) => {
  const url = `qna/mails`;
  const data = postApi(url, body);
  return data;
};

// 유저 메모 내역
export const GetMemoApi = (
  serviceNo: number,
  userId: string,
  level: number,
) => {
  const { check_code } = useSelector((state: ReducerType) => state.user);
  const isBoolean = check_code?.findIndex((el: string) => el === "c03") !== -1;
  const url = `users/memos?serviceNo=${serviceNo}&userId=${userId}`;
  const data: UseQueryResult<T.UserMemo[]> = useQuery(
    ["GetMemoApi", serviceNo, userId],
    () =>
      getApi(url, "c03").then(({ data }: { data: { memo: T.UserMemo[] } }) => {
        return data.memo;
      }),
    { staleTime: Infinity, enabled: isBoolean },
  );
  return data;
};

// 에드업 전체 서비스 카테고리 리스트
export const getServiceCategoryApi = () => {
  const url = `categories/services?state=-1`;
  const data: Promise<
    AxiosResponse<{ service_category: T.GetServiceCategory }>
  > = getApi(url);
  return data;
};

// 결제 현황
export const getPayApi = (sort: string) => {
  const url = `payments/logs?page=1&max=100000&sort=${sort}`;
  const data = getApi(url);
  return data;
};

// 기업 구독 상품
export const getCompanyProductApi = () => {
  const url = `enterprises/products`;
  const data: Promise<AxiosResponse<{ product: T.ProductInfo }>> = getApi(url);
  return data;
};

// 기본 카테고리 리스트
export const getNormalCategoryApi = () => {
  const url = `categories/loads`;
  const data: Promise<AxiosResponse<{ load_category: T.GetNormalCategory[] }>> =
    getApi(url);
  return data;
};
// 카테고리 불러오기 post
export const postNormalCategoryApi = (body: T.PostNormalCategory) => {
  const url = `categories/loads`;
  const data = postApi(url, body);
  return data;
};

// 구독 취소
export const payCancleApi = () => {
  const url = `payments/cancel-subscriptions`;
  const data = putApi(url, {});
  return data;
};

export const getSubscribeStateApi = () => {
  const url = `payments/subscribe-states`;
  const data: Promise<AxiosResponse<{ subscribe_state: T.GetSubscribeState }>> =  getApi(url);
  return data;
}
