import DatePick from "Components/Atoms/DatePick/datePick";
import { useState, useEffect, useRef } from "react";
import useDatePicker from "Util/Hooks/useDatePicker";
import { useLocation, useNavigate } from "react-router-dom";
import Space from "Components/Atoms/Space/space";
import Table, { ColumnsType } from "antd/es/table";
import { CategoryQnaList, CategoryTotal } from "types/interface";
import { useSelector } from "react-redux";
import { ReducerType } from "Store/store";
import Select from "Components/Atoms/Select/select";
import Button from "Components/Atoms/Button/button";
import { getCategoryListApi, getCategoryLogApi } from "Apis/apis";
import useServiceNo from "Util/Hooks/useServiceIdx";
import ExcelButton from "Components/Molecules/ExcelButton/excelButton";
import LANG, { getLang } from "Util/lang";
import CategoryTable from "Components/Atoms/Table/categoryTable";
import CategoryChart from "Components/Atoms/Chart/categoryChart";
import CategoryBar from "Components/Atoms/Bar/categoryBar";
import { modifyAllText } from "Util/function";

const EXCEL_OBJ = {
  date: "날짜",
  bname: "대카테고리",
  mname: "중카테고리",
  sum: "전체문의",
  reply_complete: "답변완료",
  reply_no: "미처리",
};
const ChartCategory = ({
  dateRange,
  setDateRange,
}: {
  dateRange: string[];
  setDateRange: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  const [serviceNo, setServiceNo] = useServiceNo();

  const [categoryLog, setCategoryLog] = useState<CategoryQnaList[]>([]);
  const [categoryTotal, setCategoryTotal] = useState<CategoryTotal>(
    {} as CategoryTotal
  );
  const serviceList = useSelector((state: ReducerType) => state.serviceList);

  const getCategoryLog = async () => {
    const firstDay = dateRange[0];
    const secondDay = dateRange[1];

    const {
      data: { statistic },
    } = await getCategoryLogApi(serviceNo, firstDay, secondDay);
    setCategoryLog(statistic.categoryQnaList);
    setCategoryTotal(statistic.total);
  };

  const onSubmit = () => {
    if (serviceNo === -1) return alert("서비스를 선택해주세요.");
    getCategoryLog();
  };

  const exel = () => {
    const data: any = [];
    categoryLog.forEach((dailyData) => {
      return dailyData.data.forEach((bigData) => {
        return bigData.middle.forEach((midData) => {
          data.push({
            date: dailyData.date,
            bname: bigData.bname,
            mname: midData.mname,
            sum: midData.sum,
            reply_complete: midData.reply_complete,
            reply_no: midData.reply_no,
          });
        });
      });
    });

    data.push({
      mname: "총계",
      sum: categoryTotal.sum,
      reply_complete: categoryTotal.reply_complete,
      reply_no: categoryTotal.reply_no,
    });
    return data;
  };

  return (
    <div className="w-[1200px]">
      <div id="chartCategory">
        <div className="flex">
          <DatePick dateRange={dateRange} setDateRange={setDateRange} />
          <Space x={20} />
          <Select
            dataList={modifyAllText(serviceList)}
            val={serviceNo}
            setVal={setServiceNo}
          />
          <Space x={20} />
          <Button
            btnText={LANG[getLang()].search}
            size="md"
            onClick={onSubmit}
          />
          <Space x={10} />
          <ExcelButton
            data={exel()}
            obj={EXCEL_OBJ}
            fileName={`${dateRange}카테고리별`}
          />
        </div>

        <Space y={20} />
        <CategoryBar data={categoryLog} />
        <Space y={20} />
        <CategoryChart data={categoryLog} />
        <CategoryTable data={categoryLog} total={categoryTotal} />
        <Space y={20} />
      </div>
    </div>
  );
};

export default ChartCategory;
