const H4 = ({
  name,
  isPrimary = false,
}: {
  name: string;
  isPrimary?: boolean;
}) => {
  const textColor = isPrimary ? "text-primary" : "text-181818";

  return <h2 className={`text-18 bold ${textColor}`}>{name}</h2>;
};

export default H4;
