import { ReducerType } from "Store/store";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ClickInputForm } from "types/interface";
import * as XLSX from "xlsx";

/**
 * 키값 기준으로 쿠키에 저장된 값이 있는지 확인하는 함수
 * @param {string} name : 쿠키 key 값
 * @returns 해당 쿠키 value 의 True / False
 */
export const searchCookie = (name: string) => {
  let value = "; " + document.cookie;
  const parts: string[] = value.split("; " + name + "=");

  const data: string = [...parts].pop()!;

  if (parts.length === 2) {
    return Boolean(data);
  }
};

/**
 * 쿠키 값 가져오는 함수
 * @param {string} name : 쿠키 key 값
 * @returns 해당 쿠키 값
 */
export const getCookie = (name: string) => {
  let value = "; " + document.cookie;
  let parts = value.split("; " + name + "=");
  if (parts.length === 2) {
    return parts.pop()?.split(";").shift();
  }
};

/**
 * 쿠키에 저장하는 함수
 * @param {string} name : 쿠키 Key 값
 * @param {string} value : 쿠키에 Value 값
 * @param {number} exp : 쿠키 만료일 (30)
 * @returns Key값, Value값, 만료일 쿠키에저장
 */
export const setCookie = (name: string, value: string, exp = 30) => {
  let date = new Date();
  date.setTime(date.getTime() + exp * (24 - 9) * 60 * 60 * 1000);
  document.cookie = `${name}=${value}; expires=${date.toUTCString()}; path=/`;
};

/**
 * // 쿠기 삭제 함수
 * @param {string} name : 쿠키 Key 값
 * @returns 당일 현시간 기준으로 키값 제거
 */
export const deleteCookie = (name: string) => {
  let date = new Date().toUTCString();
  document.cookie = name + "=; expires=" + date;
};

/**
 *
 * @param {DataList extends {}} dataList 객체 배열
 * @param {Key extends keyof DataList} idx dataList의 키값중 하나 (체크박스의 key가 될 속성)
 * @param {Label extends keyof DataList} label dataList의 키값중 하나 (체크박스의 label이 될 속성)
 * @returns
 */
export const toCheckboxForm = <
  DataList extends {},
  Key extends keyof DataList,
  Label extends keyof DataList,
>(
  dataList: DataList[],
  idx: Key,
  label: Label,
): ClickInputForm[] => {
  const checkboxFormDataList: ClickInputForm[] = dataList.map(
    (data: DataList) => {
      const baseObj: ClickInputForm = {} as ClickInputForm;
      baseObj.idx = data[idx] as number;
      baseObj.label = data[label] as string;
      return baseObj;
    },
  );

  return checkboxFormDataList;
};

/**
 * html을 일반텍스트로 변환시켜줍니다
 * @param {string} text html형식 문자열
 * @returns {string} 일반문자열
 */
export const htmlToText = (text: string) => {
  if (!text) return "";
  return text.replace(/<[^>]*>?/g, "");
};
/**
 * 숫자 콤마찍기
 * @param {number} number 숫자
 * @returns {string} 컴마찍힌 문자열숫자
 */
export const comma = (number: number): string => {
  const commaNumber = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return commaNumber;
};

/**
 * default서비스 set
 * @param {ClickInputForm} service - 디폴트 서비스
 */
export const setDefaultService = (serviceIdx: number) => {
  const STKey = process.env.REACT_APP_DEFAULT_SERVICE || "";
  localStorage.setItem(STKey, serviceIdx + "");
};
/**
 * default서비스 del
 * @param {ClickInputForm} service - 디폴트 서비스
 */
export const delDefaultService = () => {
  const STKey = process.env.REACT_APP_DEFAULT_SERVICE || "";
  localStorage.removeItem(STKey);
};

/**
 * default서비스 get
 * @returns {ClickInputForm | undefined} 디폴트서비스 | undefined
 */
export const getgDefaultService = (): ClickInputForm | undefined => {
  // DS : defaultService
  const STKey = process.env.REACT_APP_DEFAULT_SERVICE || "";
  const DS = localStorage.getItem(STKey);
  if (!DS) return;

  const parsedDS: ClickInputForm = JSON.parse(DS);
  return parsedDS;
};

/**
 *
 * @param {string} phoneNumber - 폰번호
 * @returns 마스킹된 폰번호
 */
export const maskingName = (phoneNumber: string) => {
  if (!phoneNumber) return "";
  const maskedNumber = phoneNumber.replace(phoneNumber.substring(3, 7), "****");
  return maskedNumber;
};

export const translate = async (text: string, lang: string) => {
  const apiKey = process.env.REACT_APP_ADDUP_GOOGLE_TOKEN;
  const {
    data: {
      data: {
        translations: [data],
      },
    },
  } = await axios.post(
    "https://translation.googleapis.com/language/translate/v2",
    {},
    {
      params: {
        q: text,
        target: lang,
        key: apiKey,
      },
    },
  );
  return data.translatedText;
};

/**
 * set폼데이터
 * @param {FormData} formData - 폼데이터
 * @param {} data - 객체
 * @param {} parentKey 키이름(재귀시에만 활용)
 */
export const setFormData = (
  formData: FormData,
  data: any,
  parentKey?: string,
) => {
  Object.keys(data).forEach((key) => {
    const val = data[key]; // value

    if (parentKey) key = `${parentKey}[${key}]`; // 값이 객체일때 해당객체에 접근

    if (val instanceof Object && !Array.isArray(val)) {
      return setFormData(formData, val, key);
    }

    if (Array.isArray(val)) {
      val.forEach((v, idx) => {
        if (v instanceof Object) {
          setFormData(formData, v, `${key}[${idx}]`);
        } else {
          formData.append(`${key}[${idx}]`, v);
        }
      });
    } else {
      formData.append(key, val);
    }
  });
};

/**
 * 수정 setState
 * @param setState setState함수
 * @param dataIdx 객체인덱스
 * @param dataProp 바꿀 프로퍼티
 * @param idx 바꿀객체 인덱스
 * @param value 바꿀 값
 */
export const putSetState = <
  Data extends {},
  Idx extends keyof Data,
  Prop extends keyof Data,
>(
  setState: React.Dispatch<React.SetStateAction<Data[]>>,
  dataIdx: Idx,
  dataProp: Prop,
  idx: number,
  value: any,
) => {
  setState((stateList: Data[]) => {
    const _stateList = stateList.map((state) => {
      if (state[dataIdx] !== idx) return state;
      const _state = { ...state };
      _state[dataProp] = value;
      return _state;
    });

    return _stateList;
  });
};
/**
 * 바뀐 객체 반환
 * @param dataList 객체배열
 * @param dataIdx 객체인덱스
 * @param dataProp 바꿀 프로퍼티
 * @param idx 바꿀객체 인덱스
 * @param value 바꿀 값
 */
export const modifyObj = <
  Data extends {},
  Idx extends keyof Data,
  Prop extends keyof Data,
>(
  dataList: Data[],
  dataIdx: Idx,
  dataProp: Prop,
  idx: number,
  value: any,
) => {
  const _dataList = dataList.map((data) => {
    if (data[dataIdx] !== idx) return data;
    const _data = { ...data };
    _data[dataProp] = value;
    return _data;
  });

  return _dataList;
};

/**
 * 쿠키 값 가져오는 함수
 * @param {any} list : 테이블 배열
 * @param {number} idx : 테이블 에서의 해당 고유 키
 * @param {string} stringIndex : 객체 키이름
 * @returns 내림차순 정렬 한 숫자
 */
export const order = (list: any, idx: number, stringIndex: string) => {
  const key = list.findIndex((el: any) => el[stringIndex] === idx);
  return list.length - key;
};

/**
 * number배열 합
 * @param {number[]} numArr : 합칠 숫자 배열
 */
export const sum = (numArr: number[]) => numArr.reduce((a, b) => a + b, 0);
export const concatSum = (numArr: number[]) => [
  ...numArr,
  numArr.reduce((a, b) => a + b, 0),
];

// 로그인 토큰 get
export const getToken = () =>
  sessionStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN!) || "";
// 로그인 토큰 set
export const setToken = (token: string) =>
  sessionStorage.setItem(process.env.REACT_APP_ACCESS_TOKEN!, token);
// 로그인 토큰 del
export const delToken = () =>
  sessionStorage.removeItem(process.env.REACT_APP_ACCESS_TOKEN!);

// 결제 signKey get
export const getSignKey = () =>
  localStorage.getItem(process.env.REACT_APP_PAY_SIGN_KEY!) || "";

export const getExcelData = (file: File) => {
  const reader = new FileReader();

  return new Promise<any[][]>((resolve, reject) => {
    reader.onload = (e) => {
      const data = new Uint8Array(e.target?.result as ArrayBuffer);
      const workbook = XLSX.read(data, { type: "array" });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData: any = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      resolve(jsonData);
    };

    reader.onerror = (e) => {
      reject(e);
    };

    reader.readAsArrayBuffer(file);
  });
};

// export const LevelCheck = (sl: string, fuc?: any, isAlert: boolean = true) => {
//   const user = useSelector((state: ReducerType) => state.user);
//   if (user.check_code.findIndex((el) => el === sl) === -1) {
//     isAlert && alert("상품 업그레이드가 필요합니다.");
//     return false;
//   }
//   fuc && fuc();
//   return true;
// };

export const modifyAllText = (arr: ClickInputForm[]) =>
  arr.map((el) => (el.idx === -1 ? { ...el, label: "서비스 선택" } : el));
