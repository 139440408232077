import React, { useState, useEffect } from "react";
import useDatePicker, { useMonthPicker } from "Util/Hooks/useDatePicker";
import { useDispatch, useSelector } from "react-redux";
import { ReducerType } from "Store/store";
import MonthPick from "Components/Atoms/MonthPick/monthPick";
import Select from "Components/Atoms/Select/select";
import Space from "Components/Atoms/Space/space";
import Button from "Components/Atoms/Button/button";
import Table, { ColumnsType } from "antd/es/table";
import { getStarHistoryApi, getStarPointApi } from "Apis/apis";
import { StarPoint, StarList, StarPointData } from "types/interface";
import { useLocation, useNavigate } from "react-router";
import useServiceNo from "Util/Hooks/useServiceIdx";
import ExcelButton from "Components/Molecules/ExcelButton/excelButton";
import LANG, { getLang } from "Util/lang";
import StarTable from "Components/Atoms/Table/starTable";
import ChartStarModal from "Components/Molecules/StarModal/chartStarModal";
import StarPieChart from "Components/Atoms/Chart/starPieChart";
import DatePick from "Components/Atoms/DatePick/datePick";
import StarBar from "Components/Atoms/Bar/starBar";
import { modifyAllText } from "Util/function";
import { levelCheck } from "Store/Slices/user";

export interface EXCEL_TYPE {
  cs_id: string;
  one: number;
  two: number;
  three: number;
  four: number;
  five: number;
  sum: number;
  avg: number;
}
const EXCEL_OBJ = {
  cs_id: LANG[getLang()].name,
  one: "⭐",
  two: "⭐⭐",
  three: "⭐⭐⭐",
  four: "⭐⭐⭐⭐",
  five: "⭐⭐⭐⭐⭐",
  sum: "총계",
  avg: "별점 평균",
};
const Starpoint = ({
  dateRange,
  setDateRange,
}: {
  dateRange: string[];
  setDateRange: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const [serviceNo, setServiceNo] = useServiceNo();
  const [csNo, setCsNo] = useState(0);
  const [starLog, setStarLog] = useState<StarPointData>({} as StarPointData);
  const [starHistoryList, setStarHistoryList] = useState<StarList[]>([]);
  const [excelData, setExcelData] = useState<EXCEL_TYPE[]>([]);
  const serviceList = useSelector((state: ReducerType) => state.serviceList);

  const first = dateRange[0];
  const second = dateRange[1];

  const getStarPoint = async () => {
    if (serviceNo === -1) {
      alert("서비스를 선택 해주세요");
      return;
    }
    const {
      data: { statistic },
    } = await getStarPointApi(serviceNo, dateRange[0], dateRange[1]);

    setStarLog(statistic);

    setExcelData(statistic.data);
  };
  const getStarHistory = async () => {
    if (!location.state.modalNum) return;
    const body = [first, second, serviceNo, location.state?.modalNum, csNo];
    const {
      data: { star },
    } = await getStarHistoryApi(body);
    setStarHistoryList(star);
  };
  const onSubmit = () => {
    getStarPoint();
  };
  const modal = useSelector((state: ReducerType) => state.modal);
  useEffect(() => {
    getStarHistory();
  }, [modal]);

  return (
    <div className="w-[1200px]">
      <div id="starPoint">
        <div className="flex">
          <DatePick dateRange={dateRange} setDateRange={setDateRange} />
          <Space x={20} />
          <Select
            val={serviceNo}
            setVal={setServiceNo}
            dataList={modifyAllText(serviceList)}
          />

          <Space x={20} />
          <Button
            btnText={LANG[getLang()].search}
            size="md"
            onClick={() =>
              dispatch(
                levelCheck({
                  sl: "re02",
                  fuc: onSubmit,
                }),
              )
            }
            standardItem="re02"
          />
          <Space x={10} />
          <ExcelButton
            data={excelData}
            obj={EXCEL_OBJ}
            fileName={`${first} ~ ${second}별점`}
          />
        </div>
        <Space y={20} />

        <StarBar data={starLog.data || []} />
        <Space y={20} />
        <StarPieChart data={starLog.data || []} />
        <ChartStarModal obj={starHistoryList} />
        <Space y={20} />
        <StarTable data={starLog} setCsNo={setCsNo} />
      </div>
    </div>
  );
};

export default Starpoint;
