import Background from "Components/Atoms/Background/background";
import H2 from "Components/Atoms/H2/h2";
import Space from "Components/Atoms/Space/space";
import { modalToggle } from "Store/Slices/modal";
import { useAppDispatch } from "Store/store";
import { ReactComponent as ModalX } from "Util/svg/modalX.svg";
import Button from "Components/Atoms/Button/button";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import LANG, { getLang } from "Util/lang";
import { getConsultantDetailApi, putHolidayApi } from "Apis/apis";
import type { DatePickerProps, RangePickerProps } from "antd/es/date-picker";
import { useState } from "react";
const HolidayTimeModal = ({
  modalNum,
  csNo,
  setStartTime,
  setEndTime,
}: {
  modalNum: number;
  csNo: number;
  setStartTime: React.Dispatch<React.SetStateAction<string>>;
  setEndTime: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const dispatch = useAppDispatch();
  const { RangePicker } = DatePicker;
  const now = dayjs().format();

  const onChange = (
    value: DatePickerProps["value"] | RangePickerProps["value"],
    dateString: [string, string] | string,
  ) => {
    if (!dateString) return;
    const firstTime = +dayjs(dateString[0]).format("YYMMDDHH");
    const nowTime = +dayjs(now).format("YYMMDDHH");

    if (firstTime <= nowTime) {
      alert("현재시간 이후로 선택 해주세요");
      return;
    }
    setStartTime(dateString[0]);
    setEndTime(dateString[1]);
  };

  return (
    <Background modalNum={modalNum}>
      <div style={{ width: "640px" }} className="p-32 bg-f2f3f8">
        <div className="flex justify-between items-center">
          <H2 isPrimary={true} name={"휴가 기간 설정"} />

          <div
            className="inline-block cursor-pointer"
            onClick={() => dispatch(modalToggle(2))}
          >
            <ModalX />
          </div>
        </div>
        <Space y={20} />
        <div className="flex ">
          <RangePicker
            format="YYYY-MM-DD HH"
            showTime={{ hideDisabledOptions: true, format: "HH" }}
            onChange={onChange}
          />
          <Space x={20} />
          <Button btnText="설정" onClick={() => dispatch(modalToggle(2))} />
        </div>
      </div>
    </Background>
  );
};

export default HolidayTimeModal;
