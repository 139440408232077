import { Table } from "antd";
import Button from "Components/Atoms/Button/button";
import SearchSelect from "Components/Molecules/SearchSelect/searchSelect";
import React, { useEffect, useState } from "react";
import useServiceNo from "Util/Hooks/useServiceIdx";
import { ColumnsType } from "antd/es/table";
import Space from "Components/Atoms/Space/space";
import { useSelector } from "react-redux";
import { ReducerType } from "Store/store";
import { useLocation, useNavigate } from "react-router-dom";
import LANG, { getLang } from "Util/lang";
import { getEmailApi } from "Apis/apis";
import { EmailDesign } from "types/interface";

import Tag from "Components/Atoms/Tag/tag";

const Email = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const serviceList = useSelector((state: ReducerType) =>
    state.serviceList.filter((el) => el.state !== 2 && el.state !== 1),
  );
  const [serviceNo, setServiceNo] = useServiceNo();
  const [emailList, setEmailList] = useState<EmailDesign[]>([]);
  const onSubmit = () => {
    navigate("", {
      state: { page: 2, serviceNo: serviceNo },
    });
  };
  const getEmail = async () => {
    const { serviceNo = -1 } = location.state || {};
    setServiceNo(serviceNo);
    const {
      data: { mailDesign },
    } = await getEmailApi(serviceNo);
    setEmailList(mailDesign);
  };

  const columns: ColumnsType<EmailDesign> = [
    {
      title: "서비스명",
      dataIndex: "service_name",
      align: "center",
      width: "300px",
    },
    {
      title: LANG[getLang()].title,
      dataIndex: "title",
      align: "center",
      render: (now, row) => (
        <span
          onClick={() =>
            navigate("/user/emailfix", { state: row.mail_design_no })
          }
          className="cursor-pointer"
        >
          {now}
        </span>
      ),
    },
    {
      title: LANG[getLang()].state,
      dataIndex: "state",
      align: "center",
      width: "200px",
      render: (now) =>
        now ? (
          <Tag name="대기" type="ready" />
        ) : (
          <Tag name="승인" type="primary" />
        ),
    },
    {
      title: "배너 광고",
      dataIndex: "banner_count",
      align: "center",
      width: "100px",
      render: (now) => <span>{now}개</span>,
    },
    {
      title: LANG[getLang()].date,
      dataIndex: "date",
      align: "center",
      width: "300px",
    },
  ];

  useEffect(() => {
    getEmail();
  }, [location.state]);
  return (
    <div>
      <SearchSelect
        dataList={serviceList}
        onClick={onSubmit}
        setValue={setServiceNo}
        value={serviceNo}
      />
      <Space y={16} />
      <div className="max-w-7xl">
        <div className="flex justify-end">
          <Button
            btnText={`+ ${LANG[getLang()].register}`}
            onClick={() => navigate("/user/emailadd")}
          />
        </div>
        <Space y={24} />
        <Table
          dataSource={emailList}
          rowKey="mail_design_no"
          columns={columns}
        />
      </div>
    </div>
  );
};

export default Email;
