import { getPageDetailApi, putPageDetailApi } from "Apis/apis";
import Button from "Components/Atoms/Button/button";
import Space from "Components/Atoms/Space/space";
import InputRow from "Components/Molecules/InputRow/inputRow";
import InputRowImg from "Components/Molecules/InputRowImg/inputRowImg";
import InputRowRadio from "Components/Molecules/InputRowRadio/inputRowRadio";
import InputText from "Components/Molecules/InputText/inputText";
import { modalToggle } from "Store/Slices/modal";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { RadioForm } from "types/interface";
import LANG, { getLang } from "Util/lang";
import PreviewBannerModal from "Components/Molecules/PreviewService/previewBannerModal";

import PreviewLogoModal from "Components/Molecules/PreviewService/previewLogoModal";
import LabelQuill from "Components/Molecules/LabelQuill/labelQuill";
import PreviewExampleModal from "Components/Molecules/PreviewService/previewExampleModal";

const RADIO: RadioForm[] = [
  { idx: 0, label: "최적화형", id: "center" },
  { idx: 1, label: "비율고정형", id: "full" },
];

const ServiceFix = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [serviceName, setServiceName] = useState("");
  const [title, setTitle] = useState("");
  const [banner, setBanner] = useState<File>({} as File);
  const [bannerUrl, setBannerUrl] = useState("");
  const [logo, setLogo] = useState<File>({} as File);
  const [logoUrl, setLogoUrl] = useState("");
  const [originBanner, setOriginBanner] = useState("");
  const [originLogo, setOriginLogo] = useState("");
  const [content, setContent] = useState("");
  const [footer, setFooter] = useState("");
  const [privacyPolicy, setPrivacyPolicy] = useState("");
  const [screen, setScreen] = useState(1);

  const getDetail = async () => {
    const { serviceNo } = location.state || {};
    const {
      data: { servicePage },
    } = await getPageDetailApi(serviceNo);
    setServiceName(servicePage.service_name);
    setTitle(servicePage.page_name);
    setOriginBanner(servicePage.original_filename);
    setOriginLogo(servicePage.logo_original_filename);
    setBannerUrl(servicePage.service_banner);
    setContent(servicePage.service_description);
    setScreen(servicePage.screen);
    setLogoUrl(servicePage.service_logo);
    setFooter(servicePage.footer);
    setPrivacyPolicy(servicePage.privacyPolicy || "");
  };
  const pass = (): boolean => {
    if (title === "") {
      alert("고객 포털 이름을 입력해주세요.");
      return false;
    } else if (content === "") {
      alert("서비스 소개를 입력해주세요.");
      return false;
    } else if (footer === "") {
      alert("푸터를 입력해주세요.");
      return false;
    } else if (privacyPolicy === "" || privacyPolicy === "<p><br></p>") {
      alert("개인정보처리방침 내용을 입력해주세요.");
      return false;
    }
    return true;
  };

  const putPageDetail = async () => {
    const isPass: boolean = pass();
    if (!isPass) return;

    const { serviceNo } = location.state || {};
    const formData: any = new FormData();
    formData.append("pageName", title);
    formData.append("serviceDescription", content);
    formData.append("screen", screen);
    formData.append("sbanner", banner);
    formData.append("slogo", logo);
    formData.append("footer", footer || "");
    formData.append("privacyPolicy", privacyPolicy);
    await putPageDetailApi(serviceNo, formData);
    alert("수정이 완료되었습니다.");

    navigate("/service/potalsetting");
  };
  const preview = () => {
    dispatch(modalToggle(1));
  };
  const previewExample = () => {
    dispatch(modalToggle(3));
  };
  useEffect(() => {
    getDetail();
  }, []);
  useEffect(() => {
    setServiceName(location.state.serviceName);
  }, [location.state]);

  return (
    <div id="service_fix">
      <div>
        <InputText name="서비스 명" text={serviceName} width="w-140" />
        <InputRowImg
          id="banner_img"
          imgUrl={bannerUrl}
          name="서비스 배너"
          setImgUrl={setBannerUrl}
          setValue={setBanner}
          originName={originBanner}
          height="h-60"
          width="w-140"
        />
        <InputRowImg
          id="banner_img"
          imgUrl={logoUrl}
          name="서비스 로고"
          setImgUrl={setLogoUrl}
          setValue={setLogo}
          originName={originLogo}
          height="h-60"
          width="w-140"
        />
        <InputRow
          id="page_title"
          name="고객 포털 이름"
          setValue={setTitle}
          value={title}
          width="w-140"
        />
        <InputRow
          id="page_content"
          name="서비스 소개"
          setValue={setContent}
          value={content}
          maxLength={15}
          placeholder="띄어쓰기 포함 15글자 이내로 작성해주세요."
          width="w-140"
        />
        <LabelQuill
          id="footer"
          name="푸터"
          setText={setFooter}
          text={footer}
          width="w-140"
          require={false}
          placeHolder="Footer(하단바) 텍스트는 고객 포털 하단에 표시되는 텍스트 정보입니다.
주소, 대표 전화, 대표 이메일 등 기업의 주요 정보 및 공지사항을 Footer에 입력해보세요."
        />
        <div className="whitespace-pre-line">
          <LabelQuill
            id="privacy"
            name={`개인정보
          처리방침
          내용삽입`}
            setText={setPrivacyPolicy}
            text={privacyPolicy}
            width="w-140"
            placeHolder="개인정보 처리방침 입력"
          />
        </div>
        <div className="relative">
          <InputRowRadio
            data={RADIO}
            name="모바일 화면"
            setValue={setScreen}
            value={screen}
            width="w-140"
          />
          <div className="absolute top-0 pt-13 left-365">
            <Button btnText="예시보기" size="md" onClick={previewExample} />
          </div>
        </div>
        <div className="relative ">
          <InputText name="미리보기" text="" width="w-140" />
          <div className="absolute flex top-13 left-153">
            <Button btnText="미리보기" size="md" onClick={preview} />
            <Space x={10} />
          </div>
        </div>
      </div>
      <div className="flex justify-end mt-10">
        <Button btnText={LANG[getLang()].change} onClick={putPageDetail} />
        <Space x={10} />
        <Button
          btnText={LANG[getLang()].list}
          onClick={() => navigate("/service/potalsetting")}
        />
      </div>
      <PreviewBannerModal
        imgUrl={bannerUrl}
        screen={screen}
        title={title}
        footer={footer}
        modalNum={1}
      />
      <PreviewLogoModal
        content={content}
        modalNum={2}
        pageNmae={title}
        serviceNo={location.state.serviceNo}
        logoUrl={logoUrl}
      />
      <PreviewExampleModal modalNum={3} />
    </div>
  );
};

export default ServiceFix;
