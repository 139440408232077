import { useState, useEffect } from "react";

import Background from "Components/Atoms/Background/background";
import H2 from "Components/Atoms/H2/h2";
import InputRow from "../InputRow/inputRow";
import InputRowRadio from "../InputRowRadio/inputRowRadio";
import Button from "Components/Atoms/Button/button";
import Space from "Components/Atoms/Space/space";

import { ReactComponent as ModalX } from "Util/svg/modalX.svg";

import { useDispatch } from "react-redux";
import { modalToggle } from "Store/Slices/modal";

import { RadioForm, PutCategory } from "types/interface";
import { putCategoryApi } from "Apis/apis";
import LANG, { getLang } from "Util/lang";
import InputRowNumber from "../InputRow/inputRowNumber";

const STATE_DATA: RadioForm[] = [
  { idx: 1, label: LANG[getLang()].waiting, id: "wait" },
  { idx: 0, label: LANG[getLang()].approval, id: "ok" },
  // { idx: 2, label: LANG[getLang()].hide, id: "hidden" },
];
const MiddleCategoryFixModal = ({
  categoryCode,
  bigCategoryCode,
  bigCategoryName,
  middleCategoryName,
  categoryState,
  cateOrder,
  onSubmit,
}: {
  categoryCode: number;
  bigCategoryCode: number;
  bigCategoryName: string;
  middleCategoryName: string;
  categoryState: number;
  cateOrder: number;
  onSubmit: () => void;
}) => {
  const dispatch = useDispatch();

  const [middleName, setMiddleName] = useState(middleCategoryName);
  const [status, setStatus] = useState(categoryState);
  const [value, setValue] = useState(bigCategoryCode);
  const [bigName, setBigName] = useState(bigCategoryName);
  const [order, setOrder] = useState(cateOrder);

  const putMiddleCategory = async (stat?: number) => {
    const body: PutCategory = {
      bigCategoryCode: value,
      categoryName: middleName,
      state: stat || status,
      order: order,
    };
    await putCategoryApi(body, categoryCode);
    alert("수정이 완료되었습니다.");

    onSubmit();
    dispatch(modalToggle(4));
  };

  useEffect(() => {
    setMiddleName(middleCategoryName);
    setValue(bigCategoryCode);
    setStatus(categoryState);
    setBigName(bigCategoryName);
    setOrder(cateOrder);
  }, [middleCategoryName]);

  return (
    <Background modalNum={4}>
      <div style={{ width: "640px" }} className="p-32 bg-f2f3f8">
        <div className="flex items-center justify-between">
          <H2 isPrimary={true} name="중 카테고리 수정" />
          <div
            className="inline-block cursor-pointer"
            onClick={() => dispatch(modalToggle(4))}
          >
            <ModalX />
          </div>
        </div>
        <Space y={20} />

        <InputRow
          id="bigCategoryName"
          name={LANG[getLang()].lCategory}
          value={bigName}
          setValue={setBigName}
          disabled={true}
        />
        <InputRow
          id="service"
          name={LANG[getLang()].categoryName}
          value={middleName}
          setValue={setMiddleName}
        />
        <InputRowRadio
          data={STATE_DATA}
          name={LANG[getLang()].state}
          value={status}
          setValue={setStatus}
        />
        <InputRowNumber
          id="service"
          name="순서"
          value={order}
          setValue={setOrder}
        />
        <div className="flex justify-end mt-10">
          <Button
            onClick={() => putMiddleCategory(2)}
            btnText="삭제"
            color="delete"
          />
          <Space x={10} />
          <Button
            onClick={() => dispatch(modalToggle(4))}
            btnText={LANG[getLang()].cancel}
          />
          <Space x={10} />
          <Button
            onClick={() => putMiddleCategory()}
            btnText={LANG[getLang()].change}
          />
        </div>
      </div>
    </Background>
  );
};

export default MiddleCategoryFixModal;
