import React, { useState, useEffect } from "react";
import Space from "Components/Atoms/Space/space";
import Table, { ColumnsType } from "antd/es/table";
import Button from "Components/Atoms/Button/button";
import { getServiceIdListApi } from "Apis/apis";
import { ServiceIdList } from "types/interface";
import LANG, { getLang } from "Util/lang";
import { order } from "Util/function";
import { Link, useNavigate } from "react-router-dom";
import Tag from "Components/Atoms/Tag/tag";

const ServiceSetting = () => {
  const navigate = useNavigate();
  const [serviceAllList, setServiceAllList] = useState<ServiceIdList[]>([]);
  const getService = async () => {
    const {
      data: { serviceList },
    } = await getServiceIdListApi("");
    setServiceAllList(serviceList.filter((el) => el.state !== 2));
  };

  const url = window.location.host;

  const columns: ColumnsType<ServiceIdList> = [
    {
      title: LANG[getLang()].index,
      dataIndex: "service_no",
      align: "center",
      width: 100,
      render: (now, row) => {
        return order(serviceAllList, row.service_no, "service_no");
      },
    },
    {
      title: "서비스 로고",
      dataIndex: "service_logo",
      align: "center",
      width: 140,
      render: (now) => {
        const defalut = "../imgs/common/defaultService.png";
        return (
          <div className="flex justify-center">
            {now ? (
              <img src={now} className="w-80 h-80" alt="서비스 로고" />
            ) : (
              <img src={defalut} alt="서비스 로고" />
            )}
          </div>
        );
      },
    },
    {
      title: "서비스명",
      dataIndex: "service_name",
      align: "center",

      render: (now, row) => {
        return <span className="flex-1 text-right">{now}</span>;
      },
    },
    {
      title: "상태",
      dataIndex: "state",
      align: "center",
      render: (now) => {
        if (now === 0) return <Tag name="승인" />;
        else if (now === 1)
          return <Tag type="ready" name={LANG[getLang()].waiting} />;
        else if (now === 2)
          return <Tag type="ready" name={LANG[getLang()].hide} />;
      },
    },
    {
      title: "고객 포털 URL",
      dataIndex: "service_no",
      align: "center",
      width: 400,
      render: (now) => {
        return (
          <div className="flex flex-col items-center justify-center">
            <span>
              {url}/menu/{now}
            </span>
            <Space y={9} />
            <Link to={`/menu/${now}`} target="_blank">
              <Button btnText="바로가기" color="ready" size="sm" />
            </Link>
          </div>
        );
      },
    },
    {
      title: "설정",
      dataIndex: "support_mail",
      align: "center",
      width: 300,
      render: (now, row) => {
        return (
          <div className="flex justify-center">
            <Button
              btnText="설정"
              color="primary"
              size="sm"
              onClick={() =>
                navigate("/service/servicefix", {
                  state: { serviceNo: row.service_no },
                })
              }
            />
            <Space x={8} />
            <Button
              btnText="카테고리"
              color="primary"
              size="sm"
              onClick={() =>
                navigate("/service/category", {
                  state: {
                    serviceNo: row.service_no,
                    serviceName: row.service_name,
                  },
                })
              }
            />
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    getService();
  }, []);
  return (
    <div className="w-[1200px]">
      <Table
        dataSource={serviceAllList}
        columns={columns}
        rowKey="service_no"
      />
    </div>
  );
};

export default ServiceSetting;
