import Background from "Components/Atoms/Background/background";
import H2 from "Components/Atoms/H2/h2";
import Space from "Components/Atoms/Space/space";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { modalToggle } from "Store/Slices/modal";
import { ReactComponent as ModalX } from "Util/svg/modalX.svg";
import Button from "Components/Atoms/Button/button";
import { ReducerType } from "Store/store";
import Table, { ColumnsType } from "antd/es/table";
import { useLocation } from "react-router-dom";
import { StarList } from "types/interface";
import { useGetQDetailApi } from "Apis/apis";
import { QDetail } from "types/interface";
import AnswerHistoryList from "../AnswerHistory/answerHistory";
import { clearConfig } from "dompurify";

const ChartStarModal = ({ obj }: { obj: StarList[] }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  let star = "⭐";
  const [qIdx, setQidx] = useState(0);
  const [imgUrl, setImgUrl] = useState("");
  const { data } = useGetQDetailApi(qIdx);
  const queryQna = data?.qna;
  const [expandedRow, setExpandedRow] = useState<number | null>(null); // 현재 확장된 행의 인덱스를 상태로 관리합니다.
  const columns: ColumnsType<StarList> = [
    {
      title: "별점",
      dataIndex: "star",
      align: "center",
      render: (now) => {
        return star.repeat(now);
      },
    },
    {
      title: "평가",
      dataIndex: "memo",
      align: "center",
      render: (now) => now || "평가 내용이 없습니다.",
    },
    Table.EXPAND_COLUMN,
    {
      title: "문의 제목",
      dataIndex: "title",
      align: "center",
    },
    {
      title: "문의 번호",
      dataIndex: "qna_no",
      align: "center",
      width: 150,
    },
    {
      title: "유저UID / 닉네임",
      dataIndex: "user_id",
      align: "center",
      width: 200,
    },
    {
      title: "날짜",
      dataIndex: "date",
      align: "center",
      width: 200,
    },
  ];
  const modalClose = () => {
    setExpandedRow(null);
    return dispatch(modalToggle(location.state.modalNum));
  };
  const handleRowExpand = (expanded: boolean, record: StarList) => {
    setExpandedRow(expanded ? record.star_no : null); // 확장된 행이 열리거나 닫힐 때 현재 행의 인덱스를 업데이트합니다.
    setQidx(expanded ? record.qna_no : 0);
  };

  return (
    <Background modalNum={location.state.modalNum}>
      <div style={{ width: "1380px" }} className="p-32 bg-f2f3f8 ">
        <div className="flex justify-between">
          <H2 isPrimary={true} name="별점 문의 내역" />
          <div onClick={modalClose} className="inline-block cursor-pointer">
            <ModalX />
          </div>
        </div>
        <Space y={8} />
        <Table
          className="overflow-auto max-h-500"
          columns={columns}
          dataSource={obj}
          expandable={{
            expandedRowKeys: expandedRow !== null ? [expandedRow] : [], // 현재 확장된 행의 인덱스를 배열로 전달합니다.
            expandedRowRender: (record, idx) => {
              return (
                <div className="pointer-events-none ">
                  <AnswerHistoryList qna={queryQna || ({} as QDetail)} />
                </div>
              );
            },

            onExpand: handleRowExpand,
          }}
          rowKey={"star_no"}
        />
        <div className="flex justify-end mt-10">
          <Button onClick={modalClose} btnText="닫기" />
        </div>
      </div>
    </Background>
  );
};

export default ChartStarModal;
