import Label from "Components/Atoms/Label/label";
import Space from "Components/Atoms/Space/space";
import { ReactComponent as Require } from "Util/svg/require.svg";
import Radio from "Components/Atoms/Radio/radio";
import { ClickInputForm, RadioForm } from "types/interface";

/**
 * 로그인페이지 : ID PW 인풋창
 * @param {string} placeholder 정의어
 */
const InputRowRadio = ({
  data,
  name,
  value,
  setValue,
  require = true,
  width = "w-130",
}: {
  data: RadioForm[];
  name: string;
  value: number;
  setValue: React.Dispatch<React.SetStateAction<number>>;
  require?: boolean;
  width?: string;
}) => {
  return (
    <div className="flex items-center">
      <div className={`flex items-center px-16 py-20 bg-e5e6ed ${width}`}>
        <Label labelfor="dummy" name={name} fontSize={16} />
        <Space x={4} />
        {require && <Require />}
      </div>
      <div className="flex flex-1 h-64 bg-white p-13">
        <Radio value={value} setValue={setValue} name={name} dataList={data} />
      </div>
    </div>
  );
};

export default InputRowRadio;
