import Input from "Components/Atoms/Input/input";
import Label from "Components/Atoms/Label/label";
import Space from "Components/Atoms/Space/space";
import { ReactComponent as Require } from "Util/svg/require.svg";
import { ReactComponent as Check } from "Util/svg/check.svg";
import { ReactComponent as Fail } from "Util/svg/fail.svg";
import Button from "Components/Atoms/Button/button";
import { useDispatch } from "react-redux";
import { modalToggle } from "Store/Slices/modal";

/**
 * 로그인페이지 : ID PW 인풋창
 * @param {string} placeholder 정의어
 */
const InputRowPw = ({
  id,
  placeholder,
  name,
  value,
  setValue,
  require = true,
  condition,
}: {
  id: string;
  name: string;
  placeholder?: string;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  require?: boolean;
  condition: boolean;
}) => {
  const dispatch = useDispatch();

  const inputBox = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  return (
    <div className="flex items-center">
      <div className="flex items-center px-16 py-20 bg-e5e6ed w-130">
        <Label labelfor={id} name={name} fontSize={16} />
        <Space x={4} />
        <Require />
      </div>
      <div className="flex-1 h-full bg-white p-13">
        <div className="flex items-center">
          <div className="w-300">
            <Input
              id={id}
              placeholder={placeholder}
              value={value}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => inputBox(e)}
              type="password"
              required
            />
          </div>
          <div className="-ml-30">{condition ? <Check /> : <Fail />}</div>
        </div>
      </div>
    </div>
  );
};

export default InputRowPw;
